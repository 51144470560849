<template>
  <transition name="fade-in-slow">
    <div :id="'row-' + r2" v-if="visible" class="second-level-category-tile flex flex-col justify-end text-sm" style="">
      <a class="text-center pt-16 pb-8" 
        :href="$router.resolve(category.url).href.indexOf('?')==-1?
        $router.resolve(category.url).href + '/?p1=' + p1 + '&r1=' + r1 + '&p2=' + p2 + '&r2=' + r2  + (toolbrand!=''? '&toolbrand=' + toolbrand: '') :
        $router.resolve(category.url).href + '&p1=' + p1 + '&r1=' + r1 + '&p2=' + p2 + '&r2=' + r2  + (toolbrand!=''? '&toolbrand=' + toolbrand: '')">
        <img :src="category.imageUrl" class="mx-auto" style="max-width: 400px; max-height: 130px;"/>
      </a>

      <div class="grid grid-cols-12 items-center px-8 font-semibold mb-8" style="height:80px">
        <div class="col-span-1"></div>
        <div class="col-span-10 flex flex-col items-center flex-grow px-2 text-15 text-medium manrope">
          <router-link 
            :to="category.url.indexOf('?')==-1? category.url + '/?p1=' + p1 + '&r1=' + r1 + '&p2=' + p2 + '&r2=' + r2  + (toolbrand!=''? '&toolbrand=' + toolbrand: ''):
            category.url + '&p1=' + p1 + '&r1=' + r1 + '&p2=' + p2 + '&r2=' + r2 + (toolbrand!=''? '&toolbrand=' + toolbrand: '')" 
            class="text-dark-gray " 
            style="text-align:center">
            {{ category.name }}
          </router-link>
          <!--<span v-if="category.quantity>0">{{ category.quantity }} items</span>-->
        </div>
        <div class="col-span-1" style="position:relative">
          <img v-if="isFavorite" src="../../assets/svg/heart-filled.svg" class="gp-h-30 cursor-pointer gp-heart gp-heart-filled" @click="unmarkAsFavorite"/>
          <img v-else src="../../assets/svg/heart.svg" class="gp-h-30 cursor-pointer gp-heart" @click="markAsFavorite"/>
        </div>
      </div>

      <!--<div class="material-variations invisible font-semibold p-5 border-t-1 text-15 text-medium manrope" style="border-color: #212121;">
        <span class="text-darkblue mr-2">Application</span> <span class="text-gray-600">...</span>
      </div>
      <div class="material-variations invisible font-semibold p-5 border-t-1 text-15 text-medium manrope" style="border-color: #212121;">
        <span class="text-darkblue mr-2">Material</span> <span class="text-gray-600">...</span>
      </div>-->

    </div>
  </transition>
</template>

<script>
export default {
  name: "SecondLevelCategoryTile",
  inject: ['openDialog'],
  props: {
    category: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    },
    'r1': {
      type: Number,
      default: 0
    },
    'r2': {
      type: Number,
      default: 0
    },
    'p1': {
      type: Text,
      default: ''
    },
    'p2': {
      type: Text,
      default: ''
    },
    'toolbrand': {
      type: Text,
      default: ''
    }
  },
  computed: {
    isFavorite() {
      return this.$store.getters['categories/favorites'] && this.$store.getters['categories/favorites'].find(element => element.id == this.category.id) !== undefined;
    }
  },
  methods: {
    async markAsFavorite() {
      if (!this.$store.getters['auth/auth']) {
        this.openDialog({
          title: 'Ενημέρωση',
          html: 'Θα πρέπει να είστε συνδεδεμένοι για να προσθέσετε προϊόντα στη λίστα αγαπημένων σας'
        });

        return false;
      }

      try {
        await this.$store.dispatch('categories/addToFavourites', this.category.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async unmarkAsFavorite() {
      try {
        await this.$store.dispatch('categories/removeFromFavourites', this.category.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  created() {
    // console.log('SLC_Tile:');
    // console.log(this.category);
  }
}
</script>

<style scoped>

.second-level-category-tile {
  height: 345px;
  position: relative;
  /*width: 213px;*/
  background-color: transparent;
  background-image: url("../../assets/svg/landscape-octagon-white-white-fill.svg");
  background-size: calc(100%) 345px;
  background-repeat: no-repeat;
  margin-right:1px;
  margin-left:1px;
  margin-top:1px;
  margin-bottom:1px;
}
.second-level-category-tile:before {
    position:absolute;
    top:-1px;
    right:-1px;
    bottom:-1px;
    left:-1px;
    content:'';
    background-image: url(../../assets/svg/landscape-octagon-gray.svg);
    background-size: calc(100%) 347px;
    background-repeat: no-repeat;
    z-index:-1;
}
.second-level-category-tile:hover:before {
    background-image: url(../../assets/svg/landscape-octagon-degrade.svg);
}


.second-level-category-tile:hover {
  /*background-color: #F5F5E8;*/
}

.second-level-category-tile:hover .material-variations {
  visibility: visible;
}

.gp-heart {
  position:absolute;
  top:-18px;
  left:0px;
  display:none;
}

.second-level-category-tile:hover .gp-heart {
  display:block;
}

.gp-heart-filled {
  display:block;
}




</style>