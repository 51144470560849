let categories = [];
let fasteners = [];

for (let i = 1; i <= 20; i++) {
    categories[i - 1] = {
        id: i,
        name: 'Category #'+i,
        quantity: i*100,
        url: '/categories/'+i,
        imageUrl: 'https://via.placeholder.com/120x120',
    }

    fasteners[i - 1] = {
        id: i,
        name: 'Category #'+i,
        quantity: i*100,
        imageUrl: 'https://via.placeholder.com/100x100',
    }
}

let businessDetails = {
    countryOfIncorporation: 'Greece',
    legalName: 'Legal name',
    vat: '123456789',
    taxOffice: 'Tax Office',
    industry: 'Manufacturing',
    website: 'https://google.com',
}

let businessAddresses = {
    registeredAddress: {
        country: 'Greece',
        postcode: '11361',
        address: 'Stadiou 99',
        city: 'Athens',
        email: 'info@stadiou.gr',
        phone: '+302108856779',
    },
    branchAddress: {
        country: 'Greece',
        postcode: '15127',
        address: 'Patisiwn 99',
        city: 'Athens',
        email: 'info@patisiwn.gr',
        phone: '+302108255928',
    }
}

let contactDetails = {
    fullName: 'George Papadopoulos',
    role: 'CEO',
    branch: 'Scranton',
    email: 'gpapado@scranton.gr',
    phone: '+306944798116'
}

let transactions = [];

let rewards = [];

let categoryProducts = {
    name: 'Inox A2',
    descr: 'DIN 934-CL 10',
    imageUrl: 'https://tsamouris.gr/eshop/eShop/img/cat10.jpg',
    variations: [
        {
            name: '1-5',
            variations: [
                {
                    name: 1.1
                },
                {
                    name: 2
                },
                {
                    name: 3
                },
                {
                    name: 4
                },
                {
                    name: 5,
                }
            ]
        },
        {
            name: '5-10',
            variations: [
                {
                    name: 1.1
                },
                {
                    name: 20
                },
                {
                    name: 30
                },
                {
                    name: 40
                },
                {
                    name: 50,
                }
            ]
        },
        {
            name: '10-15',
            variations: [
                {
                    name: 100.1
                },
                {
                    name: 200
                },
                {
                    name: 300
                },
                {
                    name: 400
                },
                {
                    name: 500,
                }
            ]
        }
    ],
};

module.exports = {
    categories,
    fasteners,
    businessDetails,
    businessAddresses,
    contactDetails,
    transactions,
    rewards,
    categoryProducts: categoryProducts
}