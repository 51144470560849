<template>
  <the-navbar
      ref="navbar"
      v-if="!fullscreen" :class="{'sm:hidden': desktopFullscreen}"
      @show-mobile-menu-overlay="mobileMenuOverlayIsVisible = true"
  ></the-navbar>

  <!-- p-4 pb-8 -->
  <main class="overflow-x-hidden--xxx mobile-app-px" style="min-height: calc(100vh - 100px); /*max-width:1920px; margin:auto*/">
    <router-view ref="main-view"></router-view>
  </main>

  <the-footer ref="footer" v-if="!fullscreen" :class="{'hidden': desktopFullscreen}"></the-footer>

  <!-- hovering mobile menu [Shop|Filter|Account|Search|Box] -->
  <mobile-nav-menu v-show="mobileNavMenuIsVisible" ref="mobile-nav-menu" class="mobile-app-mx"></mobile-nav-menu>

  <transition name="slide-down-fast">
    <mobile-menu-overlay
        v-if="mobileMenuOverlayIsVisible"
        @close="mobileMenuOverlayIsVisible = false"
    ></mobile-menu-overlay>
  </transition>

<!--  <transition name="fade-in">
    <cart-overlay-panel
        v-if="cartOverlayPanelIsVisible"
        @close="hideCartOverlayPanel"
    ></cart-overlay-panel>
  </transition>-->

  <base-notification :open="notificationIsVisible"
                     :class="notificationProperties.class"
                     :position="notificationProperties.position"
                     :title="notificationProperties.title"
                     :life-span="notificationProperties.lifeSpan"
                     @hide-notification="hideNotification">
    <template #default>
      <div v-html="notificationProperties.html"></div>
    </template>
  </base-notification>

  <!--  <base-dialog :open="dialogIsVisible"
                 :class="dialogProperties.class"
                 :title="dialogProperties.title"
                 @close-dialog="closeDialog">
      <template #default>
        <div v-html="dialogProperties.html"></div>
      </template>
      <template #actions>
        <base-button :class="'btn-outline-'+dialogProperties.class" @click="closeDialog">
          OK
        </base-button>
      </template>
    </base-dialog>-->

  <tsamouris-dialog
      :open="dialogIsVisible"
      :class="dialogProperties.class"
      :title="dialogProperties.title"
      @close-dialog="closeDialog">
    <template #default>
      <div v-html="dialogProperties.html"></div>
    </template>
    <template #actions>
      <base-button class="bg-21 text-white rounded-xl text-lg" @click="closeDialog">
        OK
      </base-button>
    </template>
  </tsamouris-dialog>
</template>

<script>

import TheNavbar from "./components/layout/navbar/TheNavbar";
import TheFooter from "./components/layout/TheFooter";
import MobileMenuOverlay from "@/pages/filters/MobileMenuOverlay";
// import CartOverlayPanel from "@/components/cart/CartOverlayPanel";
import MobileNavMenu from "@/components/misc/MobileNavMenu";
import TsamourisDialog from "@/components/UI/TsamourisDialog";

export default {
  components: {
    TsamourisDialog,
    // CartOverlayPanel,
    MobileMenuOverlay,
    TheNavbar,
    TheFooter,
    MobileNavMenu,
  },
  provide() {
    return {
      openDialog: this.openDialog,
      showNotification: this.showNotification,
      feedDebugBar: this.feedDebugBar,
      enterFullscreen: this.enterFullscreen,
      exitFullscreen: this.exitFullscreen,
      enterDesktopFullscreen: this.enterDesktopFullscreen,
      exitDesktopFullscreen: this.exitDesktopFullscreen,

      // showCartOverlayPanel: this.showCartOverlayPanel,
      // hideCartOverlayPanel: this.hideCartOverlayPanel,

      clearAlgoliaSearch: this.clearAlgoliaSearch,
      getLastSearchType: this.getLastSearchType,
      setLastSearchType: this.setLastSearchType,

      isMobile: this.isMobile,
      prettyNum: this.prettyNum,
    }
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      fullscreen: false,
      desktopFullscreen: false,
      mobileMenuOverlayIsVisible: false,
      // cartOverlayPanelIsVisible: false,
      mobileNavMenuIsVisible: true,

      dialogIsVisible: false,
      dialogProperties: {
        class: 'dark',
        html: null,
      },
      notificationIsVisible: false,
      notificationProperties: {
        class: 'default',
        title: 'Default title',
        position: 'bottom-right',
        html: null,
      },
      debugBarIsOpen: false,
      debugBarPayload: {
        App: {
          auth: this.$store.getters['auth/auth'],
          authUser: this.$store.getters['auth/user'],
        },
      },
      // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),

      lastSearch: null, // algolia|arrow-filters
    }
  },
  methods: {
    switchLocale() {
      // new way
      if (this.$store.getters['app/locale'] === 'el') {
        this.$store.commit('app/setLocale', 'en');
        this.$i18n.locale = 'en';

        const redirectPath = '/en' + this.$route.fullPath;

        this.$router.base = 'xxx';
        this.$router.push(redirectPath);
      } else {
        this.$store.commit('app/setLocale', 'el');
        this.$i18n.locale = 'el';

        const redirectPath = this.$route.fullPath.replace('/en/', '/');

        this.$router.push(redirectPath);
      }
    },
    openDialog(properties) {
      if (typeof properties === 'object') {
        this.dialogProperties = properties;
      } else if (typeof properties === 'string') {
        this.dialogProperties = {
          class: 'dark',
          html: properties,
        };
      }

      this.dialogIsVisible = true;
    },
    closeDialog() {
      this.dialogIsVisible = false;
    },
    showNotification(properties) {
      if (typeof properties === 'object') {
        this.notificationProperties = properties;
      } else if (typeof properties === 'string') {
        this.notificationProperties = {
          class: 'default',
          html: properties,
        };
      }

      this.notificationIsVisible = true;
    },
    hideNotification() {
      this.notificationIsVisible = false;
    },
    toggleDebugBar() {
      this.debugBarIsOpen = !this.debugBarIsOpen;
    },
    closeDebugBar() {
      this.debugBarIsOpen = false;
    },
    feedDebugBar(payload) {
      this.debugBarPayload = {
        ...this.debugBarPayload,
        ...payload
      };

      this.debugBarIsOpen = true;
    },
    enterFullscreen() {
      this.fullscreen = true;
    },
    exitFullscreen() {
      this.fullscreen = false;
    },
    enterDesktopFullscreen() {
      this.desktopFullscreen = true;
    },
    exitDesktopFullscreen() {
      this.desktopFullscreen = false;
    },
    // showCartOverlayPanel() {
    //   this.cartOverlayPanelIsVisible = true;
    // },
    // hideCartOverlayPanel() {
    //   this.cartOverlayPanelIsVisible = false;
    // },
    clearAlgoliaSearch() {
      this.$refs.navbar.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');
    },
    getLastSearchType() {
      return this.lastSearchType;
    },
    setLastSearchType(lastSearchType) {
      // console.log('setting the last searchType to:', lastSearchType)
      this.lastSearchType = lastSearchType;
    },
    // clearArrowFilters() {
    //   this.$refs['main-view'].clearArrowFilters();
    // }
    isMobile() {
      return this.window.width <= 800;
    },
    prettyNum(input) {
      let roundedNum = Math.round(input * 100)/100;
      let formattedNum = roundedNum.toLocaleString('el-GR', {style: 'currency', currency: 'EUR'});

      // console.log(formattedNum);
      return '€' + formattedNum.replace(' €', '');
      // return roundedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  watch: {
    // all this is done to redirect the user in case
    // he gets auto-logged-out instead of staying in
    // an "auth-only" page
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/');
      }
    }
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters['auth/didAutoLogout'];
    }
  },
  // setup () {
  //   useMeta({
  //     title: '',
  //     htmlAttrs: { lang: 'en', amp: true }
  //   })
  // },
  mounted() {
    // init window width & height
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;

    this.$nextTick(() => {

    });

    window.addEventListener('scroll', () => {
      if (!this.$refs.footer) {
        return;
      }

      const viewportHeight = window.innerHeight;
      const footerTop = this.$refs.footer.$el.getBoundingClientRect().top;
      // const fixedMobileMenuTop = this.$refs['mobile-nav-menu'].$el.getBoundingClientRect().top;

      this.mobileNavMenuIsVisible = footerTop >= viewportHeight - 20;
    });

    window.addEventListener('resize', () => {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    })
  },
  async created() {
    const baseUrl = this.$store.getters['app/apiUrl'];
    const token = this.$store.getters['auth/auth'] ? this.$store.getters['auth/token'] : null;

    // console.log('App.vue token', token);
    // console.log('App.vue this.$store.getters[\'auth/auth\']', this.$store.getters['auth/auth']);

    this.$store.commit('app/configAxios', {
      baseUrl: baseUrl,
      token: token
    });

    // console.log('calling tryLogin');
    await this.$store.dispatch('auth/tryLogin');
    // console.log('returning from tryLogin');

    this.$i18n.locale = this.$store.getters['app/locale'];

    if (this.$store.getters['auth/auth']) {
      // console.log('App.vue -- 322 is truthy, loading items and favourites...');

      this.$store.dispatch('cart/loadItems');
      this.$store.dispatch('categories/loadFavourites');
    }
  }
};
</script>

<!--
    If the style here is not scoped, it will be applied throughout the whole app
-->

<!--
<style scoped>
  
  @media (max-width: 600px) {
  .mobile-app-px {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .footer{
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  }
  </style>-->
<style>

/*@font-face {
  font-family: PolySansTrial Bulky;
  src: url('assets/fonts/PolySansTrial-Bulky.otf');
}

@font-face {
  font-family: PolySansTrial BulkyItalic;
  src: url('assets/fonts/PolySansTrial-BulkyItalic.otf');
}

@font-face {
  font-family: PolySansTrial Median;
  src: url('assets/fonts/PolySansTrial-Median.otf');
}

@font-face {
  font-family: PolySansTrial MedianItalic;
  src: url('assets/fonts/PolySansTrial-MedianItalic.otf');
}

@font-face {
  font-family: PolySansTrial Neutral;
  src: url('assets/fonts/PolySansTrial-Neutral.otf');
}

@font-face {
  font-family: PolySansTrial NeutralItalic;
  src: url('assets/fonts/PolySansTrial-NeutralItalic.otf');
}

@font-face {
  font-family: PolySansTrial Slim;
  src: url('assets/fonts/PolySansTrial-Slim.otf');
}

@font-face {
  font-family: PolySansTrial SlimItalic;
  src: url('assets/fonts/PolySansTrial-SlimItalic.otf');
}*/

body {
  /* background-color: rgb(255, 252, 240);  */
  /** 255, 254, 242 */
  background-color:#F0F0F0;
  font-family: 'Manrope', sans-serif;
  color: #212121;
}

@media (max-width: 799px) {
  .mobile-app-px {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }


  .mobile-app-cancel-px {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }
}

hr {
  margin: 1rem 0;
}

.swiper-container-horizontal > .swiper-scrollbar {
  cursor: pointer;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
}

.swiper-scrollbar-drag {
  border-radius: 0 !important;
  height: 5px !important;
  top: -1.5px !important;
}
</style>
