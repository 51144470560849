module.exports = {
  labels: {
    'about': 'Σχετικά με μας',
    'account': 'Λογαριασμός',
    'account-details': 'Λεπτομέρειες Λογαριασμού',
    'add-to-order': 'Προσθήκη στην παραγγελία',
    'add-to-order-success-title': 'Συγχαρητήρια!',
    'add-to-order-success-message': 'Το προϊόν(τα προϊόντα) προστέθηκαν με επιτυχία στην παραγγελία σας.',
    'address': 'Διεύθυνση',
    'afm': 'ΑΦΜ',
    'artificial-intelligence-search': 'Έξυπνη αναζήτηση',
    'artificial-intelligent-search': 'Έξυπνη αναζήτηση',
    'ask-a-specialist': 'Ρωτήστε έναν ειδικό.',
    'available': 'Διαθέσιμα',
    'available-in-15-days': 'Διαθ. σε 15 ημέρες',

    'back': 'Πίσω',
    'become-a-member': 'Εγγραφή',
    'billing-details': 'Τρόπος πληρωμής',
    'bolt': 'Βίδα',
    'box': 'Καλάθι',
    'boxes': 'Κουτιά',
    'brand': 'Εταιρεία',
    'business-addresses': 'Διευθύνσεις εταιρείας',
    'business-details': 'Πληροφορίες εταιρείας',

    'cash-discount': 'Έκπτ. μετρητοίς',
    'change-language': 'Αλλαγή γλώσσας',
    'chat': 'Συνομιλήστε μαζί μας',
    'checkout': 'Ολοκλήρωση παραγγελίας',
    'choose-dimension1-here': 'Ή διαλέξτε διάμετρο από εδώ',
    'choose-dimension2-here': 'Ή διαλέξτε μήκος από εδώ',
    'city': 'Πόλη',
    'clear-all': 'Καθαρισμός φίλτρων',
    'close': 'Κλείσιμο',
    'code': 'Κωδικός',
    'company': 'Εταιρία',
    'comments': 'Σχόλια',
    'confirm-empty-cart': 'Ετοιμάζεστε να αδειάσετε όλο το κουτί. \nΌλα τα είδη που έχετε περάσει θα διαγραφούν',
    'contact': 'Επικοινωνία',
    'contact-name': 'Όνομα εκπροσώπου',
    'continue': 'Συνέχεια',
    'country': 'Χώρα',
    'courier': 'Courier',
    'credit': 'Λογαριασμός',
    'credit-status': 'Υπόλοιπο λογαριασμού',
    'customer-id': 'ID',
    'customer-code': 'Κωδικός πελάτη',
    'customer-name': 'Όνομα πελάτη',

    'date-placeholder': 'ηη/μμ/εεεε',
    'decrease-all': 'Ελαχιστοποίηση όλων',
    'default_payment_type': 'Προεπιλεγμένος τρόπος πληρωμής',
    'delivery': 'Παράδοση',
    'delivery-address': 'Διεύθυνση παράδοσης',
    'deposit': 'Κατάθεση',
    'description': 'περιγραφή',
    'discount': 'έκπτωση',
    'discount-short': 'Έκπτ.',
    'discount1': 'Έκπτ.1',
    'discount2': 'Έκπτ.2',
    'discount-eshop': 'Έκπτ. eshop',
    'discount_total': 'Συνολ.Έκπτ.',
    'discount_type_id': 'Κωδικός Ποσοτικής έκπτωσης',
    'document': 'Παραγγελία/Παραστατικό',
    'download-as-pdf': 'Λήψη σε μορφή pdf',

    'email': 'Email',
    'expand-all': 'Ανάπτυξη όλων',
    'eshop-discount': 'Εκπτ. eshop',
    'empty-cart': 'Αφαίρεση όλων',

    'fasteners-title': 'Βίδες \nΣυνδετικά Υλικά',
    'fasteners-description': 'Οτι θελετε να βρείτε \nσε 28.000 προϊόντα',
    'filter': 'Φίλτρο',
    'filters': 'Φίλτρα',
    'final-price': 'Τελ.Τιμή',
    'from': 'Από',

    'head-type': 'Είδος κεφαλής',
    'hide-all': 'Κλείσιμο όλων',
    'how-find-us': 'Πως μας βρήκατε;',

    'i-am-a': 'Είμαι',
    'in-detail': 'Αναλυτικά',
    'in-store-pick-up': 'Παραλαβή από το κατάστημα',
    'initial-price': 'Αρχ.Τιμή',
    'invoice-address': 'Διεύθυνση τιμολόγησης',
    'invoice-city': 'Πόλη',
    'invoice-perfecture': 'Νομός',
    'invoice-zipcode': 'ΤΚ',
    'invoices': 'Τιμολόγια',
    'items': 'προϊόντα',

    'length': 'Μήκος',
    'legal-name': 'Επωνυμία επιχείρησης',
    'less': 'Λιγότερα',
    'limited-availability': 'Μη διαθέσιμο',
    'load-more': 'Περισσότερα',
    'logout': 'Αποσύνδεση',

    'm-u': 'μ.τιμ.',
    'material': 'Υλικό',
    'message': 'Μήνυμα',
    'method-of-shipping': 'Τρόπος αποστολής',
    'metrics': 'Διαστάσεις',
    'more': 'Περισσότερα',
    'my-account': 'Ο λογαριασμός μου',
    'my-profile': 'Το προφίλ μου',

    'need-help': 'Χρειάζεστε βοήθεια;',
    'next': 'ΕΠΟΜ',
    'not-registered': 'Δεν έχετε κάνει εγγραφή;',

    'orders-and-invoices': 'Παραγγελίες και παραστατικά',
    'order-code': 'Κωδ. παραστατικού',
    'order-date': 'Ημ/νία παραστατικού',
    'order-status': 'Κατάσταση',

    'package': 'Συσκ.',
    'packages': 'Συσκ.',
    'password': 'Password',
    'price': 'Αξία',
    'price_list_id': 'Κωδ. τιμοκαταλόγου',
    'pcs': 'τεμ',
    'pcs-per-package': 'Τεμ/Συσκ',
    'per': 'ανά',
    'per-box': 'ανά συσκ.',
    'please-wait': 'Παρακαλώ περιμένετε...',
    'phone': 'Τηλέφωνο',
    'previous': 'ΠΡΟΗΓ',
    'product_view_all_dim': 'Εμφάνιση όλων των διαστάσεων',
    'product-not-available': 'Το προϊόν δεν έχει την ποσότητα που ζητάτε. \nΑν θέλετε επιλέξτε μικρότερη ποσότητα',
    'profession': 'Επάγγελμα',
    'profile_': 'Προφίλ',
    'profile': 'Το προφίλ μου',

    'quantity-not-available': 'Μη διαθέσιμη ποσότητα',

    'recently-ordered': 'Παραγγείλατε πρόσφατα',
    'redeem': 'Εξαργύρωση',
    'register': 'Εγγραφή',
    'remain': 'Υπόλοιπο',
    'remittance-in-advance': 'Προέμβασμα',
    'remove': 'Αφαίρεση',
    'request-quotation': 'Αίτηση προσφοράς',
    'results': 'Αποτελέσματα',
    'return-back': 'Πίσω',
    'reward': 'Έκπτ. eshop (3%)',
    'reward-discount': 'Εκπτ. rewards',
    'rewards': 'Rewards',
    'rfq': 'RFQ',

    'sales': 'πωλήσεις',
    'saved-items': 'Αποθηκευμένα προϊόντα',
    'search': 'Αναζήτηση',
    'see-all-items': 'Δείτε όλα τα προϊόντα',
    'selected-filters': 'Επιλεγμένα Φίλτρα',
    'seller_id': 'Κωδ. Πωλητή',
    'shipping-address': 'Διεύθυνση αποστολής',
    'shipping-city': 'Πόλη',
    'shipping-perfecture': 'Νομός',
    'shipping-zipcode': 'ΤΚ',
    'show-all': 'Δείτε όλα τα προϊόντα',
    'show-more': 'Δείτε περισσότερα',
    'show-results': 'Εμφάνιση αποτελεσμάτων',    
    'sign-in': 'Είσοδος',
    'spec': 'specs',
    'site_id': 'ID',
    'site_code': 'Κωδικός',
    'site_description': 'Περιγραφή',
    'specs': 'Προδιαγραφές',

    'tax-dept': 'ΔΟΥ',
    'tax-office': 'ΔΟΥ',
    'technical-specs': 'Τεχνικές Προδιαγραφές',
    'to': 'Προς',
    'total': 'Σύνολο',
    'total-final': 'Τελ. σύνολο (χωρίς ΦΠΑ)',
    'total-line': 'Σύνολο',
    'total-price': 'Συνολική τιμή',
    'total-price-short': 'Συν. τιμή',
    'total-to-pay': 'Σύνολο Πληρωτέο',
    'total-w-out-vat': 'Σύνολο χωρίς ΦΠΑ',
    'total-w-vat': 'Σύνολο με ΦΠΑ',
    'transaction-type': 'Τύπος',
    'transactions': 'Οι συναλλαγές μου',
    'type': 'Τύπος',
    'type-dimension1-here': 'Εισάγετε διάμετρο',
    'type-dimension2-here': 'Εισάγετε μήκος',
    'type-here': 'Εισ. ποσότητας',

    'undo-discount': 'Αναίρεση έκπτωσης',
    'undo-redeem': 'Αναίρεση εξαργύρωσης',
    'units': 'Μονάδες',
    'use-discount': 'Χρήση έκπτωσης',
    'username': 'Username',

    'vat': 'ΦΠΑ',
    'vat-nr': 'ΑΦΜ',
    'vat_nr': 'ΑΦΜ',
    'vat-excluded': 'Εξαιρείται από ΦΠΑ',
    'vat-zone': 'Ζώνη ΦΠΑ',
    'view-all': 'Άνοιγμα όλων',

    'what-are-you-looking-for': 'Τι ψάχνετε;',
    'width-diameter' : 'Διάμετρος/Πλάτος',

    'you-also-need': 'Με αυτό το προϊόν μπορεί να χρειαστείτε',

    'zipcode': 'Τ.Κ.'
  },
  messages: {
    'auth-failed': 'Τα στοιχεία που δώσατε δεν είναι σωστά',
    'create-an-account': 'Δημιουργήστε λογαριασμό συμπληρώνοντας τα στοιχεία σας',
    'email-is-not-valid': 'Το email σας δεν είναι έγκυρο',
    'email-must-be-filled': 'Παρακαλώ συμπληρώστε το email σας',
    'enter-code-from-email': 'Εισάγετε τον κωδικό που ήρθε στο email σας',
    'password-must-be-filled': 'Παρακαλώ καταχωρήστε το password',
    'sign-into-your-account': 'Συνδεθείτε στο λογαριασμό σας',
    'you-have-to-login': 'Θα πρέπει να συνδεθείτε', 
    'you-have-purchased-x-fasteners-or-y-boxes': 'Έχετε αγοράσει {f} βίδες ή {b} κουτιά',
    'you-have-x-virtual-fasteners': 'Έχετε {vf} εικονικές βίδες για να χρησιμοποιήσετε ως έκπτωση',
  }
}