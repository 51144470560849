<template>
  <div class="" v-html="liveHtml"/>
</template>

<script>
export default {
  name: "RemoteContent1",
  data() {
    return {
      html: '',
    }
  },
  computed: {
    liveHtml() {
      return this.html;
    }
  },
  async created() {
    let response = await this.$store.getters['app/axios'].get('remote-content-1.php')
    this.html = response.data;
  }
}
</script>

<style scoped>

</style>