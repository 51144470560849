<template>

  <arrow-filters style="margin-bottom: 10px;"/>

    <div class="my-main" style="overflow:hidden">
        <!-- <div class="flex" style="position:relative">
            <div class="logo-kg" style="">
            <router-link class="logo-link--" to="/" style="">
                <img v-if="$store.getters['app/locale'] === 'el'" src="../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
                <img v-if="$store.getters['app/locale'] === 'en'" src="../assets/svg/logo-en.svg" class="gp-logo" style=""/>
            </router-link>
            </div>

        </div> -->

        <div class="page-container" >
            <div v-if="$store.getters['app/locale'] === 'el'" class="page-text">
                <div>
                    <h1 class="h1">Instant Order</h1>
                    <div class="h2">Καλωσήρθατε στο <br/>Tsamouris Instant Order <sup>&copy;</sup></div>

                    <img class="img-instant-order-mobile" src="../assets/images/instant-order.png" />

                    <div class="h3">Κωδικός Είδους + Ποσότητα</div>                    

                    <div class="text">
                        Εδώ μπορείτε πολύ γρήγορα και απλά, αν γνωρίζετε τους κωδικούς των ειδών που θέλετε να παραγγείλετε να τους γράψετε (ή να τους κάνετε copy - paste) να προσθέσετε την ποσότητα του κάθε είδους και κατευθείαν να παραγγείλετε χωρίς να χρειαστεί να μπείτε σε ένα - ένα όλα τα είδη. Ταχύτητα και ευελιξία για εσάς.
                    </div>
                    <div v-if="user_login">
                        <router-link to="instant-order" class="my-link">Item Code <img style="vertical-align: middle;display:inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                    </div>
                    <div v-else class="desktop-only">
                        <div style="font-size: 18px; margin-bottom:20px">
                            Για να χρησιμοποιήσετε τις δυνατότητες του Instant Order πρέπει να κάνετε Log In.
                        </div>
                        <div>
                            <router-link to="/login/?redirect=/instant-order-main" class="my-link">Login <img style="vertical-align: middle;display:inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                        </div>
                        
                    </div>
                </div>

                <div class="grid-col-2">
                    <div class="h1">&nbsp;</div>
                    <div class="h2">&nbsp;<br/>&nbsp;</div>
                    <div class="h3">Μαζική εισαγωγή με Excel</div>
                    <div class="text">
                        Άμεση παραγγελία πολλών προϊόντων με ταχύτητα. Εδώ μπορείτε πολύ γρήγορα και απλά, να ανεβάσετε ένα αρχείο Excel με τα είδη και τις ποσότητες και να ενημερώσετε άμεσα το καλάθι σας. Ο πιο γρήγορος τρόπος μαζικής εισαγωγής προϊόντων στο καλάθι.
                    </div>
                    <div v-if="user_login">
                        <router-link to="/upload-cart-items" class="my-link">Μαζική εισαγωγή <img style="vertical-align: middle;display: inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                    </div>
                    <div v-else class="mobile-only">
                        <div style="font-size: 18px; margin-bottom:20px">
                            Για να χρησιμοποιήσετε τις δυνατότητες του Instant Order πρέπει να κάνετε Log In.
                        </div>
                        <div>
                            <router-link to="/login/?redirect=/instant-order-main" class="my-link">Login <img style="vertical-align: middle;display:inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                        </div>
                        
                    </div>
                    
                </div>

            </div>

            <div v-if="$store.getters['app/locale'] === 'en'" class="page-text">
                <div>
                    <h1 class="h1">Instant Order</h1>
                    <div class="h2">Welcome to <br/>Tsamouris Instant Order <sup>&copy;</sup></div>
                    <div class="h3">Item Code + Quantity</div>
                    <div class="text">
                        Instantly order multiple products with great speed. Here you can very quickly and simply - if you know the codes of the items you want to order - write them down (or copy-paste them) add the quantity of each item and directly order without having to enter one by one the products. Speed and flexibility for your convenience.
                    </div>
                    <div v-if="user_login">
                        <router-link to="instant-order" class="my-link">Item Code <img style="vertical-align: middle; display: inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                    </div>
                    <div v-else class="desktop-only">
                        <div style="font-size: 18px; margin-bottom:20px">
                            In order to use the features of Instant Order you have to login.
                        </div>
                        <div>
                            <router-link to="/login/?redirect=/instant-order-main" class="my-link">Login <img style="vertical-align: middle; display: inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                        </div>
                        
                    </div>
                </div>

                <div class="grid-col-2">
                    <div class="h1">&nbsp;</div>
                    <div class="h2">&nbsp;<br/>&nbsp;</div>
                    <div class="h3">Bulk Import with XL file</div>
                    <div class="text">
                        Instantly order multiple products at speed.<br/>
                        Here you can very quickly and simply upload an Excel file with the items and quantities and update your cart. Speed and flexibility for your convenience. The fastest way to bulk add products to the cart.
                    </div>
                    <div v-if="user_login">
                        <router-link to="/upload-cart-items" class="my-link">Bulk Import <img style="vertical-align: middle;" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                    </div>
                    <div v-else class="mobile-only">
                        <div style="font-size: 18px; margin-bottom:20px">
                            In order to use the features of Instant Order you have to login.
                        </div>
                        <div>
                            <router-link to="/login/?redirect=/instant-order-main" class="my-link">Login <img style="vertical-align: middle; display: inline-block" src="../assets/svg/arrow-right-2024.svg" /></router-link>
                        </div>
                        
                    </div>
                </div>

            </div>

            <div style="position:relative">
                <img class="img-instant-order" src="../assets/images/instant-order.png" />
            </div>

        </div>
    </div>
    




</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";
export default {
  name: "InstantOrderMain",
  components: {ArrowFilters},
  inject: ['enterDesktopFullscreen', 'exitDesktopFullscreen', 'openDialog'],
  data() {
    return {
      
    };
  },
  computed: {
    auth() {
      return this.$store.getters['auth/auth'];
    },
    user_login() {
      if (this.$store.getters['auth/auth']) {
        return true;
      }
      else {
        return false;
      }
    }

  },
  beforeMount() {
    // this.enterDesktopFullscreen();
    //this.enterFullscreen();
  },
  beforeUnmount() {
    // this.exitDesktopFullscreen();
    //this.exitFullscreen();
  },
}

</script>

<style scoped>
  
  
  
  

  .logo-container {
    padding-left:96px; padding-top:64px;
  }

  @media (max-width:1400px) {
    .logo-container {
      padding-left:5px; 
      padding-top:37px;
    }
  }

  @media (max-width:1200px) {
    .logo-container {
      padding-left:5px; 
      padding-top:48px;
    }
  }

  

  .gp-login-container {
    padding-left:110px;
  }

  @media (max-width:1400px) {
  .gp-login-container {
    padding-left:88px;
  }  
  }
  @media (max-width:1200px) {
  .gp-login-container {
    padding-left:50px;
  }  
  }

  @media (max-width:799px) {
  .gp-login-container {
    padding-left:0px;
  }  
  }



  /** */
  .logo-container-abs {
    position:absolute; 
    top:64px; 
    left:96px;
    width:225px;
    /*z-index:9999;*/
  }
  @media (max-width:1600px) {
    .logo-container-abs {
      top:42px; 
      left:96px
    }
  }
  @media (max-width:1400px) {
    .logo-container-abs {
      top:37px; 
      left:50px
    }
  }
  @media (max-width:1300px) {
    .logo-container-abs {
      top:36px; 
      left:50px;
      width:226px;
    }
    .gp-logo {
      width:226px;
    }
  }
  @media (max-width:1200px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

/** ipad 810*1080 - Landscape */
  @media (width:1080px) {
    .logo-container-abs {
      top:42px; 
      left:50px;
      width:210px;
    }
    .gp-logo {
      width:211px;
    }
  }

  @media (max-width:1079px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

  @media (max-width:1000px) and (min-width:811px) {
    .logo-container-abs {
      top:30px; 
      left:49px;
      width:201px;
    }
    .gp-logo {
      width:201px;
    }
  }
  /** ipad 810*1080 Portrait */
  @media (width:810px) {
    .logo-container-abs {
      top:23px; 
      left:50px;
      width:200px;
    }
    .gp-logo {
      width:202px;
    }
  }
  

  @media (max-width:809px) {
    .logo-container-abs {
      top:60px; 
      left:20px;
      width:197px;
    }
    .gp-logo {
      width:197px;
    }    
  }

  @media (max-width:799px) {
    .logo-container-abs {
      display:none;
    }    
  }

.close_windows_btn { 
  position:absolute; bottom:50px;left:140px; text-decoration:underline; 
}
@media (max-width:1400px) {
  .close_windows_btn { 
    left:120px; 
  }
}

.link-back {
  margin-left:33px;
  text-decoration: underline;
  bottom:85px;
}



/** GG */ 

  @media (max-width:1200px) {
    .close_windows_btn {
      left: 7%;
    }
  }
  @media (max-width:820px) {
    .close_windows_btn {
      left: 10%;
      bottom: 80px;
    }
  }

  @media (max-width:420px) {
    .itms-cntr{justify-content: center !important; align-items: center;}
    #step-counter{top:unset !important;}
    .close_windows_btn {
      bottom: -80px;
      right: 0;
      left:unset;
      font-size: 13px;
    }
    .text-15 {
      font-size: 13px;
    }
    .form-control > input {font-size:15px; padding-left:16px;}
    .form-control > button {font-size:15px;}
    .text-15-mobile {margin-left:16px !important;}
    .hdr-mobile {padding-left:16px !important; margin-top: 55px !important; margin-bottom:22px !important;}
    .rtrn-back {bottom: -80px !important;}
  }

  @media (max-width: 380px) {
    .close_windows_btn {
        bottom: 100px;
    }
    .rtrn-back {
      bottom: 100px !important;
  }
  }







    .page-container {
        min-height:100vh; 
        background-color:#ff4e00;
        color:#fff; 
        /* margin-top:220px; */
        margin-top:0px;
        display:grid;
        grid-template-columns: 55% 45%;
        width:100%;
        padding-left:170px;
        padding-bottom:100px;
    }
    @media (max-width:1600px) {
      .page-container {
        padding-left:110px;
        /* margin-top:160px; */
        margin-top:0px;
      }
    }
    @media (max-width:1200px) {
      .page-container {
        padding-left:50px;
        /* margin-top:150px; */
        margin-top:0px;
        
      }
      .my-main {
        margin-left:-20px;
        margin-right: -20px;
        width:calc(100% + 40px);
      }
    }
    @media (max-width:800px) {
      .page-container {
        /* padding-left:20px;
        padding-right:20px; */
        margin-top:50px;
        /* grid-template-columns: 100%;         */
      }      
    }
    @media (max-width:600px) {
      .page-container {
        padding-left:20px;
        padding-right:20px;
        margin-top:50px;
        grid-template-columns: 100%;        
      }      
    }

    .img-instant-order {
        position:absolute;
        /* top:-170px; */
        top:-30px;
        left:-40px;
        max-width:700px;
    }
    @media (max-width:1600px) {
      .img-instant-order {
        position:absolute;
        /* top:-140px; */
        top:-20px;
        left:-80px;
        max-width:560px;
      }
    }
    @media (max-width:600px) {
      .img-instant-order {
        display:none;
      }
    }
    .img-instant-order-mobile {
      display:none;
    }
    @media (max-width:600px) {
      .img-instant-order-mobile {
        display:block;
        margin-bottom:50px;
        max-width:95%;
        margin-top: -90px;
        margin-left:-5%;
      }
    }

    .page-text {
        display:grid;
        grid-template-columns: 50% 1fr;
        gap:50px;
    }
    @media (max-width:1200px) {
      .page-text {
          grid-template-columns: 100%;
      }
    }

    .page-text .h1 {
        font-size:33px;
        font-weight:600;
        /* margin-bottom:120px; */
        margin-bottom:100px;
        /* margin-top:40px; */
        margin-top:20px;
    }
    .page-text .h2 {
        font-size:33px;
        line-height:41px;
        font-weight:600; 
        /* margin-bottom:180px; */
        margin-bottom:160px;
        width:150%;
    }
    .page-text .h3 {
        font-size:18px;
        font-weight:600;
        margin-bottom:35px;
    }
    .page-text .text {
        font-size:15px;
        font-weight:500;
        /* margin-bottom:90px; */
        margin-bottom:80px;
    }

    @media (max-width:1600px) {
      .page-text .h1 {
        margin-bottom:80px;
        margin-top:20px;
        font-size:28px;
      }
      .page-text .h2 {
        margin-bottom:120px;
        font-size:28px;
        line-height:32px;
      }
      .page-text .h3 {
        margin-bottom:30px;
      }
      .page-text .text {
        margin-bottom:60px;
      }
    }
    @media (max-width:1200px) {
      .grid-col-2 .h1,
      .grid-col-2 .h2 {
        display:none;
      }
      .page-text .h2 {
        width:100%;
      }
    }

    .my-link img {
      transition:all 0.5s;
    }
    .my-link:hover img {
      translate:15px 0px;
    }

    .mobile-only {
      display:none;
    }
    @media (max-width:1200px) {
      .mobile-only {
        display:block;
      }
      .desktop-only {
        display:none;
      }
    }

</style>