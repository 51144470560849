<template>
  <div class="flex" style="position:relative">
    
    <div class="logo-kg" style="">
      <router-link class="logo-link--" to="/" style="">
        <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/> -->
        <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
        <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>

    <div class="close_windows_btn" style="">
      <router-link class="logo-link--" to="/" style="">close window</router-link>
    </div>
    
    <div class="logo-container-abs" style="">
      <router-link class="" to="/">
          <img src="../../assets/svg/logo-en.svg" class="gp-logo"/>
        </router-link>
    </div>
    
    <div class="flex flex-1 flex-col items-center justify-center-- bd-mob">
      <!-- Logo -->
      <div class="hidden sm:flex self-start justify-center logo-container w-48 sm:w-80">
        <!--<router-link class="" to="/">
          <img src="../../assets/svg/logo-en.svg" class="gp-logo"/>
        </router-link>-->
      </div>

      <div class="flex flex-col justify-center w-full h-full-- mx-auto sm:mr-auto 
        sm:pl-24-- sm:pr-8 gp-login-container" :class="{'mb-64': !thirdStep, 'mb-16': thirdStep}">
        <div class="itms-cntr flex items-baseline max-w-md-xx hdr-mobile" 
        style="padding-left: 33px; margin-bottom: 45px; margin-top: 110px; position:relative; max-width:560px">
          <h1 id="header" class="flex-grow text-33 gp-sm-txt-24 text-semibold" style="/*line-height:30px*/">
            {{ header }}
          </h1>
          <h1 id="step-counter" class="flex-shrink font-semibold text-lg">
            {{ step }}/5
          </h1>
        </div>

        <form @submit.prevent="" v-if="firstStep || secondStep" id="registration-form" class="max-w-md" style="margin-bottom: 25px;">
          <div v-if="firstStep" class="form-control">
            <input v-model="email" type="email" class="text-21 border-21 border-b-0 manrope" placeholder="email address"/>
            <button type="button" class="manrope" @click="nextStep">{{ $t('labels.continue') }}</button>
          </div>
          <div v-if="secondStep" class="form-control">
            <input v-model="password" type="password" class="text-21 border-21 border-b-0 manrope" placeholder="password"/>
            <button type="button" class="manrope" @click="nextStep">{{ $t('labels.continue') }}</button>
          </div>
        </form>

        <div v-if="thirdStep" class="max-w-md-xx overflow-y-auto pr-2" id="account-details-form-wrapper" style="max-width:570px">
          <form id="account-details-form" 
            class="manrope w-full border-1 rounded-xl mb-4" 
            @submit.prevent="">
            <div v-for="prop in accountDetails" :key="prop.label" class="form-control" 
              :style="prop.shipping_field && hideShippingFields? 'display:none': 
              prop.field_type == 'checkbox'? 'border-bottom:none': '' "                            
              >
              <div v-if="prop.field_type == 'checkbox'" class="p-4 sm:p-8" style="padding-left:33px">
                <label style="margin-bottom:10px; display:grid">Η διεύθυνση αποστολής είναι διαφορετική από τη διεύθυνση τιμολόγησης</label>
                <input  type="checkbox" v-model="prop.val"  @click="toggleShippingFields" />
              </div>
              <input v-else v-model="prop.val" class="p-4 sm:p-8" style="padding-left:33px" :placeholder="prop.label"
                 />
              <!--<div>{{prop.keyindex}}</div>-->
            </div>
          </form>

          <div class="text-right">
            <button type="button" 
              class="border-1 rounded-xl px-12 py-4 font-semibold bg-21 text-white cont-btn"
              style="margin-bottom:20px"
              @click="nextStep">
              {{ $t('labels.ok') }}
            </button>
          </div>
        </div>

        <div v-if="fourthStep && industryTypesLoaded" 
          class="grid grid-cols-2 gap-4-xx max-w-md"
          style="margin-bottom:46px">
          <div
              v-for="industry in industryTypes" :key="industry.id"
              class="industry-type-tile flex items-center justify-center text-center-xx py-4 px-8 rounded-xl-xx cursor-pointer font-medium text-xl border-1-xx border-21-xx"
              style="--min-width: 250px; min-height: 100px;"
              :class="{selected: industry.description == industryType}"
              @click="setIndustryType(industry.description)"
          >
            {{ industry.description }}
          </div>

          <div class="col-span-2 text-right"> 
            <button type="button" 
              class="border-1 rounded-xl px-12 py-4 font-semibold bg-21 text-white"
              style="margin-top:20px; margin-bottom:20px"
              @click="nextStep">
              {{ $t('labels.continue') }}
            </button>
          </div>

          
        </div>

        <div v-if="fifthStep && leadOriginsLoaded" 
          class="grid grid-cols-2 gap-4-xx max-w-md"
          style="margin-bottom:46px">
          <div
              v-for="leadorigin in leadOrigins" :key="leadorigin.id"
              class="industry-type-tile flex items-center justify-center text-center py-4 px-8 rounded-xl-xx cursor-pointer font-medium text-xl border-1-xx border-21-xx"
              style="--min-width: 250px; min-height: 100px;"
              :class="{selected: leadorigin.description == leadOrigin}"
              @click="setLeadOrigin(leadorigin.description)"
          >
            {{ leadorigin.description }}
          </div>

          <div class="col-start-1 col-span-2 text-right">
            <button type="button" class="w-full border-1 rounded-xl px-12 py-4 font-semibold bg-21 text-white" 
              @click="register"
              style="margin-top:20px;">
              {{ $t('labels.register') }}
            </button>
          </div>
        </div>

        <p v-if="firstStep" class="text-16 gp-sm-txt-13 text-medium max-w-md text-16-mobile" 
          style="padding-left: 33px; padding-right:15px">
          {{ $t('messages.create-an-account') }}
        </p>

        <p v-if="!firstStep" class="ml-4-xx cursor-pointer text-15 gp-sm-txt-13 text-medium rtrn-back link-back" 
          style="" @click="previousStep">
          {{ $t('labels.return-back') }}
        </p>

      </div>
    </div>

    <div id="registration-right-panel" class="hidden sm:flex flex-1 min-h-screen" style=""></div>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  inject: ['enterDesktopFullscreen', 'exitDesktopFullscreen', 'openDialog', 'showNotification'],
  //inject: ['enterFullscreen', 'exitFullscreen', 'openDialog', 'showNotification'],
  data() {
    return {
      step: 1, /* 1: email, 2: password, 3: account-details, 4: company-type */
      email: null,
      password: null,
      hideShippingFields: true,
      accountDetails: {
        legalName: {
          val: null,
          label: this.$t('labels.legal-name'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        contactName: {
          val: null,
          label: this.$t('labels.contact-name'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        phone: {
          val: null,
          label: this.$t('labels.phone'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        vatNr: {
          val: null,
          label: this.$t('labels.vat-nr'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        taxOffice: {
          val: null,
          label: this.$t('labels.tax-office'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        profession: {
          val: null,
          label: this.$t('labels.profession'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        invoiceAddress: {
          val: null,
          label: this.$t('labels.invoice-address'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        invoiceCity: {
          val: null,
          label: this.$t('labels.invoice-city'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        invoicePerfecture: {
          val: null,
          label: this.$t('labels.invoice-perfecture'),
          field_type:'text',
          shipping_field: false,
          mandatory: true
        },
        invoiceZipcode: {
          val: null,
          label: this.$t('labels.invoice-zipcode'),
          field_type:'text',
          mandatory: true
        },
        shippingEqualsInvoiceAddress: {
          val: null,
          label: this.$t('labels.shipping-equals-invoice-address'),
          field_type:'checkbox',
          shipping_field: false,
          mandatory: false
        },
        shippingAddress: {
          val: null,
          label: this.$t('labels.shipping-address'),
          field_type:'text',
          shipping_field: true,
          mandatory: false
        },
        shippingCity: {
          val: null,
          label: this.$t('labels.shipping-city'),
          field_type:'text',
          shipping_field: true,
          mandatory: false
        },
        shippingPerfecture: {
          val: null,
          label: this.$t('labels.shipping-perfecture'),
          field_type:'text',
          shipping_field: true,
          mandatory: false
        },
        shippingZipcode: {
          val: null,
          label: this.$t('labels.shipping-zipcode'),
          field_type:'text',
          shipping_field: true,
          mandatory: false
        },
        /*message: {
          val: null,
          label: this.$t('labels.message')
        },*/
      },
      industryType: null,
      leadOrigin: null,
    };
  },
  computed: {
    devMode() {
      return false;
    },
    firstStep() {
      return this.step === 1;
    },
    secondStep() {
      return this.step === 2;
    },
    thirdStep() {
      return this.step === 3;
    },
    fourthStep() {
      return this.step === 4;
    },
    fifthStep() {
      return this.step === 5;
    },
    header() {
      if (this.firstStep || this.secondStep) {
        return this.$t('labels.register');
      } else if (this.thirdStep) {
        return this.$t('labels.account-details');
      } else if (this.fourthStep) {
        return this.$t('labels.i-am-a');
      } else if (this.fifthStep) {
        return this.$t('labels.how-find-us');
      } else {
        return this.$t('labels.register');
      }
    },
    industryTypes() {
      return this.$store.getters['account/industryTypes'];
    },
    industryTypesLoaded() {
      return this.$store.getters['account/industryTypesLoaded'];
    },
    leadOrigins() {
      return this.$store.getters['account/leadOrigins'];
    },
    leadOriginsLoaded() {
      return this.$store.getters['account/leadOriginsLoaded'];
    }
  },
  methods: {
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateFirstStep() {
      if (!this.email) {
        this.openDialog(this.$t('messages.email-must-be-filled'));

        return false;
      } else if (!this.validEmail(this.email)) {
        this.openDialog(this.$t('messages.email-is-not-valid'));

        return false;
      }

      return true;
    },
    validateSecondStep() {
      if (!this.password) {
        this.openDialog(this.$t('messages.password-must-be-filled'));
        return false;
      }

      return true;
    },
    validateThirdStep() {
      for (const field in this.accountDetails) {
        // console.log(this.accountDetails[field], this.accountDetails[field].val);

        if ((this.accountDetails[field].val === undefined ||
            this.accountDetails[field].val === null ||
            this.accountDetails[field].val === '') && 
            this.accountDetails[field].mandatory === true) {
          this.openDialog('Το πεδίο ' + this.accountDetails[field].label + ' είναι απαραίτητο');
          return false;
        }
      }

      return true;
    },
    validateFourthStep() {
      if (this.industryType === null) {
        this.openDialog('Παρακαλώ επιλέξτε την κατηγορία που ανήκει η επιχείρισή σας');
        return false;
      }

      return true;
    },
    validateFifthStep() {
      if (this.leadOrigin === null) {
        this.openDialog('Παρακαλώ επιλέξτε πως μας βρήκατε');
        return false;
      }

      return true;
    },
    nextStep() {
      if (this.step === 1) {
        if (!this.validateFirstStep()) {
          return;
        }
      } else if (this.step === 2) {
        if (!this.validateSecondStep()) {
          return;
        }
      } else if (this.step === 3) {
        if (!this.validateThirdStep()) {
          return;
        }
      }

      if (this.step < 5) {
        this.step++;
      }
    },
    previousStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    backToFirstStep() {
      this.step = 1;
    },
    toggleShippingFields() {
      this.hideShippingFields = !this.hideShippingFields;
    },
    setIndustryType(industryTypeDescr) {
      this.industryType = industryTypeDescr;
    },
    setLeadOrigin(leadOriginDescr) {
      this.leadOrigin = leadOriginDescr;
    },
    async loadIndustryTypes() {
      const self = this;

      try {
        await this.$store.dispatch('account/loadIndustryTypes');
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadLeadOrigins() {
      const self = this;

      try {
        await this.$store.dispatch('account/loadLeadOrigins');
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async register() {
      if (
          !this.validateFirstStep() ||
          !this.validateSecondStep() ||
          !this.validateThirdStep() ||
          !this.validateFourthStep() ||
          !this.validateFifthStep()
      ) {
        return;
      }

      const self = this;
      

      this.$store.dispatch('account/register', {
        email: this.email,
        password: this.password,
        industryType: this.industryType,
        leadOrigin: this.leadOrigin,
        accountDetails: this.accountDetails,
      }).then(() => {
        self.showNotification({
          class: 'success', // 'default'
          title: 'Επιτυχία',
          position: 'bottom-right',
          html: 'Παραλάβαμε την αίτησή σας. <br/>' +
              'Θα ενημερωθείτε με email για τα επόμενα βήματα.\n',
        });

        this.$router.push('/login');
      }).catch(() => {
        self.openDialog({
          class: 'danger',
          html: 'Σφάλμα κατά την εγγραφή'
        });
      });

      /**(function (error) {                    
          console.log(error);
      }) */
    }
  },
  created() {
    this.loadIndustryTypes();
    this.loadLeadOrigins();

    if (this.devMode) {
      this.email = 'aineiasrp2@gmail.com';
      this.password = '12345678';

      this.accountDetails.legalName.val = 'legalName';
      this.accountDetails.contactName.val = 'contactName';
      this.accountDetails.phone.val = 'phone';
      this.accountDetails.vatNr.val = 'vatNr';
      this.accountDetails.taxOffice.val = 'taxOffice';
      this.accountDetails.profession.val = 'profession';
      this.accountDetails.invoiceAddress.val = 'invoiceAddress';
      this.accountDetails.invoiceCity.val = 'invoiceCity';
      this.accountDetails.invoicePerfecture.val = 'invoicePerfecture';
      this.accountDetails.invoiceZipcode.val = 'invoiceZipcode';
      this.accountDetails.shippingAddress.val = 'shippingAddress';
      this.accountDetails.shippingCity.val = 'shippingCity';
      this.accountDetails.shippingPerfecture.val = 'shippingPerfecture';
      this.accountDetails.shippingZipcode.val = 'shippingZipcode';
      this.accountDetails.message.val = 'message';

      this.industryType = 1;
    }
  },
  beforeMount() {
    this.enterDesktopFullscreen();
    //this.enterFullscreen();
  },
  beforeUnmount() {
    this.exitDesktopFullscreen();
    //this.exitFullscreen();
  },
}
</script>

<style scoped>
  h1.header {
    font-size : 24px;
    line-height : 53.88px;
    letter-spacing : -0.24px;
  }

  h1#step-counter {
    font-size : 15px;
    line-height : 16px;
    color : #212121;
    position: absolute;
    top: 23px;
    right: 0px;
  }

  form#registration-form .form-control {
    border: 1px solid;
    display: flex;
    flex-direction: column;
    border-radius:14px;
    background-color: #212121;
  }

  form#registration-form .form-control > input {
    display: block;
    background-color: transparent;
    color: #212121;
    /*text-align: center;*/
    padding: 1.25rem 2rem;
    /*padding-left:15px;*/
    outline: none;
    font-weight: 500;
    border-radius: 12px;
    background: #fff;
    border: none;
    padding:15px;
    padding-left:33px;
  }

  form#registration-form .form-control > button {
    display: block;
    background-color: #212121;
    color: #fff;
    text-align: center;
    padding: 1.25rem 2rem;
    font-weight: 500;
    border-radius: 0px 0px 12px 12px;
    padding:15px;
  }

  form#registration-form .form-control > * >  input {
    font-family: 'PolySansTrial Neutral', sans-serif;
    font-size: 3rem;
    height: 4rem;
    background-color: #D8D7CD;
    color: #212121;
    text-align: center;
    /*padding: 1rem 2rem;*/
    padding-left:15px;
    outline: none;
    font-weight: 500;
    border-right: 1px solid #212121;
  }

  form#registration-form .form-control > * >  input:last-child {
    border-right: 0;
  }

  /*
   * Account Details form
   */

  form#account-details-form div.form-control {
    border-bottom: 1px solid #212121;
    border-radius: 12px;
  }

  form#account-details-form div.form-control:last-child {
    border-bottom: 0;
  }
  form#account-details-form div.form-control:nth-child(12) {
    border-top: 1px solid #212121;
  }

  form#account-details-form div.form-control input {
    display: block;
    width: 100%;
    background-color: transparent;
    outline: none;
  }

  form#account-details-form div.form-control input::placeholder {
    color: #929292;
  }

  /*
   * Industry types
   */
  .industry-type-tile {
    /*margin-left:-1px;
    margin-top:-1px;*/
    justify-content: left;
    text-align: left;
    background-image: url(../../assets/svg/signup-btn-bg.svg);
    background-size: 100% 100%;
  }

  .industry-type-tile.selected {
    background-image: url(../../assets/svg/signup-btn-bg-active.svg);
    /*background:#fffcf0;*/
    /*position:relative;
    border-radius:11px;
    border:none;*/
    /*margin:0px;*/
  }
  .industry-type-tile-xx.selected:after {
    position:absolute;
    top:-1px;
    left:-1px;
    right:-1px;
    bottom:-1px;
    content: '';
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5907d1+0,360ed1+60,1415d1+78,4640e1+94,9888fc+100 */
    background: #5907d1; /* Old browsers */
    background: -moz-linear-gradient(left,  #5907d1 0%, #360ed1 60%, #1415d1 78%, #4640e1 94%, #9888fc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5907d1', endColorstr='#9888fc',GradientType=1 ); /* IE6-9 */
    z-index: -1;
    border-radius:12px;
}

  div#registration-right-panel {
    background: url('../../assets/images/trucks-2.jpg') no-repeat top;
    background-attachment: fixed;
    background-size: cover;
    border-radius: 90px 0px 0px 90px;
    height: 100vh;
    position: sticky;
    top: 0px;
  }
  @media (max-width:1400px) {
    div#registration-right-panel {
      border-radius: 60px 0px 0px 60px;
    }
  }
  
  #account-details-form-wrapper {
    /*max-height: 700px;*/
    margin-bottom:30px;
  }
  
  @media screen and (max-width: 799px) {
    #account-details-form-wrapper {
      max-height: initial;
    }
  }


  .logo-container {
    padding-left:96px; padding-top:64px;
  }

  @media (max-width:1400px) {
    .logo-container {
      padding-left:5px; padding-top:37px;
    }
  }

  

  .gp-login-container {
    padding-left:110px;
    margin-top:123px;
  }

  @media (max-width:1400px) {
  .gp-login-container {
    padding-left:88px;
  }  
  }

  @media (max-width:1200px) {
  .gp-login-container {
    padding-left:50px;
  }  
  }

  @media (max-width:799px) {
  .gp-login-container {
    padding-left:0px;
  }  
  }



  /** */
  .logo-container-abs {
    position:absolute; 
    top:64px; 
    left:96px;
    width:225px;
  }
  @media (max-width:1600px) {
    .logo-container-abs {
      top:42px; 
      left:96px
    }
  }
  @media (max-width:1400px) {
    .logo-container-abs {
      top:37px; 
      left:50px
    }
  }
  @media (max-width:1300px) {
    .logo-container-abs {
      top:36px; 
      left:50px;
      width:226px;
    }
    .gp-logo {
      width:226px;
    }
  }
  @media (max-width:1200px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }
  /** ipad 810*1080 - Landscape */
  @media (width:1080px) {
    .logo-container-abs {
      top:42px; 
      left:50px;
      width:210px;
    }
    .gp-logo {
      width:211px;
    }
  }

  @media (max-width:1079px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

  @media (max-width:1000px) and (min-width:811px) {
    .logo-container-abs {
      top:30px; 
      left:49px;
      width:201px;
    }
    .gp-logo {
      width:201px;
    }
  }
  /** ipad 810*1080 Portrait */
  @media (width:810px) {
    .logo-container-abs {
      top:23px; 
      left:50px;
      width:200px;
    }
    .gp-logo {
      width:202px;
    }
  }
  

  @media (max-width:809px) {
    .logo-container-abs {
      top:60px; 
      left:20px;
      width:197px;
    }
    .gp-logo {
      width:197px;
    }    
  }

  @media (max-width:799px) {
    .logo-container-abs {
      display:none;
    }    
  }

.close_windows_btn { 
  position:absolute; bottom:50px;left:140px; text-decoration:underline; 
}
@media (max-width:1400px) {
  .close_windows_btn { 
    left:120px; 
  }
}

.link-back {
  bottom:80px; margin-top:0px; margin-bottom:20px; padding-left: 33px;
  text-decoration: underline;
}



/** GG */

    @media (max-width:1200px) {
    .close_windows_btn {
      left: 7%;
    }
  }
  
  @media (max-width:820px) {
    .close_windows_btn {
      left: 10%;
      bottom: 80px;
    }
    .rtrn-back {bottom: 80px !important; position:absolute !important; margin: unset !important; padding: unset !important;}
    .cont-btn{margin-bottom:50px !important;}
  }


  @media (max-width:420px) {
    .itms-cntr{justify-content: center !important; align-items: center;}
    #step-counter{top:unset !important;}
    .bd-mob{min-height: calc(100vh - 130px);}
    .text-16 {
        font-size: 12px;
    }
    .gp-login-container {margin-top:unset;
    }
    .hdr-mobile {padding-left:16px !important; margin-top: 55px !important; margin-bottom:22px !important;}
    .text-15 {
      font-size: 13px;
    }
    .form-control > input {font-size:15px; padding-left:16px !important;}
    .form-control > button {font-size:15px;}
    .text-16-mobile {padding-left:16px !important;}
    .close_windows_btn {
      bottom: 100px;
      right: 0;
      left:unset;
      font-size: 13px;
    }
    .rtrn-back {bottom: 100px !important;}
    .industry-type-tile {
      font-size: 15px;
      line-height: 17px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      justify-content: left;
      min-height:61px !important;
      text-align: left;
  }
  }
</style>