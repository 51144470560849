<!--
1) :value="this.modelValue" enables the incoming communication, initializing the component's value to the v-model's value
2) @change="this.handleChange" >> this.$emit('update:modelValue', e.target.value); enables the other way communication
-->
<template>
    <select class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4
                   leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
           :class="this.class"
           :name="name"
           :value="modelValue"
           @change="handleChange"
    >
        <option v-if="placeholder" value="">{{ placeholder }}</option>
        <option v-for="(value, key) in activeOptions" :key="value" :value="associative ? key : value">{{ value }}</option>
    </select>
</template>

<script>
export default {
    name: 'BaseSelect',
    props: {
        // absolutely necessary [!]
        modelValue: {},
        filterKey: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        class: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        value: {
            required: false,
        },
        options: {
            // type: Array, may be Object, too.
            required: true,
        },
        associative: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    emits: ['update:modelValue', 'update-active-filter-display-text'],
    computed: {
        activeOptions() {
            return this.options;
        }
    },
    methods: {
        handleChange(e) {
            this.$emit('update:modelValue', e.target.value);

            if (e.target.value != null && e.target.value != '') {
                this.$emit('update-active-filter-display-text', {
                    filterKey: this.filterKey,
                    text: e.target.options[e.target.selectedIndex].text
                });
            }
        },
    },
    created() {
        //
    }
};
</script>

<style>

</style>
