<template>
  <div class="rectangle arrow-shape-left text-regular text-23" style="position:relative" :style="{left: -1*leftOffset*index + 'px'}">
    <div style="position:absolute; bottom:10px; left:35px">{{ title }}</div>
  </div>
</template>
 
<script>
export default {
  name: "ArrowShapedNav",
  props: {
    title: {
      type: String,
      required: true
    },
    overlay: {
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    leftOffset: {
      type: Number,
      default: 31
    }
  },
  data() {
    return {}
  },
  computed: {
    //
  },
  methods: {
    //
  },
}
</script>

<style scoped>
.rectangle {
  flex: initial;
  height: 84px;
  padding-top: 40px;
  padding-left: 41px;
  padding-bottom: 14px;
  position: relative;
  cursor: pointer;
}

.rectangle.last {
  width: 670px;
}

.small {
  color: #212121;
  width: 237px !important;
  height: 44px;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight:500;
  background: url("../../assets/svg/filter2-input2-green.svg") no-repeat left center;
  background-size: cover;
}

.small.last {
  background: url("../../assets/svg/filter2-input1-green.svg") no-repeat;
}

.small.yellow {
  background: url("../../assets/svg/filter2-input2-yellow.svg") no-repeat left center;
}

.small.yellow.last {
  background: url("../../assets/svg/filter2-input1-yellow.svg") no-repeat;
}

.small.selected {
  color: #00D491;
  background: url("../../assets/svg/filter2-input2-dark.svg") no-repeat left center;
  /*z-index: 100;*/
}

.small.yellow.selected {
  color: #EECA5B;
  background: url("../../assets/svg/filter2-input2-dark.svg") no-repeat left center;
  /*z-index: 100;*/
}

.small.last.selected {
  background: url("../../assets/svg/filter2-input1-dark.svg") no-repeat;
}

.rectangle.din {
  min-width: 280px; /*338px;*/
  height:66px;
  background: url("../../assets/svg/input-1.svg") no-repeat;
  background-size: 100% 100%;
}

.rectangle.type {
  min-width: 300px; /*348px;*/
  height:66px;
  background: url("../../assets/svg/input-2.svg") no-repeat;
  background-size: 100% 100%;
}

.rectangle.metrics {
  min-width: 285px; /*344px;*/
  height:66px;
  background: url("../../assets/svg/input-3.svg") no-repeat;
  background-size: 100% 100%;
}

.rectangle.din:hover, .rectangle.din.selected {
  /*background-color: #0CA2FF;*/
  background: url("../../assets/svg/input-1-blue.svg") no-repeat;
  background-size: 100% 100%;
}

.rectangle.toolbrand {
  height:65px;
  width:412px;
  background-image: url(../../assets/svg/arrow-filter-tool-brand.svg);
  background-size: 100% 100%;
  margin-left:0px;
  left:0px !important;
}
.rectangle.toolbrand div {
  left:30px !important;
}

.rectangle.toolbrand:hover,
.rectangle.toolbrand.selected {
  background-image: url(../../assets/svg/arrow-filter-tool-brand-active.svg);
}

.rectangle.tooltype {
  height:65px;
  width:412px;
  background-image: url(../../assets/svg/arrow-filter-tool-type.svg);
  background-size: 100% 100%;
  margin-left:-28px;
  left:0px !important;
}




.rectangle.tooltype div {
  left:30px !important;
}

.rectangle.tooltype:hover,
.rectangle.tooltype.selected {
  background-image: url(../../assets/svg/arrow-filter-tool-type-active.svg);
}

.rectangle.type:hover, .rectangle.type.selected {
  /*background-color: #00D491;*/
  background: url("../../assets/svg/input-2-green.svg") no-repeat;
  background-size: 100% 100%;
}

.rectangle.metrics:hover, .rectangle.metrics.selected {
  /*background-color: #EECA5B;*/
  background: url("../../assets/svg/input-3-yellow.svg") no-repeat;
  background-size: 100% 100%;
}

.---arrow-shape-left:before {
  content: " ";
  position: absolute;
  left: -25px;
  top: 0;
  border-top: 45px solid transparent;
  border-right: 25px solid;
  border-left: none;
  border-bottom: 45px solid transparent;
}

.---arrow-shape-left.small:before {
  left: -15px;
  border-top: 20px solid transparent;
  border-right: 15px solid transparent; /* greenyellow */
  border-bottom: 20px solid transparent;
}

.----arrow-shape-left.small.selected:before {
  border-right-color: #212121;
}



.---last:after {
  content: " ";
  position: absolute;
  right: -25px;
  top: 0;
  border-top: 45px solid transparent;
  border-left: 25px solid transparent;
  border-right: none;
  border-bottom: 45px solid transparent;
}

.---last.small:after {
  right: 0px;
  border-top: 20px solid #00D491;
  border-left: 15px solid transparent;
  border-right: none;
  border-bottom: 20px solid #00D491;
  border-left-color: greenyellow;
}

.---metrics.last:after {
  border-left-color: #DDDCD3;
}

.---metrics.last:hover:after, .metrics.last.selected:after {
  border-left-color: #EECA5B;;
}

.---last.small.selected:after {
  border-left-color: #212121;
}


.rectangle div {
  line-height:25px;
}


@media screen and (max-width:1600px) {
  
  .rectangle.din {
    height: 62px;
    min-width: 251px;
    background-size: contain;
  }

  .rectangle.type {
    height: 62px;
    min-width: 258px;
    background-size: contain;
  }

  .rectangle.metrics {
    height: 62px;
    min-width: 255px;
    background-size: contain;
  }

  


  .rectangle.din:hover, .rectangle.din.selected {
    background-size: 100% 100%;
  }

  .rectangle.type:hover, .rectangle.type.selected {
    background-size: 100% 100%;
  }

  .rectangle.metrics:hover, .rectangle.metrics.selected {
    background-size: 100% 100%;
  }

  .rectangle.din div,
  .rectangle.type div,
  .rectangle.metrics div {
    font-size:22px;
  }

  

  
  .rectangle div {
    line-height:25px;
  }

}


@media screen and (max-width:1400px) {
  
  .rectangle.din {
    height: 60px;
    min-width: 243px;
    background-size: contain;
  }

  .rectangle.type {
    height: 60px;
    min-width: 250px;
    background-size: contain;
  }

  .rectangle.metrics {
    height: 60px;
    min-width: 247px;
    background-size: contain;
  }

  

  .rectangle.din div,
  .rectangle.type div,
  .rectangle.metrics div {
    font-size:19px;
  }

  .rectangle div {
    line-height:23px;
  }

}




@media screen and (max-width:1300px) {
  
  .rectangle.din {
    height: 55px;
    min-width: 223px;
    background-size: 100% 100%;
  }

  .rectangle.type {
    height: 55px;
    min-width: 230px;
    background-size: 100% 100%;
  }

  .rectangle.metrics {
    height: 55px;
    min-width: 231px;
    background-size: 100% 100%;
  }

}


@media screen and (max-width:1100px) {
  
  .rectangle.din {
    height: 49px;
    min-width: 199px;
  }

  .rectangle.type {
    height: 49px;
    min-width: 204px;
  }

  .rectangle.metrics {
    height: 49px;
    min-width: 202px;
  }

  .rectangle.last {
    width: 202px;
  }

}



@media screen and (max-width:1600px) {
  .rectangle.toolbrand,
  .rectangle.tooltype {
    height:62px;
    width:380px;
  }
  .rectangle.tooltype {
    margin-right:-7px;
    margin-left:-40px;
  }
}

@media screen and (max-width:1400px) {
  .rectangle.toolbrand,
  .rectangle.tooltype {
    height:58px;
    width:380px;
    font-size:19px;
  }
  .rectangle.tooltype {
    margin-left:-39px;
  }
}

@media screen and (max-width:1300px) {
  .rectangle.toolbrand,
  .rectangle.tooltype {
    width:320px;
    height:55px;
  }

  .rectangle.tooltype {
    margin-left:-20px;
  }
}

@media screen and (max-width:1200px) {
  
  .rectangle.toolbrand,
  .rectangle.tooltype {
    width:290px;
    height:55px;
  }
}



</style>
