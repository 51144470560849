<template>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="table-auto min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th v-for="header in headers" :key="header" scope="col"
                                    class="px-6 py-3 text-left font-medium text-gray-500 tracking-wider">
                                    {{ header }}
                                </th>
                                <th v-if="actionsColumn" scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <slot name="body"></slot>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTable",
    props: {
        headers: {
            type: Array,
            required: true,
            default: function() {
              return [
                  //
              ]
            },
        },
        actionsColumn: {
            type: Boolean,
            required: false,
            default: true,
        }
    }
}
</script>

<style scoped>

</style>
