<template>
  <div style="width: 100px; height: 35px;" class="tile flex items-center justify-center py-1 px-4 text-base cursor-pointer manrope font-semibold border-1 b-21">
    {{ dimension.description }}
  </div>
</template>

<script>
export default {
  name: "DimensionTile",
  components: {},
  props: ['dimension'],
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style scoped>
  .tile:hover, .tile.selected {
    background-color: rgba(0, 0, 0, .1);
  }
</style>
