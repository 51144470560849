<template>
  <div class="gp-container">
    
    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <router-link class="flex items-center mb-2 manrope font-semibold" to="/account/transactions/invoices">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/>{{ $t('labels.orders-and-invoices') }}
      </router-link>
      <!-- mobile -->
      <div class="sm:hidden text-2xl">{{ $store.getters['auth/userName'] }},</div>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.document') }}
    </div>

    <div id="expand-all-wrapper" class="text-right mb-3 pr-4-- gp-pr-30-xx gp-sm-pr-15 gp-ml-30" style="height:24px">
        <span v-show="documentLoaded && !allItemsAreExpanded" class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium pb-1" @click="expandAll">
          {{ $t('labels.expand-all') }}
        </span>
    </div>

    <!-- invoice -->
    <div v-if="documentLoaded" class="manrope pb-16 gp-ml-10-- gp-sm-ml-0" id="invoice-wrapper">
      <!-- invoice header -->
      <div class="bg-21 text-white rounded-xl grid grid-cols-2 sm:grid-cols-3 p-4 gap-2 text-base font-semibold mb-2" style="position:relative">
        <div class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ document.header.order_date }}
        </div>            
        <div class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ document.header.order_status }}
        </div>

        <div v-if="document.header.cash_discount_perc>0" class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.price') }} / €{{ document.header.total_lines }} 
        </div>      
        
        <div v-if="document.header.cash_discount_perc>0" class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.cash-discount') }} / {{ document.header.cash_discount_perc }}% 
        </div>

        <div v-if="document.header.eshop_discount_perc>0" class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.eshop-discount') }} {{ document.header.eshop_discount_perc }}% 
        </div>

        <div v-if="document.header.reward_discount_perc>0" class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.reward-discount') }} / {{ document.header.reward_discount_perc }}% 
        </div>

        <div class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.total-w-out-vat') }} / {{ prettyNum(document.header.total_price) }}
        </div>
        <div class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.vat') }} / {{ prettyNum(document.header.total_vat) }}
        </div>
        <div class="col-span-1 bg-beige text-21 text-14 text-medium rounded-lg order-info-item">
          {{ $t('labels.total-w-vat') }} / {{ prettyNum(document.header.total_charge) }}
        </div>

        <div class="col-span-2 sm:col-span-3 text-lg-- font-normal-- mt-4 text-20 text-regular gp-pl-15 gp-sm-pl-0" 
        style="line-height:26px; margin-top:50px; ">
          {{ document.header.invoice_type }}
          <br/>
          {{ document.header.order_code }}
        </div>
      </div>

      <!-- invoice details -->
      <div class="invoice-details rounded-xl grid grid-cols-2 sm:grid-cols-6 p-4 gap-2-xx text-base mb-4-- gp-mb-10 border-21 border-1">

        <div class="col-span-full order-1 sm:col-span-3 sm:order-2 leading-5 mb-8-- gp-mb-50 gp-sm-mb-30 text-15 text-medium gp-pl-15 gp-sm-pl-0" style="">
          <p class="mb-2 font-normal-- gp-mb-20">{{ $t('labels.from') }}</p>

          <p class="font-semibold">Tsamouris Douzoglou S.A.</p>
          <p>12 Archimidous str.</p>
          <p>177 78, Athens, Greece</p>
          <p>{{ $t('labels.vat-nr') }}: EL094436065</p>
          <p>T: 0030 210 4821368</p>
          <p>E: info@tsamouris.gr</p>
        </div>

        <div class="col-span-full order-2 sm:col-span-3 sm:order-1 leading-5 mb-8-- gp-mb-50 text-15 text-medium gp-pl-15 gp-sm-pl-0" 
        style="" v-if="accountDetailsLoaded">
          <p class="mb-2-- font-normal-- gp-mb-20">{{ $t('labels.to') }}</p>

          <div v-html="document.header.customer_details"></div>
        </div>

        
        <div v-if="document.header.shipping_address.trim()!='' && document.header.shipping_address!=null" class="bx1 col-span-2 sm:col-span-6 order-4 bg-beige-xx text-21 rounded-lg p-2 text-center border-21 border-1 text-14 text-medium">
          {{ document.header.shipping_address }}
        </div>
        <div v-if="document.header.comments!=null && document.header.comments.trim()!=''" class="bx2 col-span-2 sm:col-span-6 order-5 bg-beige-xx text-21 rounded-lg p-2 text-center border-21 border-1 text-14 text-medium">
          {{ document.header.comments }}
        </div>
        <div v-if="document.header.payment_method!=null" class="bx3 col-span-1 sm:col-span-2 order-6 bg-beige-xx text-21 rounded-lg p-2 text-center border-21 border-1 text-14 text-medium">
          {{ document.header.payment_method }}
        </div>
        <div v-if="document.header.shipping_method!=null" class="bx4 col-span-1 sm:col-span-2 order-7 bg-beige-xx text-21 rounded-lg p-2 text-center border-21 border-1 text-14 text-medium">
          {{ document.header.shipping_method }}
        </div>
        <a
            v-if="document.header.pdf_path!=''"
            :href="document.header.pdf_path"
            target="_blank"
            style="background-color: #D2FFFF;"
            class="col-span-1 sm:col-span-2 order-8 bg-beige text-21 rounded-lg p-2 text-center border-21 border-1 text-14 text-medium"
        >
          {{ $t('labels.download-as-pdf') }}
        </a>
      </div>

      <!-- invoice items -->
      <invoice-line-item
          v-for="item in document.lines"
          :key="item.id"
          :item="item"
          :expanded="isExpanded(item)"
          class="mb-4-- gp-mb-10 invgaps"
          @click="toggle(item)"
      />

    </div>

  </div>
</template>

<script>

import InvoiceLineItem from "@/components/misc/InvoiceLineItem";
export default {
  name: "InvoiceDetails",
  inject: ['openDialog', 'prettyNum'],
  components: {InvoiceLineItem},
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      expandedItems: [],
    }
  },
  computed: {
    document() {
      return this.$store.getters['account/document'];
    },
    documentLoaded() {
      return this.$store.getters['account/documentLoaded'];
    },
    accountDetails() {
      return this.$store.getters['account/accountDetails'];
    },
    accountDetailsLoaded() {
      return this.$store.getters['account/accountDataLoaded'];
    },
    allItemsAreExpanded() {
      return this.expandedItems.length === this.document.lines.length;
    },
  },
  methods: {
    toggle(item) {
      if (this.isExpanded(item)) {
        this.collapse(item);
      } else {
        this.expand(item);
      }
    },
    isExpanded(item) {
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        // console.log(entry, item.item_code);
        return entry === item.item_code
      });

      // console.log(itemIndex);

      return itemIndex !== -1;
    },
    expand(item) {
      // console.log('expanding item ', item);
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        return entry === item.item_code
      });

      if (itemIndex === -1) {
        this.expandedItems.push(item.item_code);
      }
    },
    collapse(item) {
      // console.log('collapsing item ', item);
      const itemIndex = this.expandedItems.findIndex(function(entry) {
        return entry === item.item_code
      });

      if (itemIndex !== -1) {
        // this.expandedItems =
        this.expandedItems.splice(itemIndex, 1);
      }
    },
    expandAll() {
      this.expandedItems = this.document.lines.map(x => x.item_code);
    },
    async loadDocument() {
      try {
        await this.$store.dispatch('account/loadDocument', this.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadAccountData() {
      try {
        await this.$store.dispatch('account/loadAccountData');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  created() {
    this.loadDocument();
    this.loadAccountData();
  },

  // {"result":"success",
  // "data":{
  // "header":{"order_id":"354","order_code":"","order_date":"2021-12-09 00:00:00","invoice_type":"",
  // "order_status":null,"payment_method":null,"shipping_method":null,"total_price":"0.000","total_vat":"0.000",
  // "total_charge":"0.00","seller_id":"0","pdf_path":""},
  //
  // "lines":[
  // {"id":"308","item_code":"001006000800","item_description":"E\u039e\u0391\u0393\u03a9\u039d\u0395\u03a3 DIN 933 \u0399\u039d\u039f\u03a7 \u03912  6 \u03a7 8","quantity":"400.000","unit_description":"100 \u03c4\u03b5\u03bc","quantity_packages":200,"initial_price":"0.00","discount_1":"0.00","discount_2":"0.00","total_discount":"0.00","final_price":"0.00","vat_percentage":null,"total_price":"0.00","total_vat":"0.00"},
  // {"id":"309","item_code":"320003005000","item_description":"\u03a0\u0395\u03a1\u0399\u039a \u0391\u03a3\u03a6\u0391\u039b DIN 985-8 \u0393\/\u0392.  3-0.5","quantity":"500.000","unit_description":"100 \u03c4\u03b5\u03bc","quantity_packages":50,"initial_price":"0.00","discount_1":"0.00","discount_2":"0.00","total_discount":"0.00","final_price":"0.00","vat_percentage":null,"total_price":"0.00","total_vat":"0.00"},{"id":"310","item_code":"620040001600","item_description":"\u03a0\u03a1\u0399\u03a4\u03a3\u0399\u039d\u0399\u0391 \u03a4\u03a5\u03a6\u039b\u0391 \u0391\u039b\u039f\u03a5\u039c SRC 4 \u03a7 16","quantity":"2500.000","unit_description":"1000 \u03c4\u03b5\u03bc.","quantity_packages":5000,"initial_price":"0.00","discount_1":"0.00","discount_2":"0.00","total_discount":"0.00","final_price":"0.00","vat_percentage":null,"total_price":"0.00","total_vat":"0.00"}]}}
}
</script>

<style scoped>

  @media (max-width:440px) {
    .bx1 {
      margin-bottom:10px;
    }  
    .bx2 {
      margin-bottom:10px;
    }  
    .bx3 {
      margin-right:5px !important;
    }  
    .bx4 {
      margin-left:5px;
    }  
    .invgaps {margin-bottom:unset;}
  }
  
  #expand-all-wrapper {
    padding-right:50px;
  }

  @media (min-width: 800px) {
    #expand-all-wrapper {
      max-width: 571px;
    }

    #invoice-wrapper {
      max-width: 571px;
    }
  }

  .order-info-item {
    text-align:center;
    padding:12px 8px;
    line-height:16px;
    max-height: 45px;
    display:flex;
    justify-content: center;
    align-items: center;
  }


  .gp-title {
    padding-left:30px;
    font-size:28px;
    margin-bottom:13px;
  }

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}

.invoice-details {
  background-color: #F5F5E8; margin-top:-30px; padding-top:50px;
}

.invoice-details > div {
  margin-right:-1px;
  margin-top: -1px;
}

.gp-account-title {
  margin-bottom:5px;
}



</style>