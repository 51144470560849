<template>
  <div class="gp-container">
    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <router-link class="flex items-center mb-2 manrope font-semibold" to="/account/my-profile">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/>{{ $t('labels.my-profile') }}
      </router-link>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.business-details') }}
    </div>

    <div v-if="dataLoaded" class="max-w-xl --sm:px-6 mb-32">
      <form class="manrope text-semibold w-full border-1 rounded-xl mb-4 bg-darkbeige-xx --sm:bg-transparent" @submit.prevent="">
        <div
            v-for="(businessDetail, index) in businessDetails"
            :key="businessDetail.label"
            class="form-control"
            :class="{hidden: !businessDetail.clientView && !backOfficeUser}"
        >
          <input
              :value="$t(businessDetail.label) + ': ' + accountDetails[index]"
              class="p-4 sm:p-8" readonly
              :placeholder="$t(businessDetail.label)"
          />
        </div>
      </form>
    </div>

  </div>

</template>

<script>
export default {
  name: "BusinessDetails",
  inject: ['openDialog'],
  data() {
    return {
      businessDetails: {
        customer_id: {
          val: null,
          label: 'labels.customer-id',
          clientView: false,
        },
        customer_code: {
          val: null,
          label: 'labels.customer-code',
          clientView: true,
        },
        customer_name: {
          val: null,
          label: 'labels.customer-name',
          clientView: true,
        },
        profession: {
          val: null,
          label: 'labels.profession',
          clientView: true,
        },
        address: {
          val: null,
          label: 'labels.address',
          clientView: true,
        },
        city: {
          val: null,
          label: 'labels.city',
          clientView: true,
        },
        country: {
          val: null,
          label: 'labels.country',
          clientView: true,
        },
        phone: {
          val: null,
          label: 'labels.phone',
          clientView: true,
        },
        email: {
          val: null,
          label: 'labels.email',
          clientView: true,
        },
        vat_nr: {
          val: null,
          label: 'labels.vat-nr',
          clientView: true,
        },
        tax_office: {
          val: null,
          label: 'labels.tax-office',
          clientView: true,
        },
        username: {
          val: null,
          label: 'labels.username',
          clientView: false,
        },
        password: {
          val: null,
          label: 'labels.password',
          clientView: false,
        },
        price_list_id: {
          val: null,
          label: 'labels.price_list_id',
          clientView: false,
        },
        cash_discount: {
          val: null,
          label: 'labels.cash-discount',
          clientView: true,
        },
        discount_type_id: {
          val: null,
          label: 'labels.discount_type_id',
          clientView: false,
        },
        comments: {
          val: null,
          label: 'labels.comments',
          clientView: false,
        },
        remain: {
          val: null,
          label: 'labels.remain',
          clientView: true,
        },
        credit_status: {
          val: null,
          label: 'labels.credit-status',
          clientView: true,
        },
        seller_id: {
          val: null,
          label: 'labels.seller_id',
          clientView: false,
        },
        default_payment_type: {
          val: null,
          label: 'labels.default_payment_type',
          clientView: true,
        },
      },
    }
  },
  computed: {
    dataLoaded() {
      return this.$store.getters['account/accountDataLoaded'];
    },
    accountDetails() {
      return this.$store.getters['account/accountDetails'];
    },
    accountBusinessDetails() {
      return this.$store.getters['account/businessDetails'];
    },
    backOfficeUser() {
      return this.$store.getters['auth/role'] !== 'customer';
    }
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch('account/loadAccountData');

        for (const property in this.accountBusinessDetails) {
          this.businessDetails[property].val = this.accountBusinessDetails[property];
        }
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  async created() {
    await this.loadData();

    // console.log(
    //     this.accountDetails,
    // )
  }
}
</script>

<style scoped>
  div.form-control {
    border-bottom: 1px solid #212121;
    border-radius:12px;
  }

  div.form-control:last-child {
    border-bottom: 0;
  }

  div.form-control input {
    display: block;
    width: 100%;
    background-color: transparent;
    outline: none;
    font-size: 20px;
  }

  div.form-control input::placeholder {
    color: #929292;
  }

  button {
    background-color: #F5F5E8;
  }

  .gp-title {
    padding-left:30px;
    font-size:28px;
    margin-bottom:48px;
  }

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}


</style>