<template>
  
  <div style="position:relative">
    <input v-model="searchDim1"
      class="searchDim1-input"
      style="" 
      @keydown.enter="handleDim1Seacrch(searchDim1)"
      :placeholder="$t('labels.type-dimension1-here')"      
       />
    <span style="position:absolute; top:-40px;left:420px; cursor:pointer"
      @click="handleDim1Seacrch(searchDim1)">
      <img src="../../../assets/svg/white-lens.svg" />
    </span>
  </div>
  
  <div v-if="dimensionsLoaded">
<!--    {{ gridTilesCount }}-->
<!--    {{ restTilesCount }}-->
    <div class="page" v-for="page in totalPages" :id="'page-'+page" :key="page">
      <div v-show="page === currentPage">
        <!-- Grid tiles -->
        <p style="padding:10px; margin-bottom:5px; font-size:15px; text-align:center">{{ $t('labels.choose-dimension1-here') }}</p>
        <div class="grid grid-cols-8 gap-2 text-center mx-auto mb-4" >
          <dimension-tile
              v-for="dimension in gridTiles(page)"
              :key="dimension.slug"
              :dimension="dimension"
              class="col-auto"
              :class="{selected: selectedDimension1Id === dimension.slug}"
              @click="handleTileClick(dimension)"
          ></dimension-tile>
        </div>

        <!-- Rest tiles (centered) -->
        <div class="flex justify-center gap-4 text-center mx-auto" v-if="dimensionsLoaded && page === totalPages && restTilesCount > 0">
          <dimension-tile
              v-for="dimension in restTiles"
              :key="dimension.slug"
              :dimension="dimension"
              class="col-auto"
              :class="{selected: selectedDimension1Id === dimension.slug}"
              @click="handleTileClick(dimension)"
          ></dimension-tile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DimensionTile from "@/components/arrow-filters/metrics/DimensionTile";
//import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "Dimension1Section",
  components: {DimensionTile},
  inject: [
    'setD1metricFilter',
    'unsetD1metricFilter',
    'openDialog',
    'initPaginator',
    'showPaginator',
    'hidePaginator',
    'selectedDin',
  ],
  props: {
    selectedCategoryId: {
      type: String,
      required: false,
      default: null,
    },
    
    selectedD1metricIdProp: {
      type: String,
      required: false,
      default: null,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    currentPageProp: {
      type: Number,
      required: false,
      default: 1,
    },

    dim1: {
      type: String,
      required: false,
      default: null,
    }

    

    /*activeFilters: {
      type: Object,
      required: false,
    }*/
  },
  data() {
    return {
      gridCols: 8,
      blocksize: 64,
      selectedDimension1Id: null,
      searchDim1: null,
    }
  },
  computed: {
    dimensionsLoaded() {
      return this.$store.getters['filters/d1metricsLoaded'];
    },
    dimensions() {
      return this.$store.getters['filters/d1metrics'];
    },
    restTilesCount() {
      return this.dimensions.length % this.gridCols;
    },
    gridTilesCount() {
      return this.dimensions.length - this.restTilesCount;
    },
    lastPageGridTilesCount() {
      return this.dimensions.length % this.blocksize - this.restTilesCount;
    },
    // gridTiles() {
    //   return this.dimensions.slice(0, this.gridTilesCount);
    // },
    restTiles() {
      return this.dimensions.slice(-this.restTilesCount);
    },
    totalPages() {
      return Math.ceil(this.dimensions.length/this.blocksize);
    }
  },
  watch: {
    selectedD1metricIdProp(newVal) {
      this.selectedDimension1Id = newVal;
    },
    dim1(newVal) {
      this.searchDim1 = newVal;
    },
    
  },
  methods: {
    async loadDimensions() {
      const self = this;
      
      /*try {
        console.log("din=" + this.selectedDin());
      }
      catch (error) {
        console.log(error.message);
      }*/

      try {
        await this.$store.dispatch('filters/loadD1Metrics', {
          category_id: this.selectedCategoryId,
          din: this.selectedDin()
        });

        if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }
        else {
          this.hidePaginator();
        }
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    handleTileClick(dimension) {
      dimension.id = dimension.slug;

      if (this.selectedDimension1Id == dimension.id) {
        this.selectedDimension1Id = null;
        this.unsetD1metricFilter();
      } else {
        this.selectedDimension1Id = dimension.id;
        this.setD1metricFilter(dimension, this.currentPage);
      }

      this.searchDim1 = dimension.id;

    },
    handleDim1Seacrch(searchDim1) {
      const myDim1 = {};
      myDim1.id = searchDim1;
      myDim1.slug = searchDim1;
      myDim1.description = searchDim1;
      this.setD1metricFilter(myDim1, this.currentPage);
    },
    gridTiles(page) {
      if (page < this.totalPages) {
        // console.log('page < this.totalPages', page, this.totalPages);
        // console.warn((page - 1) * this.blocksize, page * this.blocksize);
        return this.dimensions.slice((page - 1) * this.blocksize, page * this.blocksize);
      } else {
        // console.log('page >= this.totalPages', page, this.totalPages);
        // console.warn((page - 1) * this.blocksize, (page - 1) * this.blocksize + this.gridTilesCount);
        return this.dimensions.slice((page - 1) * this.blocksize, (page-1) * this.blocksize + this.lastPageGridTilesCount);
      }
    },
  },
  created() {
    this.loadDimensions();

    this.selectedDimension1Id = this.selectedD1metricIdProp;

    //this.searchDim1 = d1metricFilterIsActive ? activeFilters.d1metric.val : null;
    this.searchDim1 = this.selectedD1metricIdProp;
  }
}
</script>

<style scoped>

.searchDim1-input {
  position:absolute; 
  top:-50px;
  left:204px;   
  padding:5px 15px;    
  text-align:left; 
  border:none;
  background-color: transparent;
  background-image:url('../../../assets/svg/input-bg.svg');
  outline: none;
  width:259px;
  height:44px;
}

</style>
