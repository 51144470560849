import {createStore} from 'vuex';

import authModule from "./modules/auth";
import appModule from "./modules/app";
import filtersModule from "@/store/modules/filters";
import categoriesModule from "@/store/modules/categories";
import accountModule from "@/store/modules/account";
import cartModule from "@/store/modules/cart";

const store = createStore({
    modules: {
        auth: authModule,
        app: appModule,
        filters: filtersModule,
        categories: categoriesModule,
        account: accountModule,
        cart: cartModule,
    },
});

export default store;
