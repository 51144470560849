<template>
  
  <div class="gp-container">
    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <router-link class="flex items-center mb-2 manrope font-semibold" to="/account/my-profile/business-addresses">
        <img class="mr-3" src="../../../../assets/svg/arrow-left-wireframe.svg"/>{{ $t('labels.business-addresses') }}
      </router-link>
    </div>

    <div v-if="dataLoaded" class="text-semibold gp-account-title">
      {{ _address.site_code }}
    </div>

    <div v-if="dataLoaded" class="max-w-xl px-6-- mb-32">
      <form class="manrope w-full border-1 rounded-xl mb-4 bg-darkbeige-xx --sm:bg-transparent" @submit.prevent="">
  <!--      <div v-for="prop in address" :key="prop.label" class="form-control">
          <input v-model="prop.val" class="p-4 sm:p-8" :placeholder="prop.label"/>
        </div>-->
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.address') + ': ' + _address.address" :placeholder="$t('labels.address')"/>
        </div>
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.city') + ': ' + _address.city" :placeholder="$t('labels.city')"/>
        </div>
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.country') + ': ' + _address.country" :placeholder="$t('labels.country')"/>
        </div>
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.vat-excluded') + ': ' + _address.vat_excluded" :placeholder="$t('labels.vat-excluded')"/>
        </div>
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.vat-zone') + ': ' + _address.vat_zone" :placeholder="$t('labels.vat-zone')"/>
        </div>
        <div class="form-control">
          <input readonly class="p-4 sm:p-8" :value="$t('labels.zipcode') + ': ' + _address.zipcode" :placeholder="$t('labels.zipcode')"/>
        </div>
      </form>

  <!--    <div class="text-right">
        <button type="button" class="border-1 rounded-xl px-8 py-2 font-semibold">
          OK
        </button>
      </div>-->
    </div>

  </div>

</template>

<script>
export default {
  name: "BusinessAddressProfile",
  inject: ['openDialog'],
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      address: null
    }
  },
  computed: {
    dataLoaded() {
      return this.$store.getters['account/accountDataLoaded'] && this.address !== null;
    },
    _address() {
      return this.address;
    }
  },
  methods: {
    formIsValid() {
      return true;
    },
    submitForm() {
      //
    },
    async loadData() {
      try {
        await this.$store.dispatch('account/loadAccountData');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  async created() {
    if (!this.dataLoaded) {
      await this.loadData();
    }

    this.address = this.$store.getters['account/addresses'].find(address => address.site_id == this.id);
  }
}
</script>

<style scoped>
div.form-control {
  border-bottom: 1px solid #212121;
  border-radius: 12px;
}

div.form-control:last-child {
  border-bottom: 0;
}

div.form-control input {
  display: block;
  width: 100%;
  background-color: transparent;
  outline: none;
  font-size:20px;
  font-weight:400;
}

div.form-control input::placeholder {
  color: #929292;
}

button {
  background-color: #F5F5E8;
}


@media (max-width:800px) {
  div.form-control input {
    font-size:15px;
    font-weight: 500;
  }  
}





@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}


</style>

<style>


.gp-account-title {
  padding-left:30px;
  font-size:33px;
  margin-bottom:40px;
}

@media (max-width:800px) {
  .gp-account-title {
    padding-left:15px;
    font-size:24px;
  }  
}


</style>