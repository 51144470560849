<template>
    <label class="block tracking-wide text-gray-700 text-base font-bold mb-2" :class="this.class">
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: 'BaseLabel',
    props: {
        // field: {
        //     type: String,
        //     required: true,
        // },
        class: {
            type: String,
            required: false,
        }
    },
    // computed: {
    //     classObject() {
    //         return {
    //             this.class : true
    //         }
    //     }
    // }
};
</script>

<style>

</style>
