<template>
    <div class="mx-auto rounded-lg mt-4  mb-8 gp-tool-type-browse-section" style="max-width: 932px; background-color: #e4c77b; width:95vw; min-height: 300px;">
      <div class="grid grid-cols-5">
      <fastener-tile
          v-for="category in categories23" :key="category.id"
          :fastener="category"
          class="col-span-1"
          :class="{selected: selectedToolType === category.id}"
          @click="handleTileClick(category)"
      ></fastener-tile>
    </div>
    </div>

</template>

<script>

import FastenerTile from "@/components/arrow-filters/type/FastenerTile";


export default {
    name: "ToolTypeBrowseSection",
    components: {FastenerTile},
    data() {
      return {
        selectedToolType: null,
      }
    },
    computed: {
      categoriesLoaded() {
        return this.$store.getters['categories/categories23Loaded'];
      },
      categories23() {
        return this.$store.getters['categories/categories23'];
      },
      toolBrand() {
        return typeof(this.$route.query.toolbrand)=="undefined"? "": this.$route.query.toolbrand;
      },
    },
    methods: {
      async loadCategories23() {
        const self = this;
        let toolBrand = this.toolBrand;

        try {
          await this.$store.dispatch('categories/loadCategories23', {
            forceRefresh: true,
            toolbrand: toolBrand
          });
        } catch (error) {
          self.openDialog({
            class: 'danger',
            html: error.message
          });
        }
      },
      handleTileClick(category) {
      if (this.selectedToolType == category.id) {
        this.selectedToolType = null;
        //this.$emit('category-deselected');
        this.$store.commit('filters/setToolType', null);
        this.$emit('tooltype-selected', null);
      } else {
        this.selectedToolType = category.id;
        this.$store.commit('filters/setToolType', category.id);
        this.$emit('tooltype-selected', category);
      }
    }
    },
    created() {
      this.loadCategories23();
    }
}


</script>

<style scoped>

.gp-tool-type-browse-section {
    margin-left:-65px;
    padding:30px;
  }

@media screen and (max-width:1600px) {
  .gp-tool-type-browse-section {
    margin-left:0px;
  }
}

</style>