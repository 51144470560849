<!--Not used / Replaced by RemoteContentAbout2-->
<template>
  <arrow-filters style="margin-bottom: 60px;"/>
  <cms-submenu :activeItem="activeItem" />
  <div class="" v-html="liveHtml"/>
</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";
import CmsSubmenu from "@/components/layout/CmsSubmenu";

export default {
  name: "RemoteContentAbout",
  components: {ArrowFilters, CmsSubmenu},
  data() {
    return {
      html: '',
      activeItem: 'Timeline'
    }
  },
  computed: {
    liveHtml() {
      return this.html;
    }
  },
  async created() {
    let my_lang = this.$store.getters['app/locale'];
    let response = await this.$store.getters['app/axios'].get('remote-content-about.php?lang=' + my_lang);
    this.html = response.data;

    console.log("...1");  //αυτο ειναι οκ

    //document.addEventListener("DOMContentLoaded", function() {
    //document.querySelector(function(){
    window.addEventListener('load', function () {
      //δεν μπαίνει καθόλου εδώ
      console.log("...2");

      let year_dots = document.getElementsByClassName('year-dot');
      let fake_year_dots = document.getElementsByClassName('year-dot');
      let iframe = document.querySelector('iframe');
      let yd = document.querySelector('.year-dot');

      //iframe.style.height = '200px';
      // iframe[0].style.height = 200;

      console.log({iframe: iframe});
      console.log({yd: yd});
      // console.log({iframe0: iframe[0]});
      console.log({test: document.getElementById('primary')});
      console.log({fake_year_dots: fake_year_dots});
      console.log({year_dots: year_dots});     //<= παίζει OK, φέρνει τη Nodelist // τωρα δεν παιζει καθολου
      console.log({year_dots_length: year_dots.length}); //<= επιστρέφει 0 // τωρα δεν παιζει καθολου

    });

    

  },
  async mounted() {
    //let response = await this.$store.getters['app/axios'].get('remote-content-about.php')
    //this.html = response.data;

    console.log("...11");  //αυτο ειναι οκ

    document.addEventListener("DOMContentLoaded", function() {
    //document.querySelector(function(){
    //window.addEventListener('load', function () {
      
      console.log("...21"); //ok

      let year_dots = document.getElementsByClassName('year-dot');
      console.log(year_dots);     //<= παίζει OK, φέρνει τη Nodelist // τωρα δεν παιζει καθολου
      console.log(year_dots.length); //<= επιστρέφει 0 // τωρα δεν παιζει καθολου

    });

    

  }
}
</script>

<style scoped>

</style>

<style>

#iframe-about {
  height:1600px;
}

#iframe-about:focus-visible {
  outline: none;
}

@media (max-width:1680px) {
#iframe-about {
  height:1890px;
}  
}

@media (max-width:1440px) {
#iframe-about {
  height:1880px;
}  
}

@media (max-width:1366px) {
#iframe-about {
  height:1900px;
}  
}

@media (max-width:1280px) {
#iframe-about {
  height:2190px;
}  
}

@media (max-width:1024px) {
#iframe-about {
  height:2280px;
}  
}

@media (max-width:800px) {
#iframe-about {
  height:2500px;
  width: calc(100% + 44px) !important;
  margin-left:-22px;
  margin-right:-22px;
}  
}



</style>