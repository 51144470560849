<template>
  <div id="home-wrapper" style="overflow-x:hidden">
    <!-- DIN TYPE METRICS -->
    <arrow-filters class="mb-16--" :content-min-height="'50px'"/>

    <div class="mobile-spacer"></div>

    <div class="main-slider" style="display:none">
      
      <transition name="slide">
        <div class="main-slide main-slide-instant-order" v-if="current_main_slide == 1"
          style="">
          <div class="main-slide-inner home-margin-left"  
            style="">
            <a v-if="lang_gr" href="instant-order">
              <img class="slider-desktop-img" src="../assets/images/slide-instant-order-gr.png" />
              <img class="slider-mobile-img" src="../assets/images/slide-instant-order-mobile.png" /> 
            </a>
            <a v-else href="instant-order">
              <img class="slider-desktop-img" src="../assets/images/slide-instant-order-en.png" />
              <img class="slider-mobile-img" src="../assets/images/slide-instant-order-mobile.png" />
            </a>
            
          </div>
        </div>
      </transition>

      <transition name="slide">
        <div class="main-slide main-slide-offer202402" v-if="current_main_slide == 2"
          style="">
          <div class="main-slide-inner home-margin-left"  
            style="">
            <a v-if="lang_gr" href="offer-2024-02-bahco-screwdrivers">
              <img class="slider-desktop-img" src="../assets/images/slide-offer202402-dt-gr.png" />
              <img class="slider-mobile-img" src="../assets/images/slide-offer202402-mob-gr.png" /> 
            </a>
            <a v-else href="offer-2024-02-bahco-screwdrivers">
              <img class="slider-desktop-img" src="../assets/images/slide-offer202402-dt-en.png" />
              <img class="slider-mobile-img" src="../assets/images/slide-offer202402-mob-en.png" />
            </a>
            
          </div>
        </div>
      </transition>

      <transition name="slide">
        <div class="main-slide main-slide3" v-if="current_main_slide == 3"
          style="">
          <div class="main-slide-inner home-margin-left" 
            style="">
            <img v-if="lang_gr" class="slider-desktop-img" src="../assets/images/home-slide3-gr-2x.png" />
            <img v-else class="slider-desktop-img" src="../assets/images/home-slide3-en-2x.png" />
            <img v-if="lang_gr" class="slider-mobile-img" src="../assets/images/home-slide3-mobile-gr-2x.png" />
            <img v-else class="slider-mobile-img" src="../assets/images/home-slide3-mobile-en-2x.png" />
          </div>
        </div>
      </transition>
        
      <transition name="slide">
        <div class="main-slide main-slide1" v-if="current_main_slide == 4" 
          style="">
          <div class="main-slide-inner home-margin-left"  
            style="">          
            <img v-if="lang_gr" class="slider-desktop-img" src="../assets/images/home-slide1-gr-2x.png" />
            <img v-else class="slider-desktop-img" src="../assets/images/home-slide1-en.png" />
            <img v-if="lang_gr" class="slider-mobile-img" src="../assets/images/home-slide1-mobile-gr-2x.png" /> 
            <img v-else class="slider-mobile-img" src="../assets/images/home-slide1-mobile-en-2x.png" />         
          </div>
        </div>
      </transition>
        
      
        
      <transition name="slide">
        <div class="main-slide main-slide4" v-if="current_main_slide == 5"
        style="">
          <div class="main-slide-inner home-margin-left" 
            style="">
            <a v-if="lang_gr" href="offer202308novopanovides">
              <img class="slider-desktop-img" src="../assets/images/home-slide4-gr-2x.png" />
              <img class="slider-mobile-img" src="../assets/images/home-slide4-mobile-gr-2x.png" />
            </a>
            <a v-else href="offer202308novopanovides">
              <img class="slider-desktop-img" src="../assets/images/home-slide4-en-2x.png" />
              <img class="slider-mobile-img" src="../assets/images/home-slide4-mobile-en-2x.png" />
            </a>
            
          </div>
        </div>
      </transition>

      

      

      <div class="main-slider-nav-dots home-margin-left" style="/*display:none*/" >
        <div :class="{ active: current_main_slide==1 }" class="main-slider-nav-dot" @click="sliderGotoSlide(1)"></div>
        <div :class="{ active: current_main_slide==2 }" class="main-slider-nav-dot" @click="sliderGotoSlide(2)"></div>
        <div :class="{ active: current_main_slide==3 }" class="main-slider-nav-dot" @click="sliderGotoSlide(3)"></div>
        <div :class="{ active: current_main_slide==4 }" class="main-slider-nav-dot" @click="sliderGotoSlide(4)"></div>
        <div :class="{ active: current_main_slide==5 }" class="main-slider-nav-dot" @click="sliderGotoSlide(5)"></div>
      </div>

    </div>


    <router-link
        class="banner-link text-medium" 
        to="/how-we-work" >
        <div class="home-top-banner-1" style="color:#fff;">
          <h2 class="text-semibold" style="">
            <span v-if="lang_gr">Κάποια πράγματα δεν 
            πρέπει να είναι ακριβά.</span>
            <span v-else>Somethings are not meant to be expensive</span>
          </h2>
          
          <p class="text-medium subtitle" style="">
            <span v-if="lang_gr">Βίδες για τα πάντα, για παντού, για κάθε φορά.</span>
            <span v-else>Bolts for everything, for everywhere, for everytime.</span>
          </p>
          
          <router-link
            class="banner-link banner-link-1 text-medium" 
            to="/how-we-work" 
            style="">
            <span v-if="lang_gr">Μάθετε περισσότερα</span>
            <span v-else>Learn more</span> <img src="../assets/svg/arrow-right-yellow.svg" /></router-link>
        </div>
      </router-link>

      
    <div class="top-banners-2-3" style="">
      <router-link
        class="banner-link text-medium" 
        to="/instant-order-main" >
        <div class="home-top-banner-2" style="color:#fff;padding-top:50px">
          
          <h2 class="text-semibold" style="">
            Tsamouris Instant Order<sup>©</sup>
          </h2>
          <p class="text-medium subtitle" style="">
            <span v-if="lang_gr">Παραγγείλετε άμεσα online,<br/> 
            χωρίς να μπείτε σε ένα-ένα τα προϊόντα.</span>
            <span v-else>Order online without selecting products<br/> 
            one by one.</span>
          </p>
          
          <router-link
          class="banner-link text-medium" 
          to="/instant-order-main" 
          style="color: #fff;">
          <span v-if="lang_gr">Μάθετε περισσότερα</span>
          <span v-else>Learn more</span> <img src="../assets/svg/arrow-right-white.svg" /></router-link>

        </div>
      </router-link>

      <router-link
        class="banner-link text-medium" 
        to="/product/23488/?itemcode=T020505_256011">  
        <div class="home-top-banner-3" style="color:#fff;padding-top:45px">
        
          <!-- <h2 class="text-semibold" style="">
            <span v-if="lang_gr">Έξτρα έκπτωση 10%<br/>σε όλα τα <br/>εργαλεία Irimo <br/> 
              για τον Ιούλιο</span>
            <span v-else>Extra 10% discount<br/>on all <br/>Irimo tools<br/> 
            for July</span>
          </h2>
          
          <router-link
          class="banner-link text-medium" 
          to="/irimo-tools" 
          style="color: #212121;">
          <span v-if="lang_gr">Μάθετε περισσότερα</span>
          <span v-else>Learn more</span> <img src="../assets/svg/arrow-right-black.svg" /></router-link>  -->

          <!--<h2 class="text-semibold" style="">
            <span v-if="lang_gr"><span style="font-size:25px; line-height:28px">ΠΡΟΣΦΟΡΑ</span><br/>Σετ γερμανοπολύγωνων <br/>καστάνιας Irimo<br/><span style="font-size:25px; line-height:28px">-30%</span> 
              </span>
            <span v-else>OFFER<br/>Irimo Set Ratcheting  <br/>Combination Wrenches<br/> 
              -15%</span>
          </h2>
          
          <router-link
          class="banner-link text-medium" 
          to="/product/22679/?itemcode=T020204_18-8-H" 
          style="color: #212121;">
          <span v-if="lang_gr" style="padding-left:45px">Μάθετε περισσότερα</span>
          <span v-else style="padding-left:25px">Learn more</span> <img src="../assets/svg/arrow-right-black.svg" /></router-link>

        -->

      <h2 class="text-semibold" style="">
        <span v-if="lang_gr"><span style="font-size:25px; line-height:28px">Προσφορά</span><br/>Irimo Σφιγκτήρας F <br/>&nbsp;<br/><span style="font-size:25px; line-height:28px">Εξοικονομήστε 10%</span> 
          </span>
        <span v-else>Offer<br/>Irimo F-Clamp<br/>&nbsp;<br/> 
          Save 10%</span>
      </h2>
          
      <router-link
      class="banner-link text-medium" 
      to="/product/23488/?itemcode=T020505_256011" 
      style="color: #fff;">
      <span v-if="lang_gr" style="padding-left:45px">Μάθετε περισσότερα</span>
      <span v-else style="padding-left:25px">Learn more</span> <img src="../assets/svg/arrow-right-white.svg" /></router-link>

    </div>
  

    </router-link>
  </div>



    <div class="slider-rows">
      <div class="slider-row slider-row-1">
        <div v-if="lang_gr" class="slider-info" style="">
          <h2 class="text-semibold">Βίδες <br/>Συνδετικά Υλικά</h2>
          <p style="font-weight: 500;">
            Ό,τι θελετε να βρείτε <br/>σε 28.000 προϊόντα
          </p>
        </div>
        <div v-else class="slider-info" style="">
          <h2 class="text-semibold">Fasteners</h2>
          <p style="font-weight: 500;">
            Everything you need <br/>among 28.000 products
          </p>
        </div>
        
        <!-- parent categories slider -->
        <custom-slider
            ref="homeCategoriesSlider"
            class="parent-categories-slider home-margin-left"
            :slide-count="homeCategories.length"
            :slide-width="responsiveSlideWidth"
            :slide-height="222"
            :debug="false"
            :scrollbar="isMobile"
            :corner-prev-next="true"
        >
            <home-slider-item
                v-for="category in homeCategories"
                :item="category"
                :key="category.id"
                :ref="'slide-'+category.id"
            ></home-slider-item>
        </custom-slider>
      </div>

      <div class="slider-row slider-row-2">
        
        <div v-if="lang_gr" class="slider-info" style="">
          <h2 class="text-semibold">Εργαλεία Χειρός</h2>
          <p style="font-weight: 500;">
            Ό,τι θέλετε να βρείτε <br/>σε 17.000 προϊόντα
          </p>
        </div>
        <div v-else class="slider-info" style="">
          <h2 class="text-semibold">Hand Tools</h2>
          <p style="font-weight: 500;">
            Everything you need <br/>among 17.000 products
          </p>
        </div>

        <!-- parent categories slider -->
        <custom-slider
            ref="homeCategories2Slider"
            class="parent-categories-slider home-margin-left"
            :slide-count="homeCategories2.length"
            :slide-width="responsiveSlideWidth"
            :slide-height="222"
            :debug="false"
            :scrollbar="isMobile"
            :corner-prev-next="true"
        >
            <home-slider-item
                v-for="category in homeCategories2"
                :item="category"
                :key="category.id"
                :ref="'slide-'+category.id"
            ></home-slider-item>
        </custom-slider>

      </div>

      <div class="slider-row slider-row-3">
        
        <div v-if="lang_gr" class="slider-info" style="">
          <h2 class="text-semibold">Ηλεκτρικά <br/>Εργαλεία</h2>
          <p style="font-weight: 500;">
            Δύναμη και αντοχή  <br/>στα χέρια σας
          </p>
        </div>
        <div v-else class="slider-info" style="">
          <h2 class="text-semibold">Power Tools</h2>
          <p style="font-weight: 500;">
            Strength and endurance  <br/>in your hands
          </p>
        </div>

        <!-- parent categories slider -->
        <custom-slider
            ref="homeCategories3Slider"
            class="parent-categories-slider home-margin-left"
            :slide-count="homeCategories3.length"
            :slide-width="responsiveSlideWidth"
            :slide-height="222"
            :debug="false"
            :scrollbar="isMobile"
            :corner-prev-next="true"
        >
            <home-slider-item
                v-for="category in homeCategories3"
                :item="category"
                :key="category.id"
                :ref="'slide-'+category.id"
            ></home-slider-item>
        </custom-slider>

      </div>

    </div>
    


    <div class="row-3">
      <h2 v-if="lang_gr" class = "text-semibold home-margin-left h2-banner">
        Αποκτήστε την υποστήριξη που θέλετε.
      </h2>
      <h2 v-else class = "text-semibold home-margin-left h2-banner">
        Get the support you deserve
      </h2>


      <div v-if="lang_gr" class="home-margin-left banners-container">
          <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
            <a href="/info-contact">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-ask-specialist-gr.jpg" />
            </a>
          </div>
          <div class="extra-sect" style="display:flex; flex-direction: column; justify-content: space-between;margin-right: 20px;">
            <div class="home-banner">
              <a href="/info-contact">
                <img class="home-banner-img-xx" src="../assets/images/home-banner-contact2-gr.png" />
              </a>
            </div>
            <div class="home-banner">
              <a href="/info-find-product">
                <img class="home-banner-img-xx" src="../assets/images/home-banner-find-products-gr.jpg" />
              </a>
            </div>
          </div>
          
      </div>
      <div v-else class="home-margin-left banners-container">
          <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
            <a href="/en/info-contact">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-ask-specialist-en.jpg" />
            </a>
            
          </div>
          <div class="extra-sect" style="display:flex; flex-direction: column; justify-content: space-between;margin-right: 20px;">
            <div class="home-banner">
              <a href="/en/info-contact">
                <img class="home-banner-img-xx" src="../assets/images/home-banner-contact2-en.png" />
              </a>
            </div>
            <div class="home-banner">
              <a href="/en/info-find-product">
                <img class="home-banner-img-xx" src="../assets/images/BANNERS_BIG-03.jpg" />
              </a>            
            </div>
          </div>

          

      </div>
    </div>
    

    <div class="row-4">
      <h2 v-if="lang_gr" class="text-semibold home-margin-left h2-banner">
        Η διαφορά της Τσαμούρης.  <span style="color:#636363">Ακόμα περισσότεροι λόγοι να αγοράζετε απο εμάς.</span>
      </h2>
      <h2 v-else class="text-semibold home-margin-left h2-banner">
        The Tsamouris difference. <span style="color:#636363">Even more reasons to shop with us</span>
      </h2>

      <div v-if="lang_gr" class="home-margin-left banners-container swipe">
          <div class="bnr home-banner" style="margin-right: 20px;">
            <a href="/info-big-stock">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-bigstock-gr.jpg" />
            </a>
          </div>
          <div class="bnr home-banner" style="margin-right: 20px;">
            <a href="/info-discount3">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-3-discount-gr.jpg" />
            </a>
          </div>
          <div class="bnr home-banner" style="position:relative;margin-right: 20px;">
            <a href="/info-how-to-order">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-3d-gr.jpg" />
              <img src="../assets/images/wireframe-cube.png" style="position:absolute;top: -5%; left: 40%; width: 60%;" />
            </a>
          </div>
      </div>
      <div v-else class="home-margin-left banners-container">
          <div class="bnr home-banner" style="margin-right: 20px;">
            <a href="/en/info-big-stock">
              <img class="home-banner-img-xx" src="../assets/images/BANNERS_BIG-04.jpg" />
            </a>
          </div>
          <div class="bnr home-banner" style="margin-right: 20px;">
            <a href="/en/info-discount3">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-3-discount-en.png" />
            </a>
          </div>
          
          <div class="bnr home-banner" style="position:relative;margin-right: 20px;">
            <a href="/info-how-to-order">
              <img class="home-banner-img-xx" src="../assets/images/home-banner-3d-en.jpg" />
              <img src="../assets/images/wireframe-cube.png" style="position:absolute;top: -5%; left: 40%; width: 60%;" />
            </a>
          </div>

      </div>
    </div>
    

    <div class="row-5">
      <h2 v-if="lang_gr" class="text-semibold home-margin-left h2-banner">Άρθρα - Νέα</h2>
      <h2 v-else class="text-semibold home-margin-left h2-banner">Articles - News</h2>

      <div v-if="lang_gr" class="home-margin-left banners-container">
          
        <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
          <a href="/article/metal-machinery-2023">
            <img class="home-banner-img-xx" src="../assets/images/home-article-1.jpg" />
          </a>
        </div>

        <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
          <a href="/article/hydrogen-embrittlement-ο-σιωπηλός-δολοφόνος">
            <img class="home-banner-img-xx" src="../assets/images/home-article-2.jpg" />
          </a>
        </div>
      
               

        <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
          <a href="/article/η-toyota-ανακαλεί-800-000-οχήματα-για-ένα-γρήγορο-clip">
            <img class="home-banner-img-xx" src="../assets/images/home-article-3.jpg" />
          </a>
        </div>

          
          

      </div>
      <div v-else class="home-margin-left banners-container">
        <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
            <a href="/en/article/metal-machinery-2023">
              <img class="home-banner-img-xx" src="../assets/images/home-article-1.jpg" />
            </a>
          </div>

          <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
            <a href="/en/article/hydrogen-embrittlement-the-silent-killer">
              <img class="home-banner-img-xx" src="../assets/images/home-article-2.jpg" />
            </a>
          </div>

                   

          <div class="first-img-extra-section home-banner" style="margin-right: 20px;">
            <a href="/en/article/toyota-recalls-800000-vehicles-for-a-quick-clip-fix">
              <img class="home-banner-img-xx" src="../assets/images/home-article-3.jpg" />
            </a>
          </div>                

      </div>
    </div>
    


    <div class="row-6">
      <h2 v-if="lang_gr" class="text-semibold home-margin-left h2-banner">Αποκλειστικές Προσφορές</h2>
      <h2 v-else class="text-semibold home-margin-left h2-banner">Exclusive Savings</h2>

      <div v-if="lang_gr" class="home-margin-left banners-container-100">
        <router-link
        class="banner-link text-medium" 
        to="/offers">
          <div class="bnr home-banner home-banner-offers" style="margin-right: 20px;">
            <h3 class="text-semibold" style="">Χώρα των προσφορών</h3>
            <p class="text-medium subtitle" style="">Ανταμοιφθείτε με αγορές<br/>
            από τις προσφορές.</p>
            
            <router-link
            class="banner-link text-medium" 
            to="/offers" 
            style="">Μάθετε περισσότερα <img src="../assets/svg/arrow-right-black.svg" /></router-link>
            
          </div>
      </router-link>
      </div>

      <div v-else class="home-margin-left banners-container-100">
        <router-link
        class="banner-link text-medium" 
        to="/offers">
          <div class="bnr home-banner home-banner-offers" style="margin-right: 20px;">
            <h3 class="text-semibold" style="">Land of Offers</h3> 
            
            <p class="text-medium subtitle" style="">Get rewards<br/>
            for every offer purchase.</p>
            
            <router-link
          class="banner-link text-medium" 
          to="/offers" 
          style="color: #212121;font-size: 18px;">Buy Now <img src="../assets/svg/arrow-right-black.svg" /></router-link>
          </div>
        </router-link>
      </div>

    </div>




  </div>

  <!--Promo modal-->
  <div class="backdrop" v-if="popupOfferVisible" ></div>

  <transition name="modal">
    <dialog id="promo-modal" open v-if="popupOfferVisible" >
      <div style="text-align: center; padding:15px; position:relative">
        <span style="font-size:20px; font-weight:500px; color:#fff; cursor:pointer" 
          @click="popupOfferVisible = false">Κλείσιμο</span>
        <img src="../assets/svg/close.svg" 
          style="position:absolute; top:10px; right:35px; cursor:pointer" 
          @click="popupOfferVisible = false" />
      </div>
      <a href="/offer202304">
        <img src="../assets/images/offer-2023-04-desktop.png" style="width:949px; height:auto; max-width: 99%; display:inline-block" />
      </a>
    </dialog>
  </transition>
  <transition name="modal">
    <dialog id="promo-modal-mobile" open v-if="popupOfferVisible" >
      <div style="text-align: right; padding:15px; position:relative">
        <span style="font-size:14px; font-weight:500px; color:#fff; cursor:pointer" 
          @click="popupOfferVisible = false">Κλείσιμο</span>
        <img src="../assets/svg/close.svg" 
          style="position:absolute; top:15px; left:15px; width:26px; cursor:pointer" 
          @click="popupOfferVisible = false" />
      </div>
      <a href="/offer202304">
        <img src="../assets/images/offer-2023-04-mobile.png" style="width:386px; height:auto; max-width: 99%; display:inline-block" />
      </a>
      
    </dialog>

  </transition>

  <!--<div id="show-promo-modal" v-if="!popupOfferVisible" 
    style="position:fixed; top:0px; right:0px; padding:5px 10px; background:#000; color:#fff; border-bottom-left-radius: 10px; cursor:pointer; font-size: 13px;"
    @click="popupOfferVisible = true"
    >
    ΠΡΟΣΦΟΡΑ
  </div>-->

  <!--<a id="show-promo-modal"
    v-if="user_login" 
    style="position:fixed; top:0px; right:0px; padding:5px 10px; background:#000; color:#fff; border-bottom-left-radius: 10px; cursor:pointer; font-size: 13px;"
    href="offers"
    >ΠΡΟΣΦΟΡΕΣ</a>-->
  



</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";
import CustomSlider from "@/components/custom-slider/CustomSlider";
import HomeSliderItem from "@/components/home/HomeSliderItem";

export default {
  name: 'HomePage',
  components: {
    HomeSliderItem,
    CustomSlider,
    ArrowFilters,
  },
  inject: ['exitDesktopFullscreen', 'openDialog', 'showNotification'],
  data() {
    return {
      popupOfferVisible: false,
      user_login: false,
      current_main_slide: 1,
      /*mainslide1_display: 'block',
      mainslide2_display: 'none',
      mainslide3_display: 'none',
      mainslide4_display: 'none',*/
      mainslide1_class: 'main-slide-visible',
      mainslide2_class: 'main-slide-hidden',
      mainslide3_class: 'main-slide-hidden',
      mainslide4_class: 'main-slide-hidden',
      mainslide5_class: 'main-slide-hidden',
      sliderInterval: false,
      mainSliderNavDotsColor: 'mainSliderNavDotsColorLight',

    }
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
    homeCategories() {
      console.log("homeCategories=");
      console.log(this.$store.getters['categories/categories']);
      return this.$store.getters['categories/categories'];
    },
    homeCategoriesLoaded() {
      return this.$store.getters['categories/categoriesLoaded'];
    },
    homeCategories2() {
      return this.$store.getters['categories/categories2'];
    },
    homeCategories2Loaded() {
      return this.$store.getters['categories/categories2Loaded'];
    },
    homeCategories3() {
      return this.$store.getters['categories/categories3'];
    },
    homeCategories3Loaded() {
      return this.$store.getters['categories/categories3Loaded'];
    },
    responsiveSlideWidth() {
      return 214;
      /*let w = window.innerWidth;
      if (w<590) {
        return w-40;
      }
      else if (w<1300) {
        return 220;
      }
      else if (w<1400) {
        return 230;
      }
      else if (w<1600) {
        return 260;
      }
      else if (w<1800) {
        return 300;
      }
      else if (w<2200) {
        return 360;
      }
      else {
        return 400;
      }*/

    },
    isMobile() {
      return window.innerWidth <= 800;
    }
    /*mainslide1_display() {
      return this.current_main_slide == 1? 'grid': 'none';
    },
    mainslide2_display() {
      return this.current_main_slide == 2? 'grid': 'none';
    },
    mainslide3_display() {
      return this.current_main_slide == 3? 'grid': 'none';
    }*/
  },
  methods: {
    setSelectedFilterCategory(value) {
      this.selectedFilterCategory = value;
    },
    async loadHomeCategories() {
      const self = this;
      try {
        await this.$store.dispatch('categories/loadCategories', {
          forceRefresh: true,
        });

        this.$refs['homeCategoriesSlider'].setElements();
        //this.$refs.homeCategoriesSlider.setElements();
        
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message + '...1....'
        });
      }
    },
    async loadHomeCategories2() {
      const self = this;
      try {
        await this.$store.dispatch('categories/loadCategories2', {
          forceRefresh: true,
        });

        this.$refs['homeCategories2Slider'].setElements(); 
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message + '...2....'
        });
      }
    },
    async loadHomeCategories3() {
      const self = this;
      try {
        await this.$store.dispatch('categories/loadCategories3', {
          forceRefresh: true,
        });

        this.$refs['homeCategories3Slider'].setElements(); 
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message + '...3....'
        });
      }
    },
    sliderGotoSlide(val) {
      var my = this;
      clearInterval(my.sliderInterval);
      my.current_main_slide = val;
      my.mainslide1_class = my.current_main_slide == 1? 'main-slide-visible': "main-slide-hidden";
      my.mainslide2_class = my.current_main_slide == 2? 'main-slide-visible': "main-slide-hidden";
      my.mainslide3_class = my.current_main_slide == 3? 'main-slide-visible': "main-slide-hidden";
      my.mainslide4_class = my.current_main_slide == 4? 'main-slide-visible': "main-slide-hidden";
      my.mainslide5_class = my.current_main_slide == 5? 'main-slide-visible': "main-slide-hidden";

      //continue slide autoplay
      my.sliderInterval = setInterval(function () {      
        if (my.current_main_slide<4) {
          my.current_main_slide++;
        }
        else {
          my.current_main_slide = 1;
        }
        my.mainslide1_class = my.current_main_slide == 1? 'main-slide-visible': "main-slide-hidden";
        my.mainslide2_class = my.current_main_slide == 2? 'main-slide-visible': "main-slide-hidden";
        my.mainslide3_class = my.current_main_slide == 3? 'main-slide-visible': "main-slide-hidden";
        my.mainslide4_class = my.current_main_slide == 4? 'main-slide-visible': "main-slide-hidden";
        my.mainslide5_class = my.current_main_slide == 5? 'main-slide-visible': "main-slide-hidden";
        
      }, 5000);

    },

    
  },
  created() {
    this.loadHomeCategories();
    this.loadHomeCategories2();
    this.loadHomeCategories3();

    if (this.$store.getters['auth/auth']) {
      this.user_login = true;
    }

    /*if (this.$store.getters['auth/auth']) {
      this.showNotification({
        class: 'primary',
        title: 'Ανακοίνωση',
        position: 'top-left',
        html: 'Η εταιρεία θα παραμείνει κλειστή λόγω θερινών διακοπών από 12 έως 23 Αυγούστου. Οι παραγγελίες θα εκτελούνται από 26/08/2024. <br/>Ευχαριστούμε.',
        lifeSpan: 20000,
      });
    }*/

    //localStorage.setItem('enzo', '_fw_');
    //if (localStorage.getItem('role')=="customer")
    /*console.log('gp-2-2-2023=' + localStorage.getItem('gp-2-2-2023'));*/

    /**************
    let dayMS = 1000 * 60 * 60 * 24;
    if (this.$store.getters['auth/auth']) {
      if (localStorage.getItem('popup-offer-4a')!=null) {      
        let date1 = localStorage.getItem('popup-offer-3');
        let currentDate = new Date();
        let date2 = currentDate.getTime();
        let dtDiff =  date2 - date1;
        if (dtDiff / dayMS > 0.1) {
          this.popupOfferVisible = true;
          localStorage.setItem('popup-offer-4a', date2);
        }
      }
      else {
        this.popupOfferVisible = true;
        let currentDate = new Date();
        let date2 = currentDate.getTime();
        localStorage.setItem('popup-offer-4a', date2);
      }
    }
    else {
      this.popupOfferVisible = false;
    }********************/
    this.popupOfferVisible = false;

    //main slider // ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ 1/12/2023
    var my = this;
    my.sliderInterval = setInterval(function () {      
      if (my.current_main_slide<5) {
        my.current_main_slide++;
      }
      else {
        my.current_main_slide = 1;
      }
      my.mainslide1_class = my.current_main_slide == 1? 'main-slide-visible': "main-slide-hidden";
      my.mainslide2_class = my.current_main_slide == 2? 'main-slide-visible': "main-slide-hidden";
      my.mainslide3_class = my.current_main_slide == 3? 'main-slide-visible': "main-slide-hidden";
      my.mainslide4_class = my.current_main_slide == 4? 'main-slide-visible': "main-slide-hidden";
      my.mainslide5_class = my.current_main_slide == 5? 'main-slide-visible': "main-slide-hidden";
      
    }, 5000);
    
    this.$store.commit('filters/clearFilterGroup');

  },
};
</script>

<style scoped>

/* Desktop */
.parent-categories-slider {
  width: 70%; /**74 */
  /*margin-left: 26%;*/
  margin-top: 20px;
}


/* Laptop */
@media screen and (max-width:1600px) {
  .parent-categories-slider {
    width:74%;
    margin-left:26%;
  }
  
}


@media screen and (max-width:1400px) {
  .parent-categories-slider {
    width:76%;
    margin-left:24%;
  }
  
}

@media screen and (max-width:1300px) {
  .parent-categories-slider {
    width:75%;
    margin-left:25%;
  }

    
}


/* Tablet */
@media screen and (max-width:1200px) {
  .parent-categories-slider {
    width:70%;
    margin-left:30%;
  }
  
}


@media screen and (max-width:1200px) and (min-height:1000px) {
  .parent-categories-slider {
    /*margin-top:300px;*/
    margin-top:0px;
  }
}


@media screen and (max-width:820px) {

  .h2-banner {margin-left: 50px;}
  .extra-sect {margin-right: 20px;}
}

@media screen and (max-width:600px) {
.extra-sect {margin-right: 0px !important;}
}

@media screen and (max-width:1000px) {

  .parent-categories-slider {
    width:calc(100% - 50px);
    margin-left:50px;
  }
}

#home-wrapper {
  min-height: 100vh; 
  /* padding-bottom:150px; */
}

@media screen and (max-width:600px) {
  #home-wrapper { 
    position:relative;
    overflow-x: unset !important;
  }  
  .bnr {margin-right:unset !important;}
  .main-slider{
    margin-left: -22px;
    margin-right: -22px;
  }
}

@media screen and (max-width:799px) {
  

  .parent-categories-slider {
    width:100%;
    margin-left:0px;
  }
}




.parent-categories-slider .slider {
  margin-bottom:30px;
}

/* XL - Desktop */
@media screen and (min-width:2200px) {
  .parent-categories-slider {
    width:72%;
    margin-left:30%;
    margin-top:0px;
  }
  
}

@media (max-width:600px) {
  .mobile-spacer {
    height:50px;
  }
}

.slider-rows {
  background-color: #FAFAFA;
  border-bottom:7px solid #fff;
  padding-top:70px;
}


.slider-row-1 {
  height:250px; /**370 */  
  margin-bottom:0px;
}

.slider-row-2 {
  height:260px; 
  padding-top:0px;
  /* padding-bottom:10px; */
  
  /* margin-bottom:35px; */
  /* background-color: #FAFAFA; */
}

.slider-info {
  position:absolute; padding:0px 50px 50px 125px;
}


.slider-info h2 {
  font-size:33px; /**27 */ 
  font-weight:400px; 
  margin-bottom:15px;
  line-height: 35px; /**33 */
}
@media (max-width:1400px) {
.slider-info h2 {
  font-size:25px; /**21px */
  line-height: 25px; /** 22px */
}
.slider-info p {
  line-height: 17px;
}
}

.slider-info p {
  font-size:16px; 
  font-weight:300;
}

@media (max-width:1400px) {
  .slider-info {
    padding:0px 50px 50px 80px;
  }
  .slider-row-1 {
    height:220px;
    padding-top:0px;
    margin-bottom:60px;
  }

  .slider-row-2 {
    /*background:#efefe2;*/
    height:200px;
    padding-top:0px;
    margin-bottom:60px;
  }
}

@media (max-width:1000px) {
.slider-info {
  position:relative; 
  padding:20px 50px;
} 
.slider-row-1 {
  height:auto;
  margin-bottom:50px;
}

.slider-row-2 {
  background:transparent;
  height:auto;
  margin-bottom:50px;
} 
}

@media (max-width:600px) {
.h2-banner {font-size:24px; line-height: 25px;}
.slider-info { 
  padding:10px;
} 
.slider-row-1 {
  height:auto;
  margin-bottom:30px;
}

.slider-row-2 {
  margin-bottom:30px;
} 
}


div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9998;
}

#promo-modal {
  position: fixed;
  top: calc(50vh - 300px);
  z-index: 9999;
  overflow: hidden;
  width: 956px;
  max-width:90%;
  background-color: transparent;
  text-align: center;
}

#promo-modal-mobile {
  position: fixed;
  top: 20px;
  z-index: 9999;
  overflow: hidden;
  width: 383px;
  max-width: 100%;
  background-color: transparent;
  text-align: center;
}

#promo-modal-mobile {
  display:none;
}

@media (max-width:900px) {
  #promo-modal {
    display:none;
  }
  #promo-modal-mobile {
    display:block;
  }

}

.main-slider {
  height:414px; 
  
  position: relative;
  margin-bottom:70px;
  margin-top:-30px;
}

@media (max-width:900px) {
  .main-slider {
    height:750px;
    overflow:hidden;
    padding-bottom: 30px;
  }
}
@media (max-width:450px) {
  .main-slider {
    height:590px; 
    overflow:hidden;
    padding-bottom: 30px;
  }
}

.main-slide {
  height:414px;
  position: absolute; 
  width:100%;  
  background-position: center;
  background-repeat: no-repeat;
  transition:all 1s;
}
@media (max-width:1400px) {
  .main-slide  {
    background-size: 130%;
    background-position-y: 50px;
  }
}

@media (max-width:900px) {
  .main-slide {
    background-size: cover;
    padding:30px 0px;
    height:auto;
    background-position-y: 70px;
  }
}
.main-slide-hidden {  
  display:none;
  opacity:0;
}
.main-slide-visible {  
  display:block;
  opacity:1;
}




.main-slide1 {
  background-image: url("../assets/images/home-slide1-bg.png");
}
.main-slide2 {
  background-image: url("../assets/images/home-slide2a-bg.png");
}
.main-slide3 {
  background-image: url("../assets/images/home-slide3-bg.png");
}
.main-slide4 {
  background-image: url("../assets/images/home-slide4-bg.png");
}
.main-slide5 {
  background-image: url("../assets/images/slide-5-bg.png");
}
.main-slide-offer202401 {
  background-image: url("../assets/images/home-slide-bg-offer-2024-01.png");
}

.main-slide-offer202402 {
  background-image: url("../assets/images/slide-offer202402-bg.jpg");
}
@media (max-width:820px) {
  .main-slide-offer202402 {
    background-image: url("../assets/images/slide-offer202402-mob-bg.png");
  }  
}


.main-slide-instant-order {
  background-image: url("../assets/images/slide-instant-order-bg.png");
}
@media (max-width:820px) {
  .main-slide-instant-order {
    background-image: url("../assets/images/slide-instant-order-bg.png");
  }  
}




.main-slide-offer202312 {
  background-image: url("../assets/images/home-slide-offer202312-bg.png");
}

.main-slide-inner {
  max-width:990px;
  margin-right:30px;
  /*display:grid; 
  max-width:920px;
  grid-template-columns: 30% 30% 40%;*/
}
@media (max-width:900px) {
  .main-slide-inner {
    margin-left:0px !important;
  }
}

.main-slide-title {
  height:400px; display:flex; justify-content: center; align-items: center; font-size:38px; line-height:40px; color:#fff;
}

.main-slide-text {
  font-size:18px; line-height: 27px; display:flex; justify-content: left; align-items: center; color:#fff;
}

.main-slider-nav-dots {
  position: absolute;
  /*left:26%;*/
  bottom:20px;
}
@media (max-width:1400px) {
  .main-slider-nav-dots {
    bottom:40px;
  }
}
@media (max-width:1300px) {
  .main-slider-nav-dots {
    bottom:60px;
  }
}
@media (max-width:900px) {
  .main-slider-nav-dots {
    left: calc(50% - 30px); 
    bottom: 0px;
    margin-left:0px !important;
  }
}

.main-slider-nav-dot {
  width:7px; height:7px;
  border:1px solid #212121;
  border-radius: 5px;
  margin-right:12px;
  display:inline-block;
  cursor:pointer;
}

.main-slider-nav-dots .active {
  background-color: #212121;
}

.home-banner img.home-banner-img-xx {
    border-radius:18px;
    /* overflow:hidden; */
    /* box-shadow: 2px 4px 12px rgba(0,0,0,.08); */
    transition: all .3s cubic-bezier(0,0,.5,1);
    cursor:pointer;
    /* position:relative;  */
}
.home-banner:hover img.home-banner-img-xx {
    box-shadow: 2px 4px 16px rgba(0,0,0,.30); /**0.16 */
    /* transform: scale3d(1.01,1.01,1.01); */
    /* opacity:0.8; */
    filter: brightness(103%);
-webkit-filter: brightness(103%);
-moz-filter: brightness(103%);
}

.home-banner-offers {
  border-radius:18px;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  transition: all .3s cubic-bezier(0,0,.5,1);
  cursor:pointer;
  height:483px;
  background-image: url("../assets/images/home-banner-offers.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top:40px;
  padding:40px 20px 20px;
  text-align: center;
}
.home-banner-offers h3 {
  font-size: 33px; margin-bottom:15px;
}
.home-banner-offers .subtitle {
  font-size: 18px; line-height:20px; margin-bottom:35px;
}
.home-banner-offers .banner-link {
  color: #212121;font-size: 18px;
}
@media (max-width:1300px) {
  .home-banner-offers {
    height:400px;
    padding-top:25px;
  }
}
@media (max-width:600px) {
  .home-banner-offers {
    height:480px;
    padding-top:25px;
  }
  .home-banner-offers h3 {
    font-size: 24px; 
    margin-bottom:15px;
  }
  .home-banner-offers .subtitle {
    font-size: 15px;
    line-height:23px; 
    margin-bottom:35px;
  }
  .home-banner-offers .banner-link {
    font-size: 16px;
  }
}

.home-banner-offers:hover {
    box-shadow: 2px 4px 16px rgba(0,0,0,.40); /**0.16 */
    /* opacity:0.8; */
    filter: brightness(103%);
-webkit-filter: brightness(103%);
-moz-filter: brightness(103%);
}

.home-banner {
    /* border-radius:18px; */
    /* overflow:hidden; */
    /* box-shadow: 2px 4px 12px rgba(0,0,0,.08); */
    transition: all .3s cubic-bezier(0,0,.5,1);
    /* cursor:pointer; */
    position:relative; /** .... */
}
.home-banner:hover {
    /* box-shadow: 2px 4px 16px rgba(0,0,0,.40); */
    transform: scale3d(1.01,1.01,1.01);
    /* opacity:0.8; */
}


.home-banner-img {
  -webkit-filter: drop-shadow(1px 1px 2px #22222288);
  filter: drop-shadow(1px 1px 3px #22222288);
  transition: all 0.5s;
}

.home-banner:hover .home-banner-img {
  -webkit-filter: drop-shadow(3px 3px 5px #22222288);
  filter: drop-shadow(3px 3px 5px #22222288);
  translate: 0px -3px;
}

.h2-banner {
  font-size:33px; 
  margin-bottom: 20px;
}
@media (max-width:1400px) {
  .h2-banner {
    font-size:25px;
  } 
}

.banners-container {
  display:grid; 
  grid-template-columns: 33% 33% 33%;
  margin-bottom: 30px;
  margin-right:90px;
}
.banners-container-100 {
  margin-right:100px;
  margin-bottom: 40px;
}


@media (max-width:1300px) {
  .banners-container {
    margin-right:40px;
  }
  .banners-container-100 {
    margin-right:50px;
  }
}
@media (max-width:1000px) {
  .banners-container {
    margin-right:0px;
  }
  .banners-container-100 {
    margin-right:10px;
  }
}

.banners-container-100 img {
  width:100%;
}
@media (max-width:1400px) {
  .banners-container {
    margin-bottom: 35px;
  } 
}
@media (max-width:590px) {
  .banners-container {
    gap: 10px;
    flex-direction: column;
    margin-left:unset !important;
  } 
  .extra-sect{
    justify-content: space-between;
    gap: 10px;
  }
  .first-img-extra-section{
    margin-right: unset !important;
  }
  .main-slider {
    /*background-image: url("../assets/images/home-slider-background-mobile.png");*/
    /*height:420px;*/
    margin-left:-22px;
    margin-right:-22px;
    margin-bottom:50px;
  }
  .slider-mob-element1 {
    order:1; 
    /*width: 45% !important;*/
    margin-top: -20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;}
  .slider-mob-element2 { width:37% !important; order:2; font-size:22px !important; max-width: 200px !important; height: 90px !important;
    margin: auto !important;
    text-align: center !important;
    line-height: 25px !important;}
  .slider-mob-element3 {width:100% !important; order:3; font-size:13px !important; justify-content: center !important; line-height: 20px !important; max-width: 330px;
    text-align: center;
    margin: auto;}
  .home-margin-left {grid-template-columns:100% !important; margin-left: unset;}
 
  #home-wrapper{overflow-x:unset;}
  .slider-info p {
    font-size:14px !important; 
  }
  .slider-info h2 {
    font-size:24px !important;
  }
  
  
}

@media (max-width:820px) {
  .banners-container {
    margin-left: 50px;
  }
  .banners-container-100 {
    margin-left: 50px;
  }
}

@media (max-width:750px) {
  .banners-container-100 {
    margin-left: 0px;
  }
}

@media (max-width:375px) {
.slider-mob-element1 { 
  /*width: 52% !important;*/
}

.slider-info p {
  font-size:14px !important; 
}
.slider-info h2{ 
  font-size:24px !important;
} 
}


@media (max-width:360px) {
  .extra-sect{margin-right:0px;}
  .slider-mob-element1 { 
    /*width: 45% !important;*/
  }
}


.slider-mobile-img {
  display:none;
}

@media (max-width:900px) {
  .slider-desktop-img {
    display:none;
  }
  .slider-mobile-img {
    display:block;
    max-width:500px;
    width:98%;
    margin:auto;
  }
}


.home-top-banner-1 {
  height:637px; text-align:center;
  padding-top:50px;
  /* background-image:url("../assets/images/home-top-banner-2024-1.jpg"); */
  background-image:url("../assets/images/HERO.jpg");
  background-position: center;
  background-size: cover;
  margin-top:-50px;
}
/* @supports (background-image: url("../assets/images/HERO.webp")) {
  .home-top-banner-1 {
    background-image: url("../assets/images/HERO.webp");
  }
} */

@media (max-width:800px) {
  .home-top-banner-1  {
    margin-top:50px;
        
  }
}

@media (max-width:750px) {
  .home-top-banner-1  {
    margin-top:0px;
    margin-left:-20px;
    margin-right:-20px;
    height:500px;
    background-image:url("../assets/images/HERO-mobile.jpg");
    padding-left:30px;
    padding-right:30px;
  }
}

.home-top-banner-2 {
  height: 440px; text-align:center;
  background-image:url("../assets/images/home-top-banner-2024-2.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width:750px) {
  .home-top-banner-2  {
    margin-top:0px;
    margin-left:-20px;
    margin-right:-20px;
    height:500px;
    background-image:url("../assets/images/home-top-banner-2024-2-mobile.jpg");
  }
}

.home-top-banner-3 {
  height: 440px; text-align:center;
  /* background-image:url("../assets/images/home-top-banner-2024-3.jpg"); */
  /* background-image:url("../assets/images/offer-2024-07-irimo-950x440.jpg"); */
  /* background-image:url("../assets/images/offer-2024-06-desktop.jpg"); */
  background-image:url("../assets/images/offer-irimo-piston-f-clamp-2024-10.jpg");
  
  background-position: center;
  background-size: cover;
  background-color:#6fffff;
}
@media (max-width:1000px) {
  .home-top-banner-3  {
    margin-top:0px;
    margin-left:-20px;
    margin-right:-20px;
    height:500px;
    /* background-image:url("../assets/images/home-top-banner-2024-3-mobile.jpg"); */
    /* background-image:url("../assets/images/offer-2024-06-tablet.jpg"); */
    /* background-image:url("../assets/images/offer-2024-07-irimo-750x530.jpg"); */
    /* display:none; */

    background-image:url("../assets/images/offer-irimo-piston-f-clamp-2024-10-tablet.jpg");
  }
}
@media (max-width:750px) {
  .home-top-banner-3  {
    margin-top:0px;
    margin-left:-20px;
    margin-right:-20px;
    height:500px;
    /* background-image:url("../assets/images/home-top-banner-2024-3-mobile.jpg"); */
    /* background-image:url("../assets/images/offer-2024-06-mobile.jpg"); */
    /* background-image:url("../assets/images/offer-2024-07-irimo-390x440.jpg"); */
    /* display:none; */
    background-image:url("../assets/images/offer-irimo-piston-f-clamp-2024-10-mobile.jpg");
  }
}

.row-3 {
  border-bottom:7px solid #fff;
  padding-top:50px;
  background-color: #FAFAFA;
}

.row-4 {
  border-bottom:7px solid #fff;
  padding-top:50px;
  /* margin-bottom:50px; */
  background-color: #FAFAFA;
}

.row-5 {
  border-bottom:7px solid #fff;
  padding-top:50px;
  /* margin-bottom:50px; */
  background-color: #FAFAFA;
}

.row-6 {
  /* margin-bottom:50px; */
  padding-top:50px;
  border-bottom: 150px solid #fff;
  background-color: #FAFAFA;
}

@media (max-width:750px) {
  .slider-rows, .row-3, .row-4, .row-5, .row-6 {
    margin:0px -20px;
    padding-left:20px;
    padding-right:20px;
  }
}

@media (min-width:750px) and (max-width:1000px) {
  .row-3, .row-4, .row-5 {
    overflow-x:scroll;
  }
  .banners-container {
    width:1500px;
  }
}




.banner-link img {
  transition:all 0.5s;
  display:inline-block;
  width:25px;
  vertical-align: middle;
  margin-left:5px;
}

.banner-link:hover img {
  translate:10px 0px;
}

.top-banners-2-3 {
  background-color: #fff;
  padding:7px;
  display:grid;
  grid-template-columns: 1fr 1fr; gap:7px;
}
@media (max-width:1000px) {
  .top-banners-2-3 {
    grid-template-columns: 1fr;
    padding:7px 0px;
  }
}

.home-top-banner-1 h2 {
  font-size: 38px; line-height: 43px; max-width: 450px;margin:auto; margin-bottom:20px
}
@media (max-width:600px) {
  .home-top-banner-1 h2 {
    font-size: 27px; line-height: 32px;
  }
}

.home-top-banner-1 .subtitle {
  font-size: 18px;margin-bottom:35px;
}
.banner-link-1 {
  color: #FFEB00;font-size: 18px;
}
.home-top-banner-2 h2 {
  font-size: 33px; line-height: 38px; margin-bottom:20px;
}
.home-top-banner-2 .subtitle {
  font-size: 18px;margin-bottom:35px;
}
.home-top-banner-2 .banner-link {
  font-size: 17px;
} 

.home-top-banner-3 h2 {
  font-size: 33px; 
  line-height: 35px; 
  margin-bottom:12px;
}

.home-top-banner-3 .banner-link {
  font-size: 17px;
} 

@media (max-width:600px) {
  .home-top-banner-1 .subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom:35px;
  } 
  .banner-link-1 {
    font-size: 16px;
  }
  .home-top-banner-2 h2 {
    font-size: 25px; line-height: 32px; 
    margin-bottom:20px;
  }
  .home-top-banner-2 .subtitle {
    font-size: 15px;
    line-height:23px;
    margin-bottom:35px;
  }
  .home-top-banner-2 .banner-link {
    font-size: 15px;
  }

  .home-top-banner-3 h2 {
    font-size: 25px; 
    line-height: 32px; 
    margin-bottom:20px;
  }
  .home-top-banner-3 .banner-link {
    font-size: 15px;
  }

}


</style>

<style>

img {
  max-width:100%;
  height:auto;
}

.home-margin-left {
  margin-left:30%; /**26 */
}
@media (max-width:1600px) {
  .home-margin-left {
    margin-left:26%;
  }
}
@media (max-width:1400px) {
  .home-margin-left {
    margin-left:24%;
  }
}
@media (max-width:1300px) {
  .home-margin-left {
    margin-left:25%;
  }
}
@media (max-width:1200px) {
  .home-margin-left {
    margin-left:15%;
  }
}

@media (max-width:800px) {
  .home-margin-left {
    margin-left:20px;
  }
}

#app {
  max-width:1920px;
  margin:auto;
}








</style>