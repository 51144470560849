<template>
  <!-- <div style="padding:10px; background-color:rgba(59, 130, 246, 1); color:#fff; text-align:center">
    Η εταιρεία θα παραμείνει κλειστή λόγω θερινών διακοπών από 12 έως 23 Αυγούστου. Οι παραγγελίες θα εκτελούνται από 26/08/2024.
    </div> -->
  <!-- Navbar goes here -->
  <nav class="flex flex-wrap justify-between items-center sm:items-baseline mobile-app-px  mt-4 sm:mt-0 gp-navbar" style="margin-bottom: 10px; position:relative">
    
    <div class="logo-kg" style="">
      <router-link class="logo-link--" to="/" style="">
        <img v-if="$store.getters['app/locale'] === 'el'" src="../../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
        <img v-if="$store.getters['app/locale'] === 'en'" src="../../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>
    
    <!-- Logo -->
    <div class="flex flex-1 items-center justify-start sm:justify-center p-4 pl-0 sm:pl-4 sm:pb-0 sm:p-8 --w-48 --sm:w-80" 
    id="logo" style="/*max-width: 354px; position:relative*/">
      <router-link class="logo-link" to="/" style="">
        <img src="../../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>

    <!-- Algolia Search -->
    <div class="hidden lg:flex flex-auto justify-center p-2 px-8 sm:pb-0 gp-algolia-search" 
      style="padding-top:0px; flex-direction: column;">
      
      <div class="gp-top-toolbar text-semibold text-13" style="position:relative">

        <div class="instant-order-link " style="color:#FF4E00">
          <router-link to="/instant-order-main">Instant Order</router-link>
        </div>

        <div class="change-language-link">
          <a v-if="$store.getters['app/locale'] === 'el'"
              class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-el-xx " 
              :href="'/en' + $route.fullPath"
              :title="$t('labels.change-language')">
          
            <img src="../../../assets/svg/globe-black.svg" class="h-4-xx mr-2" style="width:24px">
            Ελληνικά
          </a>
          <a v-else
              class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-en-xx " 
              :href="'' + $route.fullPath"
              :title="$t('labels.change-language')">
          
            <img src="../../../assets/svg/globe-black.svg" class="h-4-xx mr-2" style="width:24px">
            English
          </a>
        </div>

        <router-link v-if="!auth && !authSeller" class="auth-link " to="/register">
          <span class="auth-title">{{ $t('labels.become-a-member') }}</span>
          <img title="Signup" class="auth-img" src="../../../assets/icons/signup.png" />
        </router-link>

        <router-link v-if="!auth && !authSeller" class="auth-link" :to="'/login?redirect='+$route.fullPath">
          <span class="auth-title">{{ $t('labels.sign-in') }}</span>
          <img title="Login" class="auth-img" src="../../../assets/icons/login.png" />
        </router-link>

        <router-link v-if="auth" class="auth-link" style="height:16px" to="/account">
          <span title="My account" class="auth-title-- auth-userinitials-xx">{{ userName }}</span>        
        </router-link>
      
        <router-link class="" to="/review-cart">
          <div class="relative">
            <img src="../../../assets/svg/shopping-cart.svg" class="cursor-pointer cart-icon" style="min-width: 33px"/>
            <span
                v-if="cartItemsLoaded && cartItems.length"
                class="absolute text-center text-white text-xs -top-3.5 font-semibold rounded-full px-1 py-1 w-6 cart-nr"
                style="background-color: #212121; "
            >
            {{ cartItems.length }}
          </span>
          </div>
        </router-link>

        <div v-if="authSeller" style="position:absolute; top:10px; right:-150px;">
          <input type="checkbox" v-model="isSalesOffer" /> Προσφορά
        </div>

      </div>
      
      <algolia-search-bar ref="algolia-search-bar"/>

      

    </div>

    

    <!-- Sign in / Sign up -->
    <div class="hidden sm:flex flex-1 pr-16 pb-2 space-x-8 text-15 font-semibold items-center gp-auth-container --w-80 text-21 sm:pb-0" id="sign-in-menu" style="position:relative">
      <!--<router-link v-if="!auth && !authSeller" class="auth-link" to="/register">
        <span class="auth-title">{{ $t('labels.become-a-member') }}</span>
        <img title="Signup" class="auth-img" src="../../../assets/icons/signup.png" />
      </router-link>
      <router-link v-if="!auth && !authSeller" class="auth-link" :to="'/login?redirect='+$route.fullPath">
        <span class="auth-title">{{ $t('labels.sign-in') }}</span>
        <img title="Login" class="auth-img" src="../../../assets/icons/login.png" />
      </router-link>
      <router-link v-if="auth" class="auth-link" style="height:16px" to="/account">
        <span title="My account" class="auth-title-- auth-userinitials">{{ userNameInitials }}</span>        
      </router-link>-->

      <router-link v-if="authSeller" class="auth-link--" 
      style="position:fixed;bottom:40px; left:20px; border: 1px solid #212121;
  padding: 8px 10px;
  border-radius: 25px; background-color:#fff; z-index:9999" to="/seller">
        <span title="My Clients" class="auth-title-- auth-userinitials--">Sales</span>        
      </router-link>
      
      


      <!--<router-link class="" to="/review-cart">
        <div class="relative">
          <img src="../../../assets/svg/shopping-cart.svg" class="cursor-pointer cart-icon" style="min-width: 33px"/>
          <span
              v-if="cartItemsLoaded && cartItems.length"
              class="absolute text-center text-white text-xs -top-3.5 font-semibold rounded-full px-1 py-1 w-6 cart-nr"
              style="background-color: #212121; "
          >
          {{ cartItems.length }}
        </span>
        </div>

      </router-link>-->


      <!--Language top-->
    <!-- lang -->
    <!--<div class="text-15 text-semibold gp-lang-top">
      <a v-if="$store.getters['app/locale'] === 'el'"
          class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-el" 
          :href="'/en' + $route.fullPath"
          :title="$t('labels.change-language')">
      
        <img src="../../../assets/svg/globe-black.svg" class="h-4 mr-2">
        Ελληνικά
      </a>
      <a v-else
          class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-en" 
          :href="'' + $route.fullPath"
          :title="$t('labels.change-language')">
      
        <img src="../../../assets/svg/globe-black.svg" class="h-4 mr-2">
        English
      </a>
    </div>-->



    </div>

    <!-- Algolia Search -->
    <div id="algolia-container" 
      class="hidden sm:flex w-full lg:hidden justify-center p-2 pt-4 px-8 sm:pb-0"
      style="flex-direction: column;"> 
      
      <div class="gp-top-toolbar text-semibold text-14" style="position:relative">
        
        <div class="instant-order-link instant-order-link-2" style="color:#FF4E00">
          <router-link to="/instant-order-main">Instant Order</router-link>
          
        </div>

        <div class="change-language-link">
          <a v-if="$store.getters['app/locale'] === 'el'"
              class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-el-xx" 
              :href="'/en' + $route.fullPath"
              :title="$t('labels.change-language')">
          
            <img src="../../../assets/svg/globe-black.svg" class="h-4 mr-2">
            Ελληνικά
          </a>
          <a v-else
              class="flex items-center justify-start sm:justify-end cursor-pointer gp-lang-top-en-xx" 
              :href="'' + $route.fullPath"
              :title="$t('labels.change-language')">
          
            <img src="../../../assets/svg/globe-black.svg" class="h-4 mr-2">
            English
          </a>
        </div>

  <router-link v-if="!auth && !authSeller" class="auth-link " to="/register">
    <span class="auth-title">{{ $t('labels.become-a-member') }}</span>
    <img title="Signup" class="auth-img" src="../../../assets/icons/signup.png" />
  </router-link>

  <router-link v-if="!auth && !authSeller" class="auth-link" :to="'/login?redirect='+$route.fullPath">
    <span class="auth-title">{{ $t('labels.sign-in') }}</span>
    <img title="Login" class="auth-img" src="../../../assets/icons/login.png" />
  </router-link>

  <router-link v-if="auth" class="auth-link" style="height:16px" to="/account">
    <span title="My account" class="auth-title-- auth-userinitials-xx">{{ userName }}</span>        
  </router-link>

  <router-link class="" to="/review-cart">
    <div class="relative">
      <img src="../../../assets/svg/shopping-cart.svg" class="cursor-pointer cart-icon" style="min-width: 33px"/>
      <span
          v-if="cartItemsLoaded && cartItems.length"
          class="absolute text-center text-white text-xs -top-3.5 font-semibold rounded-full px-1 py-1 w-6 cart-nr"
          style="background-color: #212121; "
      >
      {{ cartItems.length }}
    </span>
    </div>
  </router-link>

  <div v-if="authSeller" style="position:absolute; top:15px; right:-120px;">
    <input type="checkbox" v-model="isSalesOffer" /> Προσφορά
  </div>

</div>
      
      <algolia-search-bar/>
    </div>

    <!-- Mobile menu -->
    <div class="sm:hidden gp-mobile-menu" style="width: 48px">
      <div class="flex flex-col justify-center mt-3-- space-y-2 cursor-pointer"
           @click="$emit('showMobileMenuOverlay')"
      >
        <img src="../../../assets/icons/mobile-burger-line.png"/>
        <img src="../../../assets/icons/mobile-burger-line.png"/>
      </div>
      <!--
      <transition name="slide-down">
        <ul v-show="mobileMenuOpen" class="border-t-1 ">
          <li v-for="link in links.left" :key="link.href">
            <nav-link :mobile="true" :to="link.href" v-html="link.label"/>
          </li>
        </ul>
      </transition>
      -->
    </div>

    <a id="show-offers"
    v-if="user_login && false" 
    style="position:fixed; top:0px; right:0px; padding:5px 10px; background:#000; color:#fff; border-bottom-left-radius: 10px; cursor:pointer; font-size: 13px;"
    href="https://tsamouris.gr/offers"
    >ΠΡΟΣΦΟΡΕΣ</a>
    



  </nav>

  


</template>

<script>

import AlgoliaSearchBar from "@/components/layout/navbar/AlgoliaSearchBar";
export default {
  name: 'TheNavbar',
  inject: ['openDialog', /*'showCartOverlayPanel'*/],
  components: {
    AlgoliaSearchBar
  },
  data() {
    return {
      mobileMenuOpen: false,
      isSalesOffer: false,
      /*user_login: false,*/
    }
  },
  computed: {
    dev() {
      return this.$store.getters['app/dev'];
    },
    auth() {
      return this.$store.getters['auth/auth'];
    },
    authSeller() {
      //return localStorage.getItem('sellertoken').length>1;
      console.log("sellertoken=" + localStorage.getItem('sellertoken'));
      return localStorage.getItem('sellertoken')===null? false: localStorage.getItem('sellertoken').length>1;
    },
    userName() {
      return this.$store.getters['auth/userName'];
    },
    cartItems() {
      return this.$store.getters['cart/items'];
    },
    cartItemsLoaded() {
      return this.$store.getters['cart/itemsLoaded'];
    },
    userNameInitials() {
      return this.$store.getters['auth/userName'].substr(0, 2).toUpperCase();
    },
    user_login() {
      if (this.$store.getters['auth/auth']) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    clearAlgoliaSearch() {
      this.$refs["algolia-search-bar"].search = '';
    },
    async loadCartData() {
      try {
        await this.$store.dispatch('cart/loadItems');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  mounted() {
    // Retrieve initial state from localStorage on component mount
    const storedValue = localStorage.getItem('isSalesOffer');
    if (storedValue !== null) {
      this.isSalesOffer = storedValue === '1';
    }
  },
  watch: {
    isSalesOffer(newValue) {
      // Update localStorage when checkbox state changes
      localStorage.setItem('isSalesOffer', newValue ? '1' : '0');
    },
  },
  created() {
    if (this.$store.getters['auth/auth']) {
      this.loadCartData();
      
    }
  }
}
</script>

<style scoped>

.gp-top-toolbar {
  height:80px; 
  background-color: transparent; 
  max-width:760px; /**932 */
  width:100%; 
  margin:auto;
  display:flex; 
  justify-content: space-between; 
  padding-left:30%; 
  padding-top:15px;
  line-height:13px;
}
@media (max-width:1400px) {
  .gp-top-toolbar {
    height:60px; 
    background-color: transparent; 
    max-width:760px; /**932 */
    width:100%; 
    margin:auto;
    display:flex; 
    justify-content: space-between; 
    padding-left:30%; 
    padding-top:15px;
    font-size:14px;
  }
}
@media (max-width:1100px) {
  .gp-top-toolbar {
    height:60px; 
    background-color: transparent; 
    max-width:760px; /**932 */
    width:100%; 
    margin:auto;
    display:flex; 
    justify-content: space-between; 
    padding-left:0px; 
    padding-top:10px;
    font-size:14px;
    margin-left: -50px;
    margin-right: -50px;
    width: calc(100% + 100px);
  }
}


.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  overflow: hidden;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 1s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 300px;
  overflow: hidden;
}

#logo {
  max-width: 354px; position:relative;
}

#sign-in-menu {
  max-width: 354px;
  height:0px;
}

/*#algolia-container {

}*/

.logo-link {
  position:absolute; top:-2px;
}

.auth-link {
  position: relative;
  padding-top:5px;
  /*width: 15px;*/
}

.auth-title {
  white-space: nowrap;
}
.auth-img {
  display:none;
  width:30px;
  height:30px;
  max-width:30px;
  position:absolute;
  top:-22px;
}

/*.cart-icon {
    position:absolute;
    top:-20px;
    left:-20px;
}*/

.cart-nr {
  right: -10px;
  top:-10px;
}

.gp-auth-container {
  justify-content: space-between;
}



.gp-lang-top {
  position: absolute; 
  top:-75px; 
  right:163px; 
  color:#212121;
  margin:0px !important;
}
.gp-lang-top-el,
.gp-lang-top-en {
  position:absolute;
}
.gp-lang-top-el {
  right:0px;
}
.gp-lang-top-en {
  right:10px;
}


@media (max-width:1600px) {
.gp-lang-top {
  top:-55px;
}  
}
@media (max-width:1400px) {
.gp-lang-top {
  top:-50px;
  right:143px; 
}  
}
@media (max-width:1300px) {
.gp-lang-top {
  top:-50px;
  right:133px; 
}  
}
@media (max-width:1200px) {
.gp-lang-top {
  top:-45px;
  left:25px;
}
.gp-lang-top-el {
  right:auto;
}
.gp-lang-top-en {
  right:auto;
}   
}

@media (max-width:1000px) {
.gp-lang-top {
  top:-45px;/*-32px */
  left:25px;/**5px */
} 
.gp-lang-top-el {
  right:auto;
}
.gp-lang-top-en {
  right:auto;
} 
}




.auth-userinitials {
  border: 1px solid #212121;
  padding: 8px 10px;
  border-radius: 25px;
  /*position:absolute;
  top: -18px;
  left: 150px;*/
}



@media (min-width: 800px) {
  #logo {
    padding-left: 95px;
  }

  #sign-in-menu {
    padding-right: 95px;
  }
}

@media (max-width:1600px) {
  .auth-link {
    position: relative;
    /*width: 15px;*/
  }
}


@media (max-width:1400px) {
  .auth-userinitials {
  /*left: 120px;*/
}
}

@media (max-width:1600px) {
  .logo-link {
    top:-5px;
  }

}

@media (max-width:1400px) {
  .auth-userinitials {
    /*left: 100px;*/
  }

  .logo-link {
    left:50px;
    top:-3px;
  }

}

@media (max-width:1400px) and (min-width:1300px) {
.gp-algolia-search {
  width:420px;
  padding-right:0px;
  padding-left:20px;
}
}

@media (max-width:1300px) and (min-width:1200px) {
.gp-algolia-search {
  width:450px;
}
}



@media (max-width: 1200px) {
  .logo-link {
    top:8px;
  }

  .auth-title {
    /*display:none;*/
  }
  .auth-img {
    /*display:inline-block;
    top:-12px;*/
  }

  .auth-userinitials {
    left: 0px;
    top:-10px;
  }

  /*.cart-icon {
    position:absolute;
    top:-10px;
    left:0px;
  }*/

  .cart-nr {
    right: 0px;
    top: -10px;
    left: 5px;
  }

  .gp-auth-container {
    justify-content: space-around;
  }

  


}



@media (max-width:1000px) {
  #logo {
    max-width: 250px; 
    min-width:180px;
    padding-left:50px;
    order:1;
  }

  #algolia-container {
    width:350px;
    padding-left: 0px;
    order:2;
  }

  #sign-in-menu {
    max-width: 180px;
    padding-right: 60px;
    order:3;
  }

  .logo-link {
    top:4px;
  }

  .auth-userinitials {
    left: 0px;
    top:-10px;
  }

  
}

@media (max-width: 800px) {
  

  .gp-navbar {
    margin-top:35px;  
  }

  .gp-lang-top {
    display:none;
  }

  #logo {
    order:1;
    padding-left:50px;
  }

  .logo-link {
    top:0px;
    left:20px;
  }

  .gp-mobile-menu {
    order:2;
  }

}


@media (max-width:590px) {
  #logo {
    padding-left:0px;
  }

  .logo-link {
    top:0px;
    left:0px;
  }

}


.instant-order-link {
  position:absolute; left:10px;top:20px;
}
@media (max-width:1600px) {
  .instant-order-link {
    left:10px;top:20px;
  }
}
@media (max-width:1400px) {
  .instant-order-link {
    left:10px;top:20px;
  }
}

@media (max-width:1200px) {
  .instant-order-link {
    left:-150px;top:14px;
  }
}

@media (max-width:1000px) {
  .instant-order-link {
    left:-160px;
    top:15px;
  }
}


.change-language-link a {
  translate:0px -1px;
}

@media (max-width:1000px) {
  .change-language-link a {
    translate:0px 3px;
  }
}








</style>


<style>

#logo {
  opacity:0;
}
.logo-container-abs {
  opacity:0;
}
@media (max-width:800px) {
#logo {
  opacity:1;
} 
.logo-container-abs {
  opacity:1;
} 
}



.gp-logo {
  width:225px;
}

@media (max-width: 800px) {
  .gp-logo {
    width:197px;
  }
}

.logo-kg {
  position:absolute; 
  left:110px; 
  top: 85px; /*62px;*/ 
  width:225px;
}
@media (max-width:1600px) {
.logo-kg {
  top:46px;
}  
}
@media (max-width:1500px) {
.logo-kg {
  /* top:43px; */
  top:82px;
}  
}
@media (max-width:1400px) {
.logo-kg {
  left:60px;
  top:60px;
}  
}
@media (max-width:1100px) {
.logo-kg {
  left:50px;
  top:77px;
  width:185px
}  
}
@media (max-width:1000px) {
.logo-kg {
  left:47px;
  top:77px;
  width:185px
}  
}

@media (max-width:800px) {
.logo-kg {
  display:none;
}  
}

</style>