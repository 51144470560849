<template>
      
    
    <div v-if="auth" class="flex flex-col" :id="'selected-product-' + product.item_code">
      <!-- CODE | PCS | UNIT PRICE | + | -   -->
      <div class="flex-xx flex-wrap-- 2xl:flex-nowrap-- cart-plus-minus-row  acp-panel-components"
        style="position:relative;">
        <div
            id="acp-panel-component-0"
            class="hidden sm:inline-block text-dark-gray 
            sm:border-l-0 sm:border-r-0 acp "
        >
          <p class=" text-semibold acp-lg-txt acp-itemname" style="overflow:hidden" :title="product.item_name">{{ product.item_name }}</p>
          <p class="acp-sm-txt text-semibold text-dark-gray acp-itemcode" style="font-size:13px; overflow:hidden;white-space: nowrap;" :title="product.item_code">{{ product.item_code }}</p>
        </div>
  
        <!-- Pieces quantity per package + Package description -->
        <div id="acp-panel-component-1" class="flex flex-col justify-end border-t-1 border-b-1 font-semibold acp acp-1">
          
          <div class="flex text-dark-gray" style="align-items: flex-end;">
            <p class="text-medium acp-lg-txt" style="position:relative">
            {{ myPiecesPerPackage }} {{ $t('labels.pcs') }}</p>
            <!--<span class="text-26 text-medium txt-lh-26" style="/*position:absolute; top:5px; right:0px*/"> {{ $t('labels.pcs') }}</span>-->
          </div>
          <p class="text-lineheight-24 text-16 text-medium acp-sm-txt">/ {{ $t('labels.package') }}</p>
        </div>
  
        <!-- Price per unit + Unit description -->
        <div id="acp-panel-component-2" class="flex flex-col justify-end border-t-1 border-b-1 font-semibold acp acp-2">
          <p v-if="product.base_price>0" 
            class="text-darkblue acp-sm-txt text-medium manrope"
            style="text-decoration: line-through;">
            {{ prettyNum(product.base_price) }}
          </p>
          <p class="text-dark-gray text-26-- txt-lh-26-- acp-lg-txt text-medium manrope">{{ prettyNum(product.initial_price) }}</p>
          <span class="acp-sm-txt text-semibold">/ {{ product.unit_description }}</span>
        </div>
  
        <!-- Discount & final Price -->
        <div id="acp-panel-component-3" class="flex flex-col border-t-1 border-b-1 font-semibold acp acp-3" style="position: relative;">
          <p style="" class="text-dark-gray acp-sm-txt  text-semibold acp-discount text-lineheight-18">{{ $t('labels.discount') }} {{ product.discount }}% + {{ product.discount_customer }}% </p>
          
          <p class="text-dark-gray acp-lg-txt text-medium">
            {{ prettyNum(product.final_price2) }}            
          </p>
          <span class="text-16-- txt-lh-16-- acp-sm-txt text-semibold">{{ $t('labels.final-price') }}</span>
          
        </div>
  
        <!-- Input + Availability label -->
        <div id="acp-panel-component-4" style="position:relative" 
          class="flex flex-col justify-end border-t-1 border-b-1 font-semibold acp acp-4">
          <span class="acp-sm-txt text-semibold"
            style="position:absolute; top:20px; left:10px; font-size: 13px;" 
            :style="{color: availabilityColor}">{{ availabilityLabel }}</span>

          <div class="flex text-dark-gray" style="align-items: flex-end; ">
            <span class="acp-lg-txt text-medium">{{ $t('labels.pcs') }} </span>
            <input v-model.number="quantity"
            @keyup="checkProductQuantity" 
            @change="roundProductQuantity" type="text" :step="myPiecesPerPackage" maxlength="7" class="w-full  acp-lg-txt text-medium manrope pl-1 gp-product-quantity-input" style="/*height:26px*/"/>
            <!--<span class="ml-1">{{ $t('labels.pcs') }}</span>-->
          </div>

          <p class="acp-sm-txt  text-semibold acp-discount-xx text-lineheight-18" style="display:block; color:#bbb; padding-left:0px">{{ $t('labels.type-here') }}</p>
          
        </div>
  
        <div id="acp-panel-component-5-6" style="padding:0px;">
          <!-- Plus Button -->
          <button
              id="acp-panel-component-5-"
              class="plus-minus-button plus-btn text-3xl text-white px-0 gp-green-bg-xx border-t-1-xx border-b-1-xx acp acp-5"
              style="text-align: center; "
              @click="increaseProductQuantity"
          >
          <img src="../../assets/svg/plus.svg" style="display:inline" />
          </button>
    
          <!-- Minus Button -->
          <button
              id="acp-panel-component-6-"
              class="plus-minus-button minus-btn text-3xl text-white px-0 gp-orange-bg-xx acp acp-6"
              style="text-align: center; "

              @click="decreaseProductQuantity"
          >
          <img src="../../assets/svg/minus.svg" style="display:inline" />
          </button>
        </div>

        <div class="close-itm-mob" style="" 
          @click="removeProduct">
          <img src="../../assets/svg/close2.svg" />
        </div>

      </div>
  
      
  
      <!-- Total & Reward [Mobile] -->
      <div class="bg-white px-4 py-4 flex items-end hidden">
        <div class="flex-grow text-23 text-semibold ">
          <p>{{ $t('labels.total') }}</p>
          <p class="gp-green-txt">{{ $t('labels.reward') }}</p>
        </div>
        <div class="flex-grow text-23 text-semibold ">
          <p class="w-20 text-right">{{ prettyNum(localItemsTotalCost - reward) }}</p>
          <p class="w-20 text-right" :style="{color: rewardColorNotation}">{{ prettyNum(reward) }}</p>
        </div>
        <div class="relative flex-shrink">
          <img src="../../assets/icons/green-box-outline.png" class="h-12"/>
          <span
              class="absolute text-center text-white text-14 -top-2 right-4-- font-semibold rounded-full px-1.5-- py-1-- w-6--"
              style="background-color: rgb(53, 211, 47); left:15px; width:26px; padding:4px"
          >
            {{ localItems.length }}
          </span>
        </div>
      </div>
  
  
    </div>
    
  </template>
  
  <script>
  export default {
    name: "AddToCartPanel2",
    inject: ['showNotification', 'openDialog', 'updateLocalItemQuantity', 'resetLocalItems', 'prettyNum'],
    props: {
      product: {
        type: Object,
        required: true,
      },
      localItems: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        dev: true,
        quantity: 0,
        prev_quantity:0,
        available: true,
        // --------- //
        unitPrice: 0, // final_price
        inventoryTotal: 0, // inventory_total
        // --------- //
        productTotalCost: 0,
        // green: color: #5ad504
        // red: #E66000;
        rewardColorNotation: '#5ad504',
        inDetail: false,
        blocksize: 10,
        loadedBlocks: 1,
        debugLocalItems: [
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
          {item: {}, item_code: 99, quantity: 7},
        ]
      }
    },
    computed: {
      auth() {
        return this.$store.getters['auth/auth'];
      },
      authSeller() {
        return localStorage.getItem('sellertoken')===null? false: localStorage.getItem('sellertoken').length>1;
      },
      
      cartItems() {
        return this.$store.getters['cart/items'];
      },
      cartTotalCost() {
        // console.log('totalCost',this.$store.getters['cart/totalCost'] );
        return this.$store.getters['cart/totalCost'];
      },
      // cart item diversity
      // itemCount() {
      //   return this.$store.getters['cart/items'].length;
      // },
      cartProductItem() {
        return this.$store.getters['cart/items'].find(
            item =>
                item.itemId === this.product.itemId
                &&
                item.itemType === this.product.itemType
        );
      },
      localItemsTotalCost() {
        let sum = this.localItems.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.item.final_price2 * currentValue.item_quantity/currentValue.item.pcs_per_unit;
          }, 0);
  
        return sum;
        // return this.prettyNum(sum);
      },
      reward() {
        return localStorage.getItem('eshop_discount')=="true"? this.localItemsTotalCost * 0.03: 0;
        //return this.localItemsTotalCost * 0.03;
      },
      availabilityLabel() {
        if (this.product.product_type=='fastener') {
          return this.available ? this.$t('labels.available') : this.$t('labels.limited-availability');
        }
        else if (this.product.product_type=='tool') {
          return this.available ? this.$t('labels.available') : this.$t('labels.available-in-15-days');
        }
        else {
          return this.available ? this.$t('labels.available') : this.$t('labels.limited-availability');
        }
        
      },
      availabilityColor() {
        return this.available ? "#38d430" : "#ff5f00";
      },
      // (str) 1.000 => (int) 1000
      myPiecesPerPackage() {
        // return parseFloat(this.product.main_package_quantity_pcs.replace('.', ''));
        return this.product.main_package_quantity_pcs;
      },
      hiddenLocalItems() {
        return this.localItems.length > this.blocksize*this.loadedBlocks;
      },
      
    },
    watch: {
      quantity(currentVal) {
        if (currentVal > this.product.inventory_total) {
          this.available = false;        
        } else {
          this.available = true;
        }
  
        // this check here is important because without it,
        // the totalCost would be constantly updated even
        // on not-rounded values
        if (this.quantity % this.myPiecesPerPackage === 0) {
          this.updateProductTotalCost();
        }
  
        this.updateLocalItemQuantity(this.product, currentVal);
      }
    },
    methods: {
      removeProduct() {
        this.quantity = 0;
      },
      isSalesOffer() {
        const storedValue = localStorage.getItem('isSalesOffer');
        console.log('isSalesOffer=' + storedValue);
        if (storedValue !== null) {
          return storedValue === '1';
        }
        else {
          return false;
        }
      },
      increaseProductQuantity() {
        let new_quantity = this.quantity + this.myPiecesPerPackage;

        // console.log('authSeller=' + this.authSeller);
        // console.log('isSalesOffer=' + this.isSalesOffer());

        if (this.authSeller && this.isSalesOffer()) {
          this.quantity = new_quantity;
          this.rewardColorNotation = '#5ad504';
          return;
        }
  
        if (this.product.product_type=='fastener') {
          if (new_quantity <= this.product.inventory_total) {
            this.quantity = new_quantity;
            this.rewardColorNotation = '#5ad504';
          }
          else {
            alert(this.$t('labels.product-not-available'));
          }
        }
        else if (this.product.product_type=='tool') {
          this.quantity = new_quantity;
          this.rewardColorNotation = '#5ad504';
          if (new_quantity > this.product.inventory_total) {          
            //alert("Παράδοση σε 15 ημέρες");
          }
        }
        //this.rewardColorNotation = '#5ad504'; // 38d430
        //this.quantity += this.myPiecesPerPackage;
      },
      decreaseProductQuantity() {
        this.quantity -= this.myPiecesPerPackage;
        this.rewardColorNotation = '#5ad504'; // ff5f00
  
        if (this.quantity <= 0) {
          this.rewardColorNotation = '#5ad504'; //ff5f00
          this.quantity = 0;
        }
      },
      roundProductQuantity() {
        if (this.quantity % this.myPiecesPerPackage !== 0) {
          let divisor = Math.floor(this.quantity/this.myPiecesPerPackage);
          // console.log('divisor:', divisor);
          this.quantity = this.myPiecesPerPackage * (divisor+1);
        }
      },
      
      checkProductQuantity() {
        if (this.quantity > this.product.inventory_total) {
          if (this.product.product_type=='fastener') {
            alert(this.$t('labels.product-not-available'));
            this.quantity = this.product.inventory_total;
          }
          else if (this.product.product_type=='tool') {
            //...
          }
        }
      },
  
      updateProductTotalCost() {
        this.productTotalCost = Math.round((this.quantity/this.product.pcs_per_unit) * this.product.final_price2 * 100)/100;
  
        // console.log('total cost updated: ', this.productTotalCost);
        // console.log('this.quantity: ', this.quantity);
        // console.log('this.piecesPerUnit: ', this.product.pcs_per_unit);
        // console.log('this.product.final_price: ', this.product.final_price);
  
      },
      async addProductToCart() {
        if (this.localItems.length === 0) {
          return;
        }
  
        await this.$store.dispatch('cart/addItems', this.localItems);
  
        //console.log(this.$store.getters['app/locale']);
        let my_lang = this.$store.getters['app/locale'];
  
        if (my_lang=='el') {
            this.showNotification({
              class: 'default',
              title: 'Συγχαρητήρια!',
              position: 'bottom-left',
              html: 'Το προϊόν/τα προστέθηκε/αν στο καλάθι με επιτυχία',
              lifeSpan: 2000,
            });
        }
        else {
            this.showNotification({
              class: 'default',
              title: 'Congrats!',
              position: 'bottom-left',
              html: 'The product(s) were added to your order successfully',
              lifeSpan: 2000,
            });
        } 
        
  
        this.reset();
      },
      reset() {
        this.quantity = 0;
        // this.totalCost = 0;
        // this.reward = 0;
        this.resetLocalItems();
      },
      initializeQuantity() {
        // check if product is already in cart, if so, load the already selected quantity
        const index = this.localItems.findIndex(entry => entry.item.item_code === this.product.item_code);
  
        // console.log('init Q called!', index, this.localItems, this.product);
  
        if (index === -1) {
          this.quantity = 0;
        } else {
          this.quantity = this.localItems[index].item_quantity;
        }
      },
      loadMore() {
        this.loadedBlocks++;
      },
      viewAll() {
        this.loadedBlocks = Math.ceil(this.localItems.length/this.blocksize);
      },
      productShortname(productname) {
        if (productname.length<10) {
            return productname;
        }
        else {
            return productname.substring(0,9) + '...';
        }
      }
    },
    created() {
      // product.item_code
      this.initializeQuantity();
    }
  }
  </script>
  
  <style scoped>
    .close-itm-mob {
      position:absolute; 
      top:20px !important; 
      left:5px; 
      height:40px !important; 
      padding:0px; 
      cursor:pointer;
    }
    @media screen and (max-width:1400px) {
      .close-itm-mob {
        width:30px;
        top:7px !important; 
        left:7px; 
      }
    }
    
    @media screen and (max-width: 600px) {
      .acp-panel-components{ background-size: 100% 100%;       background-image: url(../../assets/svg/mob-bck2.svg);}
      .cart-plus-minus-row{margin-bottom: 5px; display: grid;  grid-template-rows: auto auto auto;   grid-template-columns: repeat(12, 1fr) !important;}
      #acp-panel-component-0{height: 60px;background-color:#262626 !important; background-image:unset !important; grid-row: 1 !important;    grid-column: 1 / 13 !important; background-size: 100% 100% !important; display:block;}
      #acp-panel-component-1{height: 80px; grid-row: 2; grid-column: 1/5; background-color:#EBEADF !important;}
      #acp-panel-component-2{height: 80px; grid-row: 2; grid-column: 5/9; background-color:#EBEADF !important;}
      #acp-panel-component-3{height: 80px; border-right: 1px solid #212121; grid-row: 2; grid-column: 9/13; background-color:#EBEADF !important;}
      #acp-panel-component-4{min-height:unset !important; height: 106px; background-color:#262626 !important; border: none !important; background-position: left bottom; grid-row: 3; grid-column: 1/8; background-color:unset !important; background-image: url(../../assets/svg/mob-bck2-left-btm.svg);}
      #acp-panel-component-5{height: 80px; border-top:unset !important; grid-row: 3; grid-column: 7/10; }
      #acp-panel-component-6{height: 80px; background-color:#262626 !important; background-position: bottom right !important; grid-row: 3; grid-column: 10/13; border:none; background-size: 355px 218px !important; background-image: url(../../assets/svg/mob-bck2-right-btm.svg) !important;}
      .close-itm-mob{right: 10px !important; left:unset !important;}
      #acp-panel-component-0:nth-child(1) {background-color:transparent !important;}
      .acp-lg-txt{font-size:20px !important;}
      .product-subtotal {font-size:15px !important; line-height:20px;}
    }
    
    @media screen and (max-width: 1400px) {
      #acp-panel-component-5{padding:10px;}
      #acp-panel-component-6{padding:10px;}    
    }


    .acp-panel-components {
      /*flex-wrap:wrap;*/
      display:grid;
      /*grid-template-columns: 22% 13% 13% 13% 19% 10% 10%; */
      grid-template-columns: 21% 15% 15% 19% 20% 10%;
    }
  
    @media (min-width:1200px) {
     .acp-panel-components {
        flex-wrap:nowrap;
      } 
    }
    
    @media screen and (max-width: 430px) {
      .product-subtotal{font-size:15px !important;}
    }

    #acp-panel-component-0 {
      background-color: #efefe2;      
      border-left:1px solid #212121;
      border-bottom:1px solid #212121;
      border-top:1px solid #212121;
    }

    .add-to-cart-panel-container > div:nth-child(1) #acp-panel-component-0 {
      background-image: url(../../assets/svg/product-order-line-left.svg);
      background-position: left top;
      background-size: 853px 150px;
      border-left:none;
      border-top:none;
      background-color:transparent;
    }
  
    #acp-panel-component-1 {
      background-color: #efefe2;      
      border-left:1px solid #212121;
    }
  
    #acp-panel-component-2 {
      background-color: #efefe2;
      
      border-left:1px solid #212121;
    }
  
    #acp-panel-component-3 {
      background-color: #efefe2;
      
      border-left:1px solid #212121;
    }
  
    #acp-panel-component-4 {
      background-color: #efefe2;
      flex-grow: 1;
      min-height: 104px;
      
      border-left:1px solid #212121;
      
    }
  
    #acp-panel-component-5 {
      
      border-left:1px solid #212121;
    }
  
    #acp-panel-component-6 {      
      border-left:1px solid #212121;
      background-image: url(../../assets/svg/product-order-line-right.svg);
      background-position: right;
      background-size: 853px 100%;
    }
  
  
  @media (min-width: 1400px) {
      .plus-minus-button {
        /*min-width: 85px;
        max-width: 85px;*/
      }
    }
  
  
  @media (min-width: 1200px) { /** ???? */
      /*#acp-panel-component-0 {
        display: flex !important;
        width: 23%; 
      }*/
  
      #acp-panel-component-1 {
  
        
      }
  
      #acp-panel-component-2 {
  
       
      }
  
      #acp-panel-component-3 {
  
        
      }
  
      #acp-panel-component-4 {
  
        
      }
  
      #acp-panel-component-5 {
        
      }
  
      #acp-panel-component-6 {
        
      }
    }
  
  
  @media (min-width: 1600px) { /** ???? */
      /*#acp-panel-component-0 {
        display: flex !important;
        width: 23%; 
      }*/
  
      #acp-panel-component-1 {
  
        
      }
  
      #acp-panel-component-2 {
  
        
      }
  
      #acp-panel-component-3 {
  
        
      }
  
      #acp-panel-component-4 {
  
        
      }
  
      #acp-panel-component-5 {
  
      }
  
      #acp-panel-component-6 {
  
      }
    }
  
  
  
  
  
  
  
    .plus-minus-button {
      height:50%;
      padding:3px;
    }
    @media (max-width:800px) {
      .plus-minus-button {
        padding:5px;
      }
    }
    .plus-minus-button img {
      max-height: 100%;
    }
    
    .plus-btn {
        background-image: none;
        background-color: #38d430;
        border-right:1px solid #212121;
        border-top:1px solid #212121;
      }

    @media (min-width:800px) {
      .add-to-cart-panel-container > div:nth-child(1) .plus-btn {
        background-image: url("../../assets/svg/product-order-line-right-green.svg");
        background-color:transparent;
        background-position:top right;
        border-right:none;
        border-top:none;
      }
    }
      
    
    .minus-btn {
      background-color: #E66000;
      border-right:1px solid #212121;
      border-bottom:1px solid #212121;
    }

    @media (max-width:800px) {
      .plus-btn {
        background-image: none;
        background-color: #38d430;
        border-right:1px solid #212121;
        border-top:none;
      }
      .minus-btn {
        background-color: #000;
        border-bottom: none;
        background-image: url("../../assets/svg/product-order-line-right.svg");
        background-position:bottom right;
        border-right: none;
      }
    }
  
  
    #in-detail-table,
    #in-detail-table > thead > th,
    #in-detail-table > tbody > tr > td {
      border: 1px solid white;
    }
  
    #in-detail-table th,
    #in-detail-table td {
      font-size:12px;
      padding:8px 3px;
    }
  
  
  
    #in-detail-table {
      border-left: 0;
      border-right: 0;
      /*min-width:880px;*/
    }
  
    @media (max-width:1200px) {
    #in-detail-table {
      min-width:880px;
    }
    }
  
  
  
    #in-detail-table > thead > th {
      text-transform: capitalize;
      text-align: left;
    }
  
    #in-detail-table > thead > th,
    #in-detail-table > tbody > tr > td {
      /*padding: 2px 18px;*/
    }
  
    #in-detail-table > thead > th:first-child,
    #in-detail-table > tbody > tr > td:first-child {
      border-left: 0;
    }
  
    #in-detail-table > thead > th:last-child,
    #in-detail-table > tbody > tr > td:last-child {
      border-right: 0;
    }
  
    /* width */
    #table-wrapper::-webkit-scrollbar {
      width: 10px;
      height: 5px;
      /*margin-bottom: 1rem;*/
    }
  
    /* Track */
    #table-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 5px;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  
    /* Handle */
    #table-wrapper::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 5px;
      cursor: pointer;
    }
  
    /* Handle on hover */
    #table-wrapper::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      /*background: #fff;*/
    }
  
  
    .gp-product-quantity-input {
      height:26px;
    }
  
    /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .gp-product-quantity-input {
      height:30px;
    }
  }}
  /* Test website on real Safari 11+ */
  
  /* Safari 10.1 */
  @media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .gp-product-quantity-input {
      height:30px;
    }
  }}
  
  /* Safari 6.1-10.0 (but not 10.1) */
  /*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
  @media {
    .gp-product-quantity-input {
      height:30px;
    }
  }}*/
  
  
  
  @media (max-width:1400px) {
    .gp-product-quantity-input {
      height:20px;
    }
  }
  
  
  /* Safari 11+ */
  @media not all and (min-resolution:.001dpcm) and (max-width:1400px)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .gp-product-quantity-input {
      height:24px;
    }
  }}
  /* Test website on real Safari 11+ */
  
  /* Safari 10.1 */
  @media not all and (min-resolution:.001dpcm) and (max-width:1400px) { 
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .gp-product-quantity-input {
      height:24px;
    }
  }}
  
  /* Safari 6.1-10.0 (but not 10.1) */
  /*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) and (max-width:1400px) { 
  @media {
    .gp-product-quantity-input {
      height:24px;
    }
  }}*/
  
  
  
  
  
  
  
  
  
    .gp-product-quantity-input {
      background:transparent;
    }
  
    .gp-product-quantity-input:focus {
      outline:none;
    }
  
  
    
  
    @media (max-width: 800px) {
      #acp-panel-component-1 {
        background-color:#fbfbfb;
        padding-left:20px
      }
  
      #acp-panel-component-2 {
        background-color:#efefef;
  
      }
  
      #acp-panel-component-3 {
        background-color:#ebebeb;
  
      }
  
      #acp-panel-component-4 {
        background-color:#dddddd;
        padding-left:20px
      }
    }
  
    @media (max-width:1600px) {
      #acp-panel-component-1 {
        padding-left:20px
      }
    }
    @media (max-width:1400px) {
      #acp-panel-component-1 {
        padding-left:5px
      }
    }
  
  
  
    .acp-panel-components > div {
      height:135px;
      padding:15px;
      display:flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  
    @media (max-width:1400px) {
      .acp-panel-components > div {
        height:105px;
      }
    }
  
  
    .acp-lg-txt {
      font-size:21px;
      line-height:27px;
      white-space: nowrap;
      
    }
  
    .acp-sm-txt {
      font-size:13px;
      line-height: 13px;
    }
  
    @media (max-width:1400px) {
      .acp-lg-txt {
        font-size:18px;
        line-height:20px;
      }
  
      .acp-sm-txt {
        font-size:12px;
      }
    }
  
    @media (max-width:1400px) {
      .acp-panel-components > div {
        padding:15px 5px 13px;
      }
  
      .acp-itemname,
      .acp-itemcode {
        padding-left:5px;
      }
    }
    @media (max-width:800px) {
      .acp-itemname {
        margin-right:40px;
      }
    }
  
  
    .product-subtotal {
      font-size:26px;
      line-height:30px;
    }
  
    @media (max-width:1400px) {
    .product-subtotal {
      font-size:20px;
      line-height:25px;
    }
    
    
  
    }
  
    
  
    .acp-0 {
      display:none !important;
    }

    @media (max-width:800px) {
      #acp-panel-component-5-6 {
        grid-column: 8/14;
      }
    }
    

    .acp-discount {
      position:absolute; top:20px; left:10px; font-size: 13px;
    }
    @media (max-width:1400px) {
      .acp-discount {
        left:5px; 
      }
    }
    @media (max-width:800px) {
      .acp-discount {
        top:5px; left:5px; 
      }
    }

    
  
  
  
  </style>