import ErrorUtil from "@/classes/ErrorUtil";

let timer;

export default {
    namespaced: true,
    state() {
        return {
            // auth: window.app.auth,
            userId: null,
            userName: null,
            token: null,
            role: null, // customer|???
            didAutoLogout: false,
            // ---------- dev
            tokenExpiration: null, // this one is handled solely in the localStorage API
            expiresIn: null, // in millis,
            //seller
            sellertoken: null,
            sellerName: null,
        };
    },
    actions: {
        async login(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'login'
            });
        },
        async fakeLogin(context) {
            const expiresIn = 5000; // debug purposes
            const tokenExpiration = new Date().getTime() + expiresIn;

            localStorage.setItem('token', 'fake-token-8000');
            localStorage.setItem('userId', '99');
            localStorage.setItem('userName', 'A. R.');
            localStorage.setItem('role', 'customer');
            localStorage.setItem('tokenExpiration', tokenExpiration); // timestamp

            timer = setTimeout(function() {
                context.dispatch('autoLogout');
            }, expiresIn);

            context.commit('setUser', {
                token: 'fake-token-8000',
                userId: 99,
                userName: 'A',
                role: 'customer'
            });

            context.commit('setDebugData', {
                expiresIn: expiresIn,
                tokenExpiration: new Date(tokenExpiration).toString(),
            });
        },
        async signup(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'signup'
            });
        },
        async sellerCustomer(context, customer_id) {
            await context.rootGetters['app/axios'].post('/seller/customer', {
                data: {
                    accesstoken: localStorage.getItem('sellertoken'),
                    customer_id: customer_id,
                    
                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }
                else {
                    console.log(response.data.data);
                    //console.log('got customer...');
                    
                    const responseData = response.data.data;
                    const tokenExpiration = new Date(responseData.accesstoken_validuntil.replace(/\s/, 'T')).getTime();
                    const expiresIn = tokenExpiration - new Date().getTime();

                    //console.log(responseData);

                    localStorage.setItem('token', responseData.accesstoken);
                    localStorage.setItem('userId', responseData.customer_id);
                    localStorage.setItem('userName', responseData.user_name);
                    localStorage.setItem('role', responseData.user_type);
                    localStorage.setItem('tokenExpiration', tokenExpiration);
                    localStorage.setItem('eshop_discount', responseData.eshop_discount);
                    localStorage.setItem('rfq_enabled', 1);
                    localStorage.setItem('customer_active', 1);

                    
                    timer = setTimeout(function() {
                        context.dispatch('autoLogout');
                    }, expiresIn);

                    //...
                    context.commit('setUser', {
                        token: responseData.accesstoken,
                        userId: responseData.customer_id,
                        userName: responseData.user_name,
                        role: "customer"
                        // tokenExpiration: tokenExpiration no practical need here
                    });
                    //console.log("redirecting to /account...");
                    //window.location.href = "/account";


                }                                
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async auth(context, payload) {
            const mode = payload.mode;

            let url = mode === 'signup' ? '/register' : '/login2';

            const response = await context.rootGetters['app/axios'].post(url, {
                data: {
                    email: payload.email,
                    password: payload.password,
                }
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });

            const result = response.data.result;

            //console.log('GP....' + result);

            if (result === 'error') {
                throw new Error('messages.auth-failed');
            }

            const responseData = response.data.data;            
            const tokenExpiration = new Date(responseData.accesstoken_validuntil.replace(/\s/, 'T')).getTime();
            const expiresIn = tokenExpiration - new Date().getTime();

            context.commit('setDebugData', {
                expiresIn: expiresIn,
                tokenExpiration: tokenExpiration,
            });

            if (responseData.user_type=="customer") {
                localStorage.setItem('token', responseData.accesstoken);
                localStorage.setItem('userId', responseData.customer_id);
                localStorage.setItem('userName', responseData.user_name);
                localStorage.setItem('role', responseData.user_type);
                localStorage.setItem('tokenExpiration', tokenExpiration); // timestamp
                localStorage.setItem('eshop_discount', responseData.eshop_discount);
                localStorage.setItem('rfq_enabled', responseData.rfq_enabled);
                localStorage.setItem('customer_active', responseData.active);

                timer = setTimeout(function() {
                    context.dispatch('autoLogout');
                }, expiresIn);
    
                context.commit('setUser', {
                    token: responseData.accesstoken,
                    userId: responseData.customer_id,
                    userName: responseData.user_name,
                    role: responseData.user_type
                    // tokenExpiration: tokenExpiration no practical need here
                });

            }
            else if (responseData.user_type=="seller") {
                localStorage.setItem('sellertoken', responseData.accesstoken);
                localStorage.setItem('sellerId', responseData.customer_id);
                localStorage.setItem('sellerName', responseData.user_name);
                localStorage.setItem('role', responseData.user_type);
                localStorage.setItem('sellerTokenExpiration', tokenExpiration); // timestamp
                localStorage.setItem('eshop_discount', responseData.eshop_discount);
                //state.sellertoken = responseData.accesstoken;
            }

            //...

            // console.log('finishing the auth function');
        },
        tryLogin(context) {
            const token = localStorage.getItem('token');
            //const sellertoken = localStorage.getItem('sellertoken');
            const userId = localStorage.getItem('userId');
            const userName = localStorage.getItem('userName');
            const role = localStorage.getItem('role');
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            const expiresIn = +tokenExpiration - new Date().getTime();

            // console.log('tryLogin -- token', token);
            // console.log('tryLogin -- userId', userId);
            // console.log('tryLogin -- userName', userName);
            // console.log('tryLogin -- role', role);
            // console.log('tryLogin -- tokenExpiration', tokenExpiration);
            // console.log('tryLogin -- expiresIn', expiresIn);

            if (expiresIn < 0) {
                // console.log('expiresIn is stale ... returning..');
                return;
            }

            // console.log('setting timeout for ..', expiresIn);

            timer = setTimeout(function() {
                context.dispatch('autoLogout');
            }, expiresIn);

            if (token && userId) {
                // console.log('token & userId is truthy, setting user and debug data ', {
                //     token: token,
                //     userId: userId,
                //     userName: userName,
                //     role: role
                //     // tokenExpiration: null
                // }, {
                //     expiresIn: expiresIn,
                //     tokenExpiration: tokenExpiration,
                // });

                context.commit('setUser', {
                    token: token,
                    userId: userId,
                    userName: userName,
                    role: role
                    // tokenExpiration: null
                });

                context.commit('setDebugData', {
                    expiresIn: expiresIn,
                    tokenExpiration: tokenExpiration,
                });
            }
        },
        logout(context) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('userName');
            localStorage.removeItem('role');
            localStorage.removeItem('tokenExpiration');

            localStorage.removeItem('sellertoken');
            localStorage.removeItem('sellerId');
            localStorage.removeItem('sellerName');
            localStorage.removeItem('sellerTokenExpiration');


            clearTimeout(timer);

            context.commit('setUser', {
                token: null,
                userId: null,
                userName: null,
                role: null
                // tokenExpiration: null,
            });

            // clear the cart items & favourites too
            context.commit('cart/clearCache', null, {root: true});
            context.commit('categories/clearCache', null, {root: true});
        },
        autoLogout(context) {
            context.dispatch('logout');
            context.commit('setAutoLogout');
        }
    },
    mutations: {
        setUser(state, payload) {
            // console.log('Setting user : ', payload);
            state.token = payload.token;
            state.userId = payload.userId;
            state.userName = payload.userName;
            state.role = payload.role;
            // state.tokenExpiration = payload.tokenExpiration ?? null;
            state.didAutoLogout = false;
        },
        setAutoLogout(state) {
            // console.log('Setting autoLogout !!!');
            state.didAutoLogout = true;
        },
        setDebugData(state, payload) {
            // console.log('Setting debug data: ', payload);
            state.expiresIn = payload.expiresIn;
            state.tokenExpiration = payload.tokenExpiration;
        }
    },
    getters: {
        auth(state) {
            return !!state.token;
        },
        userId(state) {
            return state.userId;
        },
        userName(state) {
            return state.userName;
        },
        role(state) {
            return state.role;
        },
        token(state) {
            return state.token;
        },
        isAuthenticated(state) { // if we have a token, we are authenticated
            return !!state.token;
        },
        didAutoLogout(state) {
            return state.didAutoLogout;
        },
        expiresIn(state) {
            return state.expiresIn;
        },
        tokenExpiration(state) {
            return state.tokenExpiration;
        }
    }
};
