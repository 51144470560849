<template>
  <a :href="$router.resolve(item.url).href + '/?p1=' + p1 + '&r1=' + r1 + (toolbrand!=''? '&toolbrand=' + toolbrand: '')" style=""
     class="gp-slider-item">
    <img :src="item.imageUrl" class="gp-img" style="" :style="{'max-height': imageHeight + 'px'}"/>
    <div class="gp-titles" style="">
      <div class="gp-title text-dark-gray text-14 text-light" >{{ item.name }}</div>
      <!--<div class="gp-subtitle" style="">{{ item.quantity }} {{ item.quantity === 1 ? 'item' : 'items'}}</div>-->
    </div>
  </a>
</template>

<script>
export default {
  name: "SliderItem",
  components: {},
  props: {
    'item': {
      type: Object,
      required: true
    },
    'imageHeight': {
      type: Number,
      default: 150
    },
    'r1': {
      type: Number,
      default: 0
    },
    'p1': {
      type: Text,
      default: ''
    },
    'toolbrand': {
      type: Text,
      default:''
    }
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style scoped>
  .gp-slider-item:hover, .gp-slider-item.selected {
    /*background-color: #f5f5e8;*/
  }


  .gp-slider-item {
    height: 200px;
    padding:20px;
    position:relative;
    text-align:center; 
    display:block;   
    /*slider-item flex-- flex-col-- items-center-- text-center-- justify-end-- mx-auto p-3 sm:pt-8 sm:px-8 md:p-6 md:pb-2 md:pt-16 md:px-16 text-sm cursor-pointer font-semibold*/
  }

  .gp-img {
    width:auto;
    display: inline;
    max-width:180px;
  }

  .gp-titles {
    /*height:40px;*/
    position:absolute;
    bottom:0px; /**10px */
    left:0px;
    right:0px;
  }

  .gp-title {
    /*font-size:14px; 
    line-height: 18px;
    font-weight:500;
    color:#002169;
    max-width:200px;
    margin:auto;*/
    height:30px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height:16px;
    max-width:200px;
    margin:auto;
  }

  .gp-subtitle {
    font-size:15px;
    font-weight:500;
    color:#212121;
    /*position:absolute;
    bottom:0px;
    left:0px;
    right:0px;*/
  }

  @media (max-width:1400px) {
   .gp-subtitle {
    font-size:13px;
   }
    
  }


</style>
