<template>
  <div class="grid grid-cols-6">
    Head Type
  </div>
</template>

<script>
export default {
  name: "HeadType",
  components: {},
  inject: [
    'hidePaginator',
  ],
  props: {
    selectedHeadTypeProp: {
      type: String,
      required: false,
      default: null,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data() {
    return {
      selectedHeadType: null,
    }
  },
  computed: {
    // categoriesLoaded() {
    //   return this.$store.getters['categories/categoriesLoaded'];
    // },
    // categories() {
    //   return this.$store.getters['categories/categories'];
    // },
  },
  watch: {
    selectedHeadTypeProp(newVal) {
      this.selectedHeadType = newVal;
    }
  },
  methods: {
    // async loadCategories() {
    //   const self = this;
    //
    //   try {
    //     await this.$store.dispatch('categories/loadCategories');
    //   } catch (error) {
    //     self.openDialog({
    //       class: 'danger',
    //       html: error.message
    //     });
    //   }
    // }
  },
  created() {
    this.hidePaginator();

    // this.loadCategories();

    this.selectedHeadType = this.selectedCategoryIdProp;
  }
}
</script>

<style scoped>

</style>
