<template>
    <arrow-filters ref="filters" @broadcast-filters="getToolCategories" style="margin-bottom: 68px;"/>
    
    
    
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  results-container" style="margin-bottom:150px">
      

      <div class="category-tile"  v-for="(category) in toolCategories" :key="category.id" >
        <a :href="'/category/' + category.id + (toolBrand!=''? '?toolbrand=' + toolBrand: '') ">
          <img :src="category.imageUrl" class="mx-auto" style="max-width: 400px; max-height: 130px;"/>
          <h3>{{ category.name }}</h3>
        </a>
        

      </div>
    
    </div>
    

</template>

<script>

import ArrowFilters from "@/components/ArrowFilters";
//import SecondLevelCategoryTile from "@/components/categories/SecondLevelCategoryTile";

export default {
  name: "SearchToolCategories",
  components: {ArrowFilters},
  computed: {
    toolCategoriesLoaded() {
      console.log("check cat2Loaded + cat3Loaded");
      let cat2Loaded = this.$store.getters['categories/categories2Loaded'];
      if (cat2Loaded) {
        console.log("cat2Loaded");
        return true;
      }
      else {
        let cat3Loaded = this.$store.getters['categories/categories3Loaded'];
        if (cat3Loaded) {
          console.log("cat3Loaded");
          return true;
        }
        else {
          let cat23Loaded = this.$store.getters['categories/categories23Loaded'];
          if (cat23Loaded) {
            console.log("cat23Loaded");
            return true;
          }
          else {
            return false;
          }
          // return false;
        }
      }
      // return this.$store.getters['categories/categories2Loaded'];
    },
    toolCategories() {
      let toolbrand = this.toolBrand;
      if (toolbrand=='bahco' || toolbrand=='irimo') {
        return this.$store.getters['categories/categories2'];
      }
      else if (toolbrand=='makita') {
        return this.$store.getters['categories/categories3'];
      }
      else {
        return this.$store.getters['categories/categories23'];
        // return null; ///xxx
      }
      
    },
    toolBrand() {
      return typeof(this.$route.query.toolbrand)=="undefined"? "": this.$route.query.toolbrand;
    },
    toolType() {
      return typeof(this.$route.query.tooltype)=="undefined"? "": this.$route.query.tooltype;
    },
  },
  methods: {
    async getToolCategories(filters) {
      //const self = this;
      console.log('search using filters ...', JSON.stringify(filters));

      //let toolbrand = this.toolBrand;
      let toolbrand = filters.toolBrand?? '';
      console.log('toolbrand='+toolbrand);

      try {
        if (toolbrand=='bahco') {
          await this.$store.dispatch('categories/loadCategories2', {
            forceRefresh: true,
            toolbrand: 'Bahco'
          });
        } 
        else if (toolbrand=='irimo') {
          await this.$store.dispatch('categories/loadCategories2', {
            forceRefresh: true,
            toolbrand: 'Irimo'
          });
        }
        else if (toolbrand=='makita') {
          await this.$store.dispatch('categories/loadCategories3', {
            forceRefresh: true,
            toolbrand: 'Makita'
          });
        }
        else {
          await this.$store.dispatch('categories/loadCategories23', {
            forceRefresh: true,
            toolbrand: ''
          });
        }
        
      } catch (error) {
        console.log(error.message);
      }
    },
    
  },
  created() {
    this.getToolCategories();
  }
}

</script>


<style scoped>

.results-container {
  padding-left:120px;
  padding-right:100px;
  padding-bottom:50px;
}
@media (max-width:800px) {
  .results-container {
    padding-left:10px;
    padding-right:10px;
    margin-top:60px;
  }  
}

/*.category-tile {
  height:300px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border:1px solid #ccc;
  background-color: #fff;
  border-radius: 15px;
}*/

.category-tile {
  height: 345px;
  position: relative;
  /*width: 213px;*/
  background-color: transparent;
  background-image: url("../assets/svg/landscape-octagon-white-white-fill.svg");
  background-size: calc(100%) 345px;
  background-repeat: no-repeat;
  margin-right:1px;
  margin-left:1px;
  margin-top:1px;
  margin-bottom:1px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.category-tile:before {
    position:absolute;
    top:-1px;
    right:-1px;
    bottom:-1px;
    left:-1px;
    content:'';
    background-image: url(../assets/svg/landscape-octagon-gray.svg);
    background-size: calc(100%) 347px;
    background-repeat: no-repeat;
    z-index:-1;
}
.category-tile:hover:before {
    background-image: url(../assets/svg/landscape-octagon-degrade.svg);
}

.category-tile h3 {
  text-align: center;
  padding:0px 10px;
}


</style>