<template>
  
  <div class="select-filter-type-container">
    <div @click="showFastenerFilters=true; showToolFilters=false;">
      {{ lang_gr? 'Αναζήτηση βιδών': 'Fasteners filters'  }}
    </div>
    <div @click="showFastenerFilters=false; showToolFilters=true;">
      {{ lang_gr? 'Αναζήτηση εργαλείων': 'Tools filters'  }}
    </div>
  </div>
  
  <div v-if="showFastenerFilters" class="pt-24 mb-32">

    <div class="section-header">DIN/ISO</div>

    <din-input
        ref="din-input"
        class="mb-8"
        :din="dinFilterIsActive ? activeFilters.din.val : null"
        @change="this.setDinFilter($refs['din-input'].search)"
    ></din-input>

    <!-- Category -->

    <div class="section-header">{{ $t('labels.bolt') }}</div>

    <div class="grid grid-cols-2 gap-2 mb-8">
      <mobile-filter-tile
          v-for="category in categories"
          :key="category.id"
          :label="category.name"
          :class="{'black': categoryFilterIsActive && category.id === activeFilters.category.val}"
          class="green"
          @click="setCategoryFilter(category)"
      />
    </div>

    <!-- Material -->

    <div class="section-header">{{ $t('labels.material') }}</div>

    <div class="grid grid-cols-2 gap-2 mb-8">
      <mobile-filter-tile
          v-for="(material, index) in materials"
          :key="material.id"
          :label="material.name"
          :visible="index < blocksize * loadedBlocks.material"
          :class="{'black': materialFilterIsActive && material.id === activeFilters.material.val}"
          class="green"
          @click="setMaterialFilter(material)"
      />

      <div
          v-if="materials.length > blocksize * loadedBlocks.material"
          class="rounded-label black col-span-2 mt-2 text-13 text-medium"
          @click="loadedBlocks.material++"
      >
        {{ $t('labels.load-more') }}
      </div>
    </div>

    <!-- d1 -->

    <div class="section-header">
      {{ $t('labels.width-diameter') }}
    </div>

    <div class="grid grid-cols-2 gap-2 mb-8">
      <mobile-filter-tile
          v-for="(d1metric, index) in d1metrics"
          :key="d1metric.slug"
          :label="d1metric.description"
          :visible="index < blocksize * loadedBlocks.d1metric"
          :class="{'black': d1metricFilterIsActive && d1metric.slug === activeFilters.d1metric.val}"
          class="yellow"
          @click="setD1metricFilter(d1metric)"
      />

      <div
          v-if="d1metrics.length > blocksize * loadedBlocks.d1metric"
          class="rounded-label black col-span-2 mt-2 text-13 text-medium"
          @click="loadedBlocks.d1metric++"
      >
        {{ $t('labels.load-more') }}
      </div>
    </div>

    <!-- d2 -->

    <div class="section-header">
      {{ $t('labels.length') }}
    </div>

    <div class="grid grid-cols-2 gap-2 --mb-8 mb-16">
      <mobile-filter-tile
          v-for="(d2metric, index) in d2metrics"
          :key="d2metric.slug"
          :label="d2metric.description"
          :visible="index < blocksize * loadedBlocks.d2metric"
          :class="{'black': d2metricFilterIsActive && d2metric.slug === activeFilters.d2metric.val}"
          class="yellow"
          @click="setD2metricFilter(d2metric)"
      />

      <div
          v-if="d2metrics.length > blocksize * loadedBlocks.d2metric"
          class="rounded-label black col-span-2 mt-2 text-13 text-medium"
          @click="loadedBlocks.d2metric++"
      >
        {{ $t('labels.load-more') }}
      </div>
    </div>

    <button
        class="w-full border-1 border-21 rounded-lg text-center p-3 manrope font-semibold-- mb-2 text-14 text-medium"
        @click="goToSearchResults"
    >
      {{$t('labels.show-results')}}
    </button>
    <div class="w-full text-sm text-right mb-10 font-semibold">
      <span class="border-b-1 cursor-pointer" @click="clearAllFilters">{{ $t('labels.clear-all')}}</span>
    </div>

  </div>

  <div v-if="showToolFilters" class="pt-24 mb-32" >
    
    <!--Tool brands-->
    <div class="section-header">
      {{ lang_gr? 'Εταιρεία': 'Company'  }}
    </div>

    <div class="grid grid-cols-2 gap-2 mb-8">
      <div class="rounded-label orange" 
      @click="if (activeFilters.toolBrand!='bahco') {activeFilters.toolBrand='bahco'} else {activeFilters.toolBrand=null;}; loadCategories2()"
      :class="{'black': activeFilters.toolBrand=='bahco'}">
        Bahco
      </div>

      <div class="rounded-label orange" 
      @click="if (activeFilters.toolBrand!='irimo') {activeFilters.toolBrand='irimo'} else {activeFilters.toolBrand=null;}; loadCategories2()"
      :class="{'black': activeFilters.toolBrand=='irimo'}">
        Irimo
      </div>

      <div class="rounded-label orange" 
      @click="if (activeFilters.toolBrand!='makita') {activeFilters.toolBrand='makita'} else {activeFilters.toolBrand=null;}; loadCategories3()"
      :class="{'black': activeFilters.toolBrand=='makita'}">
        Makita
      </div>

    </div>

    <!--Tool types-->
    <div class="section-header">
      {{ lang_gr? 'Τύπος': 'Type'  }}
    </div>

    <div class="grid grid-cols-2 gap-2 mb-8">
      <mobile-filter-tile
          v-for="category in categories23"
          :key="category.id"
          :label="category.name"
          :class="{'black': category.id === activeFilters.toolType}"
          class="orange"
          @click="setCategory2Filter(category)"
      />
    </div>

    <button
        class="w-full border-1 border-21 rounded-lg text-center p-3 manrope font-semibold-- mb-2 text-14 text-medium"
        @click="goToSearchToolResults"
    >
      {{$t('labels.show-results')}}
    </button>

  </div>


</template>

<script>
import DinInput from "@/components/arrow-filters/din/DinInput";
import MobileFilterTile from "@/components/MobileFilterTile";

export default {
  name: "MobileFilters.vue",
  emits: ['broadcast-filters'],
  provide() {
    return {
      setDinFilter: this.setDinFilter,
      unsetDinFilter: this.unsetDinFilter,
      setCategoryFilter: this.setCategoryFilter,
      unsetCategoryFilter: this.unsetCategoryFilter,
      setHeadTypeFilter: this.setHeadTypeFilter,
      unsetHeadTypeFilter: this.unsetHeadTypeFilter,
      setMaterialFilter: this.setMaterialFilter,
      unsetMaterialFilter: this.unsetMaterialFilter,
      setD1metricFilter: this.setD1metricFilter,
      unsetD1metricFilter: this.unsetD1metricFilter,
      setD2metricFilter: this.setD2metricFilter,
      unsetD2metricFilter: this.unsetD2metricFilter,
      // ------------------------------------------
      setFocusedFilter: this.setFocusedFilter,
    };
  },
  inject: ['clearAlgoliaSearch', 'getLastSearchType', 'setLastSearchType'],
  components: {MobileFilterTile, DinInput},
  data() {
    return {
      blocksize: 10,
      loadedBlocks: {
        category: 1,
        material: 1,
        d1metric: 1,
        d2metric: 1,
      },
      selectedFilterCategory: null,
      activeFilters: {
        // {label: x, val: y}
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
        // -----------
        focus: null,
        // page: 1,
        toolBrand: null,
        toolType: null,
      },
      showFastenerFilters: false,
      showToolFilters: false,
    }
  },
  computed: {
    categoriesLoaded() {
      return this.$store.getters['categories/categoriesLoaded'];
    },
    categories() {
      return this.$store.getters['categories/categories'];
    },
    categories2Loaded() {
        return this.$store.getters['categories/categories2Loaded'];
      },
    categories2() {
      return this.$store.getters['categories/categories2'];
    },
    categories3() {
      return this.$store.getters['categories/categories3'];
    },
    categories23() {
      if (this.activeFilters.toolBrand=="bahco" || this.activeFilters.toolBrand=="irimo") {
        return this.$store.getters['categories/categories2'];
      }
      else if (this.activeFilters.toolBrand=="makita") {
        return this.$store.getters['categories/categories3'];
      }
      else {
        return this.$store.getters['categories/categories2'];
      }
    },
    materialsLoaded() {
      return this.$store.getters['filters/materialsLoaded'];
    },
    materials() {
      return this.$store.getters['filters/materials'];
    },
    d1metricsLoaded() {
      return this.$store.getters['filters/d1metricsLoaded'];
    },
    d1metrics() {
      return this.$store.getters['filters/d1metrics'];
    },
    d2metricsLoaded() {
      return this.$store.getters['filters/d2metricsLoaded'];
    },
    d2metrics() {
      return this.$store.getters['filters/d2metrics'];
    },

    //

    algoliaQueryParam() {
      return this.$route.query.algolia;
    },
    lastSearchType() {
      return this.getLastSearchType();
    },
    dinFilterIsActive() {
      return this.activeFilters.din !== null;
    },
    categoryFilterIsActive() {
      return this.activeFilters.category !== null;
    },
    headTypeFilterIsActive() {
      return this.activeFilters.headType !== null;
    },
    materialFilterIsActive() {
      return this.activeFilters.material !== null;
    },
    d1metricFilterIsActive() {
      return this.activeFilters.d1metric !== null;
    },
    d2metricFilterIsActive() {
      return this.activeFilters.d2metric !== null;
    },
    filterIsActive() {
      return this.dinFilterIsActive ||
          this.categoryFilterIsActive ||
          this.headTypeFilterIsActive ||
          this.materialFilterIsActive ||
          this.d1metricFilterIsActive ||
          this.d2metricFilterIsActive;
    },
    filterCount() {
      return this.dinFilterIsActive ? 1 : 0 +
      this.categoryFilterIsActive ? 1 : 0 +
      this.headTypeFilterIsActive ? 1 : 0 +
      this.materialFilterIsActive ? 1 : 0 +
      this.d1metricFilterIsActive ? 1 : 0 +
      this.d2metricFilterIsActive ? 1 : 0;
    },
    isSearchPage() {
      return false; // this.$route.path === '/search';
    },
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  },
  watch: {
    algoliaQueryParam(val) {
      if (val) {
        this.resetFilters();
        this.setSelectedFilterCategory(null);
      }
    },
  },
  methods: {
    // din|type|metrics
    setSelectedFilterCategory(mainTab) {
      this.selectedFilterCategory = mainTab;

      if (mainTab === 'type' && !['category', 'material'].includes(this.activeFilters.focus)) {
        this.setFocusedFilter('category');
      } else if (mainTab === 'metrics' && !['dimension1', 'dimension2'].includes(this.activeFilters.focus)) {
        this.setFocusedFilter('dimension1');
      }
    },
    setFocusedFilter(focusedFilter) {
      this.activeFilters.focus = focusedFilter;
    },
    resetFilters() {
      this.activeFilters = {
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
        // -----------
        focus: null,
      }
    },
    setDinFilter(din) {
      if (din === null || din === '') {
        return;
      }

      //this.resetFilters();
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.din = {
        label: din,
        val: din
      };

      this.activeFilters.focus = 'din';

      // this.goToSearchResults();
    },
    unsetDinFilter() {
      this.activeFilters.din = null;
    },
    setCategoryFilter(category) {
      let filterDeselected = false;

      // the category was already selected and the user actually deselected it
      if (this.activeFilters.category && this.activeFilters.category.val === category.id) {
        filterDeselected = true;
      }

      //this.resetFilters();
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      if (!filterDeselected) {
        this.activeFilters.category = {
          label: category.name,
          val: category.id
        };
      }

      this.activeFilters.focus = 'category';

      this.loadMaterials();
      this.loadDimensions();

      // this.goToSearchResults();
    },
    unsetCategoryFilter() {
      this.activeFilters.category = null;
    },

    setCategory2Filter(category) {
      if (this.activeFilters.toolType == category.id) {
        this.activeFilters.toolType = null;
      }
      else {
        this.activeFilters.toolType = category.id;
      }
      
    },

    setMaterialFilter(material, page = 1) {
      let filterDeselected = false;

      // the material was already selected and the user actually deselected it
      if (this.activeFilters.material && this.activeFilters.material.val === material.id) {
        filterDeselected = true;
      }

      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      if (filterDeselected) {
        this.unsetMaterialFilter();
      } else {
        this.activeFilters.material = {
          label: material.name,
          val: material.id,
          page: page
        };
      }

      this.activeFilters.focus = 'material';

      // this.goToSearchResults();
    },
    unsetMaterialFilter() {
      this.activeFilters.material = null;
    },
    setD1metricFilter(metric, page) {
      let filterDeselected = false;

      // the filter was already selected and the user actually deselected it
      if (this.activeFilters.d1metric && this.activeFilters.d1metric.val === metric.slug) {
        filterDeselected = true;
      }

      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      if (filterDeselected) {
        this. unsetD1metricFilter();
      } else {
        this.activeFilters.d1metric = {
          label: metric.description,
          val: metric.slug,
          page: page
        };
      }

      this.activeFilters.focus = 'dimension1';

      // this.goToSearchResults();
    },
    unsetD1metricFilter() {
      this.activeFilters.d1metric = null;
    },
    setD2metricFilter(metric, page) {
      let filterDeselected = false;

      // the filter was already selected and the user actually deselected it
      if (this.activeFilters.d2metric && this.activeFilters.d2metric.val === metric.slug) {
        filterDeselected = true;
      }

      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      if (filterDeselected) {
        this.unsetD2metricFilter();
      } else {
        this.activeFilters.d2metric = {
          label: metric.description,
          val: metric.slug,
          page: page
        };
      }

      this.activeFilters.focus = 'dimension2';

      // this.goToSearchResults();
    },
    unsetD2metricFilter() {
      this.activeFilters.d2metric = null;
    },
    async initActiveFilters() {
      if (this.$route.query.algolia !== undefined) {
        this.selectedFilterCategory = null;

        return;
      }

      // attack of the clones!
      let filters = Object.assign({}, this.activeFilters);

      /*
       * Din
       */
      if (this.$route.query.din !== undefined && this.$route.query.din !== '') {
        filters.din = {
          label: this.$route.query.din,
          val: this.$route.query.din,
        };
      }

      /*
       * Category
       */
      if (this.$route.query.category !== undefined && this.$route.query.category !== '') {
        if (!this.$store.getters['categories/categoriesLoaded']) {
          try {
            await this.$store.dispatch('categories/loadCategories');
          } catch (error) {
            // console.warn(error);
          }
        }

        let category = this.$store.getters['categories/categories'].find(category => category.id === this.$route.query.category);

        // console.log('this.$route.query.category: ', this.$route.query.category);
        // console.log('category: ', category);

        if (category !== undefined) {
          filters.category = {
            label: category.name,
            val: category.id,
          };
        }
      }

      /*
       * Material
       */
      if (this.$route.query.material !== undefined && this.$route.query.material !== '') {
        if (!this.$store.getters['filters/materialsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadMaterials', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let material = this.$store.getters['filters/materials'].find(material => material.id === this.$route.query.material);

        // console.log('this.$route.query.material: ', this.$route.query.material);
        // console.log('material: ', material);

        if (material !== undefined) {
          filters.material = {
            label: material.name,
            val: material.id,
          };
        }
      }

      /*
       * Metrics - D1
       */
      if (this.$route.query.dimension1 !== undefined && this.$route.query.dimension1 !== '') {
        if (!this.$store.getters['filters/d1metricsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadD1Metrics', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let dimension1 = this.$store.getters['filters/d1metrics'].find(dimension1 => dimension1.slug === this.$route.query.dimension1);

        if (dimension1 !== undefined) {
          filters.d1metric = {
            label: dimension1.description,
            val: dimension1.slug,
          };
        }
      }

      /*
       * Metrics - D2
       */
      if (this.$route.query.dimension2 !== undefined && this.$route.query.dimension2 !== '') {
        if (!this.$store.getters['filters/d2metricsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadD2Metrics', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let dimension2 = this.$store.getters['filters/d2metrics'].find(dimension2 => dimension2.slug === this.$route.query.dimension2);

        if (dimension2 !== undefined) {
          filters.d2metric = {
            label: dimension2.description,
            val: dimension2.slug,
          };
        }
      }

      const focus = this.$route.query.focus;

      if (focus !== undefined && focus !== '') {
        filters.focus = focus;
      }

      if (focus === 'din') {
        this.selectedFilterCategory = 'din';
      } else if (focus === 'category' || focus === 'material') {
        this.selectedFilterCategory = 'type';
      } else if (focus === 'dimension1' || focus === 'dimension2') {
        this.selectedFilterCategory = 'metrics';
      }

      // console.log('init active filters finished activeFilters: ',  JSON.stringify(filters));
      // console.log('broadcasting filters to parent');

      // we do this so that every change on activeFilters does not
      // trigger the watcher function
      this.activeFilters = filters;

      // this.$emit('broadcast-filters', this.activeFilters);
    },
    focusFilter() {
      // console.log('focusFilter start');

      if (this.$route.query.focus === undefined || this.$route.query.focus === '') {
        return;
      }

      const focus = this.$route.query.focus;

      this.activeFilters.focus = focus;

      // console.log('focus: ', focus);

      if (focus === 'din') {
        this.selectedFilterCategory = 'din';
      } else if (focus === 'category' || focus === 'material') {
        this.selectedFilterCategory = 'type';

        // the ref may have not been initialized
        // if (this.$refs['type-browse-section']) {
        //   console.log('TBS was found, focusing ..')
        //   this.$refs['type-browse-section'].focusFilter(focus);
        // } else {
        //   console.log('TBS was NOT found')
        // }
      } else if (focus === 'dimension1' || focus === 'dimension2') {
        this.selectedFilterCategory = 'metrics';

        // the ref may have not been initialized
        // if (this.$refs['metrics-browse-section']) {
        //   console.log('MBS was found, focusing ..')
        //   this.$refs['metrics-browse-section'].focusFilter(focus);
        // } else {
        //   console.log('MBS was NOT found')
        // }
      }
    },
    getFiltersQuery() {
      let queryFiltersArray = [];

      if (this.dinFilterIsActive) {
        queryFiltersArray.push('din='+this.activeFilters.din.val);
      }
      if (this.categoryFilterIsActive) {
        queryFiltersArray.push('category='+this.activeFilters.category.val);
      }
      if (this.materialFilterIsActive) {
        queryFiltersArray.push('material='+this.activeFilters.material.val);
      }
      if (this.d1metricFilterIsActive) {
        queryFiltersArray.push('dimension1='+this.activeFilters.d1metric.val);
      }
      if (this.d2metricFilterIsActive) {
        queryFiltersArray.push('dimension2='+this.activeFilters.d2metric.val);
      }

      if (this.activeFilters.toolBrand!=null) {
        queryFiltersArray.push('toolbrand=' + this.activeFilters.toolBrand);
      }
      if (this.activeFilters.toolType!=null) {
        queryFiltersArray.push('tooltype=' + this.activeFilters.toolType);
      }

      if (this.activeFilters.focus !== null) {
        queryFiltersArray.push('focus='+this.activeFilters.focus);
      }

      // console.log('getFiltersQuery retured: ' + queryFiltersArray.join('&'))

      return queryFiltersArray.join('&');
    },
    clearAllFilters() {
      this.activeFilters = {
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
      };
    },
    goToSearchResults() {
      const filtersQuery = this.getFiltersQuery();
      this.$router.push('/search?'+filtersQuery);
    },
    goToSearchToolResults() {
      if (this.activeFilters.toolBrand!=null && this.activeFilters.toolType!=null) {
        this.$router.push('/category/'+this.activeFilters.toolType + '?toolbrand=' + this.activeFilters.toolBrand);
      }
      else if (this.activeFilters.toolType!=null) {
        this.$router.push('/category/'+this.activeFilters.toolType);
      }
      else if (this.activeFilters.toolBrand!=null) {
        this.$router.push('/search-tool-categories?toolbrand='+this.activeFilters.toolBrand);
      }
      else {
        let msg = this.lang_gr? "Δεν έχετε επιλέξει φίλτρο": "Yoy have not selected brand or type.";
        /*self.openDialog({
          class: 'danger',
          html: msg
        });*/
        alert(msg);
      }
    },
    async loadCategories() {
      const self = this;

      try {
        await this.$store.dispatch('categories/loadCategories');
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadCategories2() {
      const self = this;
      let toolBrand = this.activeFilters.toolBrand;

      try {
        await this.$store.dispatch('categories/loadCategories2', {
          forceRefresh: true,
          toolbrand: toolBrand
        });
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadCategories3() {
      const self = this;
      let toolBrand = this.activeFilters.toolBrand;

      try {
        await this.$store.dispatch('categories/loadCategories3', {
          forceRefresh: true,
          toolbrand: toolBrand
        });
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadMaterials() {
      const self = this;

      try {
        await this.$store.dispatch('filters/loadMaterials', {
          // category_id: this.selectedCategoryId
          category_id: this.categoryFilterIsActive ? this.activeFilters.category.val : null
        });

        /*if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }*/
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadDimensions() {
      const self = this;

      try {
        await this.$store.dispatch('filters/loadD1Metrics', {
          // category_id: this.selectedCategoryId
          category_id: this.categoryFilterIsActive ? this.activeFilters.category.val : null
        });

        /*if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }*/

        await this.$store.dispatch('filters/loadD2Metrics', {
          // category_id: this.selectedCategoryId
          category_id: this.categoryFilterIsActive ? this.activeFilters.category.val : null
        });

        /*if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }*/
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log('ArrowFilters mounted activeFilters: ', JSON.stringify(this.activeFilters));
      // console.log('ArrowFilters mounted query params: ', JSON.stringify(this.$route.query));

      // if (this.isSearchPage) {
        // this.initActiveFilters();
      // }
    });
  },
  created() {
    // console.log('init active filters created()');
    this.initActiveFilters();

    // console.log('loading categories from created()');
    this.loadCategories();
    this.loadCategories2();
    // console.log('loading materials from created()');
    this.loadMaterials();
    // console.log('loading dimensions from created()');
    this.loadDimensions();

    this.$store.commit('filters/showFastenerFilterGroup');
    
  }
}
</script>

<style scoped>
  .section-header {
    font-family: 'Manrope', sans-serif;
    font-size: 24px;
    /*padding-left: .5rem;*/
    margin-bottom: 1.5rem;
    cursor: pointer;
    font-weight: 400;
  }

  .rounded-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    border-radius: .5rem;
    padding: 12px;
    cursor: pointer;
    /*font-weight: 500;*/
    /*font-size: 1rem;*/
  }

  .rounded-label.orange {
    background-color: #e4c77b;
    border:1px solid #212121;
  }
  .rounded-label.black {
    color: #fff;
    background-color: #262626;
  }

  .select-filter-type-container {
    padding:50px 20px 30px 20px;
    border-bottom: 1px solid #ccc;
  }

  .select-filter-type-container div {
    padding:20px;
    background-color:#212121;
    color:#ffffff;
    border-radius: 15px;
    text-align: center;
    margin-bottom:20px;
  }

  

</style>