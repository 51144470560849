<template>
    <form tabindex="-1" class="w-full max-w-2xl mx-auto pt-8 pb-6 px-8 bg-white rounded-2xl shadow-2xl">
        <header class="flex flex-wrap -mx-3">
            <div class="w-full px-3 text-gray-700">
                <slot name="header"></slot>
            </div>
        </header>

        <hr/>

        <section class="space-y-5">
            <slot></slot>
        </section>

        <hr/>

        <menu class="flex flex-wrap -mx-3 my-0 pl-0">
            <div class="w-full px-3 space-x-4 text-right">
                <slot name="actions">
                    <button type="submit" class="btn btn-outline">
                        <i class="fas fa-save"></i>Save
                    </button>
                </slot>
            </div>
        </menu>
    </form>
</template>

<script>

export default {
    name: 'BaseForm',
};
</script>
