<template>
  <div class="desktop-spacer" style="height:30px"></div>

  <div id="din-search-wrapper"
       class="flex w-full rounded-md mt-4 mb-3 sm:mb-8 mx-auto--"
       style=""
       :style="{'background-color': bgColor, 'border-color': bgColor}"
  >
    <input @focusin="toggleBgColor" @focusout="toggleBgColor" type="text" v-model="search"
           class="w-full rounded-md manrope bg-beige pl-4 sm:text-xl sm:font-normal gp-sm-txt-14 gp-sm-txt-medium din-input"
           placeholder=""
           @keydown.enter="setDinFilter(search)"
           @keyup="getDins(search)"
           style=""
           ref="dininput"
    />
    <img src="../../../assets/svg/search-blue2.svg"
         class="hidden sm:flex mx-3 cursor-pointer"
         style="width: 50px; display:none"
         @click="setDinFilter(search)"
    />
    <div v-if="showDinsList"
      class="din-list-container" 
      style="">
      <ul class="din-list">
        <li v-for="din in dins" :key="din" class="din-list-option" @click="selectDinFromList(din.din)">
          {{ din.din }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DinInput",
  inject: ['setDinFilter', 'unsetDinFilter'],
  props: {
    din: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      search: null,
      bgColor: 'rgb(0, 163, 225)',
      dins: [],
      showDinsList: false,
    };
  },
  computed: {
    // bgColor() {
    //   return this.search ? 'rgb(200, 163, 225)' : 'rgb(0, 163, 225)';
    // }
  },
  watch: {
    din(newVal) {
      this.search = newVal;
      if (newVal=="") {
        this.showDinsList = false;
      }
    }
  },
  methods: {
    toggleBgColor() {
      /*if (this.bgColor === 'rgb(0, 163, 225)') {
        this.bgColor = '#0c77ff';
      } else {
        this.bgColor = 'rgb(0, 163, 225)';
      }*/ /** 31-10-2022 */
    },
    selectDinFromList(din) {
      this.setDinFilter(din);
      this.showDinsList = false;
    },
    async getDins(search_din) {
      try {
        //await this.$store.dispatch('account/loadDins', search_din);
        console.log(search_din);

        await this.$store.dispatch('filters/loadDins', {
          din: search_din
        });

        //console.log("DINS==");
        //console.log(this.$store.getters['filters/dins']);
        this.dins = this.$store.getters['filters/dins'];
        this.showDinsList = true;

        if (search_din=="") {
          this.showDinsList = false;
        }


      } catch (error) {
          console.log(error.message);
      }
    }
  },
  created() {
    this.search = this.din;    
  },
  mounted() {
    this.$refs.dininput.focus();
  }
}
</script>

<style scoped>
#din-search-wrapper {
  border: none;
  max-width: 370px; margin-left:20px; /*932*/
  position:absolute; 
  top:72px; 
  width:225px; 
}
#din-search-wrapper input {
  background-color: #0ca2ff; /** 31-10-2022 */
  /*background-color: #7a9282;*/
}

input {
  color: #212121;
  outline: rgb(0, 163, 225);
}

input::placeholder {
  /*color: rgb(0, 163, 225);*/
  color:#212121;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 799px) {
  input::placeholder {
    color: #212121;
  }
}

@media screen and (max-width:1600px) {
  #din-search-wrapper {
    width:680px;
  }
}
@media screen and (max-width:1500px) {
  #din-search-wrapper {
    margin-left:130px;
    max-width:290px;

  }
}
@media screen and (max-width:1400px) {
  #din-search-wrapper {
    margin-left:150px;
    max-width:280px;
  }
}
@media screen and (max-width:1300px) {
  #din-search-wrapper {
    margin-left:165px;
    max-width:270px;
  }
}
@media screen and (max-width:1200px) {
  #din-search-wrapper {
    margin-left:210px;
    max-width:240px;
  }
}



@media screen and (max-width:800px) {
  #din-search-wrapper {
    width:100%;
    margin-left:0px;
  }
}

.din-list-option {
  width:310px; 
  padding:1px 15px;
  font-size: 25px;
  cursor: pointer;
}
.din-list-option:hover {
  background-color: #fff;
}
.din-list-option:last-child {
  padding-bottom: 5px;
}

.din-list-container {
  position:absolute; 
  top:60px; 
  z-index:9999; 
  background:#0ca2ff;
  /*background-color: #7a9282;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.din-input {
  padding-top:15px; font-size:25px; border:none;
}

@media (max-width:1600px) {
  #din-search-wrapper {
    left: 0px;
    top: 83px;
    margin-top: 0px;
    margin-left: 17px;
    width: 200px;
    font-size: 22px;
  }
  .din-list-container {
    top:58px;
  }
  .din-list-option {
    width:220px; 
    padding:1px 8px;
    font-size: 22px;
  }
  .din-input {
    padding-left:5px;
  }
}

@media (max-width:1400px) {
  #din-search-wrapper {
    left: 26px;
    top: 75px;
    margin-top: 0px;
    margin-left: 17px;
    width: 180px;
    font-size: 19px;
  }
  .din-list-container {
    top:58px;
  }
  .din-list-option {
    width:210px; 
    padding:1px 8px;
    font-size: 19px;
  }
  .din-input {
    padding-left:7px;
    font-size: 19px;
    padding-top:20px;
  }
}

@media (max-width:1300px) {
  #din-search-wrapper {
    left: 52px;
    top: 70px;
    margin-top: 0px;
    margin-left: 17px;
    width: 170px;
    font-size: 19px;
  }
  .din-list-container {
    top:51px;
  }
  .din-list-option {
    width:192px; 
    padding:1px 8px;
    font-size: 19px;
  }
  .din-input {
    padding-left:18px;
    font-size: 19px;
    padding-top:17px;
  }
}

@media (max-width:1100px) {
  #din-search-wrapper {
    left: 88px;
    top: 77px;
    margin-top: 0px;
    margin-left: 17px;
    width: 150px;
    font-size: 19px;
  }
  .din-list-container {
    top:47px;
  }
  .din-list-option {
    width:170px; 
    padding:1px 8px;
    font-size: 19px;
  }
  .din-input {
    padding-left:7px;
    font-size: 19px;
    padding-top:10px;
  }
}

@media (max-width:800px) {
  #din-search-wrapper {
    position:relative;
    margin-bottom:50px;
    top:10px;
  }

  

  .desktop-spacer {
    display:none;
  }
}

</style>
