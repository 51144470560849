<template>
  <div class="mx-auto rounded-lg mt-4 mb-8 gp-metrics-browse-section" style="max-width: 932px; background-color: #F5F5E8; width:95vw">
    <div class="p-8 rounded-lg mx-auto" style="background-color: #EECA5B">
      <!-- Small Arrow section -->
      <div class="flex mx-auto mb-16" style="width: 490px;">
        <arrow-shaped-nav
            class="dimension-1 small yellow z-10"
            :overlay="true"
            :index="-1"
            :left-offset="leftOffset"
            :arrow-number="1"
            :class="{selected: activeFilters.focus === 'dimension1' || activeFilters.focus === null}"
            :title="$t('labels.width-diameter')"
            @click="setSelectedMetricCategory('dimension1')"
        ></arrow-shaped-nav>
        <arrow-shaped-nav
            :index="0"
            :left-offset="leftOffset"
            class="dimension-2 small yellow last z-20"
            :overlay="true"
            :arrow-number="2"
            :class="{selected: activeFilters.focus === 'dimension2'}"
            :title="$t('labels.length')"
            @click="setSelectedMetricCategory('dimension2')"
        ></arrow-shaped-nav>
      </div>

      <transition name="fade-in">
        <dimension1-section
            v-if="activeFilters.focus === 'dimension1' || activeFilters.focus === null" style="min-height: 332px"
            :current-page="currentPage"
            :selected-category-id="categoryFilterIsActive ? this.activeFilters.category.val : null"
            :selected-d1metric-id-prop="activeFilters.d1metric ? activeFilters.d1metric.val : null"
            :current-page-prop="activeFilters.d1metric ? activeFilters.d1metric.page : 1"
        ></dimension1-section>
      </transition>

      <transition name="fade-in">
        <dimension2-section
            v-if="activeFilters.focus === 'dimension2'" style="min-height: 332px"
            :current-page="currentPage"
            :selected-category-id="categoryFilterIsActive ? this.activeFilters.category.val : null"
            :selected-d2metric-id-prop="activeFilters.d2metric ? activeFilters.d2metric.val : null"
            :current-page-prop="activeFilters.d2metric ? activeFilters.d2metric.page : 1"
        ></dimension2-section>
      </transition>


      <!-- controls -->
    <div v-if="paginatorIsVisible" id="pagination-controls" class="flex items-center justify-center p-2 manrope font-semibold" style="">

      <img v-if="currentPage === 1" src="../../assets/svg/BACK_ARROW_YELLOW.svg" class="cursor-pointer" style="width: 55px;"/>
      <img v-else src="../../assets/svg/arrow-prev.svg" class="cursor-pointer" @click="previousPage"/>

      <span class="w-20 text-center">{{ currentPage }}/{{ totalPages }}</span>

      <img v-if="currentPage === totalPages" src="../../assets/svg/BACK_ARROW_YELLOW.svg" class="cursor-pointer" style="width: 55px; transform: rotate(180deg);"/>
      <img v-else src="../../assets/svg/arrow-next.svg" class="cursor-pointer" @click="nextPage"/>

    </div>




    </div>

<!--    currentPage === 1 ? -->

    
  </div>
</template>

<script>
import ArrowShapedNav from "@/components/arrow-filters/ArrowShapedNav";
import Dimension1Section from "@/components/arrow-filters/metrics/Dimension1Section";
import Dimension2Section from "@/components/arrow-filters/metrics/Dimension2Section";

export default {
  name: "MetricsBrowseSection",
  components: {Dimension2Section, Dimension1Section, ArrowShapedNav},
  provide() {
    return {
      initPaginator: this.initPaginator,
      showPaginator: this.showPaginator,
      hidePaginator: this.hidePaginator,
    }
  },
  inject: [
    // 'setCategoryFilter',
    // 'unsetCategoryFilter',
    // 'setMaterialFilter',
    // 'unsetMaterialFilter',
    'setFocusedFilter'
  ],
  props: {
    activeFilters: {
      type: Object,
      required: false,
      default: function() {
        return {
          d1metric: null,
          d2metric: null,
          focus: 'dimension1',
        };
      },
    }
  },
  data() {
    return {
      leftOffset: 15,
      // selectedMetricCategory: 'dimension-1',
      currentPage: 1,
      totalPages: 1,
      paginatorIsVisible: false
    }
  },
  computed: {
    categoryFilterIsActive() {
      return this.activeFilters.category !== null;
    },
  },
  methods: {
    setSelectedMetricCategory(value) {
      // this.selectedMetricCategory = value;
      this.setFocusedFilter(value);
    },
    initPaginator(payload) {
      // console.log('initPaginator called with payload', payload);
      this.currentPage = payload.currentPage;
      this.totalPages = payload.totalPages;
    },
    showPaginator() {
      // console.log('showpaginator called');
      this.paginatorIsVisible = true;
    },
    hidePaginator() {
      // console.log('hidepaginator called');
      this.paginatorIsVisible = false;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // focusFilter(focus) {
    //   this.selectedMetricCategory = focus;
    // },
  },
  created() {
    // console.log('received AF: ', this.activeFilters);
  }
}
</script>

<style scoped>

.gp-metrics-browse-section {
    margin-left:-65px;
  }

@media screen and (max-width:1600px) {
  .gp-metrics-browse-section {
    margin-left:0px;
  }
}



</style>
