<template>
    <arrow-filters style="margin-bottom: 65px;"/>

    <div class="pad-left-100 pad-right-104" style="overflow-x: hidden; overflow-y:visible;">
      
      <div class="big-stock-container">
        <div v-if="lang_gr" class="big-stock-title text-medium">
          Μεγάλο Στοκ
        </div>
        <div v-else class="big-stock-title text-medium">
          Large Stock
        </div>

        <div class="big-stock-content">
          
          <div class="content-1">
            <div v-if="lang_gr" class="content-1-title text-semibold">
              Προϊόντα <br/>
              σε στοκ σήμερα
            </div>
            <div v-else class="content-1-title text-semibold">
              Products <br/>
              in stock today
            </div>

            <div v-if="lang_gr" class="content-1-subtitle text-medium">
              Είδη
            </div>
            <div v-else class="content-1-subtitle text-medium">
              Items
            </div>

            <div id="mycounter" style="">{{ counter }}</div>

          </div>

          <div v-if="lang_gr" class="content-2">
            <div class="content-2-title text-medium">
              Κρατάμε μεγάλα στοκ <br/>
              για να εξυπηρετούμε άμεσα όλους <br/>
              τους πελάτες μας.
            </div>
            <div class="content-2-subtitle text-medium">
              Το μεγαλύτερο στοκ βίδας στην Ελλάδα.<br/> 
              Βρείτε οτι χρειάζεστε σήμερα.
            </div>
          </div>

          <div v-else class="content-2">
            <div class="content-2-title text-medium">
                We keep large stocks <br/>
                to serve everyone <br/>
                of our customers.
            </div>
            <div class="content-2-subtitle text-medium">
              The largest stock of screws in Greece.<br/>
               Find what you need today.
            </div>
          </div>

          

        </div>
      </div>
      

    

    </div>

    

    

</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";


export default {
  name: "InfoBigStock",
  components: {ArrowFilters},
  data() {
    return {
      counter: 23256, 
      counterInterval: false,    
    };
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  },
  methods: {
    countup() {
      //console.log("countup method starting...");
      var my = this;
      var element = document.querySelector('#mycounter');
      var count_has_started = false;
      
      window.addEventListener('scroll', function() {
        if(my.isInViewport(element) && !count_has_started ) {
          console.log("count_has_started = " + count_has_started);
          count_has_started = true;
          
          my.counterInterval = setInterval(function () {      
            console.log(my.counter);
            count_has_started = true;
            if (my.counter<25251) {
              my.counter = my.counter + 12;
              console.log(my.counter);
            } 
            else {
              clearInterval(my.counterInterval);
            }       
          }, 20);
        }
      });

      if(my.isInViewport(element) && !count_has_started ) {
          console.log("count_has_started = " + count_has_started);
          count_has_started = true;
          
          my.counterInterval = setInterval(function () {      
            console.log(my.counter);
            count_has_started = true;
            if (my.counter<25251) {
              my.counter = my.counter + 12;
              console.log(my.counter);
            } 
            else {
              clearInterval(my.counterInterval);
            }       
          }, 20);
        }
      
    },
    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
  },
  mounted() {
    this.countup();
    
  }
}

</script>

<style scoped>

.big-stock-container {
  padding:15px;
  background-image: url(../../assets/svg/big-stock-bg-mobile.svg);
  background-size: 100% 100%;
  margin-bottom: 50px;
  margin-top:50px;
  position: relative;
  z-index: -1;
}
@media (min-width:600px) {
  .big-stock-container {
    padding:30px;
  }
}
@media (min-width:800px) {
  .big-stock-container {
    background-image: url(../../assets/svg/big-stock-bg.svg);
  }
}

.big-stock-title {
  font-size: 80px;
  line-height: 85px;
  height:250px;
  padding-top:50px;
  position: relative; 
  color: #212121; 
  margin-bottom:120px;
}
@media (min-width: 800px) {
  .big-stock-title {
    font-size: 100px;
    line-height: 100px;
    height:300px;
    margin-bottom:0px;
  }
}
@media (min-width: 1200px) {
  .big-stock-title {
    font-size: 145px;
    line-height: 150px;
    height:300px;
  }
}
@media (min-width: 1400px) {
  .big-stock-title {
    font-size: 212px;
    line-height: 215px;
    height:400px;    
  }
}


.big-stock-title::before {
  position: absolute;
  z-index: -1;
  top:100px;
  left:20px;
  width:450px;
  height:350px;
  content: '';
  background-image: url(../../assets/images/big-stock-title-bg2-mobile.png);
  background-size: 100%;
  background-repeat: no-repeat;
  animation: falldown1 3s forwards;
}
@media (min-width: 800px) {
  .big-stock-title::before {
    width:800px;
    height:321px;
    left:230px;
    top:30px;
    background-image: url(../../assets/images/big-stock-title-bg1.png);
  }
}
@media (min-width: 1200px) {
  .big-stock-title::before {
    width:950px;
    height:321px;
    left:360px;
  }
}
@media (min-width: 1400px) {
  .big-stock-title::before {
    width:1403px;
    height:321px;
    left:530px;
  }
}

.big-stock-title::after {
  position: absolute;
  top:0px;
  left:30px;
  width:400px;
  height:350px;
  content: '';
  background-image: url(../../assets/images/big-stock-title-bg1-mobile.png);
  background-size: 100%;
  background-repeat: no-repeat;
  animation: falldown2 4s forwards;
}
@media (min-width: 800px) {
  .big-stock-title::after {
    width:530px;
    height:250px;
    left:40px;
    top:0px;
    background-image: url(../../assets/images/big-stock-title-bg2.png);
  }
}
@media (min-width: 1200px) {
  .big-stock-title::after {
    width:750px;
    height:250px;
    left:60px;
    top:30px;
  }
}
@media (min-width: 1400px) {
  .big-stock-title::after {
    width:1072px;
    height:250px;
    left:60px;
    top:0px;
  }
}

@keyframes falldown1 {
  from {translate: 0px -50px; opacity:0}
  to {translate: 0px 0px; opacity:1}
}

@keyframes falldown2 {
  from {translate: 0px -50px; opacity:0}
  to {translate: 0px 0px; opacity:1}
}





.big-stock-content {
  display:grid;
  grid-template-columns: 100%;
  padding-top: 30px;
}

@media (min-width: 1000px) {
  .big-stock-content {
    grid-template-columns: 45% 55%;
  }
}

.content-1 {
  order:2;
  margin-bottom:30px;
}
.content-2 {
  order:1;
  margin-bottom:30px;
}
@media (min-width:800px) {
  .content-1 {
    order:1;
  }
  .content-2 {
    order:2;
  }
}

.content-1-title {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 50px;
}

.content-1-subtitle {
  display:inline-block;
  padding:10px 20px;
  background-image: url(../../assets/svg/360-title-bg.svg);
  background-size: 100% 100%;
  font-size: 17px;
  margin-left:-10px;
  margin-bottom: 28px;
}


#mycounter {
  font-size: 97px;
  line-height: 97px;
}
@media (min-width: 1200px) {
  #mycounter {
    font-size: 150px;
    line-height: 145px;
    margin-left:-10px;
  }
}
@media (min-width: 1400px) {
  #mycounter {
    font-size: 212px;
    line-height: 220px;
    margin-left:-15px;
  }
}

.content-2-title {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 30px;
}

.content-2-subtitle {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 30px;
  color:#808080;
}




.pad-left-100 {
    padding-left:102px;
  }
  @media (max-width:1400px) {
    .pad-left-100 {
      padding-left:60px;
    }  
  }
  @media (max-width:1100px) {
    .pad-left-100 {
      padding-left:50px;
    }  
  }
  @media (max-width:1100px) {
    .pad-left-100 {
      padding-left:47px;
    }  
  }
  @media (max-width:800px) {
    .pad-left-100 {
      padding-left:20px;
    }  
  }
  @media (max-width:600px) {
    .pad-left-100 {
      padding-left:20px;
      margin-left:-20px;
    }  
  }

  .pad-right-104 {
      padding-right:104px;
  }
  @media (max-width:1400px) {
    .pad-right-104 {
      padding-right:60px;
    }  
  }
  @media (max-width:1100px) {
    .pad-right-104 {
      padding-right:50px;
    }  
  }
  @media (max-width:1100px) {
    .pad-right-104 {
      padding-right:47px;
    }  
  }
  @media (max-width:800px) {
    .pad-right-104 {
      padding-right:20px;
    }  
  }
  @media (max-width:600px) {
    .pad-right-104 {
      padding-right:20px;
      margin-right:-20px;
    }  
  }


</style>