<template>
    <arrow-filters style="margin-bottom: 65px;"/>

    <div class="title-container pad-left-110">
        <h1 v-if="lang_gr" class="text-semibold">
            Βρείτε τα προϊόντα που θέλετε <br/>
            και σιγουρευτείτε οτι είναι αυτά ακριβώς <br/>
            που χρειάζεστε με την <span>σωστή διάμετρο</span>, <br/>
            <span>μήκος</span>  και  <span>τρισδιάστατο σχέδιο.</span>
        </h1> 
    </div>

    <div class="pad-left-110 pad-right-100 product-specs" >
        
        <div>
            <img src="../../assets/svg/product-specs-1.svg" />
        </div>

        <div>
            <img src="../../assets/svg/product-specs-2.svg" />
        </div>

        <div class="section-360">
            <h2 class="title-360 text-medium">
                360<sup>o</sup>
            </h2>
            <div class="text-medium text-360">
                Γυρίστε το σχέδιο 360 μοίρες, μεγεθύνετε<br/> 
                το και δείτε τις λεπτομέρειες του προϊόντος που σας ενδιαφέρει.<br/> 
                Πατήστε την ένδειξη specs για να βρείτε <br/>
                τα τεχνικά χαρακτηριστικά του προϊόντος σας. <br/>
                Ευκολές αγορές - λιγότερα λάθη.
            </div>
        </div>

        <div class="img-3d">
            <div class="sketchfab-embed-wrapper"> <iframe title="DIN 433 ZINC" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/3f53fd85119240fab78f73c2ad3d7382/embed?autostart=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0"> </iframe> </div>
        </div>
    
    
    </div>


</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "InfoFindProduct",
  components: {ArrowFilters},
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  }
}

</script>

<style scoped>

h1 {
    font-size: 32px;
    line-height: 42px;
    padding-left:20px;
    margin-bottom:70px;
    margin-top:145px;
}
@media (max-width:1400px) {
    h1 {
        margin-top:100px;
    } 
}
@media (max-width:1000px) {
    h1 {
        font-size: 25px;
        line-height: 30px;
        padding-left:0px;
        margin-bottom:30px;
        margin-top:50px;
    } 
}

h1 span {
    padding:1px 10px;
    background-color: #212121;
    color:#fff;
    border-radius: 20px;
}

.product-specs {
    display:grid;
    grid-template-columns: 58.55% 41.45%;
    row-gap: 35px;
    margin-bottom:100px;
    
}
@media (max-width:1000px) {
    .product-specs {
        grid-template-columns: 100%;
        row-gap: 35px;
        margin-bottom:60px;
        
    }
}

.title-360 {
    display:inline-block;
    padding:10px 20px;
    background-image: url(../../assets/svg/360-title-bg.svg);
    background-size: 100% 100%;
    margin-bottom:20px;
    font-size: 17px;
    margin-left:-10px;
}


.section-360 {
    padding-left:20px;
}
@media (max-width:800px) {
    .section-360 {
        padding-left:0px;
    }
}

.text-360 {
    font-size: 15px;
    line-height: 20px;
    /*padding-left:20px;*/
}

.img-3d {
    background-image: url(../../assets/svg/3d-bg.svg);
    background-size: 100% 100%;
    padding:20px;
    
}


</style>

<style>


</style>