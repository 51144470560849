
<template>
    <button :type="type" lang="el" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        class: {
            type: String,
            required: false,
            default: 'btn-default'
        }
    },
    computed: {
        classes() {
            return 'btn ' + this.class;
        }
    }
};
</script>

<style scoped>
/*.btn-custom {
    background: rgb(244,255,0);
    background: linear-gradient(130deg, rgba(244,255,0,1) 40%, rgba(0,255,19,1) 100%);
    color: #009da5;
    border-color: #e5ff00;
}

.btn-custom:hover {
    background: rgb(228,239,0);
    background: linear-gradient(90deg, rgba(228,239,0,1) 35%, rgba(0,239,3,1) 100%);
    !*color: #fff;
    border-color: #ffc107;*!
}*/

.btn-custom:focus {
    /* add tailwind ring manually */
}
</style>
