<template>
  <div class="algolia-search-wrapper flex w-full bg-21" style="margin:auto">
    <input
        type="text"
        name="search"
        v-model="search"
        class="algolia-search placeholder-gray-300 w-full text-medium px-4 bg-beige-xx"
        style="background-color: #F0F0F0;"
        :placeholder="$t('labels.artificial-intelligence-search')"
        autocomplete="off"
        @keydown.enter="algoliaSearch"
    />
    <img src="../../../assets/svg/search.svg" class="mx-3-xx cursor-pointer algolia-img" style="" @click="algoliaSearch"/>
  </div>
</template>

<script>
export default {
  name: "AlgoliaSearchBar",
  provide() {
    return {}
  },
  inject: ['openDialog', 'getLastSearchType', 'setLastSearchType'],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    algoliaQueryParam() {
      return this.$route.query.algolia;
    }
  },
  watch: {
    algoliaQueryParam(val) {
      // initialize the algolia search from the url param (if any)
      if (val && val !== this.search) {
        this.search = val;

        this.algoliaSearch();
      }
    }
  },
  methods: {
    async algoliaSearch() {
      // console.log('Initiating algolia search using search query:' + this.search);

      if (this.search === '') {
        return;
      }

      this.setLastSearchType('algolia');

      const self = this;

      try {
        await this.$store.dispatch('filters/algoliaSearch', this.search);
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }

      await this.$router.push('/search?algolia=' + this.search);
    }
  },
  mounted() {
    this.$nextTick(() => {
      // doesn't work
      // ------------
      // if (this.$route.query.algolia !== undefined) {
      //   this.search = this.$route.query.algolia;
      // }
    });
  }
}
</script>

<style scoped>
  .algolia-search {
    /*font-family : 'PolySansTrial Neutral', sans-serif;*/
    /*font-size : 18px;*/
    line-height : 15px;
    letter-spacing : -0.2px;
    color : #212121;
    border-style : Solid;
    border-color : #212121;
    border-color : rgba(33, 33, 33, 1);
    border-width : 1px;
    border-radius : 10px;
    -moz-border-radius : 10px;
    -webkit-border-radius : 10px;
    outline: none;
    padding-top: 22px; /*40px;*/
    padding-bottom:8px;
    font-size:23px;
    background-color:rgb(255, 252, 240);;
  }

  .algolia-search::placeholder {
    color: rgba(35, 35, 35, 0.16);
  }

  .algolia-search-wrapper {
    border-radius : 10px;
    background: #212121;
    /*background: linear-gradient(90deg, rgba(255,254,242,1) 20%, rgba(33,33,33,1) 50%);*/

    max-width: 760px; /* 932px;*/

  }


  .algolia-img {
    /*width: 60px; padding-top:8px;*/
    width:25px; margin:0px 20px
  }
  


  @media screen and (max-width:1600px) {
    .algolia-search {
      padding-top:20px;
      padding-bottom:5px;
      font-size:22px;
    }
  }

  @media screen and (max-width:1400px) {
    .algolia-search {
      padding-top:15px;
      padding-bottom:0px;
      font-size:19px;
    }
    .algolia-img {
      /*width: 55px; padding-top:8px;*/
      margin:15px 20px;
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb9b4;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bbb9b4;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #bbb9b4;
  }



</style>