<template>
    <arrow-filters style="margin-bottom: 65px;"/>
    <div class="page-container">
      
      <div class="title-container pad-left-110">
        <h1 v-if="lang_gr" class="text-semibold">Επικοινωνήστε <br/>μαζί μας</h1>
        <h1 v-else class="text-semibold">Contact us</h1>
      </div>

      <div class="content">
        
        <div class="content-tile">
          <div class="content-tile-title">
            <span v-if="lang_gr" class="text-medium">Τηλέφωνο</span>
            <span v-else class="text-medium">Phone</span>
          </div>
          <div class="content-text-top">
            <span v-if="lang_gr" class="text-medium">
              Καλέστε μας και ένας ειδικός<br/>
              από την ομάδα εξυπηρέτησης<br/>
              θα σας εξυπηρετήσει.
            </span>
            <span v-else class="text-medium">
              Call us and an expert<br/>
              by the service team<br/>
              will serve you.
            </span>
          </div>
          <div class="content-text-bottom">
            <span class="text-medium text-phone">210 48 21 368</span>
            <span v-if="lang_gr" class="text-medium">
              Από σταθερό τηλέφωνο με αστική χρέωση<br/> 
              με βάση τον ισχύοντα τιμοκατάλογο του παρόχου σας
            </span>
            <span v-else class="text-medium">
              From a landline with a local charge<br/>
              based on your provider's current price list
            </span>
          </div>
        </div>

        <div class="content-tile">
          <div class="content-tile-title">
            <span class="text-medium">Email</span>
          </div>
          <div class="content-text-top">
            <span v-if="lang_gr" class="text-medium">
              Θα σας απαντήσουμε <br/>
              εντός 24 ωρών.
            </span>
            <span v-else class="text-medium">
              We will answer you <br/>
               within 24 hours.
            </span>
          </div>
          <div class="content-text-bottom" style="text-align: center; padding:30px;">
            <a href="/info-contact-form" v-if="lang_gr" class="text-medium button">
              Στείλτε μας ένα Email
            </a>
            <a href="/en/info-contact-form" v-else class="text-medium button">
              Send us an Email
            </a>
          </div>
        </div>

        


      </div>


    </div>
</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "InfoContact",
  components: {ArrowFilters},
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  }
}

</script>

<style scoped>
  .page-container {
    display:grid;
    grid-template-columns: 30% 70%;
    margin-top:100px;
  }
  @media (max-width:1600px) {    
    .page-container {
    grid-template-columns: 26% 74%;
  }
  }
  @media (max-width:1400px) {
    .page-container {
      grid-template-columns: 24% 76%;
    }
  }
  @media (max-width:1300px) {
    .page-container {
      grid-template-columns: 25% 75%;
    }
  }
  @media (max-width:1200px) {    
    .page-container {
      grid-template-columns: 100%;
    }
  }

  @media (max-width:800px) {
    .page-container {
      grid-template-columns: 100%;
    }
  }

  /*.title-container {
    padding-left:110px;
  }
  @media (max-width:1400px) {
    .title-container {
      padding-left:60px;
    }  
  }
  @media (max-width:1100px) {
    .title-container {
      padding-left:50px;
    }  
  }
  @media (max-width:1100px) {
    .title-container {
      padding-left:47px;
    }  
  }
  @media (max-width:800px) {
    .title-container {
      padding-left:20px;
    }  
  }*/

  h1 {
    font-size:33px;
    line-height: 39px;
  }

  .content {
    display:flex;
    align-items: flex-start;
  }
  @media (max-width:1200px) {
    .content {
      padding-left:50px;
      padding-top:20px;
    }
  }
  @media (max-width:800px) {
    .content {
      flex-direction: column;
      padding-top:20px;
      padding-bottom:20px;
      padding-left:0px;
    }
  }

  .content-tile {
    width:390px;
    max-width:100%;
    height:375px;
    background-image: url(../../assets/svg/info-content-tile.svg);
    background-size: 100% 100%;
    display:block;
    position: relative;
    margin-left:-1px;
  }
  @media (max-width:1400px) {
    .content-tile {
      width:360px;
      height:360px;
    }
  }
  @media (max-width:800px) {
    .content-tile {
      margin-top:-1px;
    }
  }

  .content-tile-title {
    height:56px;
    background-image: url(../../assets/svg/info-content-title.svg);
    background-size: 100% 100%;
    padding:10px 20px;
    font-size:22px;
  }

  .content-text-top, 
  .content-text-bottom {
    font-size: 15px;
    line-height: 18px;
    padding:20px;
  }

  .content-text-bottom {
    position: absolute;
    bottom:0px;
    right:0px;
    left:0px;
  }

  .text-phone {
    font-size: 52px;
    line-height: 60px;
    display: block;
  }
  @media (max-width:1400px) {
    .text-phone {
      font-size: 40px;
      line-height: 45px;
    }
  }

  .button {
    padding:10px 20px;
    background-color: #212121;
    border:1px solid #212121;
    border-radius: 12px;
    display:inline-block;
    color:#fff;
    cursor:pointer;
  }

  .button:hover {
    background-color: transparent;
    color:#212121;
  }




</style>

<style>

  .pad-left-110 {
    padding-left:110px;
  }
  @media (max-width:1400px) {
    .pad-left-110 {
      padding-left:60px;
    }  
  }
  @media (max-width:1100px) {
    .pad-left-110 {
      padding-left:50px;
    }  
  }
  @media (max-width:1100px) {
    .pad-left-110 {
      padding-left:47px;
    }  
  }
  @media (max-width:800px) {
    .pad-left-110 {
      padding-left:20px;
    }  
  }

  .pad-right-100 {
      padding-right:100px;
  }
  @media (max-width:1400px) {
      .pad-right-100 {
        padding-right:60px;
      }  
    }
    @media (max-width:1100px) {
      .pad-right-100 {
        padding-right:50px;
      }  
    }
    @media (max-width:1100px) {
      .pad-right-100 {
        padding-right:47px;
      }  
    }
    @media (max-width:800px) {
      .pad-right-100 {
        padding-right:20px;
      }  
    }


</style>