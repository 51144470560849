<template>
  <div v-if="!categoryHasBeenLoaded" style="display:flex; flex-direction: column; height:500px; align-items: center; justify-content: center; font-size: 30px;">
    {{ errorMessage }}
    <div>
      <a @click="goBack" style="cursor:pointer; font-size:20px;"><img src="../../assets/svg/arrow-left-wireframe.svg" style="display:inline" /> Back</a>
    </div>
  </div>

  <div v-else-if="categoryIsGrandParent">
    <first-level-category :category="category" :toolbrand="toolBrand" ref="firstLevelCategory"/>
  </div>

  <div v-else-if="categoryIsParent">
    <second-level-category :category="category" :toolbrand="toolBrand" />
  </div>

  

</template>

<script>

import SecondLevelCategory from "@/pages/categories/SecondLevelCategory";
import FirstLevelCategory from "@/pages/categories/FirstLevelCategory";


export default {
  name: "CategoryLandingPage",
  components: {FirstLevelCategory, SecondLevelCategory},
  inject: ['openDialog'],
  props: {
    slug: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      errorMessage: ''
    }
  },
  watch: {
    slug() {
      this.loadCategoryDetails();
    }
  },
  computed: {
    categoryHasBeenLoaded() {
      return this.$store.getters['categories/categoryLoaded'];
    },
    category() {
      return this.$store.getters['categories/category'];
    },
    categoryIsParent() {
      return this.categoryHasBeenLoaded && this.$store.getters['categories/category'].has_children === true;
    },
    categoryIsGrandParent() {
      return this.categoryHasBeenLoaded && this.$store.getters['categories/category'].has_grandchildren === true;
    },
    categoryHasNoChildren() {
      return !this.categoryIsParent;
    },
    toolBrand() {
      return typeof(this.$route.query.toolbrand)=="undefined"? "": this.$route.query.toolbrand;
    },
  },
  methods: {
    async loadCategoryDetails() {
      try {
        let toolBrand = this.toolBrand;
        await this.$store.dispatch('categories/loadCategoryDetails', {
          slug: this.slug,
          toolbrand: toolBrand
        });

        // we commented this out because the related products in the product page
        // didn't work
        if (this.category.redirect /*&& !document.referrer.includes('/product/')*/) {

          console.log('Referrer: ' + document.referrer);

          // vue router redirect
          // this.$router.push(this.category.redirect);

          // full page reload - we want this here to load page meta
          window.location.replace(this.$router.resolve(this.category.redirect).href);
        }

        if (this.categoryIsGrandParent) {
          this.$refs.firstLevelCategory.initSliders();
        }
      } catch (error) {
        this.errorMessage = 'No items found';
        /*this.openDialog({
          class: 'danger',
          html: error.message
          html: 'No items found'
        });*/
      }
    },

    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    await this.loadCategoryDetails();
  },
}
</script>

<style scoped>

</style>