import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
// import Vue3TouchEvents from "vue3-touch-events";

import './css/tailwind.css'
import './css/transitions.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faTimesCircle);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TheHeader from "./components/layout/TheHeader";
import BaseButton from "./components/UI/BaseButton";
import BaseInput from "./components/UI/BaseInput";
import BaseSelect from "./components/UI/BaseSelect";
import BaseLabel from "./components/UI/BaseLabel";
import BaseTable from "./components/UI/BaseTable";
import BaseTextarea from "./components/UI/BaseTextarea";
import BaseDialog from "./components/UI/BaseDialog";
import BaseCard from "./components/UI/BaseCard";
import BaseNotification from "./components/UI/BaseNotification";
import BaseForm from "./components/UI/BaseForm";

import router from './router.js';
import store from './store/index.js';

const app = createApp(App);

app.use(store);
app.use(router);
// app.use(Vue3TouchEvents);

/*
 * Set the initial (default) locale based on whether
 * the href includes /en/
 */
let defaultLocale = 'el';

if (window.location.href.includes('/en/') || window.location.href.endsWith('/en')) {
    defaultLocale = 'en';
}

store.commit('app/setLocale', defaultLocale);

import en from './lang/en.js';
import el from './lang/el.js';

const messages = {
    el,
    en
};

const i18n = createI18n({
    locale: store.getters['app/locale'],
    fallbackLocale: 'en',
    messages: messages,
});

app.use(i18n);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('the-header', TheHeader);
app.component('base-button', BaseButton);
app.component('base-input', BaseInput);
app.component('base-select', BaseSelect);
app.component('base-label', BaseLabel);
app.component('base-table', BaseTable);
app.component('base-textarea', BaseTextarea);
app.component('base-dialog', BaseDialog);
app.component('base-notification', BaseNotification);
app.component('base-card', BaseCard);
app.component('base-form', BaseForm);

// import axios from 'axios';
//
// app.config.globalProperties.$axios = axios.create();

app.mount('#app');
