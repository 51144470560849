<template>

<div class="gp-container">

    <div class="sm:hidden pl-4 mb-1">
      Όλα τα είδη
    </div>


    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12 mpty">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title" style="">
      Download XML
    </div>

    <div style="">
        <ul>
            <li><input type="checkbox" style="margin-bottom:20px;" v-model="selectedAll" /> Όλα τα είδη</li>
            
            <li><input type="checkbox" style="margin-bottom:20px;" v-model="selectedFasteners" /> Βίδες
              <ul v-if="selectedFasteners" class="select-categories">
                <li v-for="category in homeCategories" 
                :key="category.id">
                <input type="checkbox" 
                :value="category.id" 
                v-model="selectedCatIdsFasteners" /> {{ category.name }}</li>
              </ul>
            </li>
            
            <li><input type="checkbox" style="margin-bottom:20px;" v-model="selectedTools" /> Εργαλεία
              <ul v-if="selectedTools" class="select-categories">
                <li v-for="category in homeCategories23" :key="category.id">
                  <input type="checkbox" 
                  :value="category.id"
                  v-model="selectedCatIdsTools" /> {{ category.name }}</li>
              </ul>
            </li>
        </ul>

        <div style="margin:20px 0px 100px">

          <span class="btn btn-primary" @click="resetData" style="margin-right:20px;cursor:pointer;">Reset</span>

          <span v-if="!pleaseWait" class="btn btn-primary" @click="fetchData" style="margin-right:20px;cursor:pointer;">Download file</span>

          <span v-if="pleaseWait" class="please-wait">
            Please wait...
          </span>

        </div>

        
    </div>

    <!-- <account-navigation-menu :menu-items="menuItems" :prefix="routePrefix" /> -->

</div>    

</template>

<script>


export default {
    name: "DownloadXml",
    data() {
      return {
        selectedCategoryIds: [],
        selectedCatIdsFasteners: [],
        selectedCatIdsTools: [],
        selectedAll: false,
        selectedFasteners: false,
        selectedTools: false,
        pleaseWait: false,
      }
    },    
    methods: {      
      jsonToXml(json) {
        let xml = '<?xml version="1.0" encoding="UTF-8"?><items>';
        xml += this.convertObjectToXml(json, 0);
        xml += '</items>';
        return xml;
      },
      convertObjectToXml(obj, indent) {
        let xml = '';
        // const indentation = '  '.repeat(indent);
        const indentation = '';

        for (const key in obj) {
          // if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (typeof value === 'object' && !Array.isArray(value)) {
              // xml += `${indentation}<${key}>\n`;
                xml += `${indentation}<item>`;
              xml += this.convertObjectToXml(value, indent + 1);
              // xml += `${indentation}</${key}>\n`;
              xml += `${indentation}</item>`;
            } else if (Array.isArray(value)) {
              value.forEach((item) => {
                // xml += `${indentation}<${key}>\n`;
                  xml += `${indentation}<item>`;
                xml += this.convertObjectToXml(item, indent + 1);
                // xml += `${indentation}</${key}>\n`;
                xml += `${indentation}</item>`;
              });
            } else {
              xml += `${indentation}<${key}>${value}</${key}>`;
            }
          //}
        }

        return xml;
      },

      downloadXml(xml, filename) {
        const blob = new Blob([xml], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      },

      resetData() {
        this.selectedCategoryIds = [];
        this.selectedAll = false;
        this.selectedFasteners = false;
        this.selectedTools = false;
      },

      async fetchData() {
        this.pleaseWait = true;

        let selected_ids = this.selectedCategoryIds.join(',');
        console.log(selected_ids);

        let selected_ids_fasteners = this.selectedCatIdsFasteners.join(',');

        let selected_ids_tools = this.selectedCatIdsTools.join(',');

        let fasteners = this.selectedFasteners? 1: 0;
        let tools = this.selectedTools? 1: 0;
        let all_items = this.selectedAll? 1: 0;

        if (fasteners + tools + all_items == 0) {
          alert("Θα πρέπει να επιλέξετε κατηγορία ή όλα τα είδη");
          this.pleaseWait = false;
          return;
        }

        try {
          await this.$store.dispatch('categories/getItemsXml', {
            all_items: all_items,
            fasteners: fasteners,
            tools: tools,
            category_ids: selected_ids,
            category_ids_fasteners: selected_ids_fasteners,
            category_ids_tools: selected_ids_tools
          });
          let my_items = this.$store.getters['categories/itemsXml'];
          console.log(my_items);

          const xml = this.jsonToXml(my_items);
          console.log(xml);

          this.downloadXml(xml, 'items.xml');
          this.pleaseWait = false;


        } catch (error) {
          console.log(error.message);
          this.pleaseWait = false;
        }
      },

      async loadHomeCategories() {
        const self = this;
        try {
          await this.$store.dispatch('categories/loadCategories', {
            forceRefresh: true,
          });
          
        } catch (error) {
          self.openDialog({
            class: 'danger',
            html: error.message + '...1....'
          });
        }
      },

      async loadHomeCategories23() {
        const self = this;
        try {
          await this.$store.dispatch('categories/loadCategories23', {
            forceRefresh: true,
          });
          
        } catch (error) {
          self.openDialog({
            class: 'danger',
            html: error.message + '...1....'
          });
        }
      },

    },
    computed: {
      homeCategories() {
        return this.$store.getters['categories/categories'];
      },
      homeCategoriesLoaded() {
        return this.$store.getters['categories/categoriesLoaded'];
      },

      homeCategories23() {
        return this.$store.getters['categories/categories23'];
      },
      homeCategories23Loaded() {
        return this.$store.getters['categories/categories23Loaded'];
      },
    },

    watch: {
      selectedFasteners(curValue, oldValue) {
        if (curValue==true) {
          this.selectedAll = false;
        }
        console.log(oldValue);
      },
      selectedTools(curValue, oldValue) {
        if (curValue==true) {
          this.selectedAll = false;
        }
        console.log(oldValue);
      },
      selectedAll(curValue, oldValue) {
        if (curValue==true) {
          this.selectedFasteners = false;
          this.selectedTools = false;
        }
        console.log(oldValue);
      }


    },

    created() {
      this.loadHomeCategories();
      this.loadHomeCategories23();
    }
}
</script>

<style scoped>

.gp-title {
  padding-left:30px;
  font-size:28px;
  margin-bottom:48px;
}

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:440px) {
  .text-2xl {
    font-weight:500;
    padding-bottom:20px;
  }  
  .mpty {display: none;}
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}

.select-categories {
  margin-bottom:20px; padding-left:30px; column-count:2;
} 
.select-categories li {
  margin-bottom:10px;
}

@media (max-width:1000px) {
  .select-categories {
    column-count:1;
  }
}


</style>
