<template>
    <teleport to="body">
        <transition name="notification">
            <dialog open v-if="open" :class="concatenatedClasses">
                <!-- header -->
                <header>
                    <h2>
                        <i :class="headerIconClass"></i>{{ title }}
                    </h2>
                </header>

                <!-- body -->
                <section>
                    <slot></slot>
                </section>

                <!-- footer -->
                <menu>
                    <a @click="hide">
                        OK
                    </a>
                </menu>
            </dialog>
        </transition>
    </teleport>
</template>

<script>
export default {
    emits: ['hide-notification'],
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        class: {
            type: String,
            required: false,
            default: 'default',
            validator: function (value) {
                return ['default', 'primary', 'secondary', 'dark', 'success', 'info', 'warning', 'danger'].includes(value);
            }
        },
        position: {
            type: String,
            required: false,
            default: 'bottom-right'
        },
        title: {
            type: String,
            required: false,
            default: 'Notification'
        },
        lifeSpan: {
            type: Number, // in milliseconds
            required: false,
            default: null,
        }
    },
    computed: {
        headerIconClass() {
            if (this.class === 'warning' || this.class === 'danger') {
                return 'fas fa-exclamation-triangle';
            } else if (this.class === 'success') {
                return 'fal fa-thumbs-up';
            } else {
                return 'fas fa-info-circle';
            }
        },
        concatenatedClasses() {
            return this.class + ' ' + this.position;
        },
    },
    methods: {
        hide() {
            this.$emit('hide-notification');
        }
    },
    watch: {
        open(val) {
            if (val === true && this.lifeSpan) {
                setTimeout(() => this.hide(), this.lifeSpan);
            }
        }
    }
};
</script>

<style scoped>

dialog {
    position: fixed;
    width: 20rem;
    z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    /*animation: notification 0.3s ease-out forwards;*/
}

@keyframes notification {
    0% {
        opacity: 0;
        transform: scale(.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.notification-enter-active {
    animation: notification .3s ease-out;
}

.notification-leave-active {
    animation: notification .1s ease-in reverse;
}

header {
    color: white;
    width: 100%;
    padding: 1rem;
    border-bottom: .125rem solid;
}

header h2 {
    margin: 0;
}

section {
    color: #fff;
    padding: 2rem 1rem;
}

menu {
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

menu > a {
    color: #fff;
    font-size: 1.25rem;
    letter-spacing: .125rem;
    cursor: pointer;
}

menu > a:hover {
    color: lightgray;
}

dialog.top-left {
    top: 2rem;
    left: 2rem;
}

dialog.top-right {
    top: 2rem;
    left: calc(100vw - 22rem); /* 20rem width + 2rem margin */
}

dialog.bottom-left {
    bottom: 2rem;
    left: 2rem
}

dialog.bottom-right {
    bottom: 2rem;
    left: calc(100vw - 22rem); /* 20rem width + 2rem margin */
}

dialog.center {
    top: calc(50vh - 10rem);
    left: calc(50vw - 10rem);
}

dialog.default {
    background-color: #212121fd;
}

dialog.default > header {
    color: #fff;
    border-color: #fff;
}

dialog.primary {
    background-color: rgba(29, 127, 255, 0.95);
}

dialog.primary > header {
    color: #fff;
    border-color: #fff;
}

dialog.secondary {
    background-color: #c1c1c1fe;
}

dialog.secondary > header {
    color: #fff;
    border-color: #fff;
}

dialog.dark {
    background-color: rgba(55, 65, 81, 238);
}

dialog.dark > header {
    color: #fff;
    border-color: #fff;
}

dialog.success {
    background-color: #54de91fd;
}

dialog.success > header {
    color: #fff;
    border-color: #fff;
}

dialog.info {
    background-color: #62d4fffd;
}

dialog.info > header {
    color: #fff;
    border-color: #fff;
}

dialog.warning {
    background-color: #ffc30dfd;
}

dialog.warning > header {
    color: #fff;
    border-color: #fff;
}

dialog.danger {
    background-color: #dc2626fd;
}

dialog.danger > header {
    color: #fff;
    border-color: #fff;
}

@media (min-width: 768px) {
    /*
     *
     */
}
</style>
