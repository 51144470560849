<template>
    <arrow-filters style="margin-bottom: 65px;"/>

    <div class="pad-left-110 pad-right-100">
        <div class="contact-form">
            <h1 v-if="lang_gr" class="text-semibold">
                Συμπληρώστε τα στοιχεία επικοινωνίας σας <br/>
                και θα επικοινωνήσουμε άμεσα μαζί σας.
            </h1>
            <h1 v-else class="text-semibold">
                Please provide your contact information, <br/>
                and we will promptly get in touch with you.
            </h1>

            <div class="form-control-group text-medium">
                <input type="text" id="name" v-model="name"
                class="input1stRow" :placeholder="lang_gr?'Όνομα*':'Name*'" />

                <input type="text" id="lastname" v-model="surname" class="input1stRow" :placeholder="lang_gr?'Επίθετο*':'Surname*'" />

                <input type="text" id="email" v-model="email" class="input2ndRow" :placeholder="lang_gr?'Email*':'Email*'" />

                <input type="text" id="company" v-model="company" class="input2ndRow" :placeholder="lang_gr?'Εταιρία*':'Company*'" />

                <input type="text" id="phone" v-model="phone" class="input2ndRow" :placeholder="lang_gr?'Αριθμός τηλεφώνου*':'Phone Number*'" />

                <input v-if="!lang_gr" type="text" id="country" v-model="country" class="input2ndRow" placeholder="Country" />

            </div>

            <div class="form-control-group-2 text-medium">
                <textarea id="comment" v-model="comments" :placeholder="lang_gr?'Σχόλια':'Comments'"></textarea>

                <input type="checkbox" v-model="accept_terms" id = "accept_terms" />
                <label class="accept_terms_label" v-if="lang_gr" for="accept_terms" >Έχω διαβάσει, κατανοήσει και συμφωνώ με την Πολιτική Απορρήτου και τους Όρους και Προϋποθέσεις της Τσαμούρης.</label>
                <label class="accept_terms_label" v-else for="accept_terms" >I have read, understand, and agree to Tsamouris’s Privacy Policy and Terms and Conditions.</label>
            </div>

            <div class="submit-button" @click="sendmessage">
                <span v-if="lang_gr">αποστολή μηνύματος</span>
                <span v-else>send message</span>
            </div>

            <div class="contact-info text-medium">
                <div>
                    E: info@tsamouris.gr
                </div>

                <div v-if="lang_gr">
                    T: 210 482 1368
                </div>
                <div v-else>
                    T: +30 210 482 1368
                </div>

            </div>
            

        </div>
    </div>

    

</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "InfoContactForm",
  inject: ['openDialog', 'showNotification'],
  components: {ArrowFilters},
  data() {
    return {
      name: null,
      surname: null,
      email: null,
      company: null,
      phone: null,
      country: 'Greece',
      comments: null,
      accept_terms: false,
      error: null,
    };
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  },
  methods: {
    async sendmessage() {
        if (!this.validateForm()) {
            this.openDialog(this.error);
            return;
        }

        this.$store.dispatch('account/sendmessage', {
            name: this.name,
            surname: this.surname,
            email: this.email,
            company: this.company,
            phone: this.phone,
            country: this.country,
            comments: this.comments,

        }).then(() => {
            this.showNotification({
                class: 'success', // 'default'
                title: 'Επιτυχία',
                position: 'bottom-right',
                html: 'Παραλάβαμε την μήνυμά σας. <br/>' +
                    'Θα σας απαντήσουμε σύντομα.',
            });

        }).catch(() => {
        self.openDialog({
          class: 'danger',
          html: 'Σφάλμα κατά την αποστολή'
        });
      });
    },
    validateForm() {
        this.clearError();
        let err = false;
        if (!this.name) {
            this.error += 'Name is empty<br/>';
            err = true;
        }
        if (!this.surname) {
            this.error += 'Surname is empty<br/>';
            err = true;
        }
        if (!this.email) {
            this.error += 'Email is empty<br/>';
            err = true;
        }
        else if (!this.validEmail(this.email)) {
            this.error += 'Email is not valid<br/>';
            err = true;
        }
        if (!this.company) {
            this.error += 'Company is empty<br/>';
            err = true;
        }
        if (!this.phone) {
            this.error += 'Phone is empty<br/>';
            err = true;
        }
        if (this.country=='') {
            this.error += 'Country is empty<br/>';
            err = true;
        }
        if (!this.accept_terms) {
            this.error += 'You have to accept terms of use<br/>';
            err = true;
        }
        
        return err? false: true;
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearError() {
      this.error = '';
    },
  }
}

</script>

<style scoped>

.contact-form {
    width:calc(100% + 40px);
    max-width:1520px;
    margin-left:-20px;
    margin-right:-20px
}
@media (min-width:600px) {
    .contact-form {
        width:100%;
        margin:auto;
    }
}

h1 {
    font-size:33px;
    line-height: 41px;
    padding-left:20px;
    margin-bottom:45px;
    margin-top:50px;
}
@media (min-width:900px) {
    h1 {
        margin-top:100px;
    }
}

.form-control-group {
    display:grid;
    grid-template-columns: 100%;
    margin-bottom:30px;
}
@media (min-width:900px) {
    .form-control-group {
        grid-template-columns: 50% 50%;
        margin-bottom:45px;
    }
}

.form-control-group input {    
    font-size: 20px;
    background-color: transparent;
    outline: none;
}
@media (min-width:900px) {
    .form-control-group input {
        margin-left:-1px;
    }
}

.form-control-group .input1stRow:nth-child(1) {
    padding:20px;
    border:1px solid #212121;
    border-radius: 12px;
}

.form-control-group .input1stRow:nth-child(2),
.form-control-group .input2ndRow {
    padding:32px 20px 20px;
    border:1px solid #212121;
    border-top: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-top:-10px;
}
@media (min-width:900px) {
    .form-control-group .input1stRow:nth-child(1),
    .form-control-group .input1stRow:nth-child(2) {
        padding:32px 20px;
        border:1px solid #212121;
        border-radius: 12px;
        margin-top:0px;
    }
    .form-control-group .input2ndRow {
        padding:42px 20px 32px;
        border-top: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        margin-top:-10px;
    }
}


.form-control-group-2 {
    margin-bottom: 50px;
}

#comment {
    padding:32px 20px;
    border:1px solid #212121;
    border-radius: 12px;
    height:450px;
    background-color: transparent;
    width:100%;
    font-size: 20px;
    margin-bottom: 10px;
    outline: none;
}

#accept_terms {
    width:40px;
    height:40px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #212121;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-right:20px;
}

#accept_terms:checked {
    background-color: #888;
}

.accept_terms_label {
    font-size:15px;
} 

.submit-button {
    width:100%;
    max-width:834px;
    margin:auto;
    padding:10px 20px;
    background-color: #212121;
    border:1px solid #212121;
    border-radius: 12px;
    color:#fff;
    cursor:pointer;
    text-align: center;
    margin-bottom: 50px;
}

.contact-info {
    display:grid;
    grid-template-columns: 100%;
    font-size: 25px;
    margin-bottom:50px;
    text-align: center;
}

@media (min-width:900px) {
    .contact-info {
        display:grid;
        grid-template-columns: 50% 50%;
        font-size: 45px;
        margin-top:150px;
        margin-bottom:100px;
        text-align: left;
    }  
    .contact-info div {
        padding-left:30px;
    }  
}
@media (min-width:1400px) {
    .contact-info {
        font-size: 65px;
        margin-top:200px;
        margin-bottom:140px;
    } 
}



</style>