<template>
    <arrow-filters style="margin-bottom: 8px;" />

    <div class="mobile-spacer"></div>

    <div ref="parallaxContainer" class="parallax-container" >
      <div class="parallax-background" :style="parallaxStyle"></div>
      <div class="content">
        <!-- <h1 :style="parallaxH1Style" style="color:#212121;font-size:100px">How we work</h1>          -->

      <div class="intro-section">
        <div style="text-align: center; margin-bottom:50px; padding-top:30px">
          <img src="../assets/svg/logo-icon.svg" style="width:51px; margin:auto" />
        </div>
        <h1 v-if="lang_gr" class="text-semibold" style="" >
          Κάποια πράγματα
          <!-- <span class="things-text" style="position:relative"> 
            <span :style="parallaxH1_1_style">πρ</span>
            <span :style="parallaxH1_2_style">άγ</span>
            <span :style="parallaxH1_3_style">μα</span>
            <span :style="parallaxH1_4_style">τα</span>
            <div class="img-gr" :style="parallaxFastener" style="">
              <img src="../assets/images/how-we-work-fastener.png" style="width:274px" />
            </div> 
          </span> -->

          δεν<br/> πρέπει να είναι ακριβά
        </h1>
        <h1 v-else class="text-semibold" >
          Some things
          <!-- <span class="things-text" style="position:relative"> 
            <span :style="parallaxH1_1_style">t</span>
            <span :style="parallaxH1_2_style">hi</span>
            <span :style="parallaxH1_3_style">ng</span>
            <span :style="parallaxH1_4_style">s</span>
            <div class="img-en" :style="parallaxFastener" style="">
              <img src="../assets/images/how-we-work-fastener.png" style="width:274px" />
            </div> 
          </span> -->

          <br/>should not be expensive
        </h1>

        <div class="img-fastener" style="text-align:center">
          <img src="../assets/images/how-we-work-fastener.png" style="width:274px; margin:auto;" />
        </div> 
        
        <p v-if="lang_gr" class="p1">Ποιότητα, τιμή και συνέπεια. Αυτά ψάχνουμε στους προμηθευτές μας σε όλον τον κόσμο. Συνεργαζόμαστε με έμπιστα εργοστάσια και παραγωγούς στην Ευρώπη και όλη την Ασία. Ελέγχουμε και συγκρίνουμε καθημερινά τις τιμές μας για να ξέρουμε οτι αγοράζουμε δίκαια.</p>

        <p v-else class="p1">Quality, price and consistency. This is what we look for in our
        suppliers around the world. We work with trusted factories and
        producers in Europe and throughout Asia. We check and compare our
        prices daily to know that we are buying fairly. We know our suppliers
        in depth and work with them for a long time. We offer our customers
        the best price-quality ratio.</p>
        
        <p v-if="lang_gr" class="text-semibold" style="text-align: center; font-size:29px; line-height: 37px;">Πως δουλεύουμε<br/>στην Τσαμούρης</p>

        <p v-else class="text-semibold" style="text-align: center; font-size:29px; line-height: 37px;">How we work<br/>in Tsamouris</p>

      </div>


      </div>
    </div>

    <div class="banners" style="">

      <div ref="target1" class="banner-container">
        <transition  name="fade-in-up" >
          <div v-if="showTarget1" class="banner banner-1" style="background-color: #3820ff; color:#fff; ">
            <h2 v-if="lang_gr" class="text-semibold" style="">Παραγγέλνουμε απο <u>όλον</u><br/> 
            τον κόσμο, απο έμπιστους <br/>
            προμηθευτές.</h2>

            <h2 v-else class="text-semibold" style="">
              We order from <u>all over</u><br/> 
              the world from trusted<br/>
              suppliers</h2>

            <img src="../assets/images/how-we-work-banner-1.png" style="margin: auto;" />

          </div>
        </transition>
      </div>

      <div ref="target2" class="banner-container">
        <transition  name="fade-in-up" >
          <div v-if="showTarget2" class="banner banner-2" style="background-color: #e6e6ca; color:#000; ">
            <h2 v-if="lang_gr" class="text-semibold" style="">Κρατάμε μεγάλο<br/> 
            απόθεμα προϊόντων</h2>
            <h2 v-else class="text-semibold" style="">We keep a large<br/> 
            stock of products.</h2>

            <p v-if="lang_gr">Έτσι εξυπηρετούμε <br/>πιο άμεσα</p>
            <p v-else>This is how we serve <br/>more immediately.</p>

            <img src="../assets/images/how-we-work-banner-2.png" style="margin: auto;" />

          </div>
        </transition>
      </div>

      <div ref="target3" class="banner-container">
        <transition  name="fade-in-up" >
          <div v-if="showTarget3" class="banner banner-3" style="">
            <div class="banner-3-1" style="">
              <h2 v-if="lang_gr" class="text-semibold" style="">Το καλύτερο<br/> 
              site  παραγγελιών<br/>
              B2B στην αγορά.</h2>

              <h2 v-else class="text-semibold" style="">The best B2B<br/>
              ordering website<br/>
              on the market.</h2>

              <img src="../assets/images/how-we-work-banner-3.png" style="position:absolute; left:-150px; top:160px; max-width:140%" />

            </div>

            <div class="banner-3-2" style=" ">
              <img src="../assets/images/how-we-work-banner-3b.png" style="margin-bottom:50px" />

              <p v-if="lang_gr" class="text-semibold" style="">Διανέμουμε<br/> 
              τα προϊόντα μας <br/>
              σε Ελλάδα,<br/> 
              Κύπρο, Αλβανία, <br/>
              Βουλγαρία, <br/>
              Ουγγαρία <br/>
              και Σλοβενία.</p>

              <p v-else class="text-semibold" style="">We distribute<br/>
              our products<br/>
              in Greece,<br/>
              Cyprus, Albania,<br/>
              Bulgaria, Hungary<br/>
              and Slovenia.
              </p>

            </div>
            

          </div>
        </transition>
      </div>


      <div ref="target4" class="banner-container">
        <transition  name="fade-in-up" >
          <div class="target4 banner banner-4" v-if="showTarget4" style="">
            <h2 v-if="lang_gr" class="text-semibold" style="">Ιδιόκτητος στόλος <br/>
              απο φορτηγά.</h2>
            <h2 v-else class="text-semibold" style="">
              Privately owned<br/>
              fleet of trucks.</h2>

            <p v-if="lang_gr">
              Για διανομές εντός<br/>
              Αττικής και Κύπρου.</p>
            <p v-else>For distribution within<br/>
              Athens and Cyprus.</p>
              

          </div>
        </transition>
      </div>


      <div ref="target5" class="banner-container">
        <transition  name="fade-in-up" >
          <div class="target5 banner banner-5" v-if="showTarget5" style="background-color:#af95f5; color:#000; ">
            <h2 v-if="lang_gr" class="text-semibold" style="">Αποθήκες σε Ελλάδα <br/>
              και Κύπρο. </h2>
            <h2 v-else class="text-semibold" style="">Warehouses <br/>
              in Greece and Cyprus.</h2>

            <p v-if="lang_gr">Για άμεσες παραδόσεις <br/>
              σε αυτές τις χώρες.</p> 
            <p v-else>Direct deliveries<br/>
              to these countries.
            </p>
              
            <img src="../assets/images/how-we-work-banner-5.png" style="margin: auto;" />

          </div>
        </transition>
      </div>



    </div>


  </template>

<script>
import ArrowFilters from "@/components/ArrowFilters";
export default {
    name: 'HowWeWork',
    components: {
      ArrowFilters,
    },
    data() {
    return {
      scrollY: 0,
      showTarget1: false,
      showTarget2: false,
      showTarget3: false,
      showTarget4: false,
      showTarget5: false,
    };
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
    parallaxStyle() {
      return {
        // transform: `translateY(${this.scrollY * 0.5}px)`
        'background-color': 'black'
      };
    },
    parallaxH1Style() {
      return {
        // 'font-size': `${100 - this.scrollY * 0.1}px`
        'opacity': `${1 - this.scrollY * 0.002}`
      }
    },
    parallaxContainerStyle() {
      let mt = 0;

      let mtLimit = window.innerWidth<600? 150: 230;

      if (this.scrollY > mtLimit && this.scrollY < 1430) {
        mt = this.scrollY - mtLimit;
      }
      else if (this.scrollY >= 1430) {
        mt = 1430 - mtLimit;
      }
      return {
        'margin-top': mt+'px'
      }
    },
    parallaxH1_4_style() {
      let op = 1;
      let mw = 100;
      if (this.scrollY > 230 && this.scrollY < 430) {
        op = 1 - this.scrollY * 0.01;
        mw = 100 - this.scrollY * 0.01;
      }
      else if (this.scrollY >= 430) {
        op = 0;
      }
      return {
        opacity: op,
        'max-width': mw
      }
    },
    parallaxH1_3_style() {
      let op = 1;
      if (this.scrollY > 430 && this.scrollY < 630) {
        op = 1 - this.scrollY * 0.01;
      }
      else if (this.scrollY >= 630) {
        op = 0;
      }
      return {
        opacity: op
      }
    },
    parallaxH1_2_style() {
      let op = 1;
      if (this.scrollY > 630 && this.scrollY < 830) {
        op = 1 - this.scrollY * 0.01;
      }
      else if (this.scrollY >= 830) {
        op = 0;
      }
      return {
        opacity: op
      }
    },
    parallaxH1_1_style() {
      let op = 1;
      if (this.scrollY > 830 && this.scrollY < 1030) {
        op = 1 - this.scrollY * 0.01;
      }
      else if (this.scrollY >= 1030) {
        op = 0;
      }
      return {
        opacity: op
      }
    },
    parallaxFastener() {
      let op = 0;
      if (this.scrollY > 230) {
        op = (this.scrollY - 1030) * 0.002;
      }
      return {
        opacity: op
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.checkVisibility(); // Initial check
    window.addEventListener('scroll', this.checkVisibility);

  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    window.removeEventListener('scroll', this.checkVisibility);

  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        // rect.top >= 0 &&
        // rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        
        rect.top <= (window.innerHeight || document.documentElement.clientHeight)
      );
    },
    checkVisibility() {
      const target1 = this.$refs.target1;
      if (this.isInViewport(target1)) {
        this.showTarget1 = true;
      } else {
        this.showTarget1 = false;
      }

      const target2 = this.$refs.target2;
      if (this.isInViewport(target2)) {
        this.showTarget2 = true;
      } else {
        this.showTarget2 = false;
      }

      const target3 = this.$refs.target3;
      if (this.isInViewport(target3)) {
        this.showTarget3 = true;
      } else {
        this.showTarget3 = false;
      }

      const target4 = this.$refs.target4;
      if (this.isInViewport(target4)) {
        this.showTarget4 = true;
      } else {
        this.showTarget4 = false;
      }

      const target5 = this.$refs.target5;
      if (this.isInViewport(target5)) {
        this.showTarget5 = true;
      } else {
        this.showTarget5 = false;
      }


    }
  }
};
</script>

<style scoped>
.parallax-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-color:aqua;
  /*background-image: url('../assets/images/BANNERS_BIG-01.jpg');
  background-size: cover;
  background-position: center;*/
  z-index: -1;
  transition: transform 0.1s linear;
}

.content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 2rem;
}

.target4 {
  background-color: #e6e6ca; 
  color:#000; 
  min-height: 720px; 
  padding:65px 20px 50px; 
  text-align: center; 
  border-radius: 15px; 
  background-image: url(../assets/images/how-we-work-banner-4bg.jpg); 
  background-size: cover;
}

.banner p {
  font-size: 26px;
  line-height: 34px;
}

h1 {
  font-size:89px; line-height:111px; 
  /* margin-bottom:65px; */
}
.img-fastener {
  margin-bottom:65px;
}
.img-gr {
  position:absolute;top:-30px;left:50px; z-index: 1; width:274px;
}
.img-en {
  position:absolute;top:-30px;left:30px; z-index: 1; width:274px;
}
@media (max-width:1400px) {
  h1 {
    font-size:70px; line-height:90px; margin-bottom:50px;
  }
  .img-gr {
    position:absolute;top:-40px;left:50px; z-index: 1; width:250px;
  }
  .img-en {
    position:absolute;top:-40px;left:30px; z-index: 1; width:250px;
  }
}

.intro-section .p1 {
  max-width:750px; margin:auto;text-align: center; font-size:23px; line-height: 29px; margin-bottom:130px;
}

.banners {
  min-height:800px; background:black; padding:50px 10px;
}

.banner-container {
  max-width:780px; margin:auto; margin-bottom:40px; min-height: 720px;
}

.banner {
  min-height: 720px; padding:65px 20px 50px; text-align: center; border-radius: 15px;
}
.banner h2 {
  font-size: 45px; line-height: 56px; margin-bottom:40px;
}

.banner-3 {
  min-height: 720px; display:grid; grid-template-columns: 1fr 1fr; gap:20px;
  padding:0px;
}

.banner-3-1 {
  background-color:#212121; border-radius: 15px; position:relative; padding:50px 30px 30px
}
.banner-3-1 h2 {
  font-size:40px; line-height: 48px; color:#909090;
}

.banner-3-2 {
  padding:50px 30px 30px; background-color: #ffbd99; border-radius: 15px;
}
.banner-3-2 p {
  font-size:30px; line-height: 36px; color:#212121;
}



@media (max-width:1000px) {
  .mobile-spacer {
    height:50px;
  }
  .parallax-container {
    margin-left: -22px;
    margin-right: -22px;
    padding: 20px;
    /* margin-top:50px !important; */
  }
  .banners {
    margin-left: -22px;
    margin-right: -22px;
    padding: 20px;
  }
  .banner-3-1 {
    overflow:hidden;
  }
}

@media (max-width:600px) {
  h1 {
    font-size:40px; line-height:50px; margin-bottom:30px;
  }
  .intro-section .p1 {
    max-width:750px; margin:auto;text-align: center; font-size:18px; line-height: 23px; margin-bottom:60px;
  }

  .banner-container {
    margin-bottom:40px; min-height: 500px;
  }

  .banner {
    min-height: 500px;
    padding:40px 15px 30px;
  }

  .banner h2 {
    font-size: 35px; line-height: 42px; margin-bottom:30px;
  }

  .banner-3 {
    min-height: 500px; 
    display:grid; 
    grid-template-columns: 100%; 
    gap:20px;
    padding:0px;
  }
  .banner-3-1 {
    min-height: 500px; 
  }

  .things-text {
    display:block;
    margin:15px 0px;
  }

  .img-gr {
    position:absolute;
    top:-35px;left:65px; 
    z-index: 1; 
    width:200px;
  }

}


</style>