<template>
  <div class="grid grid-cols-5">
    <fastener-tile
        v-for="category in categories" :key="category.id"
        :fastener="category"
        class="col-span-1"
        :class="{selected: selectedCategoryId === category.id}"
        @click="handleTileClick(category)"
    ></fastener-tile>
  </div>
</template>

<script>
import FastenerTile from "@/components/arrow-filters/type/FastenerTile";

export default {
  name: "FastenersSection",
  emits: ['category-selected', 'category-deselected'],
  inject: [
    'hidePaginator',
  ],
  components: {FastenerTile},
  props: {
    selectedCategoryIdProp: {
      type: String,
      required: false,
      default: null,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data() {
    return {
      selectedCategoryId: null,
    }
  },
  computed: {
    categoriesLoaded() {
      return this.$store.getters['categories/categoriesLoaded'];
    },
    categories() {
      return this.$store.getters['categories/categories'];
    },
  },
  watch: {
    selectedCategoryIdProp(newVal) {
      this.selectedCategoryId = newVal;
    }
  },
  methods: {
    async loadCategories() {
      const self = this;

      try {
        await this.$store.dispatch('categories/loadCategories');
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    handleTileClick(category) {
      if (this.selectedCategoryId == category.id) {
        this.selectedCategoryId = null;
        this.$emit('category-deselected');
      } else {
        this.selectedCategoryId = category.id;
        this.$emit('category-selected', category);
      }
    }
  },
  created() {
    this.hidePaginator();

    this.loadCategories();

    this.selectedCategoryId = this.selectedCategoryIdProp;
  }
}
</script>

<style scoped>

</style>
