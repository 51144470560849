<template>
  <!--<arrow-filters ref="filters" @broadcast-filters="search" style="margin-bottom: 68px;"/>-->

  <div class="mt-24 mb-8 manrope text-2xl --font-semibold">{{ $t('labels.search') }}</div>

  <div class="flex w-full border-1 rounded-xl p-2 mb-8">
    <input
        type="text"
        name="search"
        v-model="search"
        style="outline: none;"
        class="placeholder-gray-400 w-full text-xl pt-2 px-2 manrope text-xl rounded-xl bg-transparent"
        :placeholder="$t('labels.what-are-you-looking-for')"
        autocomplete="off"
        @keydown.enter="executeSearch"
    />
    <img
        src="../assets/svg/microphone.svg"
        class="mx-3 cursor-pointer"
        :class="{blink: state === 'recording'}"
        style="width: 25px"
        @click="this.toggle"
    />
  </div>

  <div v-if="search !== null" class="app-button black" @click="executeSearch">
    {{ $t('labels.search') }}
  </div>
</template>

<script>
export default {
  name: "SpeechSearch",
  components: {},
  inject: ['openDialog', 'getLastSearchType', 'setLastSearchType'],
  data() {
    return {
      search: null,
      recog: {}, // speech recognition object
      state: 'idle', // recording
    }
  },
  computed: {
    //
  },
  methods: {
    init() {
      navigator.mediaDevices.getUserMedia({ audio: true })
          .then(() => {
            // (A3) SPEECH RECOGNITION OBJECT + SETTINGS
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            this.recog = new SpeechRecognition();
            //voice.recog.lang = "en-US";
            this.recog.lang = "el-GR";
            this.recog.continuous = true;
            this.recog.interimResults = false;

            // (A4) POPUPLATE SEARCH FIELD ON SPEECH RECOGNITION
            this.recog.onresult = (evt) => {
              let said = evt.results[0][0].transcript.toLowerCase();
              this.search = said;
              // voice.sform.submit();
              // OR RUN AN AJAX/FETCH SEARCH
              this.stop();
            };

            // (A5) ON SPEECH RECOGNITION ERROR
            this.recog.onerror = (err) => { console.error(err); };

            // (A6) READY!
            // voice.sbtn.disabled = false;
            this.stop();
          })
          .catch((err) => {
            console.error(err);
            // voice.sbtn.value = "Please enable access and attach microphone.";
          });
    },
    // (B) START SPEECH RECOGNITION
    start() {
      // console.log('starting recording');
      this.state = 'recording';
      this.recog.start();
      // voice.sbtn.onclick = voice.stop;
      // voice.sbtn.value = "Speak Now Or Click Again To Cancel";
    },

    // (C) STOP/CANCEL SPEECH RECOGNITION
    stop () {
      // console.log('stopping recording');
      this.state = 'idle';
      this.recog.stop();
      // voice.sbtn.onclick = voice.start;
      // voice.sbtn.value = "Press To Speak";
    },
    toggle() {
      if (this.state === 'idle') {
        this.start();
      } else {
        this.stop();
      }
    },
    async executeSearch() {
      // console.log('Initiating algolia search using search query:' + this.search);
      if (this.search === '') {
        return;
      }

      this.setLastSearchType('algolia');

      const self = this;

      try {
        await this.$store.dispatch('filters/algoliaSearch', this.search);
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }

      await this.$router.push('/search?algolia=' + this.search);
    }
  },
  created() {
    //
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
  .blink {
    animation: blink-animation 1s steps(3, start) infinite;
    -webkit-animation: blink-animation 1s steps(3, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
      /*opacity: 0;*/
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
      /*opacity: 0;*/
    }
  }
</style>