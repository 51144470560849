<template>
  <arrow-filters style="margin-bottom: 60px;"/>

  <!-- header -->
  <!--<div class="p-6 app-pl font-semibold whitespace-nowrap overflow-x-hidden" style="background-color: #EFEFE2;">
    {{ $t('labels.specs') }} 
  </div>-->

  <div v-if="dataHasBeenLoaded" class="flex flex-col sm:flex-row manrope sm:px-16 mb-32" :class="{'sm:flex-wrap': legacyLayout2}">
    <!-- black bg section -->
    <div class="flex flex-col justify-center p-4 sm:order-3 sm:w-1/3 product-info"
      style="position:relative">
      
      <div class="text-right" style="position:absolute; top:15px; right:15px">
        <a  class="relative inline-block cursor-pointer" @click="$router.go(-1)">
          <img src="../../assets/svg/close2.svg" class="right-0 top-0 h-12" style="width:25px; height:25px" />          
        </a>
      </div>

      <div class="text-darkblue text-medium" style="position:absolute; top:15px; left:15px; font-size: 15px;">
        <a  class="relative inline-block cursor-pointer" @click="$router.go(-1)">
          Back          
        </a>
      </div>

      <div class="text-2xl font-semibold product-name" style="--color: #686868;">
        {{ product.name }}
      </div>
      <div v-if="specsMeta.specs_drawing!=''">
        <img :src="specsMeta.specs_drawing" style="" />
        
      </div>

    </div>

    <!-- table section    -->
    <div v-if="modernLayout2" class="sm:order-1 sm:w-2/3 table-section" >
      <div class="p-4-x mt-4-x mb-4-x text-xl-x font-semibold w-2-x sm:mt-16-x sm:ml-4-x sm:text-lg-x page-title">
        {{ $t('labels.technical-specs') }}
      </div>

      <div
          v-for="(dimension, index) in specs2"
          :key="index"
          class="flex p-10-x border-b-1-x border-21-x bg-white-x sm:border-b-0-x table-line"
          :class="{['bg-'+index]: !isDimensionSelected(index)}"
          :id="'spec-'+index"
          :style="{width: dimWidth + 'px'}"
      >
        <div class="dimension w-20-x inline-block font-semibold dimension-title" >
          {{ dimension.description }}
        </div>
        <div class="flex flex-grow flex-start">
          <span v-for="_index in dimensions" :key="_index" class="dimensionSpec">
            <!-- {{ dimension['dim0'+_index] }} -->
            {{ dimension[_index] }}
          </span>
          <!-- [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14,15,16,17,18,19,20] -->
        </div>
      </div>
    </div>

    <div v-if="legacyLayout2" class="sm:w-full order-4 mt-8">
      <iframe :src="specsMeta.product_specs_table_html" class="w-full" style="height:300px"/>
    </div>

    <!-- dimension icons section  -->
    <div v-if="/*modernLayout2*/false" class="py-16 px-4 grid grid-cols-2 sm:order-2 sm:w-1/3">
      <div v-if="specs2.length>=1" class="col-span-1 cursor-pointer" @click="selectDimension(0)">
        <img :src="specs2[0].image" :style="{opacity: isDimensionSelected(0) ? 1 : .2}"/>
      </div>
      <div v-if="specs2.length>=2" class="col-span-1 cursor-pointer" @click="selectDimension(1)">
        <img :src="specs2[1].image" :style="{opacity: isDimensionSelected(1) ? 1 : .2}"/>
      </div>
      <div v-if="specs2.length>=3" class="col-span-1 cursor-pointer" @click="selectDimension(2)">
        <img :src="specs2[2].image" :style="{opacity: isDimensionSelected(2) ? 1 : .2}"/>
      </div>
      <div v-if="specs2.length>=4" class="col-span-1 cursor-pointer" @click="selectDimension(3)">
        <img :src="specs2[3].image" :style="{opacity: isDimensionSelected(3) ? 1 : .2}"/>
      </div>
    </div>
    
    <div v-if="legacyLayout2 && (specsMeta.product_specs_image_1 !== '' || specsMeta.product_specs_image_2 !== '' || specsMeta.product_specs_image_3 !== '')" class="order-2 sm:order-1 sm:w-2/3 flex legacy-image legacyLayout2">
      <img v-if="specsMeta.product_specs_image_1 !== ''" :src="specsMeta.product_specs_image_1"/>
      <img v-if="specsMeta.product_specs_image_2 !== ''" :src="specsMeta.product_specs_image_2"/>
      <img v-if="specsMeta.product_specs_image_3 !== ''" :src="specsMeta.product_specs_image_3"/>
    </div>

    <div v-if="this.$route.query.itemcode" class="order-2 sm:order-1 sm:w-2/3  legacy-image">      
      
      <div style="margin-bottom:40px" v-if="specsMeta.specs_text_gr!='' && lang_gr" v-html="specsMeta.specs_text_gr"></div>

      <div v-if="specsMeta.specs_pdf_gr!='' && lang_gr" style="display:flex; justify-content: flex-end;">
        <span style="padding:5px 20px;">Για περισσότερeς πληροφορίες κατεβάστε το αρχείο PDF</span>
        <a  target="_blank" :href="specsMeta.specs_pdf_gr" style="padding:5px 20px; background-color:#000; color:#fff;border-radius:10px;">Αρχείο PDF</a>
      </div>
      
    
      <div style="margin-bottom:40px" v-if="specsMeta.specs_text_en!='' && lang_en" v-html="specsMeta.specs_text_gr"></div> 
      
      <div v-if="specsMeta.specs_pdf_en!='' && lang_en" style="display:flex; justify-content: flex-end;">
        <span style="padding:5px 20px;">For more information download the PDF file</span><a  target="_blank" :href="specsMeta.specs_pdf_gr" style="padding:5px 20px; background-color:#000; color:#fff;border-radius:10px;">PDF file</a>
      </div>
      
    </div>

  </div>

  <!-- specs_drawing -->

</template>

<script>

import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "CategorySpecs",
  components: {
    ArrowFilters,
  },
  inject: ['openDialog'],
  props: {
    slug: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      category: {
        // ...
        products: [],
        productGroups: [],
        specs: [],
      },
      mockSpecs: { // mock
        d: [9, 11, 14, 18, 22, 24, 26, 30, 33],
        e: [9, 11, 14, 18, 22, 24, 26, 30, 33],
        m: [9, 11, 14, 18, 22, 24, 26, 30, 33],
        s: [9, 11, 14, 18, 22, 24, 26, 30, 33],
      },
      selectedDimension: null,
      specs: {},
    }
  },
  watch: {
    slug() {
      this.loadData();
    }
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
    lang_en() {
      return this.$store.getters['app/locale']=="en"
    },
    dataHasBeenLoaded() {
      return this.$store.getters['categories/specsLoaded'];
    },
    legacyLayout() {
      return this.specs.length === 0;
    },
    modernLayout() {
      return this.specs.length > 0;
    },
    legacyLayout2() {
      return this.specsMeta.layout=="legacy"? true: false;
    },
    modernLayout2() {
      return this.specsMeta.layout=="modern"? true: false;
    },
    specs2() {
      return this.$store.getters['categories/specs'];
    },
    
    specsMeta() {
      return this.$store.getters['categories/specsMeta'];
    },
    product() {
      return this.$store.getters['categories/product'];
    },
    dimWidth() { 
      let dw = 150 + (Object.keys(this.specs2[0]).length - 2) * 60 + 50;
      return dw;
    },
    dimensions() {
      let dimCount = Object.keys(this.specs2[0]).length - 2;
      let dimArray = [];
      for (let i = 0; i < dimCount; i++) {
        //const element = array[index];
        dimArray.push('dim'+ String(i).padStart(2, '0'));
      }
      return dimArray;      
    },

  },
  methods: {
    selectDimension(dimension) {
      this.selectedDimension = dimension;
    },
    isDimensionSelected(dimension) {
      return this.selectedDimension === dimension;
    },
    async loadSpecs() {
      try {
        let itemcode = this.$route.query.itemcode? this.$route.query.itemcode: '';
        console.log(itemcode);
        await this.$store.dispatch('categories/loadProductSpecs', 
        {
          productId: this.slug, 
          itemcode: itemcode
        });

        this.specs = this.$store.getters['categories/specs'];
        this.product.name = this.$store.getters['categories/product'].name;
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  async mounted() {
    await this.loadSpecs();
  },
}
</script>

<style scoped>
  .bg-0 {
    background-color: #F0EFE2;
  }

  .bg-1 {
    background-color: #EFEFE1;
  }

  .bg-2 {
    background-color: #E6E5DA;
  }

  .bg-3 {
    background-color: #D8D7CD;
  }

  .product-info {
    /* background-image: url(../../assets/svg/mob-bck2.svg);
    background-size:100% 100%; */
    min-height:200px;
    border:1px solid #212121;
    border-radius:15px;
    background-color:#fff;
  }

  @media (max-width:800px) {
    .product-info {
      margin-top:40px;
      margin-bottom:30px;
    }
  }

  .legacy-image {
    padding:20px;
    border:1px solid #212121;
    border-radius: 12px;
  }

  .page-title {
    padding:20px 40px;
    font-size:25px;
  }

  .table-section {
    border:1px solid #212121;
    border-radius:15px;
    background-color: #fff;
    overflow-x: scroll;
  }

  .table-line {
    border:1px solid #212121;
    border-radius:15px;
    padding:10px 40px;
    background-color:#f0f0f0;
    min-width:100%;
  }
  .table-line:hover {
    background-color:#000;
    color:#fff;
  }

  .dimensionSpec {
    width:60px;
  }

  .dimension-title {
    min-width:150px;
  }

  .product-name {
    padding:50px 0px;
  }

  

  
</style>