import ErrorUtil from "../../classes/ErrorUtil";
import mockData from "@/test/mockData";

export default {
    namespaced: true,
    state() {
        return {
            account_details: {
                /*address: null,
                cash_discount: null,
                city: null,
                comments: null,
                country: null,
                credit_status: null,
                customer_code: null,
                customer_id: null,
                customer_name: null,
                default_payment_type: null,
                discount_type_id: null,
                email: null,
                password: null,
                phone: null,
                price_list_id: null,
                profession: null,
                remain: null,
                seller_id: null,
                tax_office: null,
                username: null,
                vat_nr: null,
                vf_discount: null,
                virtual_fasteners: null,*/
            },
            addresses: [
                /*{
                    address: "ΘΕΣΗ ΛΑΚΚΑ ΠΑΠΑ..."
                    city: "ΟΙΝΟΦΥΤΑ"
                    country: "Greece"
                    customer_code: "C.11111"
                    site_code: "ΥΠΟΚΑΤΑΣΤΗΜΑ"
                    site_description: "-"
                    site_id: "44"
                    vat_excluded: "0"
                    vat_zone: "1"
                    zipcode: ""
                }*/
            ],
            industryTypes: [],
            leadOrigins: [],
            documents: [],
            clients: [],
            dins: [],
            months: [],
            document: {},
            transactions: [],
            transactionTypes: [],
            rewards: {
                buildings: [
                    // {
                    //     "text": "D> Burj Khalifa was built with 5000 fasteners",
                    //     "img_url": "https://tsamouris.aaw.gr/eshop/images/rewards-1.jpg",
                    //     "locked": false
                    // },
                    // {
                    //     "text": "D> Potemkin ship was built with 10000 fasteners",
                    //     "img_url": "https://tsamouris.aaw.gr/eshop/images/rewards-1.jpg",
                    //     "locked": true
                    // },
                    // {
                    //     "text": "D> ISS was built with 20.000 fasteners",
                    //     "img_url": "https://tsamouris.aaw.gr/eshop/images/rewards-1.jpg",
                    //     "locked": true
                    // }
                ],
                rewards_total_pcs: null,
                rewards_total_packages: null,
            },
            // ------------
            accountDataLoaded: false,
            industryTypesLoaded: false,
            leadOriginsLoaded: false,
            documentsLoaded: false,
            clientsLoaded: false,
            dinsLoaded: false,
            documentLoaded: false,
            transactionsLoaded: false,
            transactionTypesLoaded: false,
            rewardsLoaded: false,
        }
    },
    actions: {
        async loadClients(context, payload) {
            await context.rootGetters['app/axios'].post('/seller/clients', {
                data: {
                    //accesstoken: context.rootGetters['auth/sellertoken'],
                    accesstoken: localStorage.getItem('sellertoken'),
                    customercode: payload.customercode,
                    
                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }
                else {
                    console.log(response.data.data);
                    console.log('got clients...');
                    context.commit('setClients', response.data.data);
                }
                //context.commit('setDocuments', response.data.data);
                
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        
        async loadAccountData(context) {
            if (context.rootGetters['app/offlineMode']) {
                context.commit('setMockAccountData');
                return;
            }

            await context.rootGetters['app/axios'].post('/account', {
                data: {
                    'accesstoken': context.rootGetters['auth/token'],
                    'lang': context.rootGetters['app/locale']
                }
            })
                .then((response) => {
                    const accountData = response.data.data;

                    context.commit('setAccountData', accountData);
                    context.commit('setRewards', accountData);
                })
                .catch(function (error) {
                    const errorMessage = new ErrorUtil(error).composeMessage();

                    throw new Error(errorMessage);
                });
        },
        async loadIndustryTypes(context) {
            await context.rootGetters['app/axios'].get('/register-industries', {
                params: {
                    'accesstoken': context.rootGetters['auth/token'],
                    'lang': context.rootGetters['app/locale']
                }
            }).then((response) => {
                const industryTypes = response.data.data;

                context.commit('setIndustryTypes', industryTypes);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadLeadOrigins(context) {
            await context.rootGetters['app/axios'].get('/register-lead-origin', {
                params: {
                    'accesstoken': context.rootGetters['auth/token'],
                    'lang': context.rootGetters['app/locale']
                }
            }).then((response) => {
                const leadOrigins = response.data.data;

                context.commit('setLeadOrigins', leadOrigins);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadDocuments(context, payload) {
            await context.rootGetters['app/axios'].post('/account/documents', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    ordercode: payload.ordercode,
                    orderdatefrom: payload.orders_datefrom,
                    orderdateto: payload.orders_dateto,
                    lastmonth: payload.orders_lastmonth,
                    last3months: payload.orders_last3months,
                    orderstatus: payload.orderstatus,
                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }

                context.commit('setDocuments', response.data.data);
                context.commit('setMonths', response.data.meta.months);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadDocument(context, documentId) {
            await context.rootGetters['app/axios'].post('/account/document', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    document_id: documentId,
                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }

                context.commit('setDocument', response.data.data);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadTransactions(context, payload) {
            await context.rootGetters['app/axios'].post('/account/transactions', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    // ------------------------------------
                    transaction_code: payload.transaction_code,
                    transaction_datefrom: payload.transaction_datefrom,
                    transaction_dateto: payload.transaction_dateto,
                    transaction_lastmonth: payload.transaction_lastmonth,
                    transaction_last3months: payload.transaction_last3months,
                    transaction_type: payload.transaction_type,

                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }

                context.commit('setTransactions', response.data.data);
                context.commit('setMonths', response.data.meta.months);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadTransactionTypes(context) {
            await context.rootGetters['app/axios'].get('/transaction-types', {
                query: {
                    //
                }
            }).then((response) => {
                if (response.data.result !== "success") {
                    throw new Error(response.data.result);
                }

                context.commit('setTransactionTypes', response.data.data);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        register(context, payload) {
            //console.log("register start....");
            return new Promise((resolve, reject) => {
                context.rootGetters['app/axios'].post('/register', {
                    data: {
                        'accesstoken': context.rootGetters['auth/token'],
                        'lang': context.rootGetters['app/locale'],
                        // ------------------------------------------- //
                        "email": payload.email,
                        "password": payload.password,
                        "industry": payload.industryType,
                        "leadorigin": payload.leadOrigin,
                        // ------------------------------------------- //
                        "legalname": payload.accountDetails.legalName.val,
                        "contactname": payload.accountDetails.contactName.val,
                        "phone": payload.accountDetails.phone.val,
                        "vatnr": payload.accountDetails.vatNr.val,
                        "taxoffice": payload.accountDetails.taxOffice.val,
                        "profession": payload.accountDetails.profession.val,
                        "invoice_address": payload.accountDetails.invoiceAddress.val,
                        "invoice_city": payload.accountDetails.invoiceCity.val,
                        "invoice_perfecture": payload.accountDetails.invoicePerfecture.val,
                        "invoice_zipcode": payload.accountDetails.invoiceZipcode.val,
                        "shipping_address": payload.accountDetails.shippingAddress.val,
                        "shipping_city": payload.accountDetails.shippingCity.val,
                        "shipping_perfecture": payload.accountDetails.shippingPerfecture.val,
                        "shipping_zipcode": payload.accountDetails.shippingZipcode.val,
                        /*"message": payload.accountDetails.message.val,*/
                    }
                }).then((response) => {
                    console.log("response @ account.js: ", response);

                    if (response.data.result && response.data.result === "success") {
                        console.log('returning true');
                        resolve(true);
                    } else {
                        console.log('returning false');
                        resolve(false);
                    }
                }).catch(function (error) {                    
                    const errorMessage = new ErrorUtil(error).composeMessage();
                    console.log(errorMessage);
                    // throw new Error(errorMessage);
                    reject(errorMessage);
                });
            });
        },
        sendmessage(context, payload) {
            return new Promise((resolve, reject) => {
                context.rootGetters['app/axios'].post('/sendmessage', {
                    data: {
                        'lang': context.rootGetters['app/locale'],
                        // ------------------------------------------- //
                        "name": payload.name,
                        "surname": payload.surname,
                        "email": payload.email,
                        "company": payload.company,
                        "phone": payload.phone,
                        "country": payload.country,
                        "comments": payload.comments,
                        
                    }
                }).then((response) => {
                    console.log("response @ account.js: ", response);

                    if (response.data.result && response.data.result === "success") {
                        console.log('returning true');
                        resolve(true);
                    } else {
                        console.log('returning false');
                        resolve(false);
                    }
                }).catch(function (error) {                    
                    const errorMessage = new ErrorUtil(error).composeMessage();
                    console.log(errorMessage);
                    // throw new Error(errorMessage);
                    reject(errorMessage);
                });
            });
        }
    },
    mutations: {
        setAccountData(state, payload) {
            for (const property in payload) {
                //console.log('setting property:', property);
                //console.log('value:', payload[property]);
                state[property] = payload[property];
            }

            state.accountDataLoaded = true;
        },
        setRewards(state, payload) {
            state.rewards.buildings = payload.rewards;
            state.rewards.rewards_total_packages = payload.rewards_total_packages;
            state.rewards.rewards_total_pcs = payload.rewards_total_pcs;

            state.rewardsLoaded = true;
        },
        setIndustryTypes(state, payload) {
            state.industryTypes = payload;
            state.industryTypesLoaded = true;
        },
        setLeadOrigins(state, payload) {
            state.leadOrigins = payload;
            state.leadOriginsLoaded = true;
        },
        setDocuments(state, docs) {
            state.documents = docs;
            state.documentsLoaded = true;
        },
        setClients(state, clients) {
            state.clients = clients;
            state.clientsLoaded = true;
        },
        setDins(state, dins) {
            state.dins = dins;
            state.dinsLoaded = true;
        },
        setMonths(state, months) {
            state.months = months;
        },
        setDocument(state, doc) {
            state.document = doc;
            state.documentLoaded = true;
        },
        setTransactions(state, docs) {
            state.transactions = docs;
            state.transactionsLoaded = true;
        },
        setTransactionTypes(state, docs) {
            state.transactionTypes = docs;
            state.transactionTypesLoaded = true;
        },
        setMockAccountData(state) {
            state.businessDetails = mockData.businessDetails;
            state.businessAddresses.registeredAddress = mockData.businessAddresses.registeredAddress;
            state.businessAddresses.branchAddress = mockData.businessAddresses.branchAddress;
            state.contactDetails = mockData.contactDetails;
            state.transactions = mockData.transactions;
            state.rewards = mockData.rewards;

            state.accountDataLoaded = true;
        }
    },
    getters: {
        accountDetails(state) {
            return state.account_details;
        },
        industryTypes(state) {
            return state.industryTypes;
        },
        leadOrigins(state) {
            return state.leadOrigins;
        },
        businessDetails(state) {
            return state.businessDetails;
        },
        addresses(state) {
            return state.addresses;
        },
        businessAddresses(state) {
            return state.businessAddresses;
        },
        registeredAddress(state) {
            return state.businessAddresses.registeredAddress;
        },
        branchAddress(state) {
            return state.businessAddresses.branchAddress;
        },
        contactDetails(state) {
            return state.contactDetails;
        },
        documents(state) {
            return state.documents;
        },
        clients(state) {
            return state.clients;
        },
        months(state) {
            return state.months;
        },
        document(state) {
            return state.document;
        },
        transactions(state) {
            return state.transactions;
        },
        transactionTypes(state) {
            return state.transactionTypes;
        },
        rewards(state) {
            return state.rewards;
        },
        accountDataLoaded(state) {
            return state.accountDataLoaded;
        },
        industryTypesLoaded(state) {
            return state.industryTypesLoaded;
        },
        leadOriginsLoaded(state) {
            return state.leadOriginsLoaded;
        },
        documentsLoaded(state) {
            return state.documentsLoaded;
        },
        clientsLoaded(state) {
            return state.clientsLoaded;
        },
        documentLoaded(state) {
            return state.documentLoaded;
        },
        transactionsLoaded(state) {
            return state.transactionsLoaded;
        },
        transactionTypesLoaded(state) {
            return state.transactionTypesLoaded;
        },
        rewardsLoaded(state) {
            return state.rewardsLoaded;
        }
    },
}
