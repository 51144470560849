<template>
  <arrow-filters class="gp-arrow-filters-mb" style=""/>

  <!-- First Level Category (grandparent) -->
  <!--<subcategories-header
      :category="category"
      :subcategories="category.children"
      :selected-subcategory="null"
      :p1="home"
  ></subcategories-header>-->

  <div class="tool-filters home-margin-left text-medium" v-if="category.category_type=='tools'">
    <a :class="{active: toolbrand=='bahco'}" :href="'/category/'+category.category_slug + '?toolbrand=bahco'">Bahco</a>
    <a :class="{active: toolbrand=='irimo'}" :href="'/category/'+category.category_slug + '?toolbrand=irimo'">Irimo</a>
    <a :class="{active: toolbrand=='makita'}" :href="'/category/'+category.category_slug + '?toolbrand=makita'">Makita</a>
    <a :class="{active: toolbrand==''}" :href="'/category/'+category.category_slug">All</a>
    <div class="tool-filters-title text-medium">Select Brand<span>Filter by</span></div>
  </div>

  <subcategory-slider
      :ref="'subcategorySlider'+subcategory.id"
      v-for="(subcategory, index) in category.children"
      :key="subcategory.id"
      :type="index % 2 === 0 ? 'even' : 'odd'"
      :title="subcategory.name"
      :r1="subcategory.id"
      :p1="'category/' + category.category_slug"
      descr=""
      :link="subcategory.url"
      :link-descr="$t('labels.see-all-items')"
      :bottom-text="subcategory.quantity + ' items'"
      :parent="subcategory"
      :items="subcategory.children"
      :toolbrand="toolbrand"
  ></subcategory-slider>

  <div class="first-level-cat-bottom-spacer"></div>


</template>

<script>
//import SubcategoriesHeader from "@/components/misc/SubcategoriesHeader";
import SubcategorySlider from "@/components/misc/SubcategorySlider";
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "FirstLevelCategory",
  /*components: {ArrowFilters, SubcategorySlider , SubcategoriesHeader},*/
  components: {ArrowFilters, SubcategorySlider},
  props: {
    category: {
      type: Object,
      required: true,
    },
    toolbrand: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    initSliders: function() {
      const refs = this.$refs;

      this.category.children.forEach(function (subcategory/*, index*/) {
        refs['subcategorySlider'+subcategory.id].initSlider();
      });
    },
    
  },
  created() {
    //...
  },
  mounted() {
    var my_row = typeof(this.$route.query.r1)=="undefined"? "": this.$route.query.r1;
      if (my_row!="") {
        let el = 'row-' + my_row;
        //document.getElementById(el).scrollIntoView({behavior: 'smooth'});
        try {
          document.getElementById(el).scrollIntoView({behavior: 'smooth'});
        } catch (error) {
          console.log(error);
        }
      }
  }
}
</script>

<style scoped>

.first-level-cat-bottom-spacer {
  height:190px;
}


</style>


<style>

.gp-arrow-filters-mb {
  margin-bottom:60px;
}

@media (max-width:1400px) {
  .gp-arrow-filters-mb {
    margin-bottom:50px;
  }
}

.tool-filters {
  position:relative;
  font-size:14px;
}

.tool-filters a {
  margin-right:10px;
  margin-bottom: 10px;
  padding:10px 25px;
  background-image: url(../../assets/svg/select-tool-brand.svg);
  background-size: 100% 100%;
  display:inline-block;
  width:116px;
  text-align: center;
}

.tool-filters a.active,
.tool-filters a:hover {
  background-image: url(../../assets/svg/select-tool-brand-active.svg);
  background-size: 100% 100%;
}

.tool-filters-title {
  position: absolute;
  left:-120px;
  top:-2px;
  font-size:14px;
  line-height:15px;
  text-align: right;
}
.tool-filters-title span {
  display:block;
  color:#8c8c8c;
}

@media screen and (max-width:1000px) {
  .tool-filters {
    margin-left:250px !important;
  }
}

@media screen and (max-width:800px) {
  .tool-filters {
    margin-left:0px !important;
    margin-top:50px;
    padding-top:40px;
  }
  .tool-filters-title {
    position: absolute;
    left:0px;
    top:0px;
    font-size:14px;
    line-height:15px;
    text-align: left;
  }
  .tool-filters-title span {
    display:inline-block;
    margin-left:20px;
  }
}


</style>