<template>
  <div class="gp-container">
    <!-- mobile only -->
    <div class="sm:hidden pl-4 mb-1">
      <router-link class="flex items-center mb-2 manrope font-semibold" to="/account">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.account') }}
      </router-link>
      <div class="text-2xl manrope">{{ $store.getters['auth/userName']}},</div>
    </div>


    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12 mpty">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title" style="">
      {{$t('labels.profile_')}}
    </div>

    <account-navigation-menu :menu-items="menuItems" :prefix="routePrefix" />

  </div>

</template>

<script>
import AccountNavigationMenu from "@/components/misc/AccountNavigationMenu";

export default {
  name: "MyProfile",
  components: {AccountNavigationMenu},
  data() {
    return {
      menuItems: [
        {
          href: '/business-details',
          /*label: 'Business Details',*/
          label: this.$t('labels.business-details'),
        },
        {
          href: '/business-addresses',
          /*label: 'Business Addresses',*/
          label: this.$t('labels.business-addresses'),
        },
      ],
      routePrefix: '/account/my-profile',
    }
  },
  computed: {
    // currentRouteName() {
    //   return this.$route.name;
    // }
  }
}
</script>

<style scoped>

.gp-title {
  padding-left:30px;
  font-size:28px;
  margin-bottom:48px;
}

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:440px) {
  .text-2xl {
    font-weight:500;
    padding-bottom:20px;
  }  
  .mpty {display: none;}
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}

</style>