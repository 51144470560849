<template>
  <div class="" v-if="materialsLoaded">

    <div class="page" v-for="page in totalPages" :id="'page-'+page" :key="page">

        <!--<transition name="fade">-->
        <div v-show="page === currentPage">
          <!-- Grid tiles -->
          <div class="grid grid-cols-4 gap-4 text-center mx-auto mb-4">
            <material-tile
                v-for="material in gridTiles(page)"
                :key="material.id"
                :material="material"
                class="col-auto"
                :class="{selected: selectedMaterialId === material.id}"
                @click="handleTileClick(material)"
            ></material-tile>
          </div>

          <!-- Rest tiles (centered) -->
          <div class="flex justify-center gap-4 text-center mx-auto mb-4" v-if="materialsLoaded && page === totalPages && restTilesCount > 0">
            <material-tile
                v-for="material in restTiles"
                :key="material.id"
                :material="material"
                class="col-auto"
                :class="{selected: selectedMaterialId === material.id}"
                @click="handleTileClick(material)"
            ></material-tile>
          </div>
        </div>
    <!--</transition>-->
    </div>


  </div>
</template>

<script>
import MaterialTile from "@/components/arrow-filters/type/MaterialTile";

export default {
  name: "MaterialSection",
  components: {MaterialTile},
  inject: ['setMaterialFilter', 'unsetMaterialFilter', 'openDialog', 'initPaginator', 'showPaginator', 'hidePaginator'],
  props: {
    selectedCategoryId: {
      type: String,
      required: false,
    },
    selectedMaterialIdProp: {
      type: String,
      required: false,
      default: null,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    currentPageProp: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data() {
    return {
      gridCols: 4,
      blocksize: 16,
      // currentPage: 1,
      selectedMaterialId: null,
    }
  },
  computed: {
    materialsLoaded() {
      return this.$store.getters['filters/materialsLoaded'];
    },
    materials() {
      return this.$store.getters['filters/materials'];
    },
    restTilesCount() { // min:0 max:3
      return this.materials.length % this.gridCols;
    },
    gridTilesCount() {
      return this.materials.length - this.restTilesCount;
    },
    lastPageGridTilesCount() { // grid tiles are the ones in 4 per row format
      if (this.materials.length % this.blocksize === 0) {
        return this.blocksize;
      } else {
        return this.materials.length % this.blocksize - this.restTilesCount;
      }
    },
    // gridTiles() {
    //   return this.materials.slice(0, this.gridTilesCount);
    // },
    restTiles() {
      return this.materials.slice(-this.restTilesCount);
    },
    totalPages() {
      return Math.ceil(this.materials.length/this.blocksize);
    }
  },
  watch: {
    selectedMaterialIdProp(newVal) {
      this.selectedMaterialId = newVal;
    }
  },
  methods: {
    async loadMaterials() {
      const self = this;

      try {
        await this.$store.dispatch('filters/loadMaterials', {
          category_id: this.selectedCategoryId
        });

        if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    handleTileClick(material) {
      if (this.selectedMaterialId == material.id) {
        this.selectedMaterialId = null;
        this.unsetMaterialFilter();
      } else {
        this.selectedMaterialId = material.id;
        this.setMaterialFilter(material, this.currentPage);
        // this.$emit('category-selected', material);
      }
    },
    gridTiles(page) {
      // blocksize = 16

      if (page < this.totalPages) {
        return this.materials.slice((page - 1) * this.blocksize, page * this.blocksize);
      } else {
        return this.materials.slice((page - 1) * this.blocksize, (page-1) * this.blocksize + this.lastPageGridTilesCount);
      }
    },
  },
  created() {
    this.loadMaterials();

    this.selectedMaterialId = this.selectedMaterialIdProp;
  }
}
</script>

<style scoped>

</style>
