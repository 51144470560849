<template>
  <!-- Second Level Category (parent) -->

  <arrow-filters class="gp-arrow-filters-mb"/>

<!--  {{ $store.getters['categories/favorites'] }}-->

  <!-- all sizes -->
  <subcategories-header
      class="--sm:hidden"
      :category="category"
      :subcategories="category.children"
      :selected-subcategory="null"
      :r1="r1"
      :p1="p1"
      :toolbrand="toolbrand"
  ></subcategories-header>

  <div class="tool-filters home-margin-left text-medium" v-if="category.category_type=='tools'">
    <a :class="{active: toolbrand=='bahco'}" :href="'/category/'+category.category_slug + '?toolbrand=bahco'">Bahco</a>
    <a :class="{active: toolbrand=='irimo'}" :href="'/category/'+category.category_slug + '?toolbrand=irimo'">Irimo</a>
    <a :class="{active: toolbrand==''}" :href="'/category/'+category.category_slug">All</a>
    <div class="tool-filters-title text-medium">Select Brand<span>Filter by</span></div>
  </div>

  <!-- mobile -->
  <div class="sm:hidden gp-text-21 txt-lh-32 text-regular text-dark-gray manrope py-12 px-8--">
    {{ category.category_name }}
  </div>

  <!-- mobile -->
  <second-level-category-card
      class="sm:hidden cat-mob"
      v-for="subcategory in category.children"
      :key="subcategory.id"
      :category="subcategory"
  />

  <!-- desktop -->  
  <div class="hidden sm:grid desktop-container">
    <div class="text-dark-gray text-2xl-xx cat-title-container text-27-xx text-semibold manrope" style="">
      {{ category.category_name }}
    </div>

    <div class="cat-container" style="display:grid; grid-template-columns: 50% 50%; padding-right:100px">
      <second-level-category-tile
        style=""
        class=""
        v-for="subcategory in category.children"
        :key="subcategory.id"
        :category="subcategory"
        :r1="r1"
        :p1="p1"
        :p2="thisPage"
        :r2="subcategory.id"
        :toolbrand="toolbrand"
      />

    </div>

  </div>


  <div v-if="dataHasBeenLoaded && !isMobile" class="hidden-xx lg:block-xx mb-16 " style="padding-top:100px">
    <div v-if="category.related_categories.length"
         class="title-related-products text-regular manrope text-dark-gray mb-10 home-margin-left">
      {{ $t('labels.you-also-need')}}
    </div>

    <custom-slider
        ref="relatedCategoriesSlider"
        class="related-categories-slider home-margin-left"
        :slide-count="category.related_categories.length"
        :slide-width="responsiveSlideWidth2"
        :slide-height="360"
        :scrollbar="isMobile"
        :corner-prev-next="true"
        :debug="false"
    >
        <product-slider-item
          v-for="(yanCategory, index) in category.related_categories"
          :key="yanCategory.id"
          :item="yanCategory"
          :ref="'yan-slide-'+yanCategory.id"
          :index="index"
        ></product-slider-item>

    </custom-slider>
  
  </div>
 
   
  
  


  <div class="second-level-cat-bottom-spacer"></div>

  


</template>

<script>

import SubcategoriesHeader from "@/components/misc/SubcategoriesHeader";
import SecondLevelCategoryCard from "@/components/categories/SecondLevelCategoryCard";
import SecondLevelCategoryTile from "@/components/categories/SecondLevelCategoryTile";
import ArrowFilters from "@/components/ArrowFilters";
import CustomSlider from "@/components/custom-slider/CustomSlider";
import ProductSliderItem from "@/components/custom-slider/ProductSliderItem";

export default {
  name: "SecondLevelCategory",
  components: {ArrowFilters, SecondLevelCategoryTile, SecondLevelCategoryCard, SubcategoriesHeader, CustomSlider, ProductSliderItem},
  props: {
    category: {
      type: Object,
      required: true,
    },
    toolbrand: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    p1() {
      return typeof(this.$route.query.p1)=="undefined"? "": this.$route.query.p1;
    },
    r1() {
      return typeof(this.$route.query.r1)=="undefined"? "": this.$route.query.r1;
    },
    thisPage() {
      return 'category/' + this.category.category_slug;
    },
    isMobile() {
      return window.innerWidth <= 800;
    },
    dataHasBeenLoaded() {
      return true;
    }, 
    responsiveSlideWidth2() {
      return 214;
      /*let w = window.innerWidth;
      if (w<590) {
        return w-40;
      }
      else if (w<1300) {
        return 300;
      }
      else if (w<1400) {
        return 230;
      }
      else if (w<1600) {
        return 260;
      }
      else if (w<1800) {
        return 300;
      }
      else if (w<2200) {
        return 360;
      }
      else {
        return 400;
      }*/

    },
  },
  methods: {

  },
  created() {
    // console.log('SLC:');
    // console.log(this.category);
    // console.log(this.category.children);
  },
  mounted() {
    var my_row = typeof(this.$route.query.r2)=="undefined"? "": this.$route.query.r2;
      if (my_row!="") {
        let el = 'row-' + my_row;
        try {
          document.getElementById(el).scrollIntoView({behavior: 'smooth'});
        } catch (error) {
          console.log(error);
        }
        
      }
  }
}
</script>

<style scoped>

.cat-title-container {
  padding-left:125px; padding-right:20px;
  font-size: 33px;
  line-height:33px;
}
@media (max-width:1366px) {
  .cat-title-container {
    padding-left:63px;
  }
}

.second-level-cat-bottom-spacer {
  height:200px;
}

.desktop-container {
  grid-template-columns: 30% 70%;
}

@media (max-width:1400px) {
  .desktop-container {
    grid-template-columns: 24% 76%;
  }
}
@media (max-width:1300px) {
  .desktop-container {
    grid-template-columns: 25% 75%;
  }
}
@media (max-width:1200px) {
  .desktop-container {
    grid-template-columns: 15% 85%;
  }
}

@media (max-width:820px) {
  .desktop-container {
    grid-template-columns: 100%;
  }
  .cat-container {margin-left: 63px;
    justify-content: center;
    margin-right: 63px;
    padding-top: 25px;}
}



  @media screen and (max-width:590px) {
    .cat-mob {margin-bottom: unset; background-image: url(../../assets/svg/cat-mob.svg); border:none; background-size: 100% 100%;}
  }

  .title-related-products {
    font-size:32px;
  }
  @media (max-width:1600px) {
  
  .title-related-products {
    font-size:30px;
  }
  
}

@media (max-width:1400px) {
  
  .title-related-products {
    font-size:25px;
  }
  
}

.tool-filters {
  position:relative;
  font-size:14px;
}

.tool-filters a {
  margin-right:10px;
  margin-bottom: 10px;
  padding:10px 25px;
  background-image: url(../../assets/svg/select-tool-brand.svg);
  background-size: 100% 100%;
  display:inline-block;
  width:116px;
  text-align: center;
}

.tool-filters a.active,
.tool-filters a:hover {
  background-image: url(../../assets/svg/select-tool-brand-active.svg);
  background-size: 100% 100%;
}

.tool-filters-title {
  position: absolute;
  left:-120px;
  top:-2px;
  font-size:14px;
  line-height:15px;
  text-align: right;
}
.tool-filters-title span {
  display:block;
  color:#8c8c8c;
}

@media screen and (max-width:800px) {
  .tool-filters {
    margin-left:0px !important;
    margin-top:50px;
    padding-top:40px;
  }
  .tool-filters-title {
    position: absolute;
    left:0px;
    top:0px;
    font-size:14px;
    line-height:15px;
    text-align: left;
  }
  .tool-filters-title span {
    display:inline-block;
    margin-left:20px;
  }
}

</style>