<template>
  <div class="mt-32 max-w-930-- --text-center mx-auto manrope text-lg gp-content" style="min-height: calc(100vh - 100px)">    
    <span v-if="$store.getters['app/locale'] === 'el'">Ευχαριστούμε για την παραγγελία σας. <br/>
    Μπορείτε να δείτε τις παραγγελίες σας <router-link to="/account/transactions" class="font-semibold underline cursor-pointer">εδώ</router-link>.</span>
    <span v-else>Thank you for your order. <br/>
    You can see your orders <router-link to="/account/transactions" class="font-semibold underline cursor-pointer">here</router-link>.</span> 
  </div>
</template>

<script>
export default {
  name: 'OrderSuccess',
  components: {
    // HomeSliderItem,
    // CustomSlider,
    // ArrowFilters,
  },
  inject: ['exitDesktopFullscreen', 'openDialog'],
  data() {
    return {

    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
};
</script>

<style scoped>

  .gp-content {
    max-width:930px;
  }

  @media (max-width:1600px) {
    .gp-content {
      max-width:670px;
      padding-left:0px;
    }
  }

  @media (max-width:1400px) {
    .gp-content {
      max-width:635px;
      padding-left:7px;
    }
  }

  @media (max-width:1300px) {
    .gp-content {
      max-width:610px;
      padding-left:15px;
    }
  }

</style>

