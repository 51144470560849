<template>
<!--  {{ prettyNum(totalCost * 100000) }}-->

  <div class="grid grid-cols-12" style="position-:relative; /*background-color: #efefe2;*/">
    <div class="logo-kg" style="">
      <router-link class="logo-link--" to="/" style="">
        <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/> -->
        <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
        <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>

    <div class="logo-container-abs" style="">
      <router-link class="" to="/">
          <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo"/> -->
          <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
          <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
        </router-link>
    </div>


    <div class="logo-container" style="">
      <!--<router-link class="logo-link" to="/" style="">
        <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>-->
    </div>
    
    
    <div v-if="dataLoaded && cartItemsLoaded && cartItems" 
    class="col-span-12 md:mr-16-- md:col-start-5 md:col-span-8 xl:col-start-7 xl:col-span-6 mt-16-- mb-36 manrope gp-mr-100 gp-sm-mr-0 cart-container" 
    style="margin-top:40px;">

      <div class="col-span-full text-right mb-4 gp-pr-30 gp-sm-pr-17" style = "">
        <a class="text-15 text-medium underline cursor-pointer" @click="$router.go(-1)">
          {{$t('labels.close')}}
        </a>
      </div>

      <!-- Summary -->
      <div id="summary" class="mobile-app-cancel-px py-4 gp-px-30 gp-sm-px-22 mt-18-xx mb-6 text-xl-xx" style="--background-color: red;">
        <div class="text-semibold text-26 checkout-title" style="">Checkout</div>

        <div class="text-medium text-15 deliver-to" style="">Delivered to</div>

        <div>
          <table class="text-semibold text-17 summary-table" style=""> 
            <tr>
              <td>{{ $t('labels.total') }}</td>
              <td>{{ prettyNum(totalLinesPrice2) }}</td>
            </tr>
            <tr style="color:#7a7a7a">
              <!--<td>{{$t('labels.discount-eshop')}} ({{ totalLinesPrice2>=2000? "3+10%": "3%" }})</td>--><!--ΠΡΟΣΦΟΡΑ 12/2023-->
              <td>{{$t('labels.discount-eshop')}} (3%)</td>
              <td>{{ prettyNum(totalReward2) }}</td>
            </tr>
            <!--<tr style="color:#7a7a7a">
              <td>{{ $t('labels.cash-discount') }} ({{cashDiscountPerc}}%)</td>
              <td>{{ prettyNum(cashDiscountVal) }}</td>
            </tr>-->
            <tr>
              <td>{{ $t('labels.total-w-out-vat') }}</td>
              <td>{{ prettyNum(totalCost2) }}</td>
            </tr>
            <tr style="border-bottom:1px solid #212121">
              <td>{{ $t('labels.vat') }}</td>
              <td>{{ prettyNum(totalVat2) }}</td>
            </tr>
            <tr class="summary-total" style="">
              <td>{{ $t('labels.total-w-vat') }}</td>
              <td>{{ prettyNum(totalCost2 + totalVat2) }}</td>
            </tr>
          </table>
        </div>

        <div class="text-15" style="line-height: 20px; font-weight: 300;">
          <span class="text-semibold">{{ accountDetails.customer_name }}</span><br/>
          {{ accountDetails.address }}<br/>
          {{ accountDetails.city }}<br/>
          {{ $t('labels.afm') }}: {{ accountDetails.vat_nr }}<br/>
          {{ $t('labels.tax-dept') }}: {{ accountDetails.tax_office }}<br/>
          <span v-if="accountDetails.phone && accountDetails.phone !== 'NULL' && accountDetails.phone !== ''">T: {{ accountDetails.phone }}<br/></span>
          E: {{ accountDetails.email }}
        </div>

        
        
      </div>




      <div v-if="cartItems.length>0" class="col-span-full text-right mb-4 gp-pr-30 gp-sm-pr-17" style = "">
        <a class="text-15 text-medium underline cursor-pointer" @click="emptyCart">
          {{$t('labels.empty-cart')}}
        </a>
      </div>

      <!--<div v-if="loadedBlocks > 1" class="text-right pr-4 mb-4">-->
      <div class="text-right pr-4-- mb-4 gp-pr-30 gp-sm-pr-17" >
        &nbsp;
        <span v-if="loadedBlocks > 1" class="font-semibold border-b-1 cursor-pointer" @click="decreaseAll">{{ $t('labels.decrease-all') }}</span> 
      </div>

      <div class="" style="">
        <cart-overlay-panel-item
            v-for="(item, index) in cartItems"
            :item="item"
            :key="item.item_code"
            :class="{hidden: index >= blocksize * loadedBlocks}"
            :style="{'background-color': getBgColor(index)}"
            @add-package="addPackage"
            @subtract-package="subtractPackage"
        />
      </div>

      <!-- Load more & View all -->
      <div class="mb-8-- " style="margin-bottom:25px">
        <button v-if="hiddenCartItems"
                class="app-button black w-full mb-2 text-15 text-medium"
                style="line-height:15px; padding-top: 14px; padding-bottom: 14px;"
                @click="loadMore"
        >
          {{ $t('labels.load-more') }}
        </button>
        <div v-if="hiddenCartItems" class="text-right mb-4-- gp-pr-30 gp-sm-pr-17" style="margin-bottom:20px; ">
          <span class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium" style = "" @click="viewAll">
            {{ $t('labels.view-all') }}
          </span>
        </div>
      </div>

      <div class="space-y-4--">
        <!-- Checkout -->
        <button v-if="cartItems.length>0 && !isSalesOffer()" class="app-button w-full btn-cart-checkout" @click="checkout">
          {{ $t('labels.checkout') }}
        </button>
        <!-- Quotation -->
        <button 
          v-if="rfq_enabled && isSalesOffer()"
          class="app-button w-full btn-cart-rfq" 
          :style="{ background: submitBtnColor }"
          @click="requestQuotation">
          {{ submitBtnCaption }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CartOverlayPanelItem from "@/components/cart/CartOverlayPanelItem";

export default {
  name: "ReviewCart",
  components: {CartOverlayPanelItem},
  inject: ['enterFullscreen', 'exitFullscreen', 'openDialog', 'prettyNum'],
  data() {
    return {
      blocksize: 9999,
      loadedBlocks: 1,
      rewardColorNotation: '#7d7d7d',
      submitBtnEnabled: true,
      
    }
  },
  computed: {
    dataLoaded() {
      return this.$store.getters['account/accountDataLoaded'] && this.$store.getters['cart/itemsLoaded'];
    },
    cartItemsLoaded() {
      return this.$store.getters['cart/itemsLoaded'];
    },
    account() {
      return this.$store.getters['account/businessDetails'];
    },
    accountDetails() {
      return this.$store.getters['account/accountDetails'];
    },
    cartItems() {
      return this.$store.getters['cart/items'] ?? [];
    },
    totalAmount() {
      return this.$store.getters['cart/totalAmount'];
    },
    totalAmount2() {
      return this.$store.getters['cart/totalAmount2'];
    },
    totalCost() {
      return this.$store.getters['cart/totalCost'];
    },
    totalCost2() {
      return this.$store.getters['cart/totalCost2'];
    },
    totalReward() {
      return this.$store.getters['cart/totalReward']
    },
    totalReward2() {
      return this.$store.getters['cart/totalReward2']
    },
    cashDiscountPerc() {
      return this.$store.getters['cart/cashDiscountPerc']
    },
    cashDiscountVal() {
      return this.$store.getters['cart/cashDiscountVal']
    },
    totalVat() {
      return this.$store.getters['cart/totalVat']
    },
    totalVat2() {
      return this.$store.getters['cart/totalVat2']
    },
    eshopDiscount() {
      return this.$store.getters['cart/eshopDiscount']
    },
    totalLinesPrice() {
      return this.$store.getters['cart/totalLinesPrice']
    },
    totalLinesPrice2() {
      return this.$store.getters['cart/totalLinesPrice2']
    },
    hiddenCartItems() {
      return this.cartItems.length > this.blocksize*this.loadedBlocks;
    },
    submitBtnColor() {
      return this.submitBtnEnabled? "": "#ccc";
    },
    submitBtnCaption() {
      return this.submitBtnEnabled? this.$t('labels.request-quotation'): this.$t('labels.please-wait') 
    }, 
    rfq_enabled() {
      return localStorage.getItem('rfq_enabled')==1? true: false;
    },
    customer_active() {
      return localStorage.getItem('customer_active')==1? true: false;
    },
    is_seller() {
      return localStorage.getItem('sellertoken')===null? false: true;
    } ,
  },
  methods: {
    getBgColor(index) {
      if (index % 4 == 0) {
        return "#e2e7eb";
      }
      if (index % 4 == 1) {
        return "#b1e2de";
      }
      if (index % 4 == 2) {
        return "#f0e4c1";
      }
      if (index % 4 == 3) {
        return "#bbe9a6";
      }
    },
    isSalesOffer() {
      const storedValue = localStorage.getItem('isSalesOffer');
      console.log('isSalesOffer=' + storedValue);
      if (storedValue !== null) {
        return storedValue === '1';
      }
      else {
        return false;
      }
    },
    async loadAccountData() {
      try {
        await this.$store.dispatch('account/loadAccountData');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadCartData() {
      try {
        await this.$store.dispatch('cart/loadItems');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async emptyCart() {
      
      var confirm_empty_cart = confirm(this.$t('labels.confirm-empty-cart'));

      if (!confirm_empty_cart) {
          return;
      }
      
      try {
        await this.$store.dispatch('cart/empty');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }

      await this.loadCartData();
    },
    loadMore() {
      this.loadedBlocks++;
    },
    viewAll() {
      this.loadedBlocks = Math.ceil(this.cartItems.length/this.blocksize);
    },
    decreaseAll() {
      this.loadedBlocks = 1;
    },
    async addPackage(item) {
      
      await this.$store.dispatch('cart/addItems', [{
        item: item,
        item_code: item.item_code,
        item_quantity: item.main_package_quantity_pcs
      }]);

      //this.rewardColorNotation = '#5ad504';
      this.rewardColorNotation = '#7d7d7d';

      // no need
      // this.loadCartData();
    },
    async subtractPackage(item) {
      // console.log(item);

      await this.$store.dispatch('cart/updateItems', {
        // item: item,
        item_code: item.item_code,
        // εγώ όπως και να έχει, στέλνω πάντα αριθμό τεμαχίων
        item_quantity: item.main_package_quantity_pcs * (item.quantity_packages - 1),
      });

      //this.rewardColorNotation = '#E66000';
      this.rewardColorNotation = '#7d7d7d';
    },
    checkout() {
      if (this.cartItems.length === 0) {
        this.openDialog({
          class: 'danger',
          html: 'Your cart is empty.<br/>' + 'Continue shopping'
        });

        return;
      }

      if (!this.customer_active) {
        this.openDialog({
          class: 'danger',
          html: 'Η παραγγελία σας δεν μπορεί να ολοκληρωθεί.\nΠαρακαλώ να επικοινωνήσετε με την εταιρεία'
        });
        return;
      }

      this.$router.push('/checkout')
    },
    async requestQuotation() {
      if (this.cartItems.length === 0) {
        this.openDialog({
          class: 'danger',
          html: 'Your cart is empty. \n' + 'Continue shopping'
        });

        return;
      }

      if (!this.submitBtnEnabled) {
        return;
      }
      this.submitBtnEnabled = false;
     

      try {
        await this.$store.dispatch('cart/rfq', {
          address_id: null, // this.addressId,
          payment_type: null, // this.paymentTypeId,
          shipping_type: null, // this.deliveryTypeId,
          use_discount: null, // this.useExtraDiscount
        });


        // redirect
        await this.$router.push('/rfq-success');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  async created() {
    await this.loadAccountData();
    await this.loadCartData();
  },
  beforeMount() {
    this.enterFullscreen();
  },
  beforeUnmount() {
    this.exitFullscreen();
  },
}
</script>

<style scoped>

.btn-cart-checkout,
.btn-cart-rfq {
  font-size:15px;
  padding:29px 20px;
  margin-bottom:10px;
  margin-top:0px;
}



@media (max-width:800px) {

  .btn-cart-checkout,
  .btn-cart-rfq {
    font-size:21px;
    font-weight:500;
    line-height: 32px;
    padding:20px 20px;
  }


}

.summary-labels {
  padding-left:25px;
  width:240px;
}

.summary-values p {
  padding-left:80px;
  padding-right:0px;
}

@media (max-width:800px) {
.summary-labels {
  padding-left:25px;
  width:220px;
  float:left;
}

.summary-values p {
  padding-left:20px;
  padding-right:0px;
  float:left;
  display:block;
  text-align:right;
  width:100px;
}  
}


div#summary {
  display:grid;
  grid-template-columns: 70% 30%;
  font-size:17px;
}
.checkout-title {
  margin-bottom:35px;
}
.deliver-to {
  margin-bottom:35px;
}
.summary-table {
  line-height: 25px; 
}
.summary-total {
  font-size:26px; line-height: 40px;
}

@media (max-width:800px) {
  div#summary {
    grid-template-columns: 100%;
    font-size:15px;
    margin-top:40px;
  }   
  .checkout-title {
    margin-bottom:15px;
  }
  .deliver-to {
    grid-row: 3;
    margin-top:30px;
    margin-bottom:10px;
  }
  .summary-table {
    line-height: 22px; width:100%;
  }
  .summary-total {
    font-size:22px; line-height: 30px;
  }
}

.discount-gray {
  color:#7d7d7d;
}

.total-lg-txt {
  font-size:26px;
  line-height:35px;
}

@media (max-width:1400px) {
.total-lg-txt {
  font-size:22px;
  line-height:28px;
}  
}

@media (max-width:800px) {
  

  .summary-cart-icon {
    width:75px;
    margin-bottom:20px;
  }

  .total-lg-txt {
    font-size:18px;
    line-height:26px;
  }



}

.logo-container {
  position:absolute; left:96px; top:64px; 
}

@media (max-width:1400px) {
  .logo-container {
    position:absolute; left:50px; top:37px; 
  }  
  }

@media not all and (min-resolution:.001dpcm) and (max-width:1400px) { 
     @supports (-webkit-appearance:none) {
      .logo-container {
        top:33px;
      }
     }
  }

  @media (max-width:1200px) {
  .logo-container {
    position:absolute; top:70px; left:30px;
  }  
  }

@media (max-width:800px) {
.logo-container {
  position:absolute; top:30px; left:0px;
}  
}



/** */
@media (max-width:1000px) {
  .cart-container {
    padding-left:100px;
    padding-top:50px;
  }
}
@media (max-width:800px) {
  .cart-container {
    padding-left:0px;
    padding-top:0px;
  }
}




/** */
  .logo-container-abs {
    position:absolute; 
    top:64px; 
    left:96px;
    width:225px;
  }
  @media (max-width:1600px) {
    .logo-container-abs {
      top:42px; 
      left:96px
    }
  }
  @media (max-width:1400px) {
    .logo-container-abs {
      top:37px; 
      left:50px
    }
  }
  @media (max-width:1300px) {
    .logo-container-abs {
      top:36px; 
      left:50px;
      width:226px;
    }
    .gp-logo {
      width:226px;
    }
  }
  @media (max-width:1200px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }
  /** ipad 810*1080 - Landscape */
  @media (width:1080px) {
    .logo-container-abs {
      top:42px; 
      left:50px;
      width:210px;
    }
    .gp-logo {
      width:211px;
    }
  }

  @media (max-width:1079px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

  @media (max-width:1000px) and (min-width:811px) {
    .logo-container-abs {
      top:30px; 
      left:49px;
      width:201px;
    }
    .gp-logo {
      width:201px;
    }
  }
  /** ipad 810*1080 Portrait */
  @media (width:810px) {
    .logo-container-abs {
      top:23px; 
      left:50px;
      width:200px;
    }
    .gp-logo {
      width:202px;
    }
  }
  

  @media (max-width:809px) {
    .logo-container-abs {
      top:60px; 
      left:20px;
      width:197px;
    }
    .gp-logo {
      width:197px;
    }    
  }

  @media (max-width:799px) {
    .logo-container-abs {
      display:none;
    }    
  }

  .summary-table td {
    padding:1px 15px 1px 0px;
  }

  @media (max-width:800px) {
    .logo-kg {
      display: block;
      top:35px;
      left:20px;
    }
  }


</style>