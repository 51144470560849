<!--
1) :value="this.modelValue" enables the incoming communication, initializing the component's value to the v-model's value
2) @input="this.handleChange" >> this.$emit('update:modelValue', e.target.value); enables the other way communication
-->
<template>
    <input class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
           :class="this.class"
           :type="type"
           :name="name"
           :value="modelValue"
           :autocomplete="autocomplete"
           @input="handleChange"
    />
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        // absolutely necessary [!]
        modelValue: {},
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        name: {
            type: String,
            required: false,
        },
        class: {
            type: String,
            required: false,
        },
        autocomplete: {
            type: String,
            required: false,
            default: 'off'
        }
    },
    methods: {
        handleChange(e) {
            this.$emit('update:modelValue', e.target.value);
        },
    },
};
</script>

<style>
    .dev-red {
        color: red;
        background-color: red;
        border-color: red;
    }
</style>
