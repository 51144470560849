import axios from 'axios';

export default {
    namespaced: true,
    state() {
        
        return {
            env: 'live', /** live, dev */
            url: 'http://localhost:8080',
            locale : 'el',
            axios: axios,
            offlineMode: false,            
            devApiUrl: 'https://demo.tsamouris.gr/eshop/api',
            liveApiUrl: 'https://tsamouris.gr/eshop/api',
            /*filterGroupType: null,*/
        }
    },
    actions: {},
    mutations: {
        configAxios(state, payload) {
            if (payload.baseUrl) {
                state.axios.defaults.baseURL = payload.baseUrl;
            }

            if (payload.token) {
                // Bearer ?
                state.axios.headers.common['Authorization'] = payload.token;
            }
        },
        switchLocale(state) {
            if (state.locale === 'el') {
                state.locale = 'en';
            } else {
                state.locale = 'el';
            }
        },
        setLocale(state, locale) {
            state.locale = locale;
        },
        
    },
    getters: {
        env(state) {
            return state.env;
        },
        dev(state) {
            return state.env === 'dev' || state.env === 'local';
        },
        test(state) {
            return state.env === 'test';
        },
        /*filterGroupType(state) {
            return state.filterGroupType;
        },*/
        staging(state) {
            return state.env === 'staging';
        },
        live(state) {
            return state.env === 'live' || state.env === 'production';
        },
        url(state) {
            return state.url;
        },
        locale(state) {
            return state.locale;
        },
        offlineMode(state) {
            return state.offlineMode;
        },
        apiUrl(state) {
            return state.env === 'dev' ? state.devApiUrl : state.liveApiUrl;
        },
        axios(state) {
            return state.axios;
        }

    },
}
