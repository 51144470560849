import axios from 'axios';
import ErrorUtil from "../../classes/ErrorUtil";
import mockData from "@/test/mockData";

export default {
    namespaced: true,
    state() {
        return {
            fasteners: [],
            materials: [],
            d1metrics: [],
            d2metrics: [],
            dins: [],
            fastenersLoaded: false,
            materialsLoaded: false,
            d1metricsLoaded: false,
            d2metricsLoaded: false,
            dinsLoaded: false,

            searchResults: [],
            searchResultsLoaded: false,

            filterGroupType: null, /** tool, fastener, makita */
            toolBrand: null,
            toolType: null,

        }
    },
    actions: {
        async loadFasteners(context) {
            if (context.rootGetters['app/offlineMode']) {
                context.commit('setMockFasteners');
                return;
            }

            await axios.get(context.rootGetters['app/apiUrl'] + '/fasteners', {
                params: {}
            }).then((response) => {
                const fasteners =  response.data.data;

                context.commit('setFasteners', fasteners);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadMaterials(context, payload) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/materials', {
                params: {
                    lang: context.rootGetters['app/locale'],
                    category: payload.category_id ?? null
                }
            }).then((response) => {
                const materials =  response.data.data;

                context.commit('setMaterials', materials);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadD1Metrics(context, payload) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/metrics', {
                params: {
                    lang: context.rootGetters['app/locale'],
                    category: payload.category_id,
                    din: payload.din,
                    type: 'dimension1',
                }
            }).then((response) => {
                const d1metrics =  response.data.data;

                context.commit('setD1Metrics', d1metrics);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadD2Metrics(context, payload) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/metrics', {
                params: {
                    lang: context.rootGetters['app/locale'],
                    category: payload.category_id,
                    din: payload.din,
                    type: 'dimension2',
                }
            }).then((response) => {
                const d2metrics =  response.data.data;

                context.commit('setD2Metrics', d2metrics);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadDins(context, payload) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/getdins', {
                params: {
                    din: payload.din,
                }
            }).then((response) => {
                const dins =  response.data.data;
                console.log(dins);
                context.commit('setDins', dins);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async search(context, filters) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/search', {
                params: {
                    lang: context.rootGetters['app/locale'],
                    din: filters.din ? filters.din.val : null,
                    category: filters.category ? filters.category.val : null,
                    material: filters.material ? filters.material.val : null,
                    dimension1: filters.d1metric ? filters.d1metric.val : null,
                    dimension2: filters.d2metric ? filters.d2metric.val : null,
                }
            }).then((response) => {
                // const results =  response.data.data;
                context.commit('setSearchResults', response.data.data);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async algoliaSearch(context, algoliaQuery) {
            await axios.get(context.rootGetters['app/apiUrl'] + '/search', {
                params: {
                    lang: context.rootGetters['app/locale'],
                    algolia: algoliaQuery
                }
            }).then((response) => {
                context.commit('setSearchResults', response.data.data);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        }
    },
    mutations: {
        setFasteners(state, payload) {
            state.fasteners = payload;
            state.fastenersLoaded = true;
        },
        setMaterials(state, payload) {
            state.materials = payload;
            state.materialsLoaded = true;
        },
        setD1Metrics(state, payload) {
            state.d1metrics = payload;
            state.d1metricsLoaded = true;
        },
        setD2Metrics(state, payload) {
            state.d2metrics = payload;
            state.d2metricsLoaded = true;
        },
        setDins(state, payload) {
            state.dins = payload;
            state.dinsLoaded = true;
        },
        setMockFasteners(state) {
            state.fasteners = mockData.fasteners;
            state.totalResults = mockData.fasteners.length;
            state.fastenersLoaded = true;
        },

        setSearchResults(state, payload) {
            state.searchResults = payload;
            state.searchResultsLoaded = true;
        },

        /**tools */
        showFastenerFilterGroup(state) {
            state.filterGroupType = 'fastener';
        },
        showToolFilterGroup(state) {
            state.filterGroupType = 'tool';
        },
        showToolMakitaFilterGroup(state) {
            state.filterGroupType = 'makita';
        },
        clearFilterGroup(state) {
            state.filterGroupType = null;
        },
        setToolBrand(state, val) {
            state.toolBrand = val;
        },
        setToolType(state, val) {
            state.toolType = val;
        }

    },
    getters: {
        fasteners(state) {
            return state.fasteners;
        },
        hasFasteners(state) {
            return state.fasteners && state.fasteners.length > 0;
        },
        fastenersLoaded(state) {
            return state.fastenersLoaded;
        },
        materials(state) {
            return state.materials;
        },
        materialsLoaded(state) {
            return state.materialsLoaded;
        },
        d1metrics(state) {
            return state.d1metrics;
        },
        d1metricsLoaded(state) {
            return state.d1metricsLoaded;
        },
        d2metrics(state) {
            return state.d2metrics;
        },
        d2metricsLoaded(state) {
            return state.d2metricsLoaded;
        },
        dins(state) {
            return state.dins;
        },
        dinsLoaded(state) {
            return state.dinsLoaded;
        },
 
        searchResults(state) {
            return state.searchResults;
        },
        searchResultsLoaded(state) {
            return state.searchResultsLoaded;
        },

        filterGroupType(state) {
            return state.filterGroupType;
        },
        toolBrand(state) {
            return state.toolBrand;
        },
        toolType(state) {
            return state.toolType;
        },
    },
}
