<template>
  <div class="mx-auto rounded-lg mt-4  mb-8 gp-type-browse-section" style="max-width: 932px; background-color: #F5F5E8; width:95vw">
    <div class="p-8-- gp-p-20 rounded-lg mx-auto" style="background-color: #00D491">
      <!-- Small Arrow section -->
      <div class="flex mx-auto mb-16" style="width: 713px; justify-content: center;">
        <arrow-shaped-nav
            class="fastener small z-10"
            :overlay="true"
            :index="-1"
            :left-offset="leftOffset"
            :arrow-number="1"
            :class="{selected: activeFilters.focus === 'category' || activeFilters.focus === null}"
            :title="$t('labels.bolt')"
            @click="setSelectedTypeCategory('category')"
        ></arrow-shaped-nav>
        <!--<arrow-shaped-nav
            :index="0"
            :left-offset="leftOffset"
            class="head-style small z-20"
            :overlay="true"
            :arrow-number="2"
            :class="{selected: activeFilters.focus === 'head-style'}"
            :title="$t('labels.head-type')"
            @click="setSelectedTypeCategory('head-style')"
        ></arrow-shaped-nav>-->
        <arrow-shaped-nav
            :index="1"
            :left-offset="leftOffset"
            class="material small last z-30"
            :overlay="true"
            :arrow-number="4"
            :class="{selected: activeFilters.focus === 'material'}"
            :title="$t('labels.material')"
            @click="setSelectedTypeCategory('material')"
        ></arrow-shaped-nav>
      </div>

      <transition name="fade-in">
        <fasteners-list
            ref="fasteners-list"
            v-if="activeFilters.focus === 'category' || activeFilters.focus === null"
            :current-page="currentPage"
            :selected-category-id-prop="activeFilters.category ? activeFilters.category.val : null"
            @category-selected="setCategoryFilter"
            @category-deselected="unsetCategoryFilter"
        ></fasteners-list>
      </transition>

      <transition name="fade-in">
        <head-type
            ref="head-type"
            :current-page="currentPage"
            :selected-head-type-prop="activeFilters.headType ? activeFilters.headType.val : null"
            v-if="activeFilters.focus === 'head-style'" style="min-height: 332px"
        ></head-type>
      </transition>

      <transition name="fade-in">
        <material-section
            ref="material-section"
            v-if="activeFilters.focus === 'material'" style="min-height: 332px"
            :current-page="currentPage"
            :selected-category-id="categoryFilterIsActive ? activeFilters.category.val : null"
            :selected-material-id-prop="activeFilters.material ? activeFilters.material.val : null"
            :current-page-prop="activeFilters.material ? activeFilters.material.page : 1"
        ></material-section>
      </transition>


      <!-- controls -->
      <div v-if="paginatorIsVisible" id="pagination-controls" class="flex items-center justify-center p-2 manrope font-semibold" style="">

        <img v-if="currentPage === 1" src="../../assets/svg/BACK_ARROW_GREEN.svg" class="cursor-pointer" style="width: 55px;"/>
        <img v-else src="../../assets/svg/arrow-prev.svg" class="cursor-pointer" @click="previousPage"/>

        <span class="w-20 text-center">{{ currentPage }}/{{ totalPages }}</span>

        <img v-if="currentPage === totalPages" src="../../assets/svg/BACK_ARROW_GREEN.svg" class="cursor-pointer" style="width: 55px; transform: rotate(180deg);"/>
        <img v-else src="../../assets/svg/arrow-next.svg" class="cursor-pointer" @click="nextPage"/>

      </div>




    </div>

    
  </div>
</template>

<script>
import ArrowShapedNav from "@/components/arrow-filters/ArrowShapedNav";
import FastenersList from "@/components/arrow-filters/type/FastenersSection";
import HeadType from "@/components/arrow-filters/type/HeadType";
import MaterialSection from "@/components/arrow-filters/type/MaterialSection";

export default {
  name: "TypeBrowseSection",
  components: {MaterialSection, HeadType, FastenersList, ArrowShapedNav},
  provide() {
    return {
      initPaginator: this.initPaginator,
      showPaginator: this.showPaginator,
      hidePaginator: this.hidePaginator,
    }
  },
  inject: [
    'setCategoryFilter',
    'unsetCategoryFilter',
    'setMaterialFilter',
    'unsetMaterialFilter',
    'setFocusedFilter'
  ],
  props: {
    activeFilters: {
      type: Object,
      required: false,
      default: function() {
        return {
          category: null,
          headType: null,
          material: null,
          focus: 'category'
        };
      },
    }
  },
  data() {
    return {
      leftOffset: 15,
      // selectedTypeCategory: 'category',
      currentPage: 1,
      totalPages: 1,
      paginatorIsVisible: false
    }
  },
  computed: {
    categoryFilterIsActive() {
      return this.activeFilters.category !== null;
    },
  },
  methods: {
    setSelectedTypeCategory(value) {
      // this.selectedTypeCategory = value;
      this.setFocusedFilter(value);
    },
    initPaginator(payload) {
      // console.log('initPaginator called with payload', payload);
      this.currentPage = payload.currentPage;
      this.totalPages = payload.totalPages;
    },
    showPaginator() {
      // console.log('showpaginator called');
      this.paginatorIsVisible = true;
    },
    hidePaginator() {
      // console.log('hidepaginator called');
      this.paginatorIsVisible = false;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    loadCategories() {
      this.$refs['fasteners-list'].loadCategories();
    },
    loadMaterials() {
      this.$refs['material-section'].loadMaterials();
    },
    // focusFilter(focus) {
    //   this.selectedTypeCategory = focus;
    // },
  },
  created() {
    // console.log(this.activeFilters);
  }
}
</script>

<style scoped>

.gp-type-browse-section {
    margin-left:-65px;
  }

@media screen and (max-width:1600px) {
  .gp-type-browse-section {
    margin-left:0px;
  }
}




</style>
