<template> 
  <div class="pl-4-- sm:hidden manrope gp-pl-15">
    <router-link class="flex items-center mb-2 font-semibold-- gp-sm-txt-14 text-medium" to="/account">
      <img class="mr-3" src="../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.account') }}
    </router-link>
    <div class="text-2xl-- text-24 text-regular manrope">{{ $store.getters['auth/userName'] }},</div>
  </div>

  <div class="pl-4-- sm:pl-4-- mb-8 text-2xl-- gp-title gp-mb-40">
    {{ $t('labels.rewards') }}
  </div>

  <div v-if="rewardsLoaded && accountDataLoaded" class="sm:hidden border-1 border-21 p-3 pt-20 pr-12 bg-darkbeige rounded-lg manrope font-semibold-- text-16 text-regular mb-4">
    {{ $t('messages.you-have-purchased-x-fasteners-or-y-boxes', {f: pieces, b: packages}) }}
  </div>

  <div v-if="rewardsLoaded && accountDataLoaded" class="sm:hidden border-1 border-21 p-3 pt-20 pr-12 bg-darkbeige rounded-lg manrope font-semibold-- text-16 text-regular mb-4">
    {{ $t('messages.you-have-x-virtual-fasteners', {vf: virtualFasteners}) }}
  </div>

  <!-- [Mobile] rewards slider -->
  <custom-slider
      v-if="rewardsLoaded && accountDataLoaded"
      ref="rewardBuildingsSlider"
      class="reward-buildings-slider sm:hidden"
      style="margin-bottom:150px"
      :slide-count="buildings.length"
      :slide-width="233"
      :slide-height="226"
      :debug="false"
      :scrollbar-styling="{'padding-left': '0', 'padding-right': '0', 'margin-top': '1rem'}"
  >
    <div
        v-for="(building, index) in buildings"
        :key="index"
        :ref="'building-'+index"
        class="slide building-slide bg-darkbeige border-1 border-21 p-4 flex flex-col border-l-0-f"
        :class="{locked: building.locked}"
    >
      <div class="flex justify-center items-center flex-grow">
        <img :src="building.img_url" style="height: 100px; width: auto;" :alt="building.text"/>
      </div>
      <div class="building-info text-sm manrope font-semibold-- text-12 text-regular">
        {{ building.text }}
      </div>
    </div>
  </custom-slider>

  <!-- [Desktop] rewards slider -->
  <div v-if="rewardsLoaded && accountDataLoaded" id="desktop-rewards" class="hidden sm:flex overflow-x-auto-- mb-32" style="height: 537px;">
    <div id="info-tiles-container" class="flex flex-col mr-2">
      <div style="width: 347px;" class="flex-grow flex items-end border-1 border-21 p-3 bg-darkbeige rounded-lg manrope font-semibold-- mb-1 gp-dt-infobox-1 ">
        {{ $t('messages.you-have-purchased-x-fasteners-or-y-boxes', {f: pieces, b: packages}) }}
      </div>
      <div style="width: 347px;" class="flex-grow flex items-end border-1 border-21 p-3 bg-darkbeige rounded-lg manrope font-semibold-- mt-1 gp-dt-infobox-2">
        {{ $t('messages.you-have-x-virtual-fasteners', {vf: virtualFasteners}) }}
      </div>
    </div>
    <div class="flex">
      <div
          v-for="(building, index) in buildings"
          :key="index"
          :ref="'building-'+index"
          class="--slide building-slide bg-darkbeige border-1 border-21 p-4 flex flex-col border-l-0-f gp-building-box"
          :class="{locked: building.locked}"
          style="width: 567px;"
      >
        <div class="flex justify-center items-center flex-grow">
          <img :src="building.img_url" style="height: 322px; width: auto;" :alt="building.text"/>
        </div>
        <div class="building-info text-sm-- manrope font-semibold--">
          {{ building.text }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CustomSlider from "@/components/custom-slider/CustomSlider";
export default {
  name: "MyRewards",
  components: {CustomSlider},
  inject: ['openDialog', 'prettyNum'],
  data() {
    return {
      mockBuildings: [
        {
          text: 'Empire State Building was built with 500,000 fasteners',
          img_url: 'https://tsamouris.gr/tws2/Images/ProductPics/Thumb/6885-DIN-parallel-key.png',
          locked: false,
        },
        {
          text: 'Empire State Building was built with 500,000 fasteners',
          img_url: 'https://tsamouris.gr/tws2/Images/ProductPics/Thumb/6885-DIN-parallel-key.png',
          locked: true,
        },
        {
          text: 'Empire State Building was built with 500,000 fasteners',
          img_url: 'https://tsamouris.gr/tws2/Images/ProductPics/Thumb/6885-DIN-parallel-key.png',
          locked: true,
        },
        {
          text: 'Empire State Building was built with 500,000 fasteners',
          img_url: 'https://tsamouris.gr/tws2/Images/ProductPics/Thumb/6885-DIN-parallel-key.png',
          locked: true,
        },
        {
          text: 'Empire State Building was built with 500,000 fasteners',
          img_url: 'https://tsamouris.gr/tws2/Images/ProductPics/Thumb/6885-DIN-parallel-key.png',
          locked: true,
        }
      ]
    }
  },
  computed: {
    accountDataLoaded() {
      return this.$store.getters['account/accountDataLoaded'];
    },
    rewardsLoaded() {
      return this.$store.getters['account/rewardsLoaded'];
    },
    buildings() {
      return this.$store.getters['account/rewards'].buildings;
    },
    packages() {
      return Math.round(this.$store.getters['account/rewards'].rewards_total_packages);
    },
    pieces() {
      return Math.round(this.$store.getters['account/rewards'].rewards_total_pcs);
    },
    virtualFasteners() {
      return Math.round(this.$store.getters['account/accountDetails'].virtual_fasteners);
    },
  },
  watch: {
    //
  },
  methods: {
    async loadRewards() {
      try {
        await this.$store.dispatch('account/loadAccountData');

        // console.log(this.rewards);

        this.$refs['rewardBuildingsSlider'].setElements();
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
  },
  created() {
    this.loadRewards();
  }
}
</script>

<style scoped>
.slide {
  float:left;
  width: 233px;
  height: 226px;
}

.building-slide.locked {
  background-color: #E5E4D9;
}

.building-slide.locked > div > img {
  opacity: 0.41;
}

.building-slide.locked > .building-info {
  color: #262626;
  opacity: 0.25;
}

.building-slide:first-child {
  border-left: 1px solid #212121 !important;
}


.gp-title {
  font-size:28px;
  padding-left:30px;
}

#desktop-rewards {
  overflow-x: visible;
}




/* Mobile */
@media screen and (max-width:800px) {
  .gp-title {
    font-size:24px;
    padding-left:15px;
  }
}

.slider-item:hover, .slider-item.selected {
  background-color: #F0EFE2;
}

#desktop-rewards::-webkit-scrollbar {
  display: none;
}

#desktop-rewards {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gp-dt-infobox-1,
.gp-dt-infobox-2,
.gp-building-box {
  font-size:19px;
  font-weight:400;
  padding:20px;
}

</style>