<template>
  <div class="flex" style="position:relative">
    
    <div class="logo-kg" style="">
      <router-link class="logo-link--" to="/" style="">
        <!-- <img src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/> -->
        <img v-if="$store.getters['app/locale'] === 'el'" src="../../assets/svg/logo-gr.svg" class="gp-logo" style=""/>
        <img v-if="$store.getters['app/locale'] === 'en'" src="../../assets/svg/logo-en.svg" class="gp-logo" style=""/>
      </router-link>
    </div>

    <div class="close_windows_btn" style="">
      <router-link class="logo-link--" to="/" style="">close window</router-link>
    </div>

    <div class="logo-container-abs" style="">
      <router-link class="" to="/">
          <img src="../../assets/svg/logo-en.svg" class="gp-logo"/>
        </router-link>
    </div>
    
    <div class="flex flex-1 flex-col items-center justify-center">
      <!-- Logo -->
      <div class="hidden sm:flex self-start justify-center logo-container w-48 sm:w-80">
        <!--<router-link class="" to="/">
          <img src="../../assets/svg/logo-en.svg" class="gp-logo"/>
        </router-link>-->
      </div>

      <div class="flex flex-col justify-center w-full h-full mx-auto sm:mr-auto mb-64 
        sm:pl-24-- sm:pr-8 gp-login-container">
        <div class="itms-cntr flex items-baseline max-w-md-xx hdr-mobile" 
          style="padding-left: 33px; margin-bottom: 45px; margin-top: 110px; position:relative; max-width:560px">
          <h1 id="header" class="flex-grow text-33 gp-sm-txt-24 text-semibold">
            {{ $t('labels.sign-in') }}
          </h1>
          <h1 id="step-counter" class="flex-shrink font-semibold text-lg">
            {{ step }}/2
          </h1>
        </div>

        <form action="/login" method="post" class="max-w-md" @submit.prevent="login" style="margin-bottom: 25px;">
          <div class="form-control">
            <input v-if="firstStep" v-model="email" type="text" 
            class="font-semibold-- text-21 border-21 border-b-1 text-15 text-medium" 
            @blur="clearError" placeholder="email address"/>
            <input v-if="secondStep" v-model="password" type="password" class="font-semibold text-21 border-21 border-b-0 manrope text-15 text-medium" @blur="clearError" placeholder="Password"/>
            <!--<div v-show="secondStep" class="flex justify-between grid grid-cols-4">
              <input @keyup="focusDigit(2)" v-model="digit1" class="flex-1" type="text" name="digit-1" ref="d1" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
              <input @keyup="focusDigit(3)" v-model="digit2" class="flex-1" type="text" name="digit-2" ref="d2" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
              <input @keyup="focusDigit(4)" v-model="digit3" class="flex-1" type="text" name="digit-3" ref="d3" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
              <input                              v-model="digit4" class="flex-1" type="text" name="digit-4" ref="d4" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
            </div>-->
            <button v-if="firstStep" type="button" class="text-15 text-medium" @click="moveToStep2">
              {{ $t('labels.continue') }}
            </button>
            <button v-else type="submit" class="text-15 text-medium">
              {{ $t('labels.continue') }}
            </button>
          </div>

          <p v-if="error" class="pt-4 text-red-500 font-semibold mb-0" style="margin-left: 33px;">{{ error }}</p>
        </form>

        <p v-if="firstStep || secondStep" class="mb-2 text-15 gp-sm-txt-13 text-medium text-15-mobile" style="margin-left: 33px;">
          {{ $t('messages.sign-into-your-account') }}
        </p>

        <p v-if="firstStep || secondStep" class="text-15 gp-sm-txt-13 text-medium text-15-mobile" style="margin-left: 33px;">
          <router-link to="/register" class="text-21 underline">
            {{ $t('labels.not-registered') }}
          </router-link>
        </p>

<!--        <p v-if="secondStep" class="ml-4 manrope">-->
<!--          {{ $t('labels.enter-code-from-email') }}-->
<!--        </p>-->

        <p v-if="secondStep" class="ml-4-xx absolute bottom-10-- manrope cursor-pointer text-15 gp-sm-txt-13 text-medium rtrn-back link-back" style="bottom:80px" @click="backToFirstStep">
          {{ $t('labels.return-back') }}
        </p>

        

      </div>
    </div>

    <div id="login-right-panel" class="hidden sm:flex flex-1 min-h-screen"></div>
  </div>

  

</template>

<script>
export default {
  name: "SignIn",
  inject: ['enterDesktopFullscreen', 'exitDesktopFullscreen', 'openDialog'],
  /*inject: ['enterFullscreen', 'exitFullscreen', 'openDialog'],*/
  data() {
    return {
      step: 1,
      mode: 'code-request', // 'login-request'
      email: null,
      password: null,
      error: null,
      focusedDigit: 1,
      digit1: null,
      digit2: null,
      digit3: null,
      digit4: null,
    };
  },
  computed: {
    firstStep() {
      return this.step === 1;
    },
    secondStep() {
      return this.step === 2;
    },
    /*pin() {
      return this.digit1 + this.digit2 + this.digit3 + this.digit4;
    }*/
  },
  methods: {
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    moveToStep2() {
      if (!this.email) {
        this.error = 'Email must be filled';
        return;
      } else if (!this.validEmail(this.email)) {
        this.error = 'Email is not valid';

        return
      }

      this.step = 2
    },
    /*nextStep() {
      if (this.firstStep) {
        this.requestCode();
      } else {
        this.login();
      }
    },*/
    requestCode() {
      if (!this.email) {
        this.openDialog('Email must be filled');

        return;
      } else if (!this.validEmail(this.email)) {
        this.openDialog('Email is not valid');

        return
      }

      this.mode = 'login-request';

      this.$nextTick(() => {
        this.$refs['d1'].focus();
      });
    },
    async login() {
      if (!this.validateLoginForm()) {
        return;
      }

      try {
        // console.log('calling the auth/login store function');

        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        });

        // console.log('returning from the auth/login store function');

        if (this.$store.getters['auth/auth']) {
          // load cart items & favourites
          if (localStorage.getItem('role')=="customer") {
            this.$store.dispatch('cart/loadItems');
            this.$store.dispatch('categories/loadFavourites');
          }

          // console.log('this.$store.getters[\'auth/auth\'] is truthy, redirecting....');
          
          //const redirectUrl = this.$route.query.redirect ?? '/';
          //this.$router.replace(redirectUrl);
          
          console.log(localStorage.getItem('role')); //xxx

          //const redirectUrl = "";
          if (localStorage.getItem('role')=="seller") {
            this.$router.replace({ path: '/seller' });  
            
          }
          else {
            const redirectUrl = this.$route.query.redirect ?? '/';
            this.$router.replace(redirectUrl);  
          }
                  

        } else {
          //console.log('error-001');
          if (localStorage.getItem('role')=="seller") {
            //this.$router.replace({ path: '/seller' });  
            window.location.href = "/seller";
            
          }
          else {
            this.error = this.$t('messages.auth-failed');
          }
          //this.error = this.$t('messages.auth-failed');
        }
      } catch (err) {
        console.log('error-002');
        this.error = this.$t(err.message) || 'Failed to authenticate, try later.';
      }

      // if (!this.digit1 || !this.digit2 || !this.digit3 || !this.digit4) {
      //   this.openDialog('pin should be filled');
      // }
    },
    validateLoginForm() {
      if (!this.email) {
        this.error = 'Email is empty';

        return false;
      } else if (!this.validEmail(this.email)) {
        this.error = 'Email is not valid';

        return
      } else if (!this.password) {
        this.error = 'Password is empty';
        return
      }

      this.clearError();

      return true;
    },
    clearError() {
      this.error = '';
    },
    backToFirstStep() {
      // this.mode = 'code-request';
      this.step = 1;
    },
    focusDigit(digit) {
      this.$nextTick(() => {
        this.$refs['d'+digit].focus();
      });
    },
    focusNextDigit() {
      this.$refs['d'+(this.focusedDigit + 1)].focus();
    }
  },
  created() {
    // todo: delete
    if (this.$store.getters['app/dev']) {
      this.email = 'info@tsamouris.gr';
      this.password = 'C.11111#ts';

      // this.openDialog('test');
    }
  },
  beforeMount() {
    this.enterDesktopFullscreen();
    //this.enterFullscreen();
  },
  beforeUnmount() {
    this.exitDesktopFullscreen();
    //this.exitFullscreen();
  },
}
</script>

<style scoped>
  
  
  
  h1.header {
    font-size : 24px;
    line-height : 53.88px;
    letter-spacing : -0.24px;
  }

  h1#step-counter {
    font-size : 15px;
    line-height : 16px;
    color : #212121;
    position: absolute;
    top: 23px;
    right: 0px;
  }

  .form-control {
    border: 1px solid;
    display: flex;
    flex-direction: column;
    border-radius:14px;
    background-color: #212121;
  }

  .form-control > input {
    display: block;
    background-color: transparent;
    color: #212121;
    /*text-align: center;*/
    /*padding: 1.25rem 2rem;*/
    outline: none;
    font-weight: 500;
    text-align:left;
    /*padding-left:15px;*/
    border-radius: 12px;
    background: #fff;
    border: none;
    padding:15px;
    padding-left:33px;
  }

  .form-control > button {
    display: block;
    background-color: #212121;
    color: #fff;
    text-align: center;
    /*padding: 1.25rem 2rem;*/
    font-weight: 500;
    border-radius: 0px 0px 12px 12px;
    padding:15px;
  }

  .form-control > * >  input {
    font-family: 'PolySansTrial Neutral', sans-serif;
    font-size: 3rem;
    height: 4rem;
    background-color: #D8D7CD;
    color: #212121;
    /*text-align: center;*/
    /*padding: 1rem 2rem;*/
    text-align:left;
    padding-left:15px;
    outline: none;
    font-weight: 500;
    border-right: 1px solid #212121;
  }

  .form-control > * >  input:last-child {
    border-right: 0;
  }

  div#login-right-panel {
    background: url('../../assets/images/trucks-1-lg.jpg') no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    border-radius: 90px 0px 0px 90px;
  }
  @media (max-width:1400px) {
    div#login-right-panel {
      border-radius: 60px 0px 0px 60px;
    }
  }

  .logo-container {
    padding-left:96px; padding-top:64px;
  }

  @media (max-width:1400px) {
    .logo-container {
      padding-left:5px; 
      padding-top:37px;
    }
  }

  @media (max-width:1200px) {
    .logo-container {
      padding-left:5px; 
      padding-top:48px;
    }
  }

  

  .gp-login-container {
    padding-left:110px;
  }

  @media (max-width:1400px) {
  .gp-login-container {
    padding-left:88px;
  }  
  }
  @media (max-width:1200px) {
  .gp-login-container {
    padding-left:50px;
  }  
  }

  @media (max-width:799px) {
  .gp-login-container {
    padding-left:0px;
  }  
  }



  /** */
  .logo-container-abs {
    position:absolute; 
    top:64px; 
    left:96px;
    width:225px;
    /*z-index:9999;*/
  }
  @media (max-width:1600px) {
    .logo-container-abs {
      top:42px; 
      left:96px
    }
  }
  @media (max-width:1400px) {
    .logo-container-abs {
      top:37px; 
      left:50px
    }
  }
  @media (max-width:1300px) {
    .logo-container-abs {
      top:36px; 
      left:50px;
      width:226px;
    }
    .gp-logo {
      width:226px;
    }
  }
  @media (max-width:1200px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

/** ipad 810*1080 - Landscape */
  @media (width:1080px) {
    .logo-container-abs {
      top:42px; 
      left:50px;
      width:210px;
    }
    .gp-logo {
      width:211px;
    }
  }

  @media (max-width:1079px) {
    .logo-container-abs {
      top:47px; 
      left:50px;
      width:183.5px;
    }
    .gp-logo {
      width:183.5px;
    }
  }

  @media (max-width:1000px) and (min-width:811px) {
    .logo-container-abs {
      top:30px; 
      left:49px;
      width:201px;
    }
    .gp-logo {
      width:201px;
    }
  }
  /** ipad 810*1080 Portrait */
  @media (width:810px) {
    .logo-container-abs {
      top:23px; 
      left:50px;
      width:200px;
    }
    .gp-logo {
      width:202px;
    }
  }
  

  @media (max-width:809px) {
    .logo-container-abs {
      top:60px; 
      left:20px;
      width:197px;
    }
    .gp-logo {
      width:197px;
    }    
  }

  @media (max-width:799px) {
    .logo-container-abs {
      display:none;
    }    
  }

.close_windows_btn { 
  position:absolute; bottom:50px;left:140px; text-decoration:underline; 
}
@media (max-width:1400px) {
  .close_windows_btn { 
    left:120px; 
  }
}

.link-back {
  margin-left:33px;
  text-decoration: underline;
  bottom:85px;
}



/** GG */ 

  @media (max-width:1200px) {
    .close_windows_btn {
      left: 7%;
    }
  }
  @media (max-width:820px) {
    .close_windows_btn {
      left: 10%;
      bottom: 80px;
    }
  }

  @media (max-width:420px) {
    .itms-cntr{justify-content: center !important; align-items: center;}
    #step-counter{top:unset !important;}
    .close_windows_btn {
      bottom: -80px;
      right: 0;
      left:unset;
      font-size: 13px;
    }
    .text-15 {
      font-size: 13px;
    }
    .form-control > input {font-size:15px; padding-left:16px;}
    .form-control > button {font-size:15px;}
    .text-15-mobile {margin-left:16px !important;}
    .hdr-mobile {padding-left:16px !important; margin-top: 55px !important; margin-bottom:22px !important;}
    .rtrn-back {bottom: -80px !important;}
  }

  @media (max-width: 380px) {
    .close_windows_btn {
        bottom: 100px;
    }
    .rtrn-back {
      bottom: 100px !important;
  }
  }
</style>