<template>
  <arrow-filters style="margin-bottom: 60px;"/>
  <cms-submenu :activeItem="activeItem" />
  <div class="" v-html="liveHtml"/>
</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";
import CmsSubmenu from "@/components/layout/CmsSubmenu";

export default {
  name: "RemoteContentGovernance",
  components: {ArrowFilters, CmsSubmenu},
  data() {
    return {
      html: '',
      activeItem: 'About'
    }
  },
  computed: {
    liveHtml() {
      return this.html;
    },
    /*queryParam1() {
      return this.$route.query.queryParam1;
    },
    queryParam2() {
      return this.$route.query.queryParam1;
    }*/
  },
  async created() {
    let my_lang = this.$store.getters['app/locale'];
    let response = await this.$store.getters['app/axios'].get('remote-content-governance.php?lang=' + my_lang);
    this.html = response.data;
  }
}
</script>

<style scoped>

</style>