<template>
  <div class="mt-32 max-w-930 --text-center mx-auto gp-content text-lg" style="min-height: calc(100vh - 100px)">
    
    <span v-if="$store.getters['app/locale'] === 'el'">Η αίτησή σας για προσφορά έχει παραληφθεί. <br/>
    Θα επικοινωνήσουμε σύντομα μαζί σας.</span>

    <span v-else>Your request for quotation has been received. <br/>
    You will hear from us shortly.</span>


  </div>
</template>

<script>
export default {
  name: 'RfqSuccess',
  components: {
    //
  },
  inject: ['exitDesktopFullscreen', 'openDialog'],
  data() {
    return {

    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  },
};
</script>

<style scoped>

  .gp-content {
    max-width:930px;
  }

  @media (max-width:1600px) {
    .gp-content {
      max-width:670px;
      padding-left:0px;
    }
  }

  @media (max-width:1400px) {
    .gp-content {
      max-width:635px;
      padding-left:7px;
    }
  }

  @media (max-width:1300px) {
    .gp-content {
      max-width:610px;
      padding-left:15px;
    }
  }

</style>

