<template>
<!--  <iframe-->
<!--      class="pt-16 m-auto" width="400" height="300"-->
<!--      v-html="this.link"-->
<!--  />-->
  <div class="pt-16-- m-auto-- model3d-container" v-html="link"></div>
</template>

<script>
export default {
  name: "SketchFab3dViewer",
  props: {
    link: {
      type: String,
      required: true,
    }
  },
}
</script>

<style scoped>

.model3d-container {
  padding-top:64px;
  margin:auto;
  height:100%;
}

@media (max-width:1023px) {
.model3d-container {
  padding-top:20px;
  margin:0px 0px 20px;
}  
}


</style>

<style>

.sketchfab-embed-wrapper {
  position:sticky;
  position: -webkit-sticky;
  top:64px;
}

@media screen and (max-width:440px) {
    .sketchfab-embed-wrapper iframe {
        height:313px !important;
      }
}
</style>