<template>
  <div class="gp-container">
    <!-- mobile only -->
    <div class="sm:hidden pl-4 sm:pl-8 mb-1">
      <router-link class="flex items-center mb-2 manrope gp-sm-txt-14 text-medium" to="/account">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.account') }}
      </router-link>
      <div class="text-2xl-- text-24 text-regular manrope">{{ $store.getters['auth/userName'] }},</div>
    </div>

    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.transactions') }}
    </div>

    <account-navigation-menu :menu-items="menuItems" :prefix="routePrefix"/>

  </div>
</template>

<script>
import AccountNavigationMenu from "@/components/misc/AccountNavigationMenu";

export default {
  name: "TransactionsNav",
  components: {AccountNavigationMenu},
  data() {
    return {
      menuItems: [
        {
          href: '/invoices',
          label: this.$t('labels.orders-and-invoices'),
        },
        {
          href: '/list',
          label: this.$t('labels.transactions'),
        },
      ],
      routePrefix: '/account/transactions',
    }
  },
  computed: {
    // currentRoutePath() {
    //   return this.$route.path;
    // }
  }
}
</script>

<style scoped>

.gp-title {
    padding-left:30px;
    font-size:28px;
    margin-bottom:48px;
  }

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}

</style>