<template>

    <arrow-filters style="margin-bottom: 0px;"/>

    <div class="seller-container" style="">
        <h1 style="font-size:30px; margin-bottom:20px; padding-left:15px">My Clients</h1>

        <div style="padding:0px 0px 30px 15px">
            Search: <input type="text" v-model="activeFilters.customercode"
            placeholder="by name, code or vat nr" 
            style="padding:10px; border:1px solid #333; border-radius:10px; width:250px" />
        </div>

        <div class="clients-grid" style="">
            <div v-for="(item) in clients" 
                :key="item.id" 
                @click="loadCustomer(item.id)"
                class="client-container">
            <span style="display:block; font-size:12px">{{item.customer_code }}</span>
            <span style="display:block;">{{item.customerName }}</span>
            <span style="display:block; font-size:12px">{{item.afm }}</span>
            </div>
        </div>

        <div style="padding:50px 15px">
            <a class="hover:underline cursor-pointer" @click="logout" style="padding:10px; background:#000; color:#fff; border-radius:10px">
            Logout
            </a>
        </div>

        <div v-if="authSeller" class="mobile-only" style="padding-left:15px">
            <input type="checkbox" v-model="isSalesOffer" /> Προσφορά
        </div>

    </div>
</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
    name: "Seller",
    components: {ArrowFilters},
    inject: ['openDialog', 'prettyNum', 'isMobile'],
    data() {
        return {
            activeFilters: {
                customercode: null,
                
            },
            isSalesOffer: false,
        }
    },
    computed: {
        clients() {
            return this.$store.getters['account/clients'];
        },
        clientsLoaded() {
            return this.$store.getters['account/clientsLoaded'];
        },
        authSeller() {
            console.log("sellertoken=" + localStorage.getItem('sellertoken'));
            return localStorage.getItem('sellertoken')===null? false: localStorage.getItem('sellertoken').length>1;
        },
    },
    methods: {
        async loadClients() {
            if (this.activeFilters.customercode.length<3) {
                return;
            }
            try {
                await this.$store.dispatch('account/loadClients', this.activeFilters);
            } catch (error) {
                this.openDialog({
                class: 'danger',
                html: error.message
                });
            }
        },
        async loadCustomer(customer_id) {            
            try {
                await this.$store.dispatch('auth/sellerCustomer', customer_id);
                window.location.href = "/account";
                //console.log('redirect??');
            } catch (error) {
                this.openDialog({
                class: 'danger',
                html: error.message
                });
            }            
        },
        logout() {
            this.$store.dispatch('auth/logout');
            window.location.replace('/');
        },
        
    },
    mounted() {
        // Retrieve initial state from localStorage on component mount
        const storedValue = localStorage.getItem('isSalesOffer');
        if (storedValue !== null) {
        this.isSalesOffer = storedValue === '1';
        }
    },
    watch: {
        activeFilters: {
            handler() {
                this.loadClients()
            },
            deep: true,
        },
        isSalesOffer(newValue) {
            localStorage.setItem('isSalesOffer', newValue ? '1' : '0');
        },
    },
    created() {
        this.loadClients();
        //console.log(localStorage.getItem('sellertoken'));
    }
}


</script>


<style scoped>

.client-container {
    padding:10px;
    margin:5px;
    border:1px solid #333;
    border-radius:10px;
    cursor:pointer;
}
.client-container:hover {
    background-color: black;
    color:#fff;
}

.seller-container {
    padding:100px 480px ;
}

.clients-grid {
    display: grid; grid-template-columns: auto auto auto;
}

@media screen and (max-width:1400px) {
    .seller-container {
        padding:60px 340px 60px 370px ;
    }

    .clients-grid {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width:1300px) {
    .seller-container {
        padding:60px 320px 60px 350px ;
    }
}

@media screen and (max-width:1200px) {
    .seller-container {
        padding:60px 240px 60px 270px ;
    }
}

@media screen and (max-width:1000px) {
    .seller-container {
        padding:60px 130px 60px 160px ;
    }
}

.mobile-only {
    display:none;
}
@media screen and (max-width:800px) {
    .seller-container {
        padding:40px 20px ;
    }
    .clients-grid {
        grid-template-columns: auto;
    }
    .mobile-only {
        display:block;
    }
}

</style>