<template>
  
  <div style="position:relative">
    <input v-model="searchDim2"
      class="searchDim2-input"
      style="" 
      @keydown.enter="handleDim2Seacrch(searchDim2)"
      :placeholder="$t('labels.type-dimension2-here')"
       />

    <span style="position:absolute; top:-40px;left:620px; cursor:pointer"
      @click="handleDim2Seacrch(searchDim2)">
      <img src="../../../assets/svg/white-lens.svg" />
    </span>

  </div>
  
  
  
  <div v-if="dimensionsLoaded">
    <!--    {{ gridTilesCount }}-->
    <!--    {{ restTilesCount }}-->
    <div class="page" v-for="page in totalPages" :id="'page-'+page" :key="page">
      <div v-show="page === currentPage">
        <!-- Grid tiles -->
        <p style="padding:10px; margin-bottom:5px; font-size:15px; text-align:center">{{ $t('labels.choose-dimension2-here') }}</p>
        <div class="grid grid-cols-8 gap-2 text-center mx-auto mb-4" >
          <dimension-tile
              v-for="dimension in gridTiles(page)"
              :key="dimension.slug"
              :dimension="dimension"
              class="col-auto"
              :class="{selected: selectedDimension2Id === dimension.slug}"
              @click="handleTileClick(dimension)"
          ></dimension-tile>
        </div>

        <!-- Rest tiles (centered) -->
        <div class="flex justify-center gap-4 text-center mx-auto" v-if="dimensionsLoaded && page === totalPages && restTilesCount > 0">
          <dimension-tile
              v-for="dimension in restTiles"
              :key="dimension.slug"
              :dimension="dimension"
              class="col-auto"
              :class="{selected: selectedDimension2Id === dimension.slug}"
              @click="handleTileClick(dimension)"
          ></dimension-tile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DimensionTile from "@/components/arrow-filters/metrics/DimensionTile";
export default {
  name: "Dimension2Section",
  components: {DimensionTile},
  inject: [
    'setD2metricFilter',
    'unsetD2metricFilter',
    'openDialog',
    'initPaginator',
    'showPaginator',
    'hidePaginator',
    'selectedDin',
  ],
  props: {
    selectedCategoryId: {
      type: String,
      required: false,
      default: null,
    },
    selectedD2metricIdProp: {
      type: String,
      required: false,
      default: null,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    currentPageProp: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  data() {
    return {
      gridCols: 8,
      blocksize: 64,
      selectedDimension2Id: null,
      searchDim2: null,
    }
  },
  computed: {
    dimensionsLoaded() {
      return this.$store.getters['filters/d2metricsLoaded'];
    },
    dimensions() {
      return this.$store.getters['filters/d2metrics'];
    },
    restTilesCount() {
      return this.dimensions.length % this.gridCols;
    },
    gridTilesCount() {
      return this.dimensions.length - this.restTilesCount;
    },
    lastPageGridTilesCount() {
      return this.dimensions.length % this.blocksize - this.restTilesCount;
    },
    // gridTiles() {
    //   return this.dimensions.slice(0, this.gridTilesCount);
    // },
    restTiles() {
      return this.dimensions.slice(-this.restTilesCount);
    },
    totalPages() {
      return Math.ceil(this.dimensions.length/this.blocksize);
    }
  },
  watch: {
    selectedD2metricIdProp(newVal) {
      this.selectedDimension2Id = newVal;
    },
    dim2(newVal) {
      this.searchDim2 = newVal;
    },
  },
  methods: {
    async loadDimensions() {
      const self = this;

      try {
        await this.$store.dispatch('filters/loadD2Metrics', {
          category_id: this.selectedCategoryId,
          din: this.selectedDin()
        });

        if (this.totalPages > 1) {
          this.initPaginator({
            currentPage: this.currentPageProp,
            totalPages: this.totalPages
          });

          this.showPaginator();
        }
        else {
          this.hidePaginator();
        }
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    handleTileClick(dimension) {
      dimension.id = dimension.slug;

      if (this.selectedDimension2Id == dimension.id) {
        this.selectedDimension2Id = null;
        this.unsetD2metricFilter;
      } else {
        this.selectedDimension2Id = dimension.id;
        this.setD2metricFilter(dimension, this.currentPage);
      }

      this.searchDim2 = dimension.id;

    },
    handleDim2Seacrch(searchDim2) {
      const myDim2 = {};
      myDim2.id = searchDim2;
      myDim2.slug = searchDim2;
      myDim2.description = searchDim2;
      this.setD2metricFilter(myDim2, this.currentPage);
    },
    gridTiles(page) {
      if (page < this.totalPages) {
        // console.log('page < this.totalPages', page, this.totalPages);
        // console.warn((page - 1) * this.blocksize, page * this.blocksize);
        return this.dimensions.slice((page - 1) * this.blocksize, page * this.blocksize);
      } else {
        // console.log('page >= this.totalPages', page, this.totalPages);
        // console.warn((page - 1) * this.blocksize, (page - 1) * this.blocksize + this.gridTilesCount);
        return this.dimensions.slice((page - 1) * this.blocksize, (page-1) * this.blocksize + this.lastPageGridTilesCount);
      }
    },
  },
  created() {
    this.loadDimensions();

    this.selectedDimension2Id = this.selectedD2metricIdProp;

    this.searchDim2 = this.selectedD2metricIdProp;
  }
}
</script>

<style scoped>

.searchDim2-input {
  position:absolute; 
  top:-50px;
  left:405px;   
  padding:5px 15px;    
  text-align:left; 
  border:none;
  background-color: transparent;
  background-image:url('../../../assets/svg/input-bg.svg');
  outline: none;
  width:259px;
  height:44px;
}

/*position:absolute; top:-50px;left:440px; padding:5px; width:210px; border-radius:5px; text-align:center; border:1px solid #333; background:#fffef2*/

</style>
