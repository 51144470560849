<template>
  <!-- desktop filters -->
  <arrow-filters ref="filters" @broadcast-filters="search" style="margin-bottom: 68px;"/>

  <!-- mobile filters -->
  <!--<mobile-filters></mobile-filters>-->

  <!-- debug -->
  <!--{{ activeFilters }}-->

  <!-- [Mobile] Active Filters -->
  <div class="sm:hidden w-full manrope mb-16" style="margin-top:105px" v-if="$refs.filters && $refs.filters.filterIsActive">
    <!-- Header -->
    <div class="flex mb-6 items-baseline">
      <div class="w-1/2 text-2xl-- text-24 text-regular">
        {{ $refs.filters.filterCount }} {{$t('labels.filters')}}
      </div>
      <div class="w-1/2 text-sm text-right">
        <!--<span class="border-b-1 cursor-pointer font-semibold-- text-medium" @click="$refs.filters.clearAllFilters">
          {{ $t('labels.clear-all')}}
        </span>-->
        <a href="filters" class="text-medium" style="text-decoration: underline;">
          New search
        </a>
      </div>
    </div>

    <!-- Filter Labels -->
    <div v-if="!showMobileFilters" class="bg-21 text-white rounded-lg p-2 text-center cursor-pointer"
         @click="showMobileFilters = true"
    >
      {{$t('labels.selected-filters')}}
    </div>
    <div v-else class="w-full rounded-lg border-1 border-21 grid grid-cols-2 p-2 gap-2 gp-sm-txt-14 text-lineheight-18" style="max-width: 932px;">
      <div class="col-span-1 text-sm--  text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
           v-if="$refs.filters.dinFilterIsActive"
           @click="$refs.filters.unsetDinFilter"
      >
        <div class="flex items-center h-full">
          <span class="flex-grow">Din: {{ $refs.filters.activeFilters.din.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
        </div>
      </div>
      <div class="col-span-1 text-sm-- text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
           v-if="$refs.filters.categoryFilterIsActive"
           @click="$refs.filters.unsetCategoryFilter"
      >
        <div class="flex items-center h-full">
          <span class="flex-grow">Category: {{ $refs.filters.activeFilters.category.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
        </div>
      </div>

      <div class="col-span-1 text-sm-- text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
           v-if="$refs.filters.materialFilterIsActive"
           @click="$refs.filters.unsetMaterialFilter"
      >
        <div class="flex items-center h-full">
          <span class="flex-grow">Υλικό: {{ $refs.filters.activeFilters.material.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']"/>
        </div>
      </div>
      <div class="col-span-1 text-sm-- text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
           v-if="$refs.filters.d1metricFilterIsActive"
           @click="$refs.filters.unsetD1metricFilter"
      >
        <div class="flex items-center h-full">
          <span class="flex-grow">Διάμετρος/Πλάτος: {{ $refs.filters.activeFilters.d1metric.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
        </div>
      </div>
      <div class="col-span-1 text-sm-- text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
           v-if="$refs.filters.d2metricFilterIsActive"
           @click="$refs.filters.unsetD2metricFilter"
      >
        <div class="flex items-center h-full">
          <span class="flex-grow">Μήκος: {{ $refs.filters.activeFilters.d2metric.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
        </div>
      </div>
    </div>
  </div>

  <div class="block sm:hidden text-lg-- manrope font-semibold-- mb-8 gp-sm-txt-14 text-medium">
    {{ $t('labels.results') }} 
  </div>

  <!-- desktop -->
  <div class="grid grid-cols-1 sm:grid-cols-3 results-container" style="margin-bottom:150px" v-if="searchResultsLoaded">

    <!-- mobile -->
    <second-level-category-card
        class="sm:hidden col-span-1"
        v-for="(subcategory, index) in searchResults"
        :key="subcategory.id"
        :ref="'tile-'+index"
        :id="'tile-id-'+index"
        :category="subcategory"
        :visible="index < blocksize * loadedBlocks"
    />

    <!-- desktop -->
    <!-- .slice(0, blocksize*loadedBlocks) -->
    <second-level-category-tile
        style="height: 347px"
        class="hidden sm:flex sm:col-span-1"
        v-for="(subcategory, _index) in searchResults"
        :key="subcategory.id"
        :ref="'tile-'+_index"
        :id="'tile-id-'+_index"
        :category="subcategory"
        :visible="_index < blocksize * loadedBlocks"
        :p1="thisPageUrl"
    />

    <div class="col-span-full my-16 text-center" v-if="loadedBlocks*blocksize < searchResults.length">
      <button class="app-button" @click="loadMore">
        {{ $t('labels.load-more') }}
      </button>
    </div>
  </div>

</template>

<script>

/*
import SubcategoriesHeader from "@/components/misc/SubcategoriesHeader";
import SecondLevelCategoryCard from "@/components/categories/SecondLevelCategoryCard";
*/
import SecondLevelCategoryTile from "@/components/categories/SecondLevelCategoryTile";
import ArrowFilters from "@/components/ArrowFilters";
import SecondLevelCategoryCard from "@/components/categories/SecondLevelCategoryCard";
// import MobileFilters from "@/components/MobileFilters";

export default {
  name: "SearchResults",
  components: {
    SecondLevelCategoryCard,
    /*MobileFilters,*/
    ArrowFilters,
    SecondLevelCategoryTile,
    /*SecondLevelCategoryCard,*/
    /*SubcategoriesHeader*/
  },
  inject: ['openDialog'],
  props: {
    //
  },
  data() {
    return {
      showMobileFilters: false,
      blocksize: 15,
      loadedBlocks: 1,
    }
  },
  computed: {
    searchResultsLoaded() {
      return this.$store.getters['filters/searchResultsLoaded'];
    },
    searchResults() {
      var results = this.$store.getters['filters/searchResults'];
      if (results.length==1) {
        //console.log(results);
        window.location.replace('' + results[0].url);

        return;
        //return results;
      }
      else {
        return results;
      }      
    },
    isMobile() {
      return window.innerWidth <= 800;
    },
    thisPageUrl() {
      //console.log(this.$route);
      return this.$route.fullPath;
      //return "search?algolia=din"; 
    },
  },
  methods: {
    async search(filters) {
      // console.log('search using filters ...', JSON.stringify(filters));
      // this.clearAlgoliaSearch();

      const self = this;

      try {
        await this.$store.dispatch('filters/search', filters);
      } catch (error) {
        self.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    loadMore() {
      this.loadedBlocks++;
    }
  },
  mounted() {
    //
  }
}
</script>

<style scoped>

.results-container {
  padding-left:120px;
  padding-right:100px;
  padding-bottom:50px;
}
@media (max-width:800px) {
  .results-container {
    padding-left:10px;
    padding-right:10px;
  }  
}

</style>