<template>
  <div class="gp-container">
    <div class="sm:hidden manrope gp-pl-15">
      <router-link class="flex items-center mb-2 font-semibold" to="/account">
        <img class="mr-3" src="../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.account') }}
      </router-link>
      <div class="text-2xl-- text-24 text-regular manrope">{{ $store.getters['auth/userName'] }},</div>
    </div>

    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.saved-items') }}
    </div>

    <div
        v-if="favoritesLoaded"
        class="border-r-1 border-b-1 border-black manrope font-semibold mb-8 grid grid-cols-12 divide-x divide-y divide-black border-collapse gp-favorites"
        style=""
    >
      <div
          v-for="(item, index) in favourites"
          :key="item.id"
          class="favourite-tile col-span-6 sm:col-span-6 flex flex-col"
          :ref="'ft-' + index"
          :class="{'border-l-1 border-t-1': index === 0}"
          style=""
      >
        <div v-if="item.id !== -1" class="pt-2 pr-2">
          <img src="../../assets/svg/heart-filled.svg" class="h-4 cursor-pointer ml-auto" @click="unmarkAsFavorite(item)"/>
        </div>
        <a v-if="item.id !== -1" :href="$router.resolve(item.url).href"
          class="flex-grow p-2 pb-4 slider-item flex flex-col items-center justify-end text-center text-sm cursor-pointer font-semibold">
          <img :src="item.imageUrl" class="mb-4" style="height: 100px; width: auto;" alt=""/>
          <div class="text-darkblue manrope ft-title" style="">{{ item.name }}</div>
          <div class="--mb-4" style="color: #4D4D4D;">{{ item.quantity }} {{ item.quantity === 1 ? 'item' : 'items' }}</div>
        </a>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "MySavedItems",
  components: {},
  inject: ['openDialog', 'prettyNum'],
  data() {
    return {
      //
    }
  },
  computed: {
    favoritesLoaded() {
      return this.$store.getters['categories/favoritesLoaded'];
    },
    favourites() {
      let items = this.$store.getters['categories/favorites'];

      /*
       * The count should always be event otherwise the grid borders collapse!
       */
      if (items.length % 2 !== 0) {
        items.push({
          id: -1 // add a ghost tile
        });
      }

      return items;
    }
  },
  watch: {
    //
  },
  methods: {
    async loadRewards() {
      try {
        await this.$store.dispatch('account/loadAccountData');

        this.$refs['rewardBuildingsSlider'].setElements();
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async unmarkAsFavorite(item) {
      try {
        await this.$store.dispatch('categories/removeFromFavourites', item.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  async created() {
    await this.$store.dispatch('categories/loadFavourites');
  }
}
</script>

<style scoped>

.gp-title {
  font-size:28px;
  padding-left:30px;
}

.gp-favorites {
  max-width: 650px;
  margin-bottom:150px;
}

.favourite-tile {
  height: 280px; max-width: 350px;
}

.ft-title {
  height:40px;
  overflow: hidden;
}


/* Mobile */
@media screen and (max-width:800px) {
  .gp-title {
    font-size:24px;
    padding-left:15px;
  }

  .ft-title {
    height:60px;
  }
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}


</style>