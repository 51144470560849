<template>
    <div class="about-top-image" style="margin-top:50px; margin-left:-22px; margin-right:-22px">
        <!-- Mobile -->
        <div class="about-top-image-back-mobile" style="background-image:url(https://tsamouris.gr/wp/wp-content/uploads/2023/05/top-mob-back.png)"></div>
    </div>

    <div class="company">Company</div>
    
    <div class="submenu-mobile">
    <div v-if="!mobileMenuVisible" class="submenu-item-active breaditem active">{{activeItem}}</div>
    
    <div v-if="mobileMenuVisible" class="submenu-items">
      <div class="submenu-item">
        <a style="text-decoration: none;" target="_top" href="/governance">About</a></div>
      <div class="submenu-item">
        <a style="text-decoration: none;" target="_top" href="/about">Timeline</a></div>
      <div class="submenu-item">
        <a style="text-decoration: none;" target="_top" href="/trust">Trust</a></div>
      <div class="submenu-item">
        <a style="text-decoration: none;" target="_top" href="/news">Newsroom</a></div>
    </div>
    
    
      <div class="toggle-submenu-mobile" @click="toggleMobileMenu">
        <img v-if="mobileMenuVisible" src="../../assets/svg/submenu-mob-close.svg" />
        <img v-else src="../../assets/svg/submenu-mob-open.svg" />
      </div>

  </div>
    
</template>

<script>
export default {
  name: "CmsSubmenu",
  data() {
    return {
      mobileMenuVisible: false
    }
  },
  props: {
    activeItem: {
        type: String,
        default: '',
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    }
  }
}

</script>

<style scoped>

.company {
  display: none;
}
.submenu-mobile {
  display:none;
  position:relative;
  margin-top:15px;
}
.submenu-item-active {
  padding:20px 20px;
  background-size:100% 100%;
  width:100%;
}

.submenu-items {
  border:1px solid #212121;
  border-radius: 10px;
}
.submenu-item {
  padding:15px 20px;
  border-bottom:1px solid #212121;
  border-radius: 10px;
}
.submenu-item:last-child {
  border-bottom:none; 
}

.toggle-submenu-mobile {
  position:absolute;
  top:-30px;
  right:10px;
  font-size: 30px;
  cursor:pointer;

}

@media (max-width:800px) {
  .breadcrumb-desktop {
    display:none;
  }
  .submenu-mobile {
    display:block;
  }
  .company {
    display:block;
  }
}

.breaditem.active::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  content: '';
  background-image: url(https://tsamouris.gr/wp/wp-content/uploads/2023/05/breadblue.svg);
  background-size: 100% 100%;
  z-index: -1;
}

.breaditem {
  background-image: url(https://tsamouris.gr/wp/wp-content/uploads/2023/05/breadback.svg);
  /*width: fit-content;*/
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  color: #212121;
  /*width: 195px;*/
  text-align: center;
  position: relative;
  background-size: 100% 100%;
  margin-right: 2px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-top-image-back-mobile {
  display: none;
  height: 110px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width:800px) {
  .about-top-image-back-mobile {
    display: block;
  }  
}

.company {
  display: none;
  font-family: 'Manrope', sans-serif;
  font-size: 20px;
  line-height: 22px;
  margin-left: 16px;
  padding-top: 40px;
}
@media (max-width:800px) {
  .about-top-image-back-mobile {
    display: block;
  }  
  .company {
    display:block;
  }
}

</style>
