<template>
  
  <div  v-if="!auth" class="bg-white p-8">
    <p class="mb-4 font-semibold">{{$t('messages.you-have-to-login')}}</p>

    <router-link class="app-button w-full block" :to="'/login?redirect='+$route.fullPath">
      {{ $t('labels.sign-in') }}
    </router-link>
  </div>
  
  <div  v-else class="flex flex-col">
    <!-- CODE | PCS | UNIT PRICE | + | -   -->
    <div class="flex flex-wrap-- 2xl:flex-nowrap-- cart-plus-minus-row border-21 border-t-1 border-b-1 acp-panel-components">
      <div
          id="acp-panel-component-0"
          class="hidden sm:inline-block text-xl-- text-darkblue border-t-1 border-21 
          sm:border-white sm:border-l-0 sm:border-r-0 
          px-4-- pt-6-- sm:pt-12-- pb-3-- bg-white acp acp-0"
      >
        <p class="text-26-- txt-lh-26-- text-medium acp-lg-txt acp-itemname">{{ product.item_name }}</p>
        <p class="text-lineheight-24-- text-16-- acp-sm-txt text-medium text-dark-gray acp-itemcode">{{ product.item_code }}</p>
      </div>

      <!-- Pieces quantity per package + Package description -->
      <div id="acp-panel-component-1" class="flex flex-col justify-end p-4-- sm:pt-12-- font-semibold acp acp-1">
        
        <div class="flex text-darkblue" style="align-items: flex-end;">
          <p class="text-26-- txt-lh-26-- text-medium acp-lg-txt" style="position:relative">
          {{ myPiecesPerPackage }} {{ $t('labels.pcs') }}</p>
          <!--<span class="text-26 text-medium txt-lh-26" style="/*position:absolute; top:5px; right:0px*/"> {{ $t('labels.pcs') }}</span>-->
        </div>
        <p class="text-lineheight-24 text-16 text-medium acp-sm-txt">/ {{ $t('labels.package') }}</p>
      </div>

      <!-- Price per unit + Unit description -->
      <div id="acp-panel-component-2" class="flex flex-col justify-end p-4-- sm:pt-12-- font-semibold acp acp-2">
        <p v-if="product.base_price>0" 
          class="text-darkblue acp-sm-txt text-medium manrope"
          style="text-decoration: line-through;">
          {{ prettyNum(product.base_price) }}
        </p>
        <p class="text-darkblue text-26-- txt-lh-26-- acp-lg-txt text-medium manrope">{{ prettyNum(product.initial_price) }}</p>
        <span class="text-16-- acp-sm-txt text-medium">/ {{ product.unit_description }}</span>
      </div>

      <!-- Discount & final Price -->
      <div id="acp-panel-component-3" class="flex flex-col justify-end p-4-- sm:pt-4-- font-semibold acp acp-3">
        <p class="text-darkblue text-base-- h-full-- text-16-- acp-sm-txt  text-semibold acp-discount text-lineheight-18">{{ product.discount }}% {{ $t('labels.discount') }}</p>
        <p class="text-darkblue text-26-- txt-lh-26-- acp-lg-txt text-medium">{{ prettyNum(product.final_price) }}</p>
        <span class="text-16-- txt-lh-16-- acp-sm-txt text-medium">{{ $t('labels.final-price') }}</span>
      </div>

      <!-- Input + Availability label -->
      <div id="acp-panel-component-4" class="flex flex-col justify-end p-4-- sm:pt-12-- font-semibold acp acp-4">
        <p class="acp-sm-txt  text-semibold acp-discount text-lineheight-18" style="display:block; color:#bbb; padding-left:0px">{{ $t('labels.type-here') }}</p>
        <div class="flex text-darkblue" style="align-items: flex-end; ">
          
          <span class="acp-lg-txt text-medium">{{ $t('labels.pcs') }} </span>
          <input v-model.number="quantity"
          @keyup="checkProductQuantity" 
          @change="roundProductQuantity" type="text" :step="myPiecesPerPackage" maxlength="7" class="w-full  acp-lg-txt text-medium manrope pl-1 gp-product-quantity-input" style="/*height:26px*/"/>
          <!--<span class="ml-1">{{ $t('labels.pcs') }}</span>-->
        </div>
        <span class="acp-sm-txt text-medium" :style="{color: availabilityColor}">{{ availabilityLabel }}</span>
      </div>

      <!-- Plus Button -->
      <button
          id="acp-panel-component-5"
          class="plus-minus-button text-4xl text-white py-4 px-0 gp-green-bg acp acp-5"
          @click="increaseProductQuantity"
      >
        +
      </button>

      <!-- Minus Button -->
      <button
          id="acp-panel-component-6"
          class="plus-minus-button text-4xl text-white py-4 px-0 gp-orange-bg acp acp-6"
          @click="decreaseProductQuantity"
      >
        -
      </button>
    </div>

    <!-- Μπεζ κενό -->
    <div class="h-0 sm:h-12" style="---background-color: rgb(255, 254, 242)">
      <!-- {{ product }}-->
    </div>

    <!-- Total & Reward [Mobile] -->
    <div class="bg-white px-4 py-4 flex items-end sm:hidden">
      <div class="flex-grow text-23 text-semibold ">
        <p>{{ $t('labels.total') }}</p>
        <p class="gp-green-txt">{{ $t('labels.reward') }}</p>
      </div>
      <div class="flex-grow text-23 text-semibold ">
        <p class="w-20 text-right">{{ prettyNum(localItemsTotalCost - reward) }}</p>
        <p class="w-20 text-right" :style="{color: rewardColorNotation}">{{ prettyNum(reward) }}</p>
      </div>
      <div class="relative flex-shrink">
        <img src="../../assets/icons/green-box-outline.png" class="h-12"/>
        <span
            class="absolute text-center text-white text-14 -top-2 right-4-- font-semibold rounded-full px-1.5-- py-1-- w-6--"
            style="background-color: rgb(53, 211, 47); left:15px; width:26px; padding:4px"
        >
          {{ localItems.length }}
        </span>
      </div>
    </div>

    <!-- Total & Reward [Desktop] -->
    <div class="bg-white text-xl hidden sm:flex --justify-between border-t-1 border-b-1 border-21">
      <div class="sm:p-4 flex flex-col justify-end">
        <!-- Green box without the counter -->
        <!--<img src="../../assets/icons/green-box-outline.png" style="width: 84px; !important;"/>-->

        <!-- Green box with the counter -->
        <div class="relative">
          <img src="../../assets/icons/green-box-outline.png" style="width: 84px; !important;"/>
          <span v-if="localItems.length > -90" style="top: 0; left: 28px; width:30px; padding:1px 4px; background-color: rgb(53, 211, 47)"
                class="absolute text-center text-white text-14 font-semibold rounded-full px-1.5-- py-1-- w-6--"
          >{{ localItems.length }}</span>
        </div>
      </div>
      <div class="flex-grow"></div>
      <div class="product-subtotal flex flex-col justify-end p-6 sm:pt-8 text-semibold " style="">
        <p class="">{{ $t('labels.total') }}</p>
        <p class="" :style="{color: rewardColorNotation}">{{ $t('labels.reward') }}</p>
        <p class="">{{ $t('labels.total-final') }}</p>
      </div>
      <div class="product-subtotal flex flex-col justify-end p-6 sm:pt-8 text-semibold " style="min-width: 100px; max-width: 200px; ">
        <p class="text-right">{{ prettyNum(localItemsTotalCost) }}</p>
        <p class="text-right" :style="{color: rewardColorNotation}">{{ prettyNum(reward) }}</p>
        <p class="text-right">{{ prettyNum(localItemsTotalCost - reward) }}</p>
      </div>

    </div>

    <!-- In detail -->
    <div id="in-detail" class="bg-21 text-base sm:flex text-white p-4 sm:p-2">
      <span @click="inDetail = !inDetail" class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium manrope">
        {{ $t('labels.in-detail') }}
      </span>
    </div>

    <!-- Add to order -->
    <div v-if="!inDetail" class="--bg-beige flex flex-col space-y-4 p-8" style="background-color: rgb(245, 245, 232);">
      <button class="app-button w-full text-22 manrope text-medium addbtn" @click="addProductToCart">
        {{ $t('labels.add-to-order') }}
      </button>
    </div>

    <div v-else class="bg-21 text-white">
      <div id="table-wrapper" class="overflow-x-auto">
        <table id="in-detail-table" class="manrope w-full border-1 border-white mt-4 mb-4">
          <thead class="text-gray-400 text-15-- text-semibold txt-lh-18-- manrope">
            <th class="">{{ $t('labels.code') }}</th>
            <th class="">{{ $t('labels.description') }}</th>
            <th class="">{{ $t('labels.m-u') }}</th>
            <th class="">{{ $t('labels.units') }}</th>
            <th class="">{{ $t('labels.initial-price') }}</th>
            <th class="">% {{ $t('labels.discount-short') }}</th>
            <th class="">{{ $t('labels.total-price-short') }}</th>
            <th class="">{{ $t('labels.vat') }}</th>
          </thead>
          <tbody>
            <tr class="text-15-- txt-lh-18-- text-semibold manrope" v-for="(localItem, index) in localItems /*localItems*/" :key="localItem.item_code" :class="{hidden: index >= blocksize * loadedBlocks}">
              <td>{{ localItem.item.item_code }}</td>
              <td>{{ localItem.item.item_name }}</td>
              <td>{{ localItem.item.unit_description }}</td>
              <td>{{ localItem.item_quantity }}</td>
              <td>{{ prettyNum(localItem.item.initial_price) }}</td>
              <td>{{ localItem.item.discount }}</td>
              <td>{{ prettyNum(localItem.item.final_price * localItem.item_quantity/localItem.item.pcs_per_unit) }}</td>
              <td>{{ prettyNum(localItem.item.final_price * localItem.item_quantity/localItem.item.pcs_per_unit * 0.24) }}</td>
<!--              <td>{{ localItem.item.vat_value }}</td>-->


<!--              <td>{{ localItem.item }}</td>-->
<!--                    dimensions: "6 x 36"-->
<!--                    discount: "0.00"-->
<!--                    discount_1: null-->
<!--                    discount_2: "0.00"-->
<!--                    final_price: null-->
<!--                    item_code: "A83006003600"-->
<!--                    item_description: "ΒΙΔΕΣ U-BOLTS Γ/ΒΑΝ.  6 Χ 36 X 60"-->
<!--                    package_description: "ΚΟΥΤΙ 100 TEM"-->
<!--                    quantity_packages: 12-->
<!--                    quantity_pcs: "1200.00"-->
<!--                    total_price: 0-->
<!--                    unit_description: "100 τεμ"-->
<!--                    unit_price: null-->
<!--                    vat_percentage: "24.00"-->
<!--                    vat_value: 0-->

            </tr>
          </tbody>
        </table>
      </div>

      <!-- Load more & View all -->
      <div class="px-6">
        <button v-if="hiddenLocalItems" class="app-button black w-full mb-2 font-semibold mt-4 sm:mt-0 text-15 text-medium manrope" style="/*font-size: 1rem;*/" @click="loadMore">
          {{ $t('labels.load-more') }}
        </button>
        <div v-if="hiddenLocalItems" class="text-right mb-4">
          <span class="border-b-1 border-white mx-auto cursor-pointer text-15 text-medium manrope" @click="viewAll">
            {{ $t('labels.view-all') }}
          </span>
        </div>
        <button class="app-button w-full no-hover text-22 text-medium manrope mb-4" :class="{'mt-4 sm:mt-0': !hiddenLocalItems}" style="font-size: 1.25rem;" @click="addProductToCart">
          {{ $t('labels.add-to-order') }}
        </button>
      </div>
    </div>

  </div>
  
</template>

<script>
export default {
  name: "AddToCartPanel",
  inject: ['showNotification', 'openDialog', 'updateLocalItemQuantity', 'resetLocalItems', 'prettyNum'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    localItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dev: true,
      quantity: 0,
      prev_quantity:0,
      available: true,
      // --------- //
      unitPrice: 0, // final_price
      inventoryTotal: 0, // inventory_total
      // --------- //
      productTotalCost: 0,
      // green: color: #5ad504
      // red: #E66000;
      rewardColorNotation: '#5ad504',
      inDetail: false,
      blocksize: 10,
      loadedBlocks: 1,
      debugLocalItems: [
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
        {item: {}, item_code: 99, quantity: 7},
      ]
    }
  },
  computed: {
    auth() {
      return this.$store.getters['auth/auth'];
    },
    cartItems() {
      return this.$store.getters['cart/items'];
    },
    cartTotalCost() {
      // console.log('totalCost',this.$store.getters['cart/totalCost'] );
      return this.$store.getters['cart/totalCost'];
    },
    // cart item diversity
    // itemCount() {
    //   return this.$store.getters['cart/items'].length;
    // },
    cartProductItem() {
      return this.$store.getters['cart/items'].find(
          item =>
              item.itemId === this.product.itemId
              &&
              item.itemType === this.product.itemType
      );
    },
    localItemsTotalCost() {
      let sum = this.localItems.reduce(function(previousValue, currentValue) {
          return previousValue + currentValue.item.final_price * currentValue.item_quantity/currentValue.item.pcs_per_unit;
        }, 0);

      return sum;
      // return this.prettyNum(sum);
    },
    reward() {
      return localStorage.getItem('eshop_discount')=="true"? this.localItemsTotalCost * 0.03: 0;
      //return this.localItemsTotalCost * 0.03;
    },
    availabilityLabel() {
      if (this.product.product_type=='fastener') {
        return this.available ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
      else if (this.product.product_type=='tool') {
        return this.available ? this.$t('labels.available') : this.$t('labels.available-in-15-days');
      }
      else {
        return this.available ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
      
    },
    availabilityColor() {
      return this.available ? "#38d430" : "#ff5f00";
    },
    // (str) 1.000 => (int) 1000
    myPiecesPerPackage() {
      // return parseFloat(this.product.main_package_quantity_pcs.replace('.', ''));
      return this.product.main_package_quantity_pcs;
    },
    hiddenLocalItems() {
      return this.localItems.length > this.blocksize*this.loadedBlocks;
    }
  },
  watch: {
    quantity(currentVal) {
      if (currentVal > this.product.inventory_total) {
        this.available = false;        
      } else {
        this.available = true;
      }

      // this check here is important because without it,
      // the totalCost would be constantly updated even
      // on not-rounded values
      if (this.quantity % this.myPiecesPerPackage === 0) {
        this.updateProductTotalCost();
      }

      this.updateLocalItemQuantity(this.product, currentVal);
    }
  },
  methods: {
    increaseProductQuantity() {
      let new_quantity = this.quantity + this.myPiecesPerPackage;

      if (this.product.product_type=='fastener') {
        if (new_quantity <= this.product.inventory_total) {
          this.quantity = new_quantity;
          this.rewardColorNotation = '#5ad504';
        }
        else {
          alert(this.$t('labels.product-not-available'));
        }
      }
      else if (this.product.product_type=='tool') {
        this.quantity = new_quantity;
        this.rewardColorNotation = '#5ad504';
        if (new_quantity > this.product.inventory_total) {          
          //alert("Παράδοση σε 15 ημέρες");
        }
      }

      
      
      //this.rewardColorNotation = '#5ad504'; // 38d430
      //this.quantity += this.myPiecesPerPackage;
    },
    decreaseProductQuantity() {
      this.quantity -= this.myPiecesPerPackage;
      this.rewardColorNotation = '#5ad504'; // ff5f00

      if (this.quantity <= 0) {
        this.rewardColorNotation = '#5ad504'; //ff5f00
        this.quantity = 0;
      }
    },
    roundProductQuantity() {
      if (this.quantity % this.myPiecesPerPackage !== 0) {
        let divisor = Math.floor(this.quantity/this.myPiecesPerPackage);
        // console.log('divisor:', divisor);
        this.quantity = this.myPiecesPerPackage * (divisor+1);
      }
    },
    
    checkProductQuantity() {
      if (this.quantity > this.product.inventory_total) {
        if (this.product.product_type=='fastener') {
          alert(this.$t('labels.product-not-available'));
          this.quantity = this.product.inventory_total;
        }
        else if (this.product.product_type=='tool') {
          //...
        }
      }
    },

    updateProductTotalCost() {
      this.productTotalCost = Math.round((this.quantity/this.product.pcs_per_unit) * this.product.final_price * 100)/100;

      // console.log('total cost updated: ', this.productTotalCost);
      // console.log('this.quantity: ', this.quantity);
      // console.log('this.piecesPerUnit: ', this.product.pcs_per_unit);
      // console.log('this.product.final_price: ', this.product.final_price);

    },
    async addProductToCart() {
      if (this.localItems.length === 0) {
        return;
      }

      await this.$store.dispatch('cart/addItems', this.localItems);

      //console.log(this.$store.getters['app/locale']);
      let my_lang = this.$store.getters['app/locale'];

      if (my_lang=='el') {
          this.showNotification({
            class: 'default',
            title: 'Συγχαρητήρια!',
            position: 'bottom-left',
            html: 'Το προϊόν/τα προστέθηκε/αν στο καλάθι με επιτυχία',
            lifeSpan: 2000,
          });
      }
      else {
          this.showNotification({
            class: 'default',
            title: 'Congrats!',
            position: 'bottom-left',
            html: 'The product(s) were added to your order successfully',
            lifeSpan: 2000,
          });
      }

      

      this.reset();
    },
    reset() {
      this.quantity = 0;
      // this.totalCost = 0;
      // this.reward = 0;
      this.resetLocalItems();
    },
    initializeQuantity() {
      // check if product is already in cart, if so, load the already selected quantity
      const index = this.localItems.findIndex(entry => entry.item.item_code === this.product.item_code);

      // console.log('init Q called!', index, this.localItems, this.product);

      if (index === -1) {
        this.quantity = 0;
      } else {
        this.quantity = this.localItems[index].item_quantity;
      }
    },
    loadMore() {
      this.loadedBlocks++;
    },
    viewAll() {
      this.loadedBlocks = Math.ceil(this.localItems.length/this.blocksize);
    },
  },
  created() {
    // product.item_code
    this.initializeQuantity();
  }
}
</script>

<style scoped>

  .addbtn {font-size:18px;}
  
  .acp-panel-components {
    flex-wrap:wrap;
  }

  @media (min-width:1200px) {
   .acp-panel-components {
      flex-wrap:nowrap;
    } 
  }
  
  
  #acp-panel-component-0 {
    display: none;
  }

  #acp-panel-component-1 {
    background-color: #f6f6f6;
    width: 33%;
  }

  #acp-panel-component-2 {
    background-color: #EEEEEE;
    width: 33%;
  }

  #acp-panel-component-3 {
    background-color: #e4e4e4;
    width: 34%;
  }

  #acp-panel-component-4 {
    background-color: #EEEEEE;
    flex-grow: 1;
    min-height: 104px;
    width: 60%;
  }

  #acp-panel-component-5 {
    width: 17%;
  }

  #acp-panel-component-6 {
    width: 17%;
  }


@media (min-width: 1400px) {
    .plus-minus-button {
      min-width: 85px;
      max-width: 85px;
    }
  }


@media (min-width: 1200px) { /** ???? */
    /*#acp-panel-component-0 {
      display: flex !important;
      width: 23%; 
    }*/

    #acp-panel-component-1 {

      width: 20%;
    }

    #acp-panel-component-2 {

      width: 20%;
    }

    #acp-panel-component-3 {

      width: 20%;
    }

    #acp-panel-component-4 {

      width: 30%;
    }

    #acp-panel-component-5 {
      width: 15%;
    }

    #acp-panel-component-6 {
      width: 15%;
    }
  }


@media (min-width: 1600px) { /** ???? */
    /*#acp-panel-component-0 {
      display: flex !important;
      width: 23%; 
    }*/

    #acp-panel-component-1 {

      width: 20%;
    }

    #acp-panel-component-2 {

      width: 15%;
    }

    #acp-panel-component-3 {

      width: 18%;
    }

    #acp-panel-component-4 {

      width: 24%;
    }

    #acp-panel-component-5 {

    }

    #acp-panel-component-6 {

    }
  }







  .plus-minus-button {
    /*min-width: 65px;*/
    /*max-width: 65px;*/
  }

  #in-detail-table,
  #in-detail-table > thead > th,
  #in-detail-table > tbody > tr > td {
    border: 1px solid white;
  }

  #in-detail-table th,
  #in-detail-table td {
    font-size:12px;
    padding:8px 3px;
  }



  #in-detail-table {
    border-left: 0;
    border-right: 0;
    /*min-width:880px;*/
  }

  @media (max-width:1200px) {
  #in-detail-table {
    min-width:880px;
  }
  }



  #in-detail-table > thead > th {
    text-transform: capitalize;
    text-align: left;
  }

  #in-detail-table > thead > th,
  #in-detail-table > tbody > tr > td {
    /*padding: 2px 18px;*/
  }

  #in-detail-table > thead > th:first-child,
  #in-detail-table > tbody > tr > td:first-child {
    border-left: 0;
  }

  #in-detail-table > thead > th:last-child,
  #in-detail-table > tbody > tr > td:last-child {
    border-right: 0;
  }

  /* width */
  #table-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    /*margin-bottom: 1rem;*/
  }

  /* Track */
  #table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  /* Handle */
  #table-wrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Handle on hover */
  #table-wrapper::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    /*background: #fff;*/
  }


  .gp-product-quantity-input {
    height:26px;
  }

  /* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .gp-product-quantity-input {
    height:30px;
  }
}}
/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm){ 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  .gp-product-quantity-input {
    height:30px;
  }
}}

/* Safari 6.1-10.0 (but not 10.1) */
/*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
@media {
  .gp-product-quantity-input {
    height:30px;
  }
}}*/



@media (max-width:1400px) {
  .gp-product-quantity-input {
    height:20px;
  }
}


/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) and (max-width:1400px)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .gp-product-quantity-input {
    height:24px;
  }
}}
/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm) and (max-width:1400px) { 
@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  .gp-product-quantity-input {
    height:24px;
  }
}}

/* Safari 6.1-10.0 (but not 10.1) */
/*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) and (max-width:1400px) { 
@media {
  .gp-product-quantity-input {
    height:24px;
  }
}}*/









  .gp-product-quantity-input {
    background:transparent;
  }

  .gp-product-quantity-input:focus {
    outline:none;
  }


  

  @media (max-width: 800px) {
    #acp-panel-component-1 {
      background-color:#fbfbfb;
      padding-left:20px
    }

    #acp-panel-component-2 {
      background-color:#efefef;

    }

    #acp-panel-component-3 {
      background-color:#ebebeb;

    }

    #acp-panel-component-4 {
      background-color:#dddddd;
      padding-left:20px
    }
  }


    


  @media (max-width:1600px) {
    #acp-panel-component-1 {
      padding-left:20px
    }
  }



  .acp-panel-components > div {
    height:115px;
    padding:15px;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media (max-width:1400px) {
    .acp-panel-components > div {
      height:105px;
    }
  }


  .acp-lg-txt {
    font-size:26px;
    line-height:26px;
  }

  .acp-sm-txt {
    font-size:16px;
    line-height: 16px;
  }

  @media (max-width:1400px) {
    .acp-lg-txt {
      font-size:18px;
      line-height:20px;
    }

    .acp-sm-txt {
      font-size:14px;
    }
  }

  @media (max-width:1400px) {
    .acp-panel-components > div {
      padding:15px 5px 13px;
    }

    .acp-itemname,
    .acp-itemcode {
      padding-left:15px;
    }
  }


  .product-subtotal {
    font-size:26px;
    line-height:30px;
  }

  @media (max-width:1400px) {
  .product-subtotal {
    font-size:20px;
    line-height:25px;
  }
  
  

  }

  

  .acp-0 {
    display:none !important;
  }



</style>