<template>
  <!--<div class="subcategories-header p-6 app-pl font-semibold whitespace-nowrap overflow-x-hidden text-15 gp-sm-mt-50 mobile-app-cancel-px">
    {{ category.category_name }}<span class="mx-2">|</span>
    <a class="mr-1" :class="{underline: selectedSubcategory === subcategory}" v-for="(subcategory, index) in subcategories" :key="subcategory">
       {{ subcategory.name }}{{ index === subcategories.length - 1 ? ' '  : ',' }}
    </a>
  </div>-->

  <div class="subcategories-header p-6-- app-pl-xx font-semibold whitespace-nowrap overflow-x-hidden text-15 gp-sm-mt-50 mobile-app-cancel-px">
    <div class="flex items-center mb-2-- manrope font-semibold text-dark-gray">
      <a @click="goBack" class="gp-link" style="cursor:pointer"><img src="../../assets/svg/arrow-left-wireframe.svg" style="display:inline" /> Back</a>
    </div>
  </div>


</template> 

<script>
export default {
  name: "SubcategoriesHeader",
  props: [
    'category',
    'subcategories',
    'selectedSubcategory',
    'p1',
    'r1',
    'p2',
    'r2',
    'toolbrand'
  ],
  methods: {
    goBack() {
      /*if (this.frompage=='') {
        this.$router.go(-1);
      }
      else {
        window.location.href = "/" + this.frompage + "/?row=" +  this.fromrow;
      }*/
      if (typeof(this.p2)!='undefined' && this.p2!="") {
        window.location.href = "/" + this.p2 + '/?p1=' + this.p1 + '&r1=' + this.r1 + '&r2=' + this.r2 + (this.toolbrand!='' && this.toolbrand!=null? '&toolbrand=' + this.toolbrand: '' );
      }
      else if (typeof(this.p1)!='undefined'  && this.p1.indexOf('search')>0) {
        window.location.href = this.p1;
      }
      else if (typeof(this.p1)!='undefined'  && this.p1!="") {
        window.location.href = "/" + this.p1 + '/?r1=' + this.r1 + (this.toolbrand!='' && this.toolbrand!=null? '&toolbrand=' + this.toolbrand: '' );
      }
      else if (this.p1!="home") {
        if (this.$store.getters['app/locale']=="el") {
          window.location.href = "/";
        }
        else {
          window.location.href = "/en";
        }        
      }
      else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    // console.log(this.category);
  }
}
</script>

<style scoped>
  div.subcategories-header {
    padding-left:120px;
  }
  @media (max-width:1400px) {
    div.subcategories-header {
      padding-left:70px;
    }
  }
</style>

<style>

.subcategories-header > div {
  padding:10px 0px;
}

@media (max-width:1400px) {
 .subcategories-header {
    /*padding-top:15px;
    padding-bottom:15px;*/
  } 
}

.gp-link:hover {
  text-decoration: underline;
}

</style>