<template>
    <arrow-filters style="margin-bottom: 65px;"/>

    <div class="how-to-order-container pad-left-110 pad-right-100">

        <div class="info">
            <div class="info-text1 text-semibold">
                Τώρα απλή παραγγελία<br/> 
                σε τρία βήματα: <br/><br/>

                <span>1</span> Διαλέξτε διάσταση <br/>
                <span>2</span> Διαλέξτε ποσότητα <br/>
                <span>3</span> Δείτε την αξία της παραγγελία σας<br/><br/>

                και φύγατε! 
            </div>
            <div class="info-text2 text-medium">
                Βρείτε τα προϊόντα που θέλετε και σιγουρευτείτε 
                οτι είναι αυτά ακριβώς που χρειάζεστε με το 3D σχέδιο. 
                Γυρίστε το 360 μοίρες, μεγενθύνετε το και δείτε τις 
                λεπτομέρειες του προϊόντος που σας ενδιαφέρει.  

                Η τεχνολογία στην υπηρεσία σας. 
                Περισσότερη ευκολία, λιγότερα λάθη.
            </div>
        </div>

        <div class="video">
            <img @click="play_video=true;" src="../../assets/images/how-it-works-video-thumb2.png" />
        </div>

    </div>

    <div v-if="play_video" class="video-container">
        <video class="video-player" autoplay loop>
            <source src="../../assets/images/TS_EXPLANATION_ANIMATION.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div @click="play_video=false;" class="close-video">Κλείσιμο</div>
    </div>




</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "InfoHowToOrder",
  components: {ArrowFilters},
  data() {
    return {
        play_video: false,      
    };
  },
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  }
}

</script>

<style scoped>

    .how-to-order-container {
        display:grid;
        grid-template-columns: 100%;
        margin-top:50px;
        margin-bottom:50px;
    }
    @media (min-width:1200px) {
        .how-to-order-container {
            grid-template-columns: 50% 50%;
            margin-top:130px;
        }
        
    }

    .info, .video {
        padding:50px 30px;
        /*border:1px solid #ccc;*/
        margin-left:-20px;
    }
    @media (min-width:800px) {
        .info, .video {
            margin-left:0px;
            background-image: url(../../assets/svg/how-to-order-info-bg.svg);
            background-size: 100% 100%;
        }
    }

    .video {
        margin-left:-1px;
    }

    .info-text1 {
        font-size: 23px;
        line-height: 31px;
        margin-bottom: 50px;
    }
    @media (min-width:800px) {
        .info-text1 {
            font-size: 28px;
            line-height: 33px;
            margin-bottom: 110px;
        }        
    }
    @media (min-width:1400px) {
        .info-text1 {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 110px;
        }        
    }

    .info-text1 span {
        width:30px;
        height:30px;
        background-color: #212121;
        color:#fff;
        border-radius:20px;
        margin-left:-5px;        
        font-size: 15px;
        display:inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    @media (min-width:800px) {
        .info-text1 span {
            width:40px;
            height:40px;
            font-size: 20px;
        }
        
    }

    .info-text2 {
        font-size: 14px;
        line-height: 19px;
        max-width:500px;
    }
    @media (min-width:800px) {
        .info-text2 {
            font-size: 18px;
            line-height: 27px;
        }
        
    }

    .video-container {
        position:fixed;
        top:0px;
        right:0px;
        bottom:0px;
        left:0px;
        background:#00000055;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .video img {
        cursor:pointer;
    }

    .video-player{
        width:960px;
        height:720px;
        
    }

    .close-video {
        position:absolute;
        top:20px;
        right:20px;
        cursor:pointer;
        font-weight: bold;
        padding:5px 15px;
        background-color: #212121;
        color:#fff;
        border-radius: 20px;
    }



</style>