<template>
  <!-- old val: {-62px} -->
  <div class="sm:hidden fixed flex rounded-2xl justify-between bg-21 px-4 py-1 text-14 text-semibold" style="width: calc(100vw - 44px); left:22px; bottom:22px;">
  <!--    <div @click="dev" class="mobile-nav-item">
      dev
    </div>-->
    <router-link to="/" class="mobile-nav-item">
      Shop
    </router-link>
    <div @click="carryOverFilters" class="mobile-nav-item">
      Filter
    </div>
    <router-link to="/account" class="mobile-nav-item">
       Account
    </router-link>
    <router-link to="/speech" class="mobile-nav-item">
      <img src="../../assets/icons/search.png" class="h-8"/>
      <div>Search</div>
    </router-link>
    <router-link to="/review-cart" class="mobile-nav-item">
      <img src="../../assets/icons/gray-box.png" class="h-8"/>
      <div>Box</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MobileNavMenu",
  data() {
    return {}
  },
  computed: {
    //
  },
  methods: {
    dev() {
      // console.log(this.$route);
      // alert(this.$route.query);
    },
    carryOverFilters() {
      const currentPath = this.$route.path;
      const redirect = this.$route.fullPath.replace(currentPath, '/filters');

      this.$router.push(redirect);
    }
  },
  created() {

  }
}
</script>

<style scoped>
  .mobile-nav-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #7D7D7D;
    font-weight: 600;
    padding: .5rem;
    cursor: pointer;
  }

  .mobile-nav-item:hover {
    color: #fff;
  }

  .mobile-nav-item > img {
    margin-bottom: .5rem;
  }
</style>
