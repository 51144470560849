<template>
    <arrow-filters style="margin-bottom: 65px;"/>

    <div class="page-discount3-container home-margin-left">

        <div class="text-1 text-semibold">
            Κερδίστε επιπλέον 3% 
            σε όλες τις παραγγελίες σας  
            χρησιμοποιώντας το eshop μας.
            Εξτρά έκπτωση για online αγορές.
        </div>

        <div class="text-discount text-medium">
            -3%
        </div>

        <div class="text-2 text-medium">
            Αγοράστε 3% φθηνότερα απο το site <br/>
            σε σχέση με τηλεφωνικές προαραγγελίες<br/>
            ή παραγγελίες με email. Επωφεληθείτε τώρα!
        </div>


    </div>




</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "InfoDiscount3",
  components: {ArrowFilters},
  computed: {
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
  }
}

</script>

<style scoped>
    
    .text-1 {
        font-size:23px;
        line-height: 31px;
        margin-top:50px;
        margin-bottom:50px;
        max-width:500px;
        margin-left:-20px;
    }
    @media (min-width:800px) {
        .text-1 {
            font-size:32px;
            line-height: 42px;
            margin-top:50px;
            margin-bottom:50px;
            margin-left:0px;
        }
    }
    @media (min-width:1200px) {
        .text-1 {
            font-size:25px;
            line-height: 30px;
            margin-top:120px;
            margin-bottom:60px;
        }
    }
    @media (min-width:1400px) {
        .text-1 {
            font-size:32px;
            line-height: 42px;
            margin-top:190px;
            margin-bottom:70px;
        }
    }

    .text-discount {
        font-size:194px;
        line-height: 198px;
        margin-bottom:150px;
        position: relative;
        margin-left:-20px;
        letter-spacing: -20px;
    }
    @media (min-width:800px) {
        .text-discount {
            font-size:226px;
            line-height: 231px;
            margin-bottom:150px;
            margin-left:0px;
            letter-spacing: normal;
        }
    }
    @media (min-width:1400px) {
        .text-discount {
            font-size:226px;
            line-height: 231px;
            margin-bottom:195px;
        }
    }

    .text-discount::after {
        position:absolute;
        top:150px;
        left:200px;
        width:165px;
        height:156px;
        content:'';
        background-image:url(../../assets/images/eshop-click.png);
        animation: movecursor 2s forwards;
    }
    @media (min-width:800px) {
        .text-discount::after {
            top:150px;
            left:300px;
            width:165px;
            height:156px;            
        }
    }

    @keyframes movecursor {
    from {translate: 50px 50px}
    to {translate: 0px 0px}
    }

    .text-2 {
        font-size:14px;
        line-height: 19px;
        margin-bottom:100px;
        margin-left:-20px;
    }
    @media (min-width:800px) {
        .text-2 {
            font-size:18px;
            line-height: 27px;
            margin-bottom:100px;
            margin-left:0px;
        }
    }

</style>