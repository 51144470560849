<template>
  <teleport to="body">
    <div v-if="open" class="backdrop" @click="$emit('close-dialog')"></div>

    <transition name="modal">
      <dialog open v-if="open" :class="allClasses" :style="style">
        <!-- header -->
        <header>
          <slot name="header">
            <!-- this is the default content for the slot if the user doesn't provide us with one -->
            <h2>
              <i :class="headerIconClass"></i>{{ title ?? defaultTitleText }}
            </h2>
          </slot>
        </header>

        <!-- body -->
        <section>
          <slot></slot>
        </section>

        <!-- footer -->
        <menu v-if="footer" class="space-x-2">
          <slot name="actions">
            <!-- default content -->
            <base-button class="app-button" @click="$emit('close-dialog')">
              Close
            </base-button>
          </slot>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  name: 'TsamourisDialog',
  emits: ['close-dialog'],
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      required: false,
      default: 'dark',
      validator: function (value) {
        return ['default', 'primary', 'secondary', 'dark', 'success', 'info', 'warning', 'danger'].includes(value);
      }
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    html: {
      type: String,
      required: false,
      default: '<strike>...</strike>'
    },
    extraClasses: {
      type: String,
      required: false,
      default: ''
    },
    style: {
      // type: String,
      required: false,
      default: ''
    },
    footer: {
      required: false,
      default: true
    }
  },
  computed: {
    headerIconClass() {
      if (this.class === 'warning' || this.class === 'danger') {
        return 'fas fa-exclamation-triangle';
      } else if (this.class === 'success') {
        return 'fal fa-thumbs-up';
      } else {
        return 'fas fa-info-circle';
      }
    },
    defaultTitleText() {
      if (this.class === 'default' || this.class === 'info' || this.class === 'primary') {
        return 'Info';
      } else if (this.class === 'success') {
        return 'Success';
      } else if (this.class === 'warning') {
        return 'Warning';
      } else if (this.class === 'danger') {
        return 'Error';
      } else {
        return 'Info';
      }
    },
    actionButtonClass() {
      return 'app-btn';
    },
    allClasses() {
      return this.class + ' ' + this.extraClasses;
    }
  }
};
</script>

<style scoped>
div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 0;
  width: auto;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  /* animation: modal 0.3s ease-out forwards; */
  margin-left: 22px !important;
  margin-right: 22px !important;
  background-color: #F5F5E8;
}

@keyframes modal {
  0% {
    opacity: 0;
    transform: translateY(-50px) scale(.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/*.modal-enter-from {*/
/*    opacity: 0;*/
/*    transform: translateY(-50px) scale(.9);*/
/*}*/

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

/*.modal-enter-to {*/
/*    opacity: 1;*/
/*    transform: translateY(0) scale(1);*/
/*}*/

/*.modal-leave-from {*/
/*    opacity: 1;*/
/*    transform: translateY(0) scale(1);*/
/*}*/

.modal-leave-active {
  animation: modal 0.2s ease-out reverse;
  /*transition: 0.3s ease-out;*/
}

/*.modal-leave-to {*/
/*    opacity: 0;*/
/*    transform: translateY(-50px) scale(.9);*/
/*}*/

header {
  /*color: white;*/
  width: 100%;
  padding: 1rem;
  font-weight: 500;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

dialog.no-padding > section {
  padding: 0;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
