<template>
    <div class="md:flex min-h-screen font-sans">
        <div class="w-full md:w-1/2 bg-white flex items-center justify-center">
            <div class="max-w-md m-8">
                <div class="text-5xl md:text-9xl font-semibold" style="color: #22292f;">
                    404
                </div>

                <div class="w-16 h-1 bg-purple-500 my-3 md:my-6"></div>

                <p class="text-gray-500 text-2xl md:text-3xl font-light mb-8 leading-normal">
                    Sorry, the page you are looking for could not be found.
                </p>

                <router-link to="/">
                    <button
                        class="bg-transparent text-gray-700 font-extrabold uppercase tracking-wide py-3 px-6 border-2 border-gray-300 hover:border-gray-400 rounded-lg">
                        Go Home
                    </button>
                </router-link>
            </div>
        </div>

        <div class="relative pb-full md:flex md:pb-0 md:min-h-screen w-full md:w-1/2">
            <img id="404-svg" src="../../assets/svg/404.svg" class="absolute pin bg-cover bg-no-repeat md:bg-left lg:bg-center h-full w-full"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    inject: ['enterFullscreen', 'exitFullscreen'],
    beforeMount() {
        this.enterFullscreen();
    },
    beforeUnmount() {
        this.exitFullscreen();
    }
};
</script>

<style scoped>
.pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.pb-full {
    padding-bottom: 100%;
}

@media (min-width: 768px) {
    .md\:pb-0 {
        padding-bottom: 0;
    }
}

#404-svg {
  background-image: url('../../assets/svg/404.svg');
}
</style>
