module.exports = {
  labels: {
    'about': 'About us',
    'account': 'Account',
    // 'account-details': 'Account details',
    'account-details': 'Register',
    'add-to-order': 'Add to order',
    'add-to-order-success-title': 'Congrats!',
    'add-to-order-success-message': 'The product(s) were added successfully to your order.',
    'address': 'Address',
    'afm': 'VAT Nr',
    'artificial-intelligence-search': 'Artificial Intelligence Search',
    'artificial-intelligent-search': 'AI search',
    'ask-a-specialist': 'Ask a specialist',
    'available': 'Available',
    'available-in-15-days': 'Avail. in 15 days',

    'back': 'Back',
    'become-a-member': 'Register',
    'billing-details': 'Payment method',
    'bolt': 'Bolt',
    'box': 'Cart',
    'boxes': 'Boxes',
    'brand': 'Company',
    'business-addresses': 'Business Addresses',
    'business-details': 'Business Details',

    'cash-discount': 'Cash discount',
    'change-language': 'Change language',
    'chat': 'Chat',
    'checkout': 'Checkout',
    'choose-dimension1-here': 'Or choose diameter here',
    'choose-dimension2-here': 'Or choose length here',
    'city': 'City',
    'clear-all': 'Clear all',
    'close': 'Close',
    'code': 'Code',
    'company': 'Company',
    'comments': 'Comments',
    'confirm-empty-cart': 'You are about to empty the whole box. \nAll products that you have selected will be erased',
    'contact': 'Contact',
    'contact-name': 'Contact Name',
    'continue': 'Continue',
    'ok': 'OK',
    'country': 'Country',
    'courier': 'Courier',
    'credit': 'Credit',
    'credit-status': 'Credit status',
    'customer-id': 'ID',
    'customer-code': 'Code',
    'customer-name': 'Legal name',

    'date-placeholder': 'dd/mm/yyyy',
    'decrease-all': 'Decrease all',
    'default_payment_type': 'Default payment type',
    'delivery': 'Delivery',
    'delivery-address': 'Delivery address',
    'deposit': 'Deposit',
    'description': 'Description',
    'discount': 'Discount',
    'discount-short': 'disc.',
    'discount1': 'Disc.1',
    'discount2': 'Disc.2',
    'discount-eshop': 'Eshop Disc.',
    'discount_total': 'Total Disc.',
    'discount_type_id': 'Discount type ID',
    'document': 'Order/Document',
    'download-as-pdf': 'Download pdf',

    'email': 'Email',
    'expand-all': 'Expand all',
    'eshop-discount': 'Eshop disc.',
    'empty-cart': 'Remove all',

    'fasteners-title': 'Fasteners',
    'fasteners-description': 'Everything you need <br/>among 28.000 products',
    'filter': 'Filter',
    'filters': 'Filters',
    'final-price': 'Final Price',
    'from': 'From',

    'head-type': 'Head Type',
    'hide-all': 'Decrease all',
    'how-find-us': 'How did you find us?',

    'i-am-a': 'I am a',
    'in-detail': 'In detail',
    'in-store-pick-up': 'I store pick up',
    'initial-price': 'Initial Price',
    'invoice-address': 'Invoice Address',
    'invoice-city': 'Invoice City',
    'invoice-perfecture': 'Invoice Perfecture',
    'invoice-zipcode': 'Invoice Zipcode',
    'invoices': 'Invoices',
    'items': 'Items',

    'length': 'Length',
    'legal-name': 'Legal Name',
    'less': 'Less',
    'limited-availability': 'Limited availability',
    'load-more': 'Load more',
    'logout': 'Logout',

    'm-u': 'M.U.',
    'material': 'Material',
    'message': 'Message',
    'method-of-shipping': 'Method of shipping',
    'metrics': 'Metrics',
    'more': 'More',
    'my-account': 'My account',
    'my-profile': 'My profile',

    'need-help': 'Need help?',
    'next': 'ΝΕΧΤ',
    // 'not-registered': 'Not registered?',
    'not-registered': 'Not register?',

    'orders-and-invoices': 'Orders and Invoices',
    'order-code': 'Transaction code',
    'order-date': 'Order date',
    'order-status': 'Order status',

    'package': 'Package',
    'packages': 'Packages',
    'password': 'Password',
    'price': 'Price',
    'price_list_id': 'Price list ID',
    'pcs': 'pcs',
    'pcs-per-package': 'Pcs/Pack',
    'per': 'per',
    'per-box': 'per box',
    'phone': 'Phone',
    'please-wait': 'Please wait...',
    'previous': 'PREVIOUS',
    'product_view_all_dim': 'View all dimensions',
    'product-not-available': 'We have not the quantity that you want.\nPlease choose less.',
    'profession': 'Profession',
    'profile_': 'Profile',
    'profile': 'My profile',

    'quantity-not-available': 'Quantity not available',

    'recently-ordered': 'Recently ordered',
    'redeem': 'Redeem',
    'register': 'Register',
    'remain': 'Remain',
    'remittance-in-advance': 'Remittance in advance',
    'remove': 'Remove',
    'request-quotation': 'Request for quotation',
    'results': 'Results',
    'return-back': 'Return back',
    'reward': 'Eshop Discount (3%)',
    'reward-discount': 'Reward disc.',
    'rewards': 'Rewards',
    'rfq': 'RFQ',

    'sales': 'sales',
    'saved-items': 'Saved items',
    'search': 'Search',
    'see-all-items': 'See all items',
    'selected-filters': 'Selected Filters',
    'seller_id': 'Seller ID',
    'shipping-address': 'Shipping Address',
    'shipping-city': 'Shipping City',
    'shipping-perfecture': 'Shipping Perfecture',
    'shipping-zipcode': 'Shipping Zipcode',
    'show-all': 'Show all products',
    'show-more': 'Show more',
    'show-results': 'Show results',
    // 'sign-in': 'Login',
    'sign-in': 'Sign in',
    'spec': 'specs',
    'site_id': 'ID',
    'site_code': 'Site code',
    'site_description': 'Site description',
    'specs': 'Specs',
    

    'tax-dept': 'Tax office',
    'tax-office': 'Tax office',
    'technical-specs': 'Technical specs',
    'to': 'To',
    'total': 'Total',
    'total-final': 'Final total (without VAT)',
    'total-line': 'Total',
    'total-price': 'Total price',
    'total-price-short': 'Tot. price',
    'total-to-pay': 'Total Payment',
    'total-w-out-vat': 'Total without VAT',
    'total-w-vat': 'Total with VAT',
    'transaction-type': 'Type',
    'transactions': 'My transactions',
    'type': 'Type',
    'type-dimension1-here': 'Enter diameter',
    'type-dimension2-here': 'Enter length',
    'type-here': 'Type here',

    'undo-discount': 'Undo discount',
    'undo-redeem': 'Undo redeem',
    'units': 'Units',
    'use-discount': 'Use discount',
    'username': 'Username',

    'vat': 'VAT',
    'vat-nr': 'VAT Nr',
    'vat_nr': 'VAT Nr',
    'vat-excluded': 'VAT excluded',
    'vat-zone': 'VAT zone',
    'view-all': 'View all',

    'what-are-you-looking-for': 'What are you looking for',
    'width-diameter' : 'Diameter/Width',

    'you-also-need': 'With this item you may need',

    'zipcode': 'Zipcode'
  },
  messages: {
    'auth-failed': 'Authorization failed',
    // 'create-an-account': 'Create an account',
    'create-an-account': 'Create an account by entering your email address',
    'email-is-not-valid': 'Your email is not valid',
    'email-must-be-filled': 'Please fill in your email',
    'enter-code-from-email': 'enter-code-from-email',
    'password-must-be-filled': 'Please fill in your password',
    //'sign-into-your-account': 'Log into your account',
    'sign-into-your-account': 'Sign into your account by entering your email address',
    'you-have-to-login': 'You have to login',
    'you-have-purchased-x-fasteners-or-y-boxes': 'You have purchased {f} fasteners or {b} boxes',
    'you-have-x-virtual-fasteners': 'You have {vf} virtual fasteners to use as discount',
  }
}