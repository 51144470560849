export default class ErrorUtil {
    error;
    env;

    constructor(error) {
        this.error = error;
        this.env = window.app.env;

        if (this.env === 'dev' || this.env === 'local') {
            this.logErrorInfo();
        }
    }

    logErrorInfo() {
        // console.warn('----- Error Debug Start -----');

        let errorInfo = {};

        if (this.error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            errorInfo.message = this.error.response.data.message;
            errorInfo.status = this.error.response.status;
            errorInfo.data = this.error.response.data;
            errorInfo.headers = this.error.response.headers;
        } else if (this.error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            errorInfo.request = this.error.request;
        } else {
            // Something happened in setting up the request that triggered an Error
            errorInfo.message = this.error.message;
        }

        errorInfo.config = this.error.config;

        console.warn(errorInfo);

        // console.warn('----- Error Debug End -----');
    }

    composeMessage() {
        let basicMessage;

        if (this.error.response) {
            basicMessage = this.error.response.data.message;
        } else {
            basicMessage = this.error.message;
        }

        if (this.env !== 'dev' && this.env !== 'local') {
            return basicMessage;
        }

        let verboseMessage = '';

        if (this.error.response && this.error.response.status) {
            verboseMessage += '<p><b>Status:</b> ' + this.error.response.status + '</p>';
        }

        if (this.error.response && this.error.response.data.exception) {
            verboseMessage += '<p><b>Exception:</b> ' + this.error.response.data.exception + '</p>';
        }

        verboseMessage += '<p><b>Message:</b> ' + basicMessage + '</p>';

        if (this.error.response && this.error.response.data.file) {
            verboseMessage += '<p><b>File:</b> ' + this.error.response.data.file + '</p>';
        }

        if (this.error.response && this.error.response.data.line) {
            verboseMessage += '<p><b>Line:</b> ' + this.error.response.data.line + '</p>';
        }

        return verboseMessage;
    }
}
