<template>
  <arrow-filters style="margin-bottom: 52px;"/>

  <div class="sm:hidden h-12"></div>

  
  <div class="ribbon" style=""></div>
  
  
  <div class="grid grid-cols-3-- gp-grid-temp-cols">
    <!-- left-side menu | desktop only -->
    <menu class="hidden sm:flex col-span-1 gp-account-nav-menu leading-8 text-15 text-semibold">
      <ul>
        <li v-for="menuItem in menuItems" :key="menuItem.href">
          
          <router-link v-if="menuItem.href == '/instant-order'" to="/instant-order">Instant Order</router-link>

          <router-link
              v-else-if="menuItem.href !== '/logout'"
              :to="routePrefix + menuItem.href"
              :href="routePrefix + menuItem.href"
              :class="{underline: $route.path.startsWith(routePrefix + menuItem.href)}"
              class="hover:underline"
          >
            {{ menuItem.label }}
          </router-link>

          <a v-else class="hover:underline cursor-pointer" @click="logout">
            {{ menuItem.label }}
          </a>

        </li>

        


      </ul>
    </menu>

    <!-- Fullscreen on mobile - 1/3 on Desktop -->
    <div class="col-span-3 sm:col-span-2 gp-account-content">
      <!-- Mobile only -->
      <div v-if="isExactRoute" class="sm:hidden">
        <div class="pl-4-- mb-8 text-2xl-- slim-- text-24 text-regular" style="padding-left:15px">Account</div>

        <!-- This one here SHOULD be hardcoded -->
        <account-navigation-menu
            :menu-items="menuItems"
            :prefix="'/account'"
            :arrow-icon="true"
        />
      </div>

      <router-view></router-view>

      <!-- on desktop "/account" route, show my-profile by default -->
      <div v-if="isExactRoute" class="hidden sm:block">
        <my-profile></my-profile>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNavigationMenu from "@/components/misc/AccountNavigationMenu";
import MyProfile from "@/pages/account/profile/MyProfile";
import ArrowFilters from "@/components/ArrowFilters";
export default {
  name: "AccountNavigation",
  components: {ArrowFilters, MyProfile, AccountNavigationMenu},
  data() {
    return {
      menuItems: [
        {
          href: '/my-profile',
          label: this.$t('labels.profile'),
        },
        {
          href: '/instant-order',
          label: 'Instant Order',
        },
        {
          href: '/transactions',
          label: this.$t('labels.transactions'),
        },
        {
          href: '/saved-items',
          label: this.$t('labels.saved-items'),
        },
        /*{
          href: '/rewards',
          label: this.$t('labels.rewards'),
        },*/
        {
          href: '/download-xml',
          label: "Download XML",
        },
        {
          href: '/logout',
          label: this.$t('labels.logout'),
        },
      ],
      routePrefix: '/account',
    }
  },
  computed: {
    /*currentRouteName() {
      return this.$route.name;
    },*/
    isExactRoute() {
      return this.$route.path === '/account' || this.$route.path === '/account/';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');      
      //this.$router.replace('/');
      window.location.replace('/');
    }
  },
  created() {

  }
}
</script>

<style scoped>
  
  .gp-grid-temp-cols {
    /*grid-template-columns: repeat(3, minmax(0, 1fr));*/
    grid-template-columns: 35% 33% 32%;
  }

  .gp-account-nav-menu {
    padding-top:94px;
    padding-left:130px;
  }

  .ribbon {
    height:166px; 
    background-image: url("../../assets/svg/ribbon.svg");
    background-size: cover;
    background-position: center;
    margin-top:67px;
    margin-bottom:35px;
  }
  @media (max-width:1600px) {
    .ribbon {
      height:150px; 
      margin-top:55px;
      margin-bottom:35px;
    }
  }
  @media (max-width:1400px) {
    .ribbon {
      height:120px; 
      margin-top:45px;
      margin-bottom:30px;
    }
  }
  @media (max-width:1300px) {
    .ribbon {
      height:100px; 
      margin-top:35px;
      margin-bottom:20px;
    }
  }

  @media (max-width:1600px) {
    .gp-grid-temp-cols {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .gp-account-content {
      padding-left:50px;
    }

    .gp-account-nav-menu {
      padding-left:120px;
    }
  }


  @media (max-width:1400px) {
    .gp-account-nav-menu {
      padding-left:110px;
    }
  }

  @media (max-width:1300px) {
    .gp-account-content {
      padding-left:25px;
    }
    .gp-account-nav-menu {
      padding-left:80px;
    }
  }

  @media (max-width:1200px) {
    .gp-account-nav-menu {
      padding-left:80px;
    }
  }

  @media (max-width:800px) {
    .gp-account-content {
      padding-left:0px;
    }
    .gp-grid-temp-cols {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  
  
  @media screen and (min-width: 2200px) {
    .gp-account-content {
      margin-left:110px;
    }
  }

  
@media (max-width:440px) {
  .ribbon {
    margin-left: -22px !important;
    margin-right: -22px !important;
  }  
}

</style>