<template>
  <!-- Full component wrapper (slider + scrollbar) -->
  <div class="slider-container overflow-hidden">
    <!-- Slider items carousel -->
    <div class="slider relative w-full overflow-x-hidden cursor-pointer" ref="slider" :style="{height: slideHeight+'px'}">
      <div class="slider-inner absolute top-0" :style="{left: sliderInnerX+'px', width: sliderInnerWidth+'px', height: slideHeight+'px'}"
           @mousedown="swipeMouseDownHandler"
           @touchstart="sliderTouchStartHandler"
           @mousemove="swipeMouseMoveHandler"
           @mouseup="mousePressed = false"
           @touchend="sliderTouchEndHandler"
      >
        <slot></slot>
        <div style="clear:both"></div>
      </div>
    </div>

    <!-- Scrollbar + Prev/Next buttons -->
    <div
        class="flex items-center row-mob"
        :class="{'hidden': slideCount === 1, 'sm:hidden': !scrollbar}"
        :style="scrollbarStyling"
        id="slider-navigation"
    >
      <!--<font-awesome-icon :icon="['fas', 'angle-left']" />-->
      <div class="hidden sm:flex cursor-pointer" id="navigation-prev" style="" @click="showPrev(1)">
        <img src="../../assets/svg/arrow-prev.svg"/>
        <span class="text-15 px-4 text-medium manrope">{{$t('labels.previous')}}</span>
      </div>

      <div class="scrollbar flex-grow relative" style="max-width: 1052px; height: 1px; background-color: rgb(122, 122, 122);" ref="scrollbar">
        <div class="scroll-btn bg-21 absolute cursor-pointer" style="height: 5px !important; top: -2px !important;" ref="scroll-btn"
             :style="{left: scrollbarButtonX+'px', width: scrollbarButtonWidth+'px', transition: scrollbarButtonTransition}"
             @mousedown="sliderScrollbarMouseDown"
        ></div>
      </div>

      <!--<font-awesome-icon icon="angle-right"/>-->
      <div class="hidden sm:flex cursor-pointer" id="navigation-next" style="" @click="showNext(1)">
        <span class="text-15 px-4 text-medium manrope">{{$t('labels.next')}}</span>
        <img src="../../assets/svg/arrow-next.svg"/>
      </div>
    </div>

    <!-- Corner Prev/Next Buttons -->
    <div v-if="cornerPrevNext && shouldDisplayCornerButtons" class="hidden sm:flex cursor-pointer absolute gp-top-25-- left-5 slider-top-nav-prev" style="top:80px" @click="showPrev(1)">
      <img src="../../assets/svg/arrow-left-circle.svg"/>
      <!--<span class="text-sm px-4 text-medium manrope">{{$t('labels.previous')}}</span>-->
    </div>
    <div v-if="cornerPrevNext && shouldDisplayCornerButtons" class="hidden sm:flex cursor-pointer absolute gp-top-25-- right-32-- slider-top-nav-next" style="top:80px" @click="showNext(1)">
      <!--<span class="text-sm px-4 text-medium manrope">{{$t('labels.next')}}</span>-->
      <img src="../../assets/svg/arrow-right-circle.svg"/>
    </div>

    <div v-if="debug">
      scrollbarWidth: {{ this.scrollbarWidth }} <br/>
      scrollbarButtonWidth: {{ scrollbarButtonWidth }} <br/>
      scrollbarWidth/scrollbarButtonWidth: {{ scrollbarWidth/scrollbarButtonWidth }} <br/>
      scrollbarButtonX: {{ scrollbarButtonX }} <br/>
      scrollbarButtonX + scrollbarButtonWidth: {{ scrollbarButtonX + scrollbarButtonWidth }}
      condition "scrollbarWidth > scrollbarButtonX + scrollbarButtonWidth": {{ scrollbarWidth > scrollbarButtonX + scrollbarButtonWidth }}
      <hr/>
      this.eventStartScrollbarButtonX: {{ this.eventStartScrollbarButtonX }}
      <hr/>
      this.sliderInnerWidth: {{ this.sliderInnerWidth }} <br/>
      this.sliderStepWidth: {{ this.sliderStepWidth }} <br/>
      this.sliderInnerWidth/this.sliderStepWidth: {{ this.sliderInnerWidth/this.sliderStepWidth }} <br/>
      Math.floor( this.sliderInnerWidth / this.sliderStepWidth): {{ Math.floor( this.sliderInnerWidth / this.sliderStepWidth) }} <br/>
      this.slideCount: {{ this.slideCount }} <br/>
      this.visibleSlides: {{ this.visibleSlides }} <br/>
      this.slideIndex: {{ this.slideIndex }} <br/>
      this.maxSlideIndex: {{ this.maxSlideIndex }} <br/>
      cornerPrevNext: {{ cornerPrevNext }} <br/>
      shouldDisplayCornerButtons {{ shouldDisplayCornerButtons }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSlider",
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    slideCount: {
      type: Number,
      default: 5
    },
    slideWidth: {
      type: Number,
      default: 220
    },
    slideHeight: {
      type: Number,
      default: 220
    },
    scrollbar: {
      type: Boolean,
      default: true
    },
    cornerPrevNext: {
      type: Boolean,
      default: false
    },
    scrollbarStyling: {
      type: Object,
      default: null,
    }
  },
  watch: {
    // slideCount() {
    //   console.log('Slide count changed.');
    //   this.setElements();
    // },
    // slideWidth() {
    //   // console.log('Slide width changed.');
    //   this.setElements();
    // }
  },
  data() {
    return {
      // screen width - we need the initial value to detect any screen x-resize events
      initialWindowWidth: window.innerWidth,

      // slide item width WITH margin
      sliderStepWidth: 0,
      visibleSlides: 0,
      slideIndex: 0,
      maxSlideIndex: 0,
      sliderInnerWidth: 0,
      scrollbarWidth: 0,
      scrollbarButtonWidth: 0, // return this.$refs['scroll-btn'] ? this.$refs['scroll-btn'].clientWidth : -1;
      scrollbarButtonTransition: 'all 0.5s',

      // slider inner
      sliderInnerX: 0,
      mousePressed: false,
      touchStartX: 0,
      touchEndX: 0,

      // slide items
      swipeStartX: 0,
      swipeEndX: 0,

      // scrollbar button
      scrollbarButtonMousePressed: false,
      eventStartScrollbarButtonX: 0,
      scrollbarButtonX: 0,
    }
  },
  computed: {
    shouldDisplayCornerButtons() {
      //return this.visibleSlides + 1 < this.slideCount;
      return this.visibleSlides < this.slideCount;
    },
    swipeSlidesNr() {
      let mysliderStepWidth = this.slideWidth; // + 20;
      let mysliderInnerWidth = this.$refs['slider'].clientWidth; // ? this.$refs['slider'].clientWidth : -1;
      let myVisibleSlides =  Math.floor( mysliderInnerWidth / mysliderStepWidth);
      console.log('myVisibleSlides=' + myVisibleSlides);
      if (window.innerWidth<=590) {
        return 1;
      }
      else {
        return myVisibleSlides;
      }
    }
  },
  methods: {
    setElements() {
      // console.log('Starting setElements');
      this.sliderStepWidth = this.slideWidth; // + 20;

      this.sliderInnerWidth = this.$refs['slider'].clientWidth; // ? this.$refs['slider'].clientWidth : -1;

      this.visibleSlides =  Math.floor( this.sliderInnerWidth / this.sliderStepWidth); // - 1;

      if (this.visibleSlides <= 0) {
        this.visibleSlides = 1;
      }

      // console.warn( this.sliderInnerWidth);
      // console.warn(this.sliderStepWidth);
      // console.warn(Math.floor( this.sliderInnerWidth / this.sliderStepWidth));
      // console.warn(Math.floor( this.sliderInnerWidth / this.sliderStepWidth) - 1);

      this.sliderInnerWidth = this.slideCount * this.sliderStepWidth;
      console.log('GP.sliderInnerWidth=' + this.sliderInnerWidth);

      if (this.scrollbar) {
        this.scrollbarWidth = Math.ceil(this.$refs['scrollbar'].clientWidth); // ? this.$refs['scrollbar'].clientWidth : -1;
        this.scrollbarButtonWidth = Math.ceil(this.scrollbarWidth / (this.slideCount - this.visibleSlides + 1));
      } else {
        // just a random value so that the component works
        this.scrollbarWidth = 1000;
        this.scrollbarButtonWidth = Math.ceil(this.scrollbarWidth / (this.slideCount - this.visibleSlides + 1));
      }

      // console.log(
      //     this.sliderInnerWidth,
      //     '-------------',
      //     // this.$refs['scrollbar'].clientWidth,
      //     // this.$refs['scrollbar'].scrollWidth,
      //     // this.$refs['scrollbar'].offsetWidth,
      //     this.scrollbarWidth,
      //     this.scrollbarButtonWidth,
      //     this.slideCount,
      //     this.visibleSlides
      // );

      // auxiliary
      this.maxSlideIndex = this.slideCount - this.visibleSlides; //- 1;

      // console.log(
      //     'End of "set elements"',
      //     // this.$refs['scrollbar'].clientWidth,
      //     'scrollbarWidth: ' + this.scrollbarWidth,
      //     'scrollbarButtonWidth: ' + this.scrollbarButtonWidth,
      //     'visibleslides: ' + this.visibleSlides,
      // );
    },
    showNext(steps) {

      // console.log('-----------------');
      // console.log('this.scrollbarButtonX | ' + this.scrollbarButtonX);
      // console.log('this.scrollbarButtonX + this.scrollbarButtonWidth | ' + (this.scrollbarButtonX + this.scrollbarButtonWidth));
      // console.log('-----------------');
      //
      // const scrollbarButtonMax_X = Math.floor(this.scrollbarWidth - this.scrollbarButtonWidth);
      //
      // console.log('scrollbarButtonMax_X: ' + scrollbarButtonMax_X);
      // console.log('-----------------');

      const maxSteps = this.maxSlideIndex - this.slideIndex;

      if (steps > maxSteps) {
        steps = maxSteps;
      }

      // +1 fixes a bug regarding going "next" on the very last slide
      if (this.scrollbarWidth > this.scrollbarButtonX + this.scrollbarButtonWidth) {
        this.sliderInnerX -= this.sliderStepWidth * steps;
        this.scrollbarButtonX += this.scrollbarButtonWidth * steps;
        this.slideIndex += steps;
      }
    },
    showPrev(steps) {
      for (let index = 0; index < steps; index++) {
        if (this.sliderInnerX < 0 && this.slideIndex > 0) {
          this.sliderInnerX += this.sliderStepWidth;
          this.scrollbarButtonX -= this.scrollbarButtonWidth;
          this.slideIndex--;
        }
      }
    },

    sliderScrollbarMouseDown(event) {
      this.scrollbarButtonMousePressed = true;
      this.eventStartScrollbarButtonX = this.scrollbarButtonX;
      this.mousePressX = event.clientX;
      this.disableScrollbarButtonTransitions();
    },
    sliderScrollbarMouseUp(event) {
      this.scrollbarButtonMousePressed = false;
      this.enableScrollbarButtonTransitions();

      // we use Math.ceil so that even the slight drag/swipe will result in a step move
      let steps = Math.ceil((event.clientX - this.mousePressX) / this.scrollbarButtonWidth);
      let residualLeft = event.clientX - this.mousePressX;

      // console.log('arithm: ' + (event.clientX - this.mousePressX)/this.scrollbarButtonWidth);
      // console.log('arithm ceil: ' + Math.ceil((event.clientX - this.mousePressX)/this.scrollbarButtonWidth));
      // console.log('steps: ' + steps);
      // console.log('residualLeft: ' + residualLeft);

      if (residualLeft === 0) {
        return;
      }

      // reset scrollbarButtonX
      this.scrollbarButtonX = this.eventStartScrollbarButtonX;

      // console.log('event.clientX: ' + event.clientX);
      // console.log('this.mousePressX: ' + this.mousePressX);
      // console.log('this.scrollbarBtnWidth:' + this.scrollbarButtonWidth);
      // console.log('steps:' + steps);
      // console.log('residualLeft:' + residualLeft);

      // console.log(this.slideCount, this.visibleSlides);

      // if (steps > this.slideCount - this.visibleSlides - 1) {
      //   steps = this.slideCount - this.visibleSlides - 1;
      // }

      if (steps > 0) {
        this.showNext(steps);
      } else {
        this.showPrev(-steps + 1);
      }
    },
    sliderScrollbarMouseMove(event) {
      if (!this.scrollbarButtonMousePressed) {
        return;
      }

      event.preventDefault();

      let left = this.eventStartScrollbarButtonX + event.clientX - this.mousePressX;
      const maxLeft = this.scrollbarWidth - this.scrollbarButtonWidth;

      if (left < 0) {
        left = 0;
      } else if (left > maxLeft) {
        left = maxLeft;
      }

      this.scrollbarButtonX = left;
    },
    enableScrollbarButtonTransitions() {
      this.scrollbarButtonTransition = 'all 0.5s';
    },
    disableScrollbarButtonTransitions() {
      this.scrollbarButtonTransition = 'none';
    },

    swipeMouseDownHandler(event) {
      event.preventDefault();
      this.swipeStartX = event.screenX;
      this.mousePressed = true;
    },
    swipeMouseMoveHandler(event) {
      if (!this.mousePressed) {
        return;
      }

      if (event.screenX > this.swipeStartX) { // swipe left
        this.showPrev(1);
      } else if (event.screenX < this.swipeStartX) { // swipe right
        this.showNext(1);
      }

      // stop swiping
      this.mousePressed = false;
    },


    sliderTouchStartHandler(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    sliderTouchEndHandler(event) {
      this.touchEndX = event.changedTouches[0].screenX;

      if (this.touchStartX > this.touchEndX) { // swipe right
        this.showNext(this.swipeSlidesNr); //1
      } else if (this.touchStartX < this.touchEndX) {
        this.showPrev(this.swipeSlidesNr); //1
      }
    },

    screenResizeHandler() {
      /*if (window.innerWidth != this.initialWindowWidth) {
        let w = window.innerWidth;
        if (w>800) {
          location.reload();
        }        
      }*/
      if (Math.abs(window.innerWidth - this.initialWindowWidth)>50) {
        location.reload();1100
      }
    }
  },
  created() {
    window.addEventListener("resize", this.screenResizeHandler);

    window.addEventListener('mouseup', (event) => {
      if (this.scrollbarButtonMousePressed) {
        this.sliderScrollbarMouseUp(event)
      }

      // if (this.mousePressed) {
      //   event.preventDefault();
      // }

      this.mousePressed = false;
      this.scrollbarButtonMousePressed = false;
    });

    window.addEventListener('mousemove', (event) => {
      if (this.scrollbarButtonMousePressed) {
        this.sliderScrollbarMouseMove(event);
      }
    });

    // window.addEventListener('load', () => {
    //   this.setElements();
    // });
  },
  mounted() {
    this.setElements();
    // this.$nextTick(() => {  });

    // use this workaround to calculate the correct clientWidth for
    // the scrollbar as it is dynamic and not static
  }
}
</script>

<style scoped>




.slider {
  position:relative;
  /*margin-bottom:30px;*/
}

.slider-inner {
  transition:all 0.5s;
  overflow-y:hidden;
}

#slider-navigation {
  padding-left:70px;
  padding-right:50px;
  margin-bottom:40px;
}

@media screen and (max-width:600px) {
.row-mob {
  /* margin-top: -80px !important; */
}  
}

@media screen and (max-width:1600px) {
#slider-navigation {
  padding-left:30px;
  padding-right:70px;
}  
}

@media screen and (max-width:1400px) {
#slider-navigation {
  padding-left:30px;
  padding-right:90px;
}  
}



.slider-top-nav-next {
  right:128px;
}

@media (max-width:1400px) {
  .slider-top-nav-next {
    right:30px;
  }
}


@media screen and (max-width:590px) {
#slider-navigation {
  padding-left:22px;
  padding-right:22px;
}  
}



.slider-top-nav-prev, .slider-top-nav-next {
  opacity:0;
  transition:all 0.5s;
  z-index:999999;
}

@media screen and (min-width:1200px) {
.slider-container:hover .slider-top-nav-prev, 
.slider-container:hover .slider-top-nav-next {
  opacity:1;
}
}

.slider-container {
  position: relative;
}


</style>