<template>
    <div v-if="!cardHasBeenClosed" class="card max-w-2xl rounded-2xl">
        <div v-if="hasHeaderSlot" class="header p-4 rounded-t-2xl bg-white flex" :class="{'rounded-2xl': !bodyIsVisible, 'border-b-2': bodyIsVisible}">
            <div class="flex-grow">
                <slot name="header"></slot>
            </div>
            <div v-if="controls" class="flex-shrink text-gray-500" id="controls">
                <a @click="toggleBody" class="link link-default">
                    <i  class="fal fa-minus fa-icon"></i>
                </a>
                <a @click="selfDestruct" class="link link-default hover:link-danger">
                    <i  class="fal fa-times fa-icon"></i>
                </a>
            </div>
        </div>
        <transition name="slide-down">
            <div v-if="bodyIsVisible" class="body p-4" :class="{'bg-white rounded-2xl': !hasHeaderSlot && !hasFooterSlot}">
                <slot></slot>
            </div>
        </transition>
        <div v-if="hasFooterSlot" class="footer p-4 rounded-b-2xl bg-white border-t-2">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseCard",
    props: {
        controls: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            bodyIsVisible: true,
            cardHasBeenClosed: false,
        }
    },
    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        }
    },
    methods: {
        toggleBody() {
            this.bodyIsVisible = !this.bodyIsVisible;
        },
        selfDestruct() {
            this.cardHasBeenClosed = true;
        }
    },
}
</script>

<style scoped>
.card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.slide-down-enter-from,
.slide-down-leave-to {
    max-height: 0;
    overflow: hidden;

}

.slide-down-enter-active,
.slide-down-leave-active {
    transition: all .3s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
    max-height: 300px;
    overflow: hidden;
}
</style>
