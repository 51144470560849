<template>
  <arrow-filters style="margin-bottom: 65px;"/>
  <div class="" v-html="liveHtml"/>
</template>

<script>
import ArrowFilters from "@/components/ArrowFilters";

export default {
  name: "RemoteContentAbout2",
  components: {ArrowFilters},
  data() {
    return {
      html: '',
    }
  },
  computed: {
    liveHtml() {
      return this.html;
    }
  },
  methods: {
    remoteContentHasBeenLoaded() {
      let year_dots = document.getElementsByClassName('year-dot');
      console.log(year_dots);     // looks good
      console.log(year_dots.length); // looks good as well
    }
  },
  async created() {
    let response = await this.$store.getters['app/axios'].get('remote-content-about2.php')
    this.html = response.data;

    setTimeout(() => {
      this.remoteContentHasBeenLoaded();
    }, 500);

    // this.remoteContentHasBeenLoaded();

    // document.addEventListener("DOMContentLoaded", function() {
    //     console.log("..on created"); //δεν μπαίνει καθόλου εδώ...
    //   let year_dots = document.getElementsByClassName('year-dot');
    //   console.log(year_dots);     //<= παίζει OK, φέρνει τη Nodelist
    //   console.log(year_dots.length);
    // });


  },
  async mounted() {
    //let response = await this.$store.getters['app/axios'].get('remote-content-about.php')
    //this.html = response.data;

    /*console.log("..on mounted..");  //αυτο ειναι οκ

    document.addEventListener("DOMContentLoaded", function() {     
      
      console.log("..on content loaded.."); //ok

      let year_dots = document.getElementsByClassName('year-dot');
      console.log(year_dots);     //<= παίζει OK, φέρνει τη Nodelist 
      console.log(year_dots.length); //<= επιστρέφει 0 ............ 
      console.log(document.getElementsByClassName('year-dot').length); //<= επιστρέφει 0 ............

    });*/
  }
}
</script>

<style scoped>

</style>

<style>

</style>