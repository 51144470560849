<template>
  <div class="manrope max-w-xl gp-ml-30-- gp-sm-ml-0 gp-nav-account-mb-pad">
    <ul class="w-full border-1-xx rounded-xl-xx bg-darkbeige gp-nav-account">
      <li v-for="menuItem in menuItems" :key="menuItem.href"
          class="flex cursor-pointer border-b-1 ">
        <router-link v-if="!menuItem.href.endsWith('logout')"
           class="account-navigation-menu-item flex-auto p-4-xx sm:p-8-xx block --rounded-xl text-20 gp-sm-txt-15 text-medium gp-sm-txt-medium"
           :href="prefix + menuItem.href"
           :to="prefix + menuItem.href"
        >
          {{ menuItem.label }}
        </router-link>
        <a v-else class="account-navigation-menu-item flex-auto p-4-xx sm:p-8-xx block cursor-pointer  --rounded-xl-- text-20 gp-sm-txt-15 text-medium gp-sm-txt-medium" @click="logout">
          {{ menuItem.label }}
        </a>
        <div class="arrow-img w-12 h-6 self-center mr-4" v-if="arrowIcon"/>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AccountNavigationMenu",
  props: {
    menuItems: {
      type: Array,
    },
    prefix: {
      type: String,
      default: '',
    },
    arrowIcon: {
      type: Boolean,
      default: false
    },
    
  },
  data() {
    return {
      whiteArrowSrc: '',
      blackArrowSrc: '',
    }
  },
  computed: {
    //
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');

      this.$router.replace('/');
    }
  }
}
</script>

<style scoped>
  /*li:hover {
    background-color: #212121;
  }*/

  

  /*li:first-child, li:first-child a.account-navigation-menu-item {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  li:last-child, li:last-child a.account-navigation-menu-item {
    border-bottom: 0;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }*/

  div.arrow-img {
    background: url("../../assets/icons/black-arrow.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  li:hover div.arrow-img {
    background: url("../../assets/icons/white-arrow.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .gp-nav-account {
    border-radius:12px;
    /*z-index: -1;*/
  }
  .gp-nav-account li:nth-child(1) {
    border:1px solid #212121;
    background:#fffcf0;
    border-radius:12px;
    z-index: 2;
    position:relative;
  }
  .gp-nav-account li:nth-child(2) {
    border:1px solid #212121;
    background:#fffcf0;
    border-radius:12px;
    margin-top:-20px;
    z-index: 1;
    position:relative;
  }
  .gp-nav-account li:nth-child(2) a {
    padding-top:50px;
  }

  a.account-navigation-menu-item {
    padding:30px;
    border-radius:11px;
    background-color: #fffcf0;
  }
  @media (max-width:1400px) {
    a.account-navigation-menu-item {
      padding:25px 30px;
    }
  }


  .gp-nav-account li {
    position:relative;

  }
  .gp-nav-account li:hover {
    background:#fffcf0 !important;
    position:relative;
    border-radius:12px;
    /*border:transparent;*/
    color:#212121;
  }

  

  .gp-nav-account li:hover:before {
    position:absolute;
    top:-1px;
    left:-1px;
    right:-1px;
    bottom:-1px;
    content: '';
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5907d1+0,360ed1+60,1415d1+78,4640e1+94,9888fc+100 */
    background: #5907d1; /* Old browsers */
    background: -moz-linear-gradient(left,  #5907d1 0%, #360ed1 60%, #1415d1 78%, #4640e1 94%, #9888fc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5907d1', endColorstr='#9888fc',GradientType=1 ); /* IE6-9 */
    z-index: -1;
    border-radius:12px;
}


  @media (min-width: 800px) {
    ul {
      background-color: transparent;
    }
  }

  
  @media (max-width:420px) {
    .gp-nav-account li:nth-child(1) {
      z-index: 3;
    }
    .gp-nav-account li:nth-child(2) {
      z-index: 2;
    }
    .gp-nav-account li:nth-child(3) {
      border:1px solid #212121;
      background:#fffcf0;
      border-radius:12px;
      margin-top:-20px;
      z-index: 1;
      position:relative;
    }
    .gp-nav-account li:nth-child(4) {
      border:1px solid #212121;
      background:#fffcf0;
      border-radius:12px;
      margin-top:-20px;
      /* z-index: 1; */
      position:relative;
    }
    .gp-nav-account li:nth-child(2) a {
      padding-top: 38px;
    }
    div.arrow-img{display: none;}
    .gp-nav-account li:nth-child(3) a {
      padding-top: 38px;
    }
    .gp-nav-account li:nth-child(4) a {
      padding-top: 38px;
    }

  }

  
  @media (max-width:360px) {
      .gp-nav-account-mb-pad{padding-bottom: 50px;}
  }

</style>