<template>
  <div class="slide">
    <a :href="$router.resolve(item.url).href"
       class="h-full p-2 slider-item flex flex-col items-center justify-end text-center text-sm cursor-pointer">
      <img :src="item.imageUrl" class="mb-8-- gp-mb-42-- gp-slide-img" style="" alt=""/>
      <div class="text-dark-gray text-14 text-light gp-slide-title">
        <div :title="item.name">{{ productShortname(item.name) }}</div>
      </div>
      <!--<div class="gp-mb-22 text-15 text-medium gp-slide-itemcount" style="color: #4D4D4D;">{{ item.quantity }} {{ item.quantity === 1 ? 'item' : 'items' }}</div>-->
    </a>
  </div> 
</template>

<script>
export default {
  name: "ProductSliderItem",
  components: {},
  props: ['item'],
  data() {
    return {}
  },
  methods: {
    productShortname(productname) {
      if (productname.length<40) {
          return productname;
      }
      else {
          return productname.substring(0,40) + '...';
      }
    }
  },
}
</script>

<style scoped>
/*.slide {
  float:left;
  width: 330px;
  height: 360px;
  margin: 0px 0px 10px 0px;
  background-color: transparent;
}*/
.slide {
  float:left;
  position: relative;
  width: 213px;
  height: 220px;
  margin: 0px 0px 10px 0px;
  background-color: transparent;
  background-image: url("../../assets/svg/slide-bg-octag-white-fill.svg");
  background-size: 213px 220px;
  margin-right:1px;
  margin-left:1px;
  margin-top:1px;
}
.slide:hover {
  background-image: url("../../assets/svg/slide-bg-octag-blueborder-deg-white-fill.svg");
  z-index:9999;
}

.slide:not(:first-child) {
  margin-left:-1px;
}

/*.slide:before {
    position:absolute;
    top:-1px;
    right:-1px;
    bottom:-1px;
    left:-1px;
    content:'';
    background-image: url(../../assets/svg/octagon-gray.svg);
    background-size: 100% 100%;
    z-index:-1;
}
.slide:hover:before {
    background-image: url(../../assets/svg/octagon-degrade.svg);
}*/


.gp-slide-img {
  height: 150px; width: auto; /**140 */
}

@media (max-width:1400px) {
.gp-slide-img {
  height: 150px;  /**130 */
}
}



.gp-slide-title {
  height:30px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height:16px;
  margin-bottom:10px;
}



@media (min-width:2200px) {
  .slide {
    /*width: 400px;
    height: 380px;
    margin: 0px 0px 10px 0px;*/
  }

  .gp-slide-img {
    /*height: 220px; width: auto;*/
  }
}

/*@media (max-width:1800px) {
.slide {
  width: 350px;
  height: 360px;
  margin: 0px 0px 10px 0px;
}
}


@media (max-width:1600px) {
.slide {
  width: 350px;
  height: 360px;
  margin: 0px 0px 10px 0px;
}
}

@media (max-width:1400px) {
.slide {
  width: 300px;
  height: 300px;
  margin: 0px 0px 10px 0px;
}
}

@media (max-width:1300px) {
.slide {
  width: 300px;
  height: 300px;
  margin: 0px 0px 10px 0px;
}
}*/


/* Mobile */
/*@media screen and (max-width:590px) {
  .slide {
    width: calc(100vw - 40px);
    margin: 0;
  }
}*/

.slider-item:hover, .slider-item.selected {
  /*background-color: #F0EFE2;*/
}
</style>
