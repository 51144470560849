<template>
    <arrow-filters style="margin-bottom: 60px;"/>
    <div class="" v-html="liveHtml"/>

  </template>
  
  <script>
  import ArrowFilters from "@/components/ArrowFilters";
  export default {
    name: "UploadCartItems",
    components: {ArrowFilters},
    data() {
      return {
        html: '',
      }
    },
    computed: {
      liveHtml() {
        return this.html;
      },
      
    },
    async created() {
      let my_lang = this.$store.getters['app/locale'];
      let sellertoken = localStorage.getItem('sellertoken')===null? "": localStorage.getItem('sellertoken');
      let response = await this.$store.getters['app/axios'].get('upload-cart-items.php?lang=' + my_lang + '&accesstoken=' + this.$store.getters['auth/token'] + '&sellertoken=' + sellertoken);
      this.html = response.data;
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>