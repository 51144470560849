<template>
  <div class="invoine-line flex flex-col border-1 border-21 rounded-lg p-4 font-semibold cursor-pointer" style="background-color: #F0EFE2;">
    <p class="invoice-line-itemname text-23 gp-sm-txt-20 text-medium ">{{ item.item_description }}</p>

    <transition name="slide-down-fast">
      <div v-if="expanded" class="grid grid-cols-2 md:grid-cols-4 gap-2-xx mt-8-- gp-mt-20 text-14 text-medium invoice-line-item-details">
        <div class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ item.item_code }}
        </div>        

        <div class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ item.quantity_pcs }} {{ $t('labels.pcs') }}
        </div>

        <div class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ item.package_description }}
        </div>

        <div class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ item.quantity_packages }} {{ $t('labels.packages') }}
        </div>


        <div v-if="item.initial_price>0" class="col-span-2 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.initial-price') }} / {{ prettyNum(item.initial_price) }} {{ $t('labels.per') }} {{ item.unit_description }}
        </div>

        <div v-if="item.discount_1>0" class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.discount1') }} / {{ numParse(item.discount_1) }}%
        </div>

        <div v-if="item.discount_2>0"  class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.discount2') }} / {{ numParse(item.discount_2) }}%
        </div>

        <div v-if="item.total_discount>0"  class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.discount_total') }} / {{ numParse(item.total_discount) }}%
        </div>

        <div v-if="item.final_price>0" class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.final-price') }} / {{ prettyNum(item.final_price) }}
        </div>

        <div v-if="item.total_price>0" class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.total-line') }} / {{ prettyNum(item.total_price) }}
        </div>

        <div v-if="item.total_vat>0" class="col-span-1 line-item-box flex items-center justify-center bg-beige-xx">
          {{ $t('labels.vat') }} / {{ prettyNum(item.total_vat) }}
        </div>



      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "InvoiceLineItem",
  inject: ['prettyNum'],
  emits: ['add-package', 'subtract-package'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    numParse(x) {
      if (x === undefined || x === null) {
        return '';
      }

      if (typeof x === 'number') {
        return x;
      }

      return x; // parseFloat(x.replace('.', ''));
    }
  }
}
</script>

<style scoped>
  .line-item-box {
    border: 1px solid #212121;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    padding: 12px 10px;
    line-height: 16px;
    max-height: 45px;
    margin-left: -1px;
    margin-top:-1px;
  }

  .invoice-line-itemname {
    line-height: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width:800px) {
    .invoice-line-itemname {
      line-height: 22px;
    }
  }

    @media (max-width:440px) {
    .invoice-line-itemname {
    padding-left: unset;
    padding-right: unset;
    }
  }

  .invoice-line-item-details > div {
    
  }

  
  @media (max-width:820px) {
    .invoice-line-item-details {
      grid-gap: 10px;
    }
  }

</style>