<template>
  <!-- desktop only -->
  <div class="mx-auto hidden sm:block gp-arrow-filters" style="">
    <div class="filter-selector text-regular">
      <div 
        class="filter-selector-item filter-selector-fasteners" 
        :class="{selected: this.$store.getters['filters/filterGroupType']=='fastener'}"
        @click="showFastenerFilterGroup">
        {{ lang_gr? 'Αναζήτηση βιδών': 'Fasteners filters'  }}
      </div>
      <div 
        class="filter-selector-item filter-selector-tools"
        :class="{selected: this.$store.getters['filters/filterGroupType']=='tool'}" 
        @click="showToolFilterGroup">
        {{ lang_gr? 'Αναζήτηση εργαλείων': 'Tools filters'  }}
      </div>
    </div>

    <transition name="fade-in">
      <div class="flex arrow-filters-fasteners" v-if="this.$store.getters['filters/filterGroupType']=='fastener'">
        <arrow-shaped-nav
            :index="0"
            class="din"
            :class="{selected: selectedFilterCategory === 'din'}"
            title="DIN/ISO"
            @click="setSelectedFilterCategory('din')"
        ></arrow-shaped-nav>
        <arrow-shaped-nav
            :index="1"
            class="type"
            :class="{selected: selectedFilterCategory === 'type'}"
            :title="$t('labels.type')"
            @click="setSelectedFilterCategory('type')"
        ></arrow-shaped-nav>
        <arrow-shaped-nav
            :index="2"
            class="metrics last"
            :class="{selected: selectedFilterCategory === 'metrics'}"
            :title="$t('labels.metrics')"
            @click="setSelectedFilterCategory('metrics')"
        ></arrow-shaped-nav>
      </div>
    </transition>
    

    <transition name="fade-in">
      <div class="flex arrow-filters-tools" v-if="this.$store.getters['filters/filterGroupType']=='tool'">
        
        <arrow-shaped-nav
            :index="1"
            class="toolbrand"
            :class="{selected: selectedFilterCategory=='toolbrand'}"
            :title="$t('labels.brand')"
            @click="setSelectedFilterCategory('toolbrand')"
        ></arrow-shaped-nav>
        <arrow-shaped-nav
            :index="2"
            class="tooltype"
            :class="{selected: selectedFilterCategory=='tooltype'}"
            :title="$t('labels.type')"
            @click="setSelectedFilterCategory('tooltype')"
        ></arrow-shaped-nav>
      </div>
    </transition>
    
    

    <div id="content" class="h-auto gp-content" :style="{'min-height': contentMinHeight}">
      <transition name="fade-in-gp">
        <din-input
            v-if="selectedFilterCategory === 'din'"
            :din="dinFilterIsActive ? activeFilters.din.val : null"
        ></din-input>
      </transition>

      <transition name="fade-in-gp">
        <type-browse-section
            v-if="selectedFilterCategory === 'type'"
            ref="type-browse-section"
            :active-filters="activeFilters"
        ></type-browse-section> 
      </transition>

      <transition name="fade-in-gp">
        <metrics-browse-section
            ref="metrics-browse-section"
            v-if="selectedFilterCategory === 'metrics'"
            :active-filters="activeFilters"
        ></metrics-browse-section>
      </transition>

      <transition name="fade-in-gp">
        <tool-brand-browse-section
            ref="tool-brand-browse-section"
            v-if="selectedFilterCategory === 'toolbrand'"
            @toolbrand-selected="setToolBrandFilter"
            :active-filters="activeFilters"
        ></tool-brand-browse-section>
      </transition>

      <transition name="fade-in-gp">
        <tool-type-browse-section
            ref="tool-type-browse-section"
            v-if="selectedFilterCategory === 'tooltype'"
            @tooltype-selected="setToolTypeFilter"
            :active-filters="activeFilters"
        ></tool-type-browse-section>
      </transition>


    </div>

    <transition name="fade-in-gp">
      <!-- Active Type Filters -->
      <div class="w-full mx-auto manrope gp-active-filters-container" style="/*max-width: 932px;*/" v-if="filterIsActive">
        <!-- Header -->
        <div class="flex mb-1">
          <div class="w-1/2 text-xl">
            <span v-if="filterCount>1" class="active-filter-handler">{{ filterCount }} {{$t('labels.filters')}}</span>
            <span v-else-if="filterCount==1" class="active-filter-handler">{{ filterCount }} {{$t('labels.filter')}}</span>
          </div>
          <div class="w-1/2 text-sm text-right">
            <span class="border-b-1 cursor-pointer active-filter-handler" @click="clearAllFilters">{{ $t('labels.clear-all')}}</span>
          </div>
        </div>

        <!-- debug -->
        <!--{{ activeFilters }}-->

        <!-- Filter Labels -->
        <div class="w-full rounded-lg border-1 border-21 grid grid-cols-4 p-2 gap-2 gp-active-filters" style="max-width: 932px;">
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="dinFilterIsActive"
               @click="unsetDinFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">Din: {{ activeFilters.din.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>
          </div>
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="categoryFilterIsActive"
               @click="unsetCategoryFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">{{ activeFilters.category.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>
          </div>
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="headTypeFilterIsActive"
               @click="unsetHeaderTypeFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">Είδος κεφαλής: {{ activeFilters.headType.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>
          </div>
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="materialFilterIsActive"
               @click="unsetMaterialFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">{{ activeFilters.material.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']"/>
            </div>
          </div>
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="d1metricFilterIsActive"
               @click="unsetD1metricFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">Διάμ./Πλάτος: {{ activeFilters.d1metric.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>
          </div>
          <div class="col-span-1 text-sm text-center bg-21 p-2 text-white rounded-lg cursor-pointer"
               v-if="d2metricFilterIsActive"
               @click="unsetD2metricFilter"
          >
            <div class="flex items-center h-full">
              <span class="flex-grow">Μήκος: {{ activeFilters.d2metric.label }}</span> <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ArrowShapedNav from "@/components/arrow-filters/ArrowShapedNav";
import DinInput from "@/components/arrow-filters/din/DinInput";
import TypeBrowseSection from "@/components/arrow-filters/TypeBrowseSection";
import MetricsBrowseSection from "@/components/arrow-filters/MetricsBrowseSection";

import ToolBrandBrowseSection from "@/components/arrow-filters/ToolBrandBrowseSection";
import ToolTypeBrowseSection from "@/components/arrow-filters/ToolTypeBrowseSection";

export default {
  name: "ArrowFilters",
  emits: ['broadcast-filters'],
  provide() {
    return {
      setDinFilter: this.setDinFilter,
      unsetDinFilter: this.unsetDinFilter,
      setCategoryFilter: this.setCategoryFilter,
      unsetCategoryFilter: this.unsetCategoryFilter,
      setHeadTypeFilter: this.setHeadTypeFilter,
      unsetHeadTypeFilter: this.unsetHeadTypeFilter,
      setMaterialFilter: this.setMaterialFilter,
      unsetMaterialFilter: this.unsetMaterialFilter,
      setD1metricFilter: this.setD1metricFilter,
      unsetD1metricFilter: this.unsetD1metricFilter,
      setD2metricFilter: this.setD2metricFilter,
      unsetD2metricFilter: this.unsetD2metricFilter,
      // ------------------------------------------
      setFocusedFilter: this.setFocusedFilter,
      selectedDin: this.selectedDin,
    }
  },
  inject: ['clearAlgoliaSearch', 'getLastSearchType', 'setLastSearchType'],
  components: {
    ArrowShapedNav,
    DinInput,
    TypeBrowseSection,
    MetricsBrowseSection,
    ToolBrandBrowseSection,
    ToolTypeBrowseSection,
  },
  props: {
    contentMinHeight: {
      type: String,
      default: '0',
    }
  },
  data() {
    return {
      selectedFilterCategory: null,
      activeFilters: {
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
        focus: null, 
        toolBrand: null,
        toolType: null,               
      },
      /*activeToolFilters: {
        brand: null,
        toolType: null,
      },*/
      showFastenerFilters: false,
      showToolFilters: false,
    }
  },
  computed: {
    algoliaQueryParam() {
      return this.$route.query.algolia;
    },
    lastSearchType() {
      return this.getLastSearchType();
    },
    dinFilterIsActive() {
      return this.activeFilters.din !== null;
    },
    categoryFilterIsActive() {
      return this.activeFilters.category !== null;
    },
    headTypeFilterIsActive() {
      return this.activeFilters.headType !== null;
    },
    materialFilterIsActive() {
      return this.activeFilters.material !== null;
    },
    d1metricFilterIsActive() {
      return this.activeFilters.d1metric !== null;
    },
    d2metricFilterIsActive() {
      return this.activeFilters.d2metric !== null;
    },
    filterIsActive() {
      return this.dinFilterIsActive ||
          this.categoryFilterIsActive ||
          this.headTypeFilterIsActive ||
          this.materialFilterIsActive ||
          this.d1metricFilterIsActive ||
          this.d2metricFilterIsActive;
    },
    filterCount() {
      return (this.dinFilterIsActive ? 1 : 0) +
          (this.categoryFilterIsActive ? 1 : 0) +
          (this.headTypeFilterIsActive ? 1 : 0) +
          (this.materialFilterIsActive ? 1 : 0) +
          (this.d1metricFilterIsActive ? 1 : 0) +
          (this.d2metricFilterIsActive ? 1 : 0);
    },
    isSearchPage() {
      return this.$route.path === '/search';
    },
    isSearchToolCatPage() {
      return this.$route.path === '/search-tool-categories';
    },
    lang_gr() {
      return this.$store.getters['app/locale']=="el"
    },
    isMobile() {
      return window.innerWidth <= 800;
    },
    
  },
  watch: {
    activeFilters: {
      handler(val) {        
        if (this.getLastSearchType() === 'algolia') {
          return;
        }
        // refresh results
        if (this.isSearchPage || this.isSearchToolCatPage) {
          this.$emit('broadcast-filters', val);
        }
        const filtersQuery = this.getFiltersQuery();

        if (this.$store.getters['filters/filterGroupType']=='fastener') {
          this.$router.push('/search?'+filtersQuery);
        }
        else if (this.$route.query.din != undefined 
            || this.$route.query.category != undefined
            || this.$route.query.material != undefined
            || this.$route.query.dimension1 != undefined
            || this.$route.query.dimension2 != undefined) {
              this.$router.push('/search?'+filtersQuery);
        }
        else if (this.$store.getters['filters/toolType']==null 
          && (
            this.$route.query.din == undefined 
            && this.$route.query.material == undefined
            && this.$route.query.d1metric == undefined
            && this.$route.query.d2metric == undefined
            )) {
          console.log('redirecting to search-tool-categories....');
          this.$router.push('/search-tool-categories?'+filtersQuery);          
        }
        else {
          let my_category = this.$store.getters['filters/toolType'];
          let my_brand = this.$store.getters['filters/toolBrand']?? '';
          this.$router.push('/category/'+my_category + '/?toolbrand='+ my_brand);
          this.$store.commit('filters/clearFilterGroup');
        }
        
      },
      deep: true,
    },
   
    algoliaQueryParam(val) {
      if (val) {
        this.resetFilters();
        this.setSelectedFilterCategory(null);
      }
    }
    
  },
  methods: {
    // din|type|metrics
    setSelectedFilterCategory(mainTab) {
      this.selectedFilterCategory = mainTab;

      if (mainTab === 'type' && !['category', 'material'].includes(this.activeFilters.focus)) {
        this.setFocusedFilter('category');
      } else if (mainTab === 'metrics' && !['dimension1', 'dimension2'].includes(this.activeFilters.focus)) {
        this.setFocusedFilter('dimension1');
      }
    },
    setFocusedFilter(focusedFilter) {
      this.activeFilters.focus = focusedFilter;
    },
    resetFilters() {
      this.activeFilters = {
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
        focus: null,
        toolBrand: null,
        toolType: null,
      }
      this.$store.commit('filters/setToolBrand', null);
      this.$store.commit('filters/setToolType', null);
      
    },
    setDinFilter(din) {
      if (din === null || din === '') {
        return;
      }

      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.din = {
        label: din,
        val: din
      };

      this.activeFilters.focus = 'din';

      console.log(this.activeFilters.din.val);

    },
    selectedDin() {
      return this.activeFilters.din? this.activeFilters.din.val: "";
    },
    
    unsetDinFilter() {
      this.activeFilters.din = null;
    },
    
    setCategoryFilter(category) {
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.category = {
        label: category.name,
        val: category.id
      };

      this.activeFilters.focus = 'category';
    },
    unsetCategoryFilter() {
      this.activeFilters.category = null;
    },

    setMaterialFilter(material, page) {
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.material = {
        label: material.name,
        val: material.id,
        page: page
      };

      this.activeFilters.focus = 'material';
    },
    unsetMaterialFilter() {
      this.activeFilters.material = null;
    },
    setD1metricFilter(metric, page) {
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.d1metric = {
        label: metric.description,
        val: metric.slug,
        page: page
      };

      this.activeFilters.focus = 'dimension1';
    },
    unsetD1metricFilter() {
      this.activeFilters.d1metric = null;
    },
    setD2metricFilter(metric, page) {
      this.clearAlgoliaSearch();
      this.setLastSearchType('arrow-filters');

      this.activeFilters.d2metric = {
        label: metric.description,
        val: metric.slug,
        page: page
      };

      this.activeFilters.focus = 'dimension2';
    },
    unsetD2metricFilter() {
      this.activeFilters.d2metric = null;
    },

    setToolBrandFilter(toolbrand) {
      this.activeFilters.toolBrand = toolbrand;
    },

    setToolTypeFilter(tooltype) {
      this.activeFilters.toolType = tooltype;
    },


    async initActiveFilters() {
      if (this.$route.query.algolia !== undefined) {
        this.selectedFilterCategory = null;

        return;
      }

      // attack of the clones!
      let filters = Object.assign({}, this.activeFilters);

      /*
       * Din
       */
      if (this.$route.query.din !== undefined && this.$route.query.din !== '') {
        filters.din = {
          label: this.$route.query.din,
          val: this.$route.query.din,
        };
      }

      /*
       * Category
       */
      if (this.$route.query.category !== undefined && this.$route.query.category !== '') {
        if (!this.$store.getters['categories/categoriesLoaded']) {
          try {
            await this.$store.dispatch('categories/loadCategories');
          } catch (error) {
            // console.warn(error);
          }
        }

        let category = this.$store.getters['categories/categories'].find(category => category.id === this.$route.query.category);

        // console.log('this.$route.query.category: ', this.$route.query.category);
        // console.log('category: ', category);

        if (category !== undefined) {
          filters.category = {
            label: category.name,
            val: category.id,
          };
        }
      }

      /*
       * Material
       */
      if (this.$route.query.material !== undefined && this.$route.query.material !== '') {
        if (!this.$store.getters['filters/materialsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadMaterials', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let material = this.$store.getters['filters/materials'].find(material => material.id === this.$route.query.material);

        // console.log('this.$route.query.material: ', this.$route.query.material);
        // console.log('material: ', material);

        if (material !== undefined) {
          filters.material = {
            label: material.name,
            val: material.id,
          };
        }
      }

      /*
       * Metrics - D1
       */
      if (this.$route.query.dimension1 !== undefined && this.$route.query.dimension1 !== '') {
        if (!this.$store.getters['filters/d1metricsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadD1Metrics', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let dimension1 = this.$store.getters['filters/d1metrics'].find(dimension1 => dimension1.slug === this.$route.query.dimension1);

        if (dimension1 !== undefined) {
          filters.d1metric = {
            label: dimension1.description,
            val: dimension1.slug,
          };
        }
      }

      /*
       * Metrics - D2
       */
      if (this.$route.query.dimension2 !== undefined && this.$route.query.dimension2 !== '') {
        if (!this.$store.getters['filters/d2metricsLoaded']) {
          try {
            await this.$store.dispatch('filters/loadD2Metrics', {});
          } catch (error) {
            // console.warn(error);
          }
        }

        let dimension2 = this.$store.getters['filters/d2metrics'].find(dimension2 => dimension2.slug === this.$route.query.dimension2);

        if (dimension2 !== undefined) {
          filters.d2metric = {
            label: dimension2.description,
            val: dimension2.slug,
          };
        }
      }

      const focus = this.$route.query.focus;

      if (focus !== undefined && focus !== '') {
        filters.focus = focus;
      }

      if (focus === 'din') {
        this.selectedFilterCategory = 'din';
      } else if (focus === 'category' || focus === 'material') {
        this.selectedFilterCategory = 'type';
      } else if (focus === 'dimension1' || focus === 'dimension2') {
        this.selectedFilterCategory = 'metrics';
      }

      // console.log('init active filters finished activeFilters: ',  JSON.stringify(filters));
      // console.log('broadcasting filters to parent');

      // we do this so that every change on activeFilters does not
      // trigger the watcher function
      this.activeFilters = filters;

      this.$emit('broadcast-filters', this.activeFilters);
    },
    focusFilter() {
      // console.log('focusFilter start');

      if (this.$route.query.focus === undefined || this.$route.query.focus === '') {
        return;
      }

      const focus = this.$route.query.focus;

      this.activeFilters.focus = focus;

      // console.log('focus: ', focus);

      if (focus === 'din') {
        this.selectedFilterCategory = 'din';
      } else if (focus === 'category' || focus === 'material') {
        this.selectedFilterCategory = 'type';

        // the ref may have not been initialized
        // if (this.$refs['type-browse-section']) {
        //   console.log('TBS was found, focusing ..')
        //   this.$refs['type-browse-section'].focusFilter(focus);
        // } else {
        //   console.log('TBS was NOT found')
        // }
      } else if (focus === 'dimension1' || focus === 'dimension2') {
        this.selectedFilterCategory = 'metrics';

        // the ref may have not been initialized
        // if (this.$refs['metrics-browse-section']) {
        //   console.log('MBS was found, focusing ..')
        //   this.$refs['metrics-browse-section'].focusFilter(focus);
        // } else {
        //   console.log('MBS was NOT found')
        // }
      }
    },
    getFiltersQuery() {
      let queryFiltersArray = [];

      if (this.dinFilterIsActive) {
        queryFiltersArray.push('din='+this.activeFilters.din.val);
      }
      /*else if (this.$route.query.din != undefined) {
        queryFiltersArray.push('din='+this.$route.query.din);
      }*/

      if (this.categoryFilterIsActive) {
        queryFiltersArray.push('category='+this.activeFilters.category.val);
      }
      /*else if (this.$route.query.category != undefined) {
        queryFiltersArray.push('category='+this.$route.query.category);
      }*/

      if (this.materialFilterIsActive) {
        queryFiltersArray.push('material='+this.activeFilters.material.val);
      }
      /*else if (this.$route.query.material != undefined) {
        queryFiltersArray.push('material='+this.$route.query.material);
      }*/

      if (this.d1metricFilterIsActive) {
        queryFiltersArray.push('dimension1='+this.activeFilters.d1metric.val);
      }
      /*else if (this.$route.query.dimension1 != undefined) {
        queryFiltersArray.push('dimension1='+this.$route.query.dimension1);
      }*/

      if (this.d2metricFilterIsActive) {
        queryFiltersArray.push('dimension2='+this.activeFilters.d2metric.val);
      }
      /*else if (this.$route.query.dimension2 != undefined) {
        queryFiltersArray.push('dimension2='+this.$route.query.dimension2);
      }*/

      if (this.$store.getters['filters/toolBrand']!=null) {
        queryFiltersArray.push('toolbrand=' + this.$store.getters['filters/toolBrand']);
      }
      if (this.$store.getters['filters/toolType']!=null) {
        queryFiltersArray.push('tooltype=' + this.$store.getters['filters/toolType']);
      }

      if (this.activeFilters.focus !== null) {
        queryFiltersArray.push('focus='+this.activeFilters.focus);
      }

      // console.log('getFiltersQuery returned: ' + queryFiltersArray.join('&'))

      return queryFiltersArray.join('&');
    },
    clearAllFilters() {
      this.activeFilters = {
        din: null,
        category: null,
        headType: null,
        material: null,
        d1metric: null,
        d2metric: null,
        toolBrand: null,
        toolType: null,
      };
      this.$store.commit('filters/setToolBrand', null);
      this.$store.commit('filters/setToolType', null);
      //this.$router.push('/search');
      //this.$router.replace({'query': null});
      //window.history.pushState({}, '', '/search');
      
    },
    showFastenerFilterGroup() {
      this.$store.commit('filters/showFastenerFilterGroup');
      this.resetFilters();
      this.selectedFilterCategory = "";
    },
    showToolFilterGroup() {
      this.$store.commit('filters/showToolFilterGroup');
      this.resetFilters();
      this.selectedFilterCategory = "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log('ArrowFilters mounted activeFilters: ', JSON.stringify(this.activeFilters));
      // console.log('ArrowFilters mounted query params: ', JSON.stringify(this.$route.query));

      if (this.isSearchPage) {
        this.initActiveFilters();
        // this.focusFilter();
      }
    });
  }
}
</script>

<style scoped>

  .gp-arrow-filters {
    width: 800px; /*970px; */
    position:relative;
  }

  @media screen and (max-width:1600px) {
    .gp-arrow-filters {
      width: 695px;
    }

    .gp-content {
      width:932px;
      margin-left:-117px;
      margin-right:-117px;
    }

  }

  @media screen and (max-width:1400px) {
    .gp-arrow-filters {
      padding-left:25px;
    }
    .gp-content {
      margin-left:-130px;
    }
  }

  @media screen and (max-width:1300px) {
    .gp-arrow-filters {
      padding-left:52px;
    }
    .gp-content {
      margin-left:-160px;
    }
  }

  @media screen and (max-width:1100px) {
    .gp-arrow-filters {
      padding-left:90px;
    }

    .gp-content {
      margin-left:-200px;
    }

  }

  @media screen and (max-width:1000px) {
    
    .gp-content {
      margin-left:-208px;
    }

  }


  .gp-active-filters-container {
    max-width:932px;
    position:sticky;
    bottom:10px;
  }
  @media screen and (max-width:1000px) {
  .gp-active-filters-container {
    margin-left:-30px;
  }  
  }

  .gp-active-filters {
    background-color: rgb(255,254,242);
  }

  .active-filter-handler {
    padding:3px;
    text-shadow:
    -1px -1px 0 rgb(255,254,242),
     0   -1px 0 rgb(255,254,242),
     1px -1px 0 rgb(255,254,242),
     1px  0   0 rgb(255,254,242),
     1px  1px 0 rgb(255,254,242),
     0    1px 0 rgb(255,254,242),
    -1px  1px 0 rgb(255,254,242),
    -1px  0   0 rgb(255,254,242);
  }

  .filter-selector {
    display:grid;
    grid-template-columns: 50% 50%;    
  }

  .filter-selector-item {
    cursor:pointer;
    height:65px;
    margin-bottom:10px;
    font-size:23px;
    line-height: 25px;
    padding-top:26px;
    padding-left:30px;
    color:#212121;
  }
  .filter-selector-item.selected,
  .filter-selector-item:hover {
    color: #212121;
  }

  .filter-selector-fasteners {
    width:412px;
    background-image: url(../assets/svg/filtergroup-fasteners.svg);
    background-size: 100% 100%;
  }
  

  .filter-selector-tools {
    width:412px;
    background-image: url(../assets/svg/filtergroup-tools.svg);
    background-size: 100% 100%;
    margin-left:-18px;
  }

  

  .filter-selector-fasteners:hover,
  .filter-selector-fasteners.selected {
    background-image: url(../assets/svg/filtergroup-fasteners-active.svg);
  }

  .filter-selector-tools:hover,
  .filter-selector-tools.selected {
    background-image: url(../assets/svg/filtergroup-tools-active.svg);
  }

  .arrow-filters-fasteners,
  .arrow-filters-tools {
    height:66px;
  }




  @media screen and (max-width:1600px) {
    .filter-selector-fasteners,
    .filter-selector-tools {
      height:62px;
      width:372px;
      padding-top:28px;
    }  
  }


  @media screen and (max-width:1400px) {
    .filter-selector-item {
      font-size:19px;
      padding-top:24px;
      height:58px;
    }
    
    .filter-selector-fasteners,
    .filter-selector-tools {      
      width:360px;
    }  
  }

  @media screen and (max-width:1300px) {
    .filter-selector-item {
      padding-top:24px;
      height:55px;
    }
    
    .filter-selector-fasteners,
    .filter-selector-tools {      
      width:320px;
    }

    .filter-selector-tools {
      margin-left:-20px;
    }

  }

  @media screen and (max-width:1200px) {
    .filter-selector {
      width:540px;
    }
    .filter-selector-fasteners,
    .filter-selector-tools {      
      width:290px;
    }

    .arrow-filters-tools {
      width:540px;
    }
  }





</style>

<style>



</style>