<template>
  <div class="gp-container">    
  
    <div class="pl-4 sm:hidden manrope">
      <router-link class="flex items-center mb-2 font-semibold" to="/account/transactions">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.transactions') }}
      </router-link>
      <div class="text-2xl-- text-24 text-regular manrope">{{ $store.getters['auth/userName'] }},</div>
    </div>

    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12 after-name">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.orders-and-invoices') }}
    </div>

  <!-- debug -->
  <!-- {{ activeFilters }} -->

  <!-- Filters -->
  <div id="filters" class="grid grid-cols-1 sm:grid-cols-3 mb-2 text-20 gp-sm-txt-16 text-medium" style="">
    <div class="col-span-1 text-center border-1 border-21 p-4 py-4 manrope  cursor-pointer bg-darkbeige" >
      <!--{{ $t('labels.order-code') }}-->
      <input type="text" :placeholder="$t('labels.order-code')" class="bg-transparent outline-none w-full gp-txt-center " v-model="activeFilters.ordercode">
    </div>

    <div class="col-span-1 text-center border-1 border-21 p-4 py-4 manrope  cursor-pointer bg-darkbeige" style="position:relative">
      <!-- {{ $t('labels.order-date') }} -->
      <input type="date" :placeholder="$t('labels.date-placeholder')" class="bg-transparent outline-none text-center gp-focus" v-model="activeFilters.orders_datefrom">

      <span style="position:absolute; top:3px; left:5px; font-size:12px">From</span>
      
    </div>

    <div class="col-span-1 text-center border-1 border-21 p-4 py-4 manrope  cursor-pointer bg-darkbeige" style="position:relative">
      <!-- {{ $t('labels.order-date') }} -->
      <input type="date" :placeholder="$t('labels.date-placeholder')" class="bg-transparent outline-none text-center gp-focus" v-model="activeFilters.orders_dateto">
      
      <span style="position:absolute; top:3px; left:5px; font-size:12px">To</span>
    </div>


    <div :class="{selected: activeFilters.orders_lastmonth == 1}"
        class="col-span-1 px-2 py-4 text-center text-medium border-1 border-21 cursor-pointer flex justify-center items-center bg-darkbeige gp-orders-last-month" style=""
        @click="toggleLastMonthFilter()"
      >
      Last month
    </div>

    <div :class="{selected: activeFilters.orders_last3months == 1}"
        class="col-span-1 px-2 py-4 text-center text-medium border-1 border-21  cursor-pointer flex justify-center items-center bg-darkbeige gp-orders-last-month" style=""
        @click="toggleLast3MonthsFilter()"
      >
      Last 3 months
    </div>


    <div class="col-span-1 text-center border-1 border-21  p-4- py-4- manrope cursor-pointer bg-darkbeige"
         @click="openOrderStatusModal"
    >
      {{ activeFilters.orderstatus === null ? $t('labels.order-status') : printOrderStatus(activeFilters.orderstatus) }}
    </div>

    <div class="col-span-1 sm:col-span-3 text-center text-15 clear-filters-container" 
      style="margin-top:10px"
      @click="clearFilters()">
      Clear filters
    </div>


  </div>

  <!-- Months slider -->
  <!--<div
      v-if="documentsLoaded"
      id="month-slider"
      class="mobile-app-cancel-px bg-darkbeige flex justify-between overflow-x-scroll sm:overflow-x-auto border-t-1 border-21 pb-4 sm:pb-0 mb-8-- gp-mb-25 sm:rounded-lg sm:px-16--"
  >
    <span v-for="month in months" :key="month.code"
          class="whitespace-nowrap p-2 py-4 text-20-- gp-sm-txt-16--  text-medium border-b-1 sm:border-0 cursor-pointer month-slider-item"
          :class="{'text-black': activeFilters.month === month.code, 'text-gray-500': activeFilters.month !== month.code}"
          @click="activeFilters.month = month.code"
    >
      {{ month.description }}
    </span>
  </div>-->

  <!-- debug -->
<!--  {{ documents }}-->

  <!-- Results -->
  <div id="results" v-if="documentsLoaded" class="mb-32 gp-ml-15 gp-sm-ml-0 text-14 text-medium">

    <div class="text-right mb-4" style="padding-right:15px">
      <span v-if="hiddenDocuments" class="border-b-1 border-white mx-auto cursor-pointer d" @click="viewAll">
        {{ $t('labels.view-all') }}
      </span>
      <span v-else class="border-b-1 border-white mx-auto cursor-pointer d" @click="decreaseAll">
        {{ $t('labels.hide-all') }}
      </span>
    </div>

    <div v-for="(item, index) in documents" :key="item.id"
         class="flex flex-col border-1 border-21 gp-p-15 manrope  cursor-pointer bg-darkbeige invoice-item"
         :class="{hidden: index >= blocksize * loadedBlocks}"
         @click="$router.push('/account/invoice/'+item.id)"
    >
      <div class="text-xl-- text-20 text-medium sm:hidden">{{ $t('labels.order-code') }}</div>
      <div class="text-xl-- text-20 text-medium sm:hidden">{{ item.order_code }}</div>

      <div class="grid grid-cols-3 md:grid-cols-5 gap-2 mt-4 sm:mt-0 gp-sm-txt-15 gp-sm-txt-medium gp-invoice-list-items">
        <div class="hidden sm:flex flex-col sm:col-span-2 --document-item-box items-left justify-center">
          <div class="">{{ $t('labels.order-code') }}</div>
          <div class="">{{ item.order_code }}</div>
        </div>

        <div class="col-start-2 sm:col-start-3 col-span-1 document-item-box flex items-center justify-center order-status-tile">
          {{ item.order_status }}
        </div>

        <div class="col-span-1 document-item-box flex items-center justify-center order-date-tile">
          {{ item.order_date }}
        </div>

        <div class="col-start-2 sm:col-start-0 col-span-2 sm:col-span-1 document-item-box flex items-center justify-center total-charge-tile">
            {{ prettyNum(item.total_charge) }}
        </div>
      </div>
    </div>

    <button v-if="hiddenDocuments"
            class="app-button black-- w-full mb-2 text-15 text-medium"
            style="padding-top: 14px; padding-bottom: 14px; line-height:15px; margin-top:10px"
            @click="loadMore"
    >
      {{ $t('labels.load-more') }}
    </button>
  </div>

  </div>

  <!-- Order status modal -->
  <div v-if="orderStatusModalIsOpen" class="backdrop" @click="orderStatusModalIsOpen = false"></div>

  <transition name="modal">
    <dialog id="state-filter-modal" open v-if="orderStatusModalIsOpen" 
    class="flex flex-col manrope p-6 sm:p-4 bg-darkbeige" style="padding-bottom:10px">
      <!-- header -->
      <header class="text-semibold text-2xl sm:pl-2 flex-grow">
        {{ $t('labels.order-status') }}
      </header>

      <!-- body -->
      <section class="gap-4 grid grid-cols-2">
        <div class="app-button-gp col-span-2 sm:col-span-1"
             :class="{__black: activeFilters.orderstatus === 'ΣΕ ΕΚΡΕΜΜΟΤΗΤΑ'}"
             @click="setOrderStatusFilter(1)"
        >
          Σε εκκρεμότητα
        </div>

        <div class="app-button-gp col-span-2 sm:col-span-1"
             :class="{__black: activeFilters.orderstatus === 'ΥΠΟ ΕΠΕΞΕΡΓΑΣΙΑ'}"
             @click="setOrderStatusFilter(2)"
        >
          Υπο επεξεργασία
        </div>

        <div class="app-button-gp col-span-2 sm:col-span-1"
             :class="{__black: activeFilters.orderstatus === 'ΠΑΡΑΔΟΘΗΚΕ'}"
             @click="setOrderStatusFilter(3)"
        >
          Παραδόθηκε
        </div>

        <div class="app-button-gp col-span-2 sm:col-span-1"
             :class="{__black: activeFilters.orderstatus === 'All'}"
             @click="setOrderStatusFilter(0)"
        >
          Εμφάνιση όλων
        </div>

      </section>
    </dialog>
  </transition>
</template>

<script>
export default {
  name: "InvoicesList",
  inject: ['openDialog', 'prettyNum', 'isMobile'],
  data() {
    return {
      activeFilters: {
        ordercode: null,
        orderstatus: null,
        orders_datefrom: null,
        orders_dateto: null,        
        orders_lastmonth: 0,
        orders_last3months: 0
      },
      /*months: [
        // {label, val, selected}
      ],*/
      orderStatusModalIsOpen: false,
      blocksize: 3,
      loadedBlocks: 1,
    }
  },
  computed: {
    documents() {
      return this.$store.getters['account/documents'];
    },
    documentsLoaded() {
      return this.$store.getters['account/documentsLoaded'];
    },
    months() {
      return this.$store.getters['account/months'];
    },
    allDocumentsAreVisible() {
      return this.documents.length <= this.blocksize * this.loadedBlocks;
    },
    hiddenDocuments() {
      return this.documents.length > this.blocksize * this.loadedBlocks;
    }
  },
  watch: {
    activeFilters: {
      handler() {
        this.loadDocs()
      },
      deep: true,
    },
  },
  methods: {
    loadMore() {
      this.loadedBlocks++;
    },
    viewAll() {
      this.loadedBlocks = Math.ceil(this.documents.length/this.blocksize);
    },
    decreaseAll() {
      this.loadedBlocks = 1;
    },
    async loadDocs() {
      try {
        await this.$store.dispatch('account/loadDocuments', this.activeFilters);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    loadMonths() {
      /*const englishMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];*/

      const greekMonths = [
        "Ιανουάριος",
        "Φεβρουάριος",
        "Μάρτιος",
        "Απρίλιος",
        "Μάιος",
        "Ιούνιος",
        "Ιούλιος",
        "Αύγουστος",
        "Σεπτέμβριος",
        "Οκτώβριος",
        "Νοέμβριος",
        "Δεκέμβριος"
      ];

      // 2021-12
      const today = new Date();
      // let month = today.getMonth();
      // let year = today.getFullYear();

      // const minus2 = new Date();
      // minus2.setMonth(minus2.getMonth() - 2);
      // let minus2month = minus2.getMonth();
      // let minus2year = minus2.getFullYear();

      const minus1 = new Date();
      minus1.setMonth(minus1.getMonth() - 1);
      let minus1month = minus1.getMonth();
      let minus1year = minus1.getFullYear();

      // this.months.push({
      //   label: greekMonths[minus2month] + ' ' + minus2year,
      //   val: minus2year + '-' + (minus2month+1),
      //   selected: false
      // });

      this.months.push({
        label: greekMonths[minus1month] + ' ' + minus1year,
        val: minus1year + '-' + (minus1month+1),
        selected: false
      });

      let count = 4;

      if (this.isMobile()) {
        count = 11;
      }

      for (let i = 0; i < count; i++) {
        if (i > 0) {
          today.setMonth(today.getMonth() + 1);
        }

        this.months.push({
          label: greekMonths[today.getMonth()] + ' ' + today.getFullYear(),
          val: today.getFullYear() + '-' + (today.getMonth()+1),
          selected: i === 0,
        });
      }

      // console.log(this.months);
    },
    openOrderStatusModal() {
      this.orderStatusModalIsOpen = true;
    },
    setOrderStatusFilter(val) {
      if (this.activeFilters.orderstatus === val) {
        // reset filter
        this.activeFilters.orderstatus = null;
        this.orderStatusModalIsOpen = false;
      } else {
        this.activeFilters.orderstatus = val;
        this.orderStatusModalIsOpen = false;
      }
    },
    printOrderStatus(index) {
      if (index === 1) {
        return 'Σε εκκρεμότητα';
      } else if (index === 2) {
        return 'Υπο επεξεργασία';
      } else if (index === 3) {
        return 'Παραδόθηκε';
      } else {
        return '-';
      }
    },
    toggleLastMonthFilter() {
      this.clearFilters();
      if (this.activeFilters.orders_lastmonth==1) {
        this.activeFilters.orders_lastmonth=0;
      }
      else {
        this.activeFilters.orders_lastmonth=1;
      }
    },
    toggleLast3MonthsFilter() {
      this.clearFilters();
      if (this.activeFilters.orders_last3months==1) {
        this.activeFilters.orders_last3months=0;
      }
      else {
        this.activeFilters.orders_last3months=1;
      }
    },
    clearFilters() {
      this.activeFilters.ordercode = '';
      this.activeFilters.orderstatus = null;
      this.activeFilters.orders_datefrom=0;
      this.activeFilters.orders_dateto=0;
      this.activeFilters.orders_lastmonth=0;
      this.activeFilters.orders_last3months=0;

    }
  },
  created() {
    this.loadDocs();
    // this.loadMonths();
  }
}
</script>

<style scoped>


@media (max-width: 440px) {
  .order-date-tile {
    background-color: #E2E1D7 !important;
  }
  .total-charge-tile {
    background-color: #E2E1D7 !important;
  }
  .app-button-gp:last-child {margin-bottom:1rem;}
}


#month-slider {
  padding-left:10px;
  padding-right:10px;
}

/* width */
#month-slider::-webkit-scrollbar {
  height: 4px;
  
}

/* Track */
#month-slider::-webkit-scrollbar-track {
  box-shadow: inset 0 1px 0 0 gray;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: -1px;
}

/* Handle */
#month-slider::-webkit-scrollbar-thumb
{
  background: #212121;
  cursor: pointer !important;
  /*position: relative;*/
  /*top: 1px;*/
}

/* Handle on hover */
#month-slider::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
}

.document-item-box {
  border: 1px solid #212121;
  border-radius: .5rem;
  text-align: center;
  width: 100%;
  padding: .75rem 5px;
  background-color: #FFFCF0;
}

div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: hidden;
  /* animation: modal 0.3s ease-out forwards; */

  margin-left: 22px !important;
  margin-right: 22px !important;
  width: auto;
}

@media (min-width: 800px) {
  dialog {
    left: 10%;
    width: 80%;
  }

  div#filters {
    max-width: 970px;
    column-gap: -1px;
    /*margin-left: -170px;*/
  }

  div#month-slider {
    border: 1px solid #212121;
    max-width: 970px;
    /*margin-left: -170px;*/
  }

  /* Track */
  #month-slider::-webkit-scrollbar-track {
    /*box-shadow: inset 0 1px 0 0 gray;
    margin-left: 1.5rem;
    margin-right: 1.5rem;*/
    margin-top: 1px;
  }

  div#results {
    max-width: 571px;
    width: 100%;
  }

  .order-status-tile {
    background-color: #EEEDE2;
  }

  .order-date-tile {
    background-color: #E4E3D9;
  }

  .total-charge-tile {
    background-color: #D8D7CD;
  }

  .app-button-gp {
    background-color: #E7E6DB;
  }

  #state-filter-modal {
    width: 574px;
    height: 233px;
    left: auto;
    right: auto;
  }

  #state-filter-modal .app-button-gp {
    font-size: 1rem !important;
  }

  dialog {
    top: 30vh;
  }
}

#state-filter-modal .app-button-gp {
  font-size: 1.35rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 500;
}

#filters {
  margin-left:-170px;
  margin-right:-170px;
}
#month-slider {
  margin-left:-170px;
  margin-right:-170px;
}



@media (max-width:1600px) {
  #filters {
    margin-left:-80px;
    margin-right:-50px;
  }
  #month-slider {
    margin-left:-80px;
    margin-right:-50px;
  }
}

@media (max-width:1400px) {
  #filters {
    margin-left:-50px;
    margin-right:-50px;
  }
  #month-slider {
    margin-left:-50px;
    margin-right:-50px;
  }
}

@media (max-width:1300px) {
  #filters {
    margin-left:-50px;
    margin-right:-80px;
  }
  #month-slider {
    margin-left:-50px;
    margin-right:-80px;
  }
}

@media (max-width:1200px) {
  #filters {
    margin-left:0px;
    margin-right:-70px;
  }
  #month-slider {
    margin-left:0px;
    margin-right:-70px;
  }
}

@media (max-width:800px) {
  #filters {
    margin-left:0px;
    margin-right:0px;
  }
  #month-slider {
    margin-left:0px;
    margin-right:0px;
  }
}

/** ------MONTHS SCROLLBAR-------- */
#month-slider {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
#month-slider:-webkit-scrollbar {
  width: 11px;
}
#month-slider:-webkit-scrollbar-track {
  background: transparent;
}
#month-slider:-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid transparent;
}

/* webkit browsers */
#month-slider::-webkit-scrollbar,
#month-slider::-webkit-scrollbar-thumb {
  height: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

#month-slider::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 10px;
}


.month-slider-item {
  font-size:20px;
}

@media (max-width:1400px) {
.month-slider-item {
  font-size:18px;
}
}

@media (max-width:800px) {
.month-slider-item {
  font-size:16px;
}
}

@media only screen and (max-width: 820px) and (min-width: 800px) {
#filters {
  margin-right: -100px;
}
}

@media (max-width:440px) {
.after-name {
  display: none;
}
.gp-title{
  font-weight:400;
}
.flex-grow {
  padding-bottom: 50px;
}
}

.gp-orders-last-month.selected {
  background-color: #000 !important;
  color:#fff;
}

.clear-filters-container {
  cursor:pointer; 
  text-decoration:underline; 
  max-width: 542px;
  margin: auto;
  width: 100%;
  text-align: right;
  padding-right: 15px;
}

@media (max-width:1500px) {
.clear-filters-container {
  padding-right: 20px;
}
}
@media (max-width:1300px) {
.clear-filters-container {
  padding-right: 10px;
}
}
@media (max-width:1100px) {
.clear-filters-container {
  padding-right: 20px;
  max-width: 487px;
}
}
@media (max-width:800px) {
.clear-filters-container {
  max-width: 800px;
  padding-right: 15px;
}
}

.gp-focus:focus {
  background: #0078d7;
  color:#fff;
}


.gp-title {
    padding-left:30px;
    font-size:28px;
    margin-bottom:48px;
  }

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}

#filters > div {
  margin-right:-1px;
  margin-bottom:-1px;
  border-radius: 12px;
}

.invoice-item {
  margin-top:-1px;
  border-radius: 12px;
}

#state-filter-modal .app-button-gp {
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border:1px solid #212121;
  height:60px;
}
#state-filter-modal .app-button-gp:hover {
  border:none;
  height:58px;
}
#state-filter-modal .app-button-gp:hover:after {
  position:absolute;
    top:-1px;
    left:-1px;
    right:-1px;
    bottom:-1px;
    content: '';
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5907d1+0,360ed1+60,1415d1+78,4640e1+94,9888fc+100 */
    background: #5907d1; /* Old browsers */
    background: -moz-linear-gradient(left,  #5907d1 0%, #360ed1 60%, #1415d1 78%, #4640e1 94%, #9888fc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #5907d1 0%,#360ed1 60%,#1415d1 78%,#4640e1 94%,#9888fc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5907d1', endColorstr='#9888fc',GradientType=1 ); /* IE6-9 */
    z-index: -1;
    border-radius:13px;
    background-color: #fff !important;
    color:#000 !important;
}

#filters > div {
  height:52px;
  display:flex;
  justify-content: center;
  align-content: center;
  padding:10px 3px;
}

.gp-invoice-list-items > div {
  height:40px;
  display:flex;
  justify-content: center;
  align-content: center;
}

</style>

