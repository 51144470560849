<template>
  <div class="gp-container">
    <div class="sm:hidden manrope gp-pl-15">
      <router-link class="flex items-center mb-2 font-semibold-- gp-sm-txt-14 text-medium" to="/account/transactions">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/> {{ $t('labels.transactions') }}
      </router-link>
      <div class="text-2xl-- text-24 text-regular manrope">{{ $store.getters['auth/userName'] }},</div>
    </div>

    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <div class="flex items-center mb-2 manrope font-semibold"> &nbsp; </div>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.transactions') }}
    </div>

    <!-- debug -->
  <!--    {{ activeFilters }}-->

    <!-- Filters -->
    <div id="filters" class="grid grid-cols-1 sm:grid-cols-3  gp-gap-10-xx mb-2-- gp-mb-10 text-20 gp-sm-txt-16 text-regular gp-sm-txt-medium">
      <div class="col-span-1 text-center border-1 border-21 rounded-lg p-4 py-4 manrope  cursor-pointer bg-darkbeige">
        <!--{{ $t('labels.order-code') }}-->
        <input type="text" :placeholder="$t('labels.order-code')" class="bg-transparent outline-none w-full text-center" v-model="activeFilters.transaction_code">
      </div>

      <div class="col-span-1 text-center border-1 border-21 rounded-lg p-4 py-4 manrope  cursor-pointer bg-darkbeige" style="position:relative">
        <!-- {{ $t('labels.order-date') }} -->
        <input type="date" :placeholder="$t('labels.date-placeholder')" 
        class="bg-transparent outline-none text-center gp-focus"
        style="width:100%" 
        v-model="activeFilters.transaction_datefrom">

        <span style="position:absolute; top:3px; left:5px; font-size:12px">From</span>

      </div>

      <div class="col-span-1 text-center border-1 border-21 rounded-lg p-4 py-4 manrope  cursor-pointer bg-darkbeige" style="position:relative">
        <!-- {{ $t('labels.order-date') }} -->
        <input type="date" :placeholder="$t('labels.date-placeholder')" 
        class="bg-transparent outline-none text-center gp-focus"
        style="width:100%" 
        v-model="activeFilters.transaction_dateto">

        <span style="position:absolute; top:3px; left:5px; font-size:12px">To</span>

      </div>


      <div :class="{selected: activeFilters.transaction_lastmonth == 1}"
          class="col-span-1 px-2 py-4 text-center text-regular border-1 border-21 rounded-xl cursor-pointer flex justify-center items-center bg-darkbeige gp-orders-last-month" style=""
          @click="toggleLastMonthFilter()"
        >
        Last month
      </div>

      <div :class="{selected: activeFilters.transaction_last3months == 1}"
          class="col-span-1 px-2 py-4 text-center text-regular border-1 border-21 rounded-xl cursor-pointer flex justify-center items-center bg-darkbeige gp-orders-last-month" style=""
          @click="toggleLast3MonthsFilter()"
        >
        Last 3 months
      </div>



      <div class="col-span-1 text-center border-1 border-21 rounded-lg p-4 py-4 manrope cursor-pointer bg-darkbeige text-medium"
          @click="openOrderStatusModal"
      >
        {{ activeFilters.transaction_type === null ? $t('labels.transaction-type') : activeFilters.transactionTypeLabel }}
      </div>

      <div class="col-span-3 text-center text-15" 
        style="cursor:pointer; text-decoration:underline; margin-top:10px"
        @click="clearFilters()">
        Clear filters
      </div>


    </div>

    <!-- Months slider -->
    <!--<div
        v-if="transactionsLoaded"
        id="month-slider"
        class="mobile-app-cancel-px bg-darkbeige flex justify-between overflow-x-scroll sm:overflow-x-auto border-t-1 border-21 pb-4 sm:pb-0 mb-8-- gp-mb-25 sm:rounded-lg sm:px-16--"
        >
      <span v-for="month in months" :key="month.code"
            class="whitespace-nowrap p-2 py-4 text-lg manrope text-20-- gp-sm-txt-16-- text-medium -b-1 sm:border-0 cursor-pointer month-slider-item"
            :class="{'text-black': activeFilters.transaction_month === month.code, 'text-gray-500': activeFilters.transaction_month !== month.code}"
            @click="activeFilters.transaction_month = month.code"
      >
        {{ month.description }}
      </span>
    </div>-->

    <!-- debug -->
    <!--  {{ transactions }}-->

    <!-- Results -->
    <div id="results" v-if="transactionsLoaded" class="mb-32 gp-ml-30 gp-sm-ml-0 text-14 text-medium">

      <div v-if="hiddenTransactions" class="text-right mb-4" style="padding-right:15px">
          <span class="border-b-1 border-white mx-auto cursor-pointer d" @click="viewAll">
            {{ $t('labels.view-all') }}
          </span>
      </div>

      <div v-for="(item, index) in transactions" :key="item.id"
          class="flex flex-col border-1 border-21 rounded-lg p-4 manrope font-semibold-- text-medium cursor-pointer bg-darkbeige mb-4-- gp-mb-10 sm:mb-2--"
          :class="{hidden: index >= blocksize * loadedBlocks}"
      >
        <div class="grid grid-cols-12 gp-gap-10">
          <div class="col-span-6 sm:col-span-3 transaction-item-box flex items-center justify-center --col-start-0 --md:col-start-3 order-status-tile">
            {{ item.transaction_code }}
          </div>

          <div class="col-span-6 sm:col-span-3 transaction-item-box flex items-center justify-center order-status-tile">
            {{ item.transaction_type }}
          </div>

          <div class="col-span-6 sm:col-span-3 transaction-item-box flex items-center justify-center order-date-tile">
            {{ item.transaction_date }}
          </div>

          <div class="col-span-6 sm:col-span-3 transaction-item-box flex items-center justify-center total-charge-tile">
            {{ prettyNum(item.amount) }}
          </div>
        </div>
      </div>

      <button
          v-if="hiddenTransactions"
          class="app-button black-- w-full mb-2 text-15 text-medium"
          style="padding-top: 14px; padding-bottom: 14px; line-height:15px"
          @click="loadMore"
        >
        {{ $t('labels.load-more') }}
      </button>

    </div>

  </div>

  <!-- Order status modal -->
  <div v-if="orderStatusModalIsOpen" class="backdrop" @click="orderStatusModalIsOpen = false"></div>

  <transition name="modal">
    <dialog id="state-filter-modal" open v-if="orderStatusModalIsOpen" class="flex flex-col manrope p-6 sm:p-4 bg-darkbeige">
      <!-- header -->
      <header class="--font-semibold text-2xl mb-32 sm:mb-16 sm:pl-2 flex-grow">
        {{ $t('labels.transaction-type') }}
      </header>

      <!-- body -->
      <section v-if="transactionTypesLoaded" class="gap-4 grid grid-cols-3">
        <div v-for="transactionType in transactionTypes" :key="transactionType.id"
             class="app-button col-span-3 sm:col-span-1"
             :class="{__black: activeFilters.transaction_type === transactionType.id}"
             @click="setOrderStatusFilter(transactionType)"
        >
          {{ transactionType.description }}
        </div>
      </section>
    </dialog>
  </transition>
</template>

<script>
export default {
  name: "InvoicesList",
  inject: ['openDialog', 'prettyNum', 'isMobile'],
  data() {
    return {
      activeFilters: {
        transaction_code: null,
        transaction_lastmonth: 0,
        transaction_last3months: 0,
        transaction_datefrom: null,
        transaction_dateto: null,
        transaction_type: null,
        //
        transactionTypeLabel: null,
      },
      // months: [
      //   // {label, val, selected}
      // ],
      orderStatusModalIsOpen: false,
      blocksize: 2,
      loadedBlocks: 1,
    }
  },
  computed: {
    transactions() {
      return this.$store.getters['account/transactions'];
    },
    transactionsLoaded() {
      return this.$store.getters['account/transactionsLoaded'];
    },
    transactionTypes() {
      return this.$store.getters['account/transactionTypes'];
    },
    transactionTypesLoaded() {
      return this.$store.getters['account/transactionTypesLoaded'];
    },
    months() {
      return this.$store.getters['account/months'];
    },
    allTransactionsAreVisible() {
      return this.transactions.length <= this.blocksize * this.loadedBlocks;
    },
    hiddenTransactions() {
      return this.transactions.length > this.blocksize * this.loadedBlocks;
    }
  },
  watch: {
    activeFilters: {
      handler() {
        this.loadTransactions()
      },
      deep: true,
    },
  },
  methods: {
    loadMore() {
      this.loadedBlocks++;
    },
    viewAll() {
      this.loadedBlocks = Math.ceil(this.transactions.length/this.blocksize);
    },
    decreaseAll() {
      this.loadedBlocks = 1;
    },
    async loadTransactions() {
      try {
        await this.$store.dispatch('account/loadTransactions', this.activeFilters);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async loadTransactionTypes() {
      try {
        await this.$store.dispatch('account/loadTransactionTypes');
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    loadMonths() {
      /*const englishMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];*/

      const greekMonths = [
        "Ιανουάριος",
        "Φεβρουάριος",
        "Μάρτιος",
        "Απρίλιος",
        "Μάιος",
        "Ιούνιος",
        "Ιούλιος",
        "Αύγουστος",
        "Σεπτέμβριος",
        "Οκτώβριος",
        "Νοέμβριος",
        "Δεκέμβριος"
      ];

      // 2021-12
      const today = new Date();
      // let month = today.getMonth();
      // let year = today.getFullYear();

      // const minus2 = new Date();
      // minus2.setMonth(minus2.getMonth() - 2);
      // let minus2month = minus2.getMonth();
      // let minus2year = minus2.getFullYear();

      const minus1 = new Date();
      minus1.setMonth(minus1.getMonth() - 1);
      let minus1month = minus1.getMonth();
      let minus1year = minus1.getFullYear();

      // this.months.push({
      //   label: greekMonths[minus2month] + ' ' + minus2year,
      //   val: minus2year + '-' + (minus2month+1),
      //   selected: false
      // });

      this.months.push({
        label: greekMonths[minus1month] + ' ' + minus1year,
        val: minus1year + '-' + (minus1month+1),
        selected: false
      });

      let count = 4;

      if (this.isMobile()) {
        count = 11;
      }

      for (let i = 0; i < count; i++) {
        if (i > 0) {
          today.setMonth(today.getMonth() + 1);
        }

        this.months.push({
          label: greekMonths[today.getMonth()] + ' ' + today.getFullYear(),
          val: today.getFullYear() + '-' + (today.getMonth()+1),
          selected: i === 0,
        });
      }

      // console.log(this.months);
    },
    openOrderStatusModal() {
      this.orderStatusModalIsOpen = true;
    },
    setOrderStatusFilter(transactionType) {
      if (this.activeFilters.transaction_type === transactionType.id) {
        // reset filter
        this.activeFilters.transaction_type = null;
        this.activeFilters.transactionTypeLabel = null;
        this.orderStatusModalIsOpen = false;
      } else {
        this.activeFilters.transaction_type = transactionType.id;
        this.activeFilters.transactionTypeLabel = transactionType.description;
        this.orderStatusModalIsOpen = false;
      }
    },
    printOrderStatus(index) {
      if (index === 1) {
        return 'Εκκρεμής';
      } else if (index === 2) {
        return 'Επεξεργασία';
      } else if (index === 3) {
        return 'Παραδόθηκε';
      } else {
        return '-';
      }
    },

    toggleLastMonthFilter() {
      this.clearFilters();
      if (this.activeFilters.transaction_lastmonth==1) {
        this.activeFilters.transaction_lastmonth=0;
      }
      else {
        this.activeFilters.transaction_lastmonth=1;
      }
    },
    toggleLast3MonthsFilter() {
      this.clearFilters();
      if (this.activeFilters.transaction_last3months==1) {
        this.activeFilters.transaction_last3months=0;
      }
      else {
        this.activeFilters.transaction_last3months=1;
      }
    },
    clearFilters() {
      this.activeFilters.transaction_code = '';
      this.activeFilters.transaction_type = null;
      this.activeFilters.transaction_datefrom=0;
      this.activeFilters.transaction_dateto=0;
      this.activeFilters.transaction_lastmonth=0;
      this.activeFilters.transaction_last3months=0;

    }


  },
  created() {
    this.loadTransactions();
    this.loadTransactionTypes();
    // this.loadMonths();
  }
}
</script>

<style scoped>


#filters {
  margin-bottom:10px;
}


#month-slider {
  padding-left:10px;
  padding-right:10px;
}


/* width */
#month-slider::-webkit-scrollbar {
  /*width: 10px;*/
  height: 4px;
  /*margin-bottom: 1rem;*/
}

/* Track */
#month-slider::-webkit-scrollbar-track {
  box-shadow: inset 0 1px 0 0 gray;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: -1px;
}

/* Handle */
#month-slider::-webkit-scrollbar-thumb
{
  background: #212121;
  cursor: pointer !important;
  /*position: relative;*/
  /*top: 1px;*/
}

/* Handle on hover */
#month-slider::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
}

.transaction-item-box {
  border: 1px solid #212121;
  border-radius: .5rem;
  text-align: center;
  width: 100%;
  padding: .75rem 10px;
  background-color: #FFFCF0;
}

div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: hidden;
  /* animation: modal 0.3s ease-out forwards; */

  margin-left: 22px !important;
  margin-right: 22px !important;
  width: auto;
}

@media (min-width: 800px) {
  dialog {
    left: 10%;
    width: 80%;
  }

  div#filters {
    max-width: 970px;
    
    /*margin-left: -170px;*/
  }

  div#month-slider {
    border: 1px solid #212121;
    max-width: 970px;
    /*margin-left: -170px;*/
  }

  /* Track */
  #month-slider::-webkit-scrollbar-track {
    /*box-shadow: inset 0 1px 0 0 gray;
    margin-left: 1.5rem;
    margin-right: 1.5rem;*/
    margin-top: 1px;
  }

  div#results {
    max-width: 571px;
    width: 100%;
  }

  .order-status-tile {
    background-color: #EEEDE2;
  }

  .order-date-tile {
    background-color: #E4E3D9;
  }

  .total-charge-tile {
    background-color: #D8D7CD;
  }

  .app-button {
    background-color: #E7E6DB;
  }

  #state-filter-modal {
    width: 574px;
    min-height: 233px;
    left: auto;
    right: auto;
  }

  #state-filter-modal .app-button {
    font-size: 1rem !important;
  }

  dialog {
    top: 30vh;
  }
}

#state-filter-modal .app-button {
  font-size: 1.35rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 500;
}


#filters {
  margin-left:-170px;
  margin-right:-170px;
}
#month-slider {
  margin-left:-170px;
  margin-right:-170px;
}



@media (max-width:1600px) {
  #filters {
    margin-left:-80px;
    margin-right:-50px;
  }
  #month-slider {
    margin-left:-80px;
    margin-right:-50px;
  }
}

@media (max-width:1400px) {
  #filters {
    margin-left:-50px;
    margin-right:-50px;
  }
  #month-slider {
    margin-left:-50px;
    margin-right:-50px;
  }
}

@media (max-width:1300px) {
  #filters {
    margin-left:-50px;
    margin-right:-80px;
  }
  #month-slider {
    margin-left:-50px;
    margin-right:-80px;
  }
}

@media (max-width:1200px) {
  #filters {
    margin-left:0px;
    margin-right:-70px;
  }
  #month-slider {
    margin-left:0px;
    margin-right:-70px;
  }
}


@media (max-width:800px) {
  #filters {
    margin-left:0px;
    margin-right:0px;
  }
  #month-slider {
    margin-left:0px;
    margin-right:0px;
  }
}

/** ------MONTHS SCROLLBAR-------- */
#month-slider {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
#month-slider:-webkit-scrollbar {
  width: 11px;
}
#month-slider:-webkit-scrollbar-track {
  background: transparent;
}
#month-slider:-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid transparent;
}

/* webkit browsers */
#month-slider::-webkit-scrollbar,
#month-slider::-webkit-scrollbar-thumb {
  height: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

#month-slider::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 10px;
}


.month-slider-item {
  font-size:20px;
}

@media (max-width:1400px) {
.month-slider-item {
  font-size:18px;
}
}

@media (max-width:800px) {
.month-slider-item {
  font-size:16px;
}
}

.gp-orders-last-month.selected {
  background-color: #000 !important;
  color:#fff;
}


.gp-focus:focus {
  background: #0078d7;
  color:#fff;
}


.gp-title {
    padding-left:30px;
    font-size:28px;
    margin-bottom:48px;
  }

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}



@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}


</style>