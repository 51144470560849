<template>
    <div class="mx-auto rounded-lg mt-4  mb-8 gp-tool-brand-browse-section" style="max-width: 932px; background-color: #efde96; width:95vw; min-height: 0px;">
      <div 
        @click="handleBrandClick('bahco')" 
        :class="{selected: this.$store.getters['filters/toolBrand']=='bahco'}">
        Bahco
      </div>
      <div 
        @click="handleBrandClick('irimo')"
        :class="{selected: this.$store.getters['filters/toolBrand']=='irimo'}">
        Irimo
      </div>
      <div 
        @click="handleBrandClick('makita')"
        :class="{selected: this.$store.getters['filters/toolBrand']=='makita'}">
        Makita
      </div>
    </div>

</template>

<script>

export default {
    name: "ToolBrandBrowseSection",
    data() {
      return {
        selectedToolBrand: null,
      }
    },
    props: {
    activeFilters: {
      type: Object,
      required: false,
      default: function() {
        return {
          toolBrand: null,
          toolType: null,
        };
      },
    }
  },
    methods: {
      handleBrandClick(val) {
        if (this.selectedToolBrand==val) {
          this.selectedToolBrand = null;
          this.$store.commit('filters/setToolBrand', null);
          this.$emit('toolbrand-selected', null); 
        }
        else {
          this.selectedToolBrand = val;
          this.$store.commit('filters/setToolBrand', val);
          this.$emit('toolbrand-selected', val); 
        }          
      }
    },
    
}


</script>

<style scoped>

.gp-tool-brand-browse-section {
  margin-left:-65px;
  display:grid;
  /* grid-template-columns: 49% 49%; */
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2%;
  padding:30px;
}

.gp-tool-brand-browse-section div {
  border:1px solid #212121;
  text-align: center;
  height: 50px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gp-tool-brand-browse-section div:hover,
.gp-tool-brand-browse-section div.selected {
  background-color: #212121;
  color:#efde96;
}

@media screen and (max-width:1600px) {
  .gp-tool-brand-browse-section {
    margin-left:0px;
  }
}

</style>