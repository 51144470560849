<template>
  <div class="gp-container">
    <div class="gp-pl-30 gp-sm-pl-15 gp-mb-12">
      <router-link class="flex items-center mb-2 manrope font-semibold" to="/account/my-profile">
        <img class="mr-3" src="../../../assets/svg/arrow-left-wireframe.svg"/>{{ $t('labels.my-profile') }}
      </router-link>
    </div>

    <div class="text-semibold gp-account-title">
      {{ $t('labels.business-addresses') }}
    </div>
    
    <account-navigation-menu :menu-items="menuItems" :prefix="currentRouteName"/>

  </div>

</template>

<script>
import AccountNavigationMenu from "@/components/misc/AccountNavigationMenu";

export default {
  name: "BusinessAddresses",
  components: {AccountNavigationMenu},
  inject: ['openDialog'],
  data() {
    return {
      addresses: [],

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    dataLoaded() {
      return this.$store.getters['account/accountDataLoaded'];
    },
    accountAddresses() {
      return this.$store.getters['account/addresses'];
    },
    menuItems() {
      let menuItemsArray = [];

      this.addresses.forEach((address) => {
        menuItemsArray.push({
          href: '/account/my-profile/business-addresses/'+address.site_id,
          label: address.site_code
        });
      });

      return menuItemsArray;
    }
  },
  methods: {
    async loadAccountData() {
      try {
        await this.$store.dispatch('account/loadAccountData');

        this.addresses = this.$store.getters['account/addresses'];
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  created() {
    this.loadAccountData();
  }
}
</script>

<style scoped>

.gp-title {
  padding-left:30px;
  font-size:28px;
}

@media (max-width:800px) {
  .gp-title {
    padding-left:15px;
    font-size:24px;
  }  
}

@media (max-width:1300px) and (min-width:800px) {
  .gp-container {
    padding-left:25px;
  }
}


</style>