<template>
    <textarea class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
           :name="name"
           :rows="rows"
           :class="this.class"
           :placeholder="placeholder"
    ></textarea>
</template>

<script>
export default {
    name: 'BaseTextarea',
    props: {
        type: {
            type: String,
            default: 'text'
        },
        name: {
            type: String,
            required: true,
        },
        rows: {
            type: Number,
            required: false,
            default: 5,
        },
        class: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
        }
    },
};
</script>

<style>

</style>
