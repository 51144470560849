<!-- Page Heading -->
<template>
    
    <header class="shadow mb-8 bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-200">
        <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h2 class="font-semibold text-xl leading-tight">
                <slot></slot>
            </h2>
        </div>
    </header>


</template>

