<template>
  <div class="tile flex flex-col items-center text-center gp-fastener-tile p-4 cursor-pointer manrope text-15 text-medium">
    <img :src="fastener.imageUrl" class="gp-w-auto gp-h-56- mb-2--"/>
    <div style="height: 60px; line-height:17px; position:relative; width:100%">
      <div class="fastener-name" style="">{{ fastener.name }}</div>
    </div>
    <!--<div class="text-white">{{ fastener.quantity }} {{ fastener.quantity === 1 ? 'item' : 'items'}}</div>-->
  </div>
</template>

<script>
export default {
  name: "FastenerTile",
  components: {},
  props: ['fastener'],
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

<style scoped>
  .tile:hover, .tile.selected {
    background-color: rgba(0, 0, 0, .1);
  }

  .gp-w-auto {
    width:auto;
  }

  .gp-w-120 {
    width:120px;
  }

  .gp-w-140 {
    width:140px;
  }

  .gp-w-160 {
    width:160px;
  }
  
  .gp-h-56 {
    height:56px;
  }

  .gp-fastener-tile {
    width:175px;
  }

  .fastener-name {
    position:absolute; left:0px; right:0px; bottom:0px;
    font-size:15px;
    line-height: 15px;
    height: 45px;
  }
  @media (max-width:1600px) {
  .fastener-name {
    font-size:14px;
    line-height: 14px;
    height: 42px;
  } 
  .text-white {
    font-size:14px;
  } 
  }


</style>
