<template>
  <transition name="fade-in-slow">
    <div v-if="visible" class="flex flex-col mb-4 --mx-4 border-1 border-21 rounded-xl bck-fram">
      <router-link class="text-center pt-24 pb-8" :to="category.url">
        <img :src="category.imageUrl" class="mx-auto" style="max-height: 150px;"/>
      </router-link>

      <div class="grid grid-cols-12 items-center px-8 font-semibold mb-16">
        <div class="col-span-1"></div>
        <div class="col-span-10 flex flex-col items-center flex-grow px-2 text-15 text-medium text-lineheight-18">
          <router-link :to="category.url" class="text-center text-darkblue">
            {{ category.name }}
          </router-link>
          <span class="qntt">{{ category.quantity }} items</span>
        </div>
        <div class="col-span-1">
          <img v-if="isFavorite" src="../../assets/svg/heart-filled.svg" class="h-6 cursor-pointer" @click="unmarkAsFavorite"/>
          <img v-else src="../../assets/svg/heart.svg" class="h-6 cursor-pointer" @click="markAsFavorite"/>
        </div>
      </div>

      <div class="font-semibold p-3 border-t-1 text-15-sm-15 text-semibold app">
        <span class="text-darkblue mr-2">Application</span> <span class="text-gray">...</span>
      </div>
      <div class="font-semibold p-3 border-t-1 text-15-sm-15 text-semibold material">
        <span class="text-darkblue mr-2">Material Variations</span> <span class="text-gray">...</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SecondLevelCategoryCard",
  inject: ['openDialog'],
  props: {
    category: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    isFavorite() {
      return this.$store.getters['categories/favorites'] && this.$store.getters['categories/favorites'].find(element => element.id == this.category.id) !== undefined;
    }
  },
  methods: {
    async markAsFavorite() {
      if (!this.$store.getters['auth/auth']) {
        this.openDialog({
          title: 'Ενημέρωση',
          html: 'Θα πρέπει να είστε συνδεδεμένοι για να προσθέσετε προϊόντα στη λίστα αγαπημένων σας'
        });

        return false;
      }

      try {
        await this.$store.dispatch('categories/addToFavourites', this.category.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async unmarkAsFavorite() {
      try {
        await this.$store.dispatch('categories/removeFromFavourites', this.category.id);
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    }
  },
  created() {
    //console.log('SLC_C:');
    //console.log(this.category);
  }
}
</script>

<style scoped>


  @media screen and (max-width:590px) {
    .app{display:none;}
    .material{display:none;}
    .qntt{display:none;}
    .bck-fram{margin-bottom: unset; background-image: url(../../assets/svg/cat-mob.svg); border:none; background-size: 100% 100%;}
  }



</style>