<template>
  <div class="overlay flex flex-col gp-text-21 text-regular">
    <div class="x-container text-right mb-16--" style="margin-top:45px; margin-bottom:80px">
      <a class="cursor-pointer" @click="$emit('close')">
        <img src="../../assets/icons/x.png" class="h-10 ml-auto"/>
      </a>
    </div>

    <a :href="$router.resolve('/instant-order-main').href" class="overlay-link p-3 mb-4" style="background-color:#ff4e00">
      Instant Order
    </a>

    <div class="grid grid-cols-2 space-x-4 mb-4">
      <a :href="$router.resolve('/about').href" class="col-span-1 overlay-link p-3">
        {{ $t('labels.company')}}
      </a>
      <a :href="$router.resolve('/contact').href" class="col-span-1 overlay-link p-3">
        {{ $t('labels.contact')}}
      </a>
    </div>

    <a :href="$router.resolve('/speech').href" class="overlay-link p-3 mb-4">
      {{ $t('labels.search')}}
    </a>

    <div class="grid grid-cols-2 space-x-4 mb-4--" style="margin-bottom:150px">
      <a :href="$router.resolve('/account').href" class="col-span-1 overlay-link p-3 py-8  mb-4">
        {{ $t('labels.account')}}
      </a>
      <a :href="$router.resolve('/chat').href" class="col-span-1 overlay-link p-3 py-8  mb-4">
        {{ $t('labels.chat')}}
      </a>

      <a v-if="authSeller" class="col-span-2 overlay-link p-3" 
      style="margin-left:0px" :href="$router.resolve('/seller').href" >
        <span title="My Clients" class="auth-title-- auth-userinitials--">Sales</span>        
      </a>


    </div>

    <div class="text-14 text-regular" style="color:#fff">
      Terms & Conditions <br/>
      Copyright
    </div>

  </div>
</template>

<script>
export default {
  name: "MobileMenuOverlay",
  computed: {
    authSeller() {
        //return localStorage.getItem('sellertoken').length>1;
        //console.log("sellertoken=" + localStorage.getItem('sellertoken'));
        return localStorage.getItem('sellertoken')===null? false: localStorage.getItem('sellertoken').length>1;
      }
  }
}

</script>

<style scoped>
.overlay {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 38, 38, 1);
  padding: 25px;
}

.overlay-link {
  border: 1px solid white;
  border-radius: 1rem;
  color: white;
  /*font-size: 1.25rem;*/
  text-align: center;
  cursor: pointer;
}


</style>