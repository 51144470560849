<template>
    <arrow-filters style="margin-bottom: 60px;"/>
    <div class="" v-html="liveHtml"/>
  </template>
  
  <script>
  import ArrowFilters from "@/components/ArrowFilters";
  export default {
    name: "RemoteContentOffer202401",
    components: {ArrowFilters},
    data() {
      return {
        html: '',
      }
    },
    computed: {
      liveHtml() {
        return this.html;
      },
      
    },
    async created() {
      let my_lang = this.$store.getters['app/locale'];
      let response = await this.$store.getters['app/axios'].get('remote-content-offer-2024-01-bahco-gazotan.php?lang=' + my_lang);
      this.html = response.data;
    }
  }
  </script>
  
  <style scoped>
  
  </style>