<template>
  <div :id="'row-' + r1" 
  class="p-4-- gp-p-22 sm:flex sm:p-0 gp-sm-h-560-- subcategory-row-wrapper mobile-app-cancel-px" :class="{odd: type === 'odd'}"
  style="position:relative">
    <!-- fixed item | mobile -->
    <div class="sm:hidden text-lg-- text-darkblue manrope mb-16 gp-sm-mb-0 font-semibold-- gp-text-21 text-regular">
      {{ title }} 
    </div> 

    <!-- fixed item | desktop -->
    <div class="hidden sm:flex flex-col sm:p-6 sm:pt-8--xx app-pl text-sm-- manrope justify-start  gp-subcatrow-fixed-item"
        style="padding-top:0px" >
      <div class="text-semibold text-dark-gray gp-subcatrow-fixed-item-title">
        {{ title }}
      </div>
      <div v-if="parent.has_more">
        <a :href="$router.resolve(link.startsWith('/') ? link : '/' + link).href  + '/?p1=' + p1 + '&r1=' + r1 + (toolbrand!=null && toolbrand!=''? '&toolbrand=' + toolbrand: '')" style="font-size:14px">
            {{ $t('labels.show-all')}}
        </a>
      </div>
      
      <!--<a :href="$router.resolve(link.startsWith('/') ? link : '/' + link).href  + '/?p1=' + p1 + '&r1=' + r1" 
        class="flex justify-start items-end text-darkblue text-13 text-sm-12 text-medium">
        <span style="position:relative">
          <span>{{ linkDescr }}</span>
          <img src="../../assets/svg/arrow-right-wireframe.svg" class="" style="position:absolute; top:4px; right:-47px; width:34px">
        </span>
      </a>
      <div class="text-13 text-sm-12 text-medium">
        {{ bottomText }}
      </div>-->
    </div>

    <!-- slider /2/ -->
    <div class="flex-grow overflow-x-hidden relative gp-slider gp-slider-cat">
      <custom-slider
          :ref="'customSlider' + parent.id"
          :slide-count="parent.has_more? items.length + 1: items.length"
          :slide-width="liveSlideWidth"
          :slide-height="222" 
          :scrollbar="isMobile"
          :corner-prev-next="true"
          :debug="false"
      >
        <div class="slide" v-for="item in items" :key="item.id">
          <subcategory-slider-item
              :item="item"
              :image-height="150"
              :r1="r1"
              :p1="p1"
              :toolbrand="toolbrand"
          ></subcategory-slider-item>
        </div>

        <div v-if="parent.has_more" class="slide" style="padding-top:60px;padding-left:20px;">
          <a :href="$router.resolve(link.startsWith('/') ? link : '/' + link).href  + '/?p1=' + p1 + '&r1=' + r1 + (toolbrand!=null && toolbrand!=''? '&toolbrand=' + toolbrand: '')">
            {{ $t('labels.show-more')}}
          </a>          
        </div>

      </custom-slider>
    </div>

    <!-- fixed item | mobile -->
    <div class="sm:hidden text-xs text-darkblue manrope mt-6 mb-6 gp-sm-mb-0 font-semibold gp-see-all-products">
      <a :href="$router.resolve(link.startsWith('/') ? link : '/' + link).href" class="flex text-darkblue">
        <span class="mr-4">{{ linkDescr }}</span>
        <img src="../../assets/icons/blue-arrow.png" width="40">
      </a>
      <div class="text-21">
        {{ bottomText }}
      </div>
    </div>
  </div>
</template>

<script>
// import {Swiper, SwiperSlide} from 'swiper/vue';

// import 'swiper/swiper.min.css';
import SubcategorySliderItem from "@/components/misc/SubcategorySliderItem";
import CustomSlider from "@/components/custom-slider/CustomSlider";

export default {
  name: "SubcategorySlider",
  components: {
    CustomSlider,
    SubcategorySliderItem,
    // Swiper,
    // SwiperSlide,
  },
  props: [
      'type',
      'title',
      'r1',
      'p1',
      'descr',
      'link',
      'linkDescr',
      'bottomText',
      'parent',
      'items',
      'toolbrand'
  ],
  data() {
    return {
      //
    }
  },
  computed: {
    subCategoriesLoaded() {
      return true;
    },
    liveSlideWidth() {
      return 215;
      /*if (window.innerWidth <= 590) {
        // console.log('Passing SubCategory Slider liveSlideWidth: ' + (window.innerWidth - 80));
        return window.innerWidth - 80;
      } 
      else if (window.innerWidth <= 1400) {
        return 300;
      }
      else {
        // console.log('Passing SubCategory Slider liveSlideWidth: ' + 330);
        return 330;
      }*/
    },
    isMobile() {
      return window.innerWidth <= 800;
    }
  },
  methods: {
    initSlider: function() {
      this.$refs['customSlider' + this.parent.id].setElements();
    },
    
  },
  created() {
    //
  }
}
</script>

<style scoped>
  .odd {
    /*background-color: #F0EFE2;*/
  }

  .slide {
    float:left;
    position: relative;
    width: 213px;
    height: 220px;
    margin: 0px 0px 10px 0px;
    background-color: transparent;
    background-image: url("../../assets/svg/slide-bg-octag-white-fill.svg");
    background-size: 213px 220px;
    margin-right:1px;
    margin-left:1px;
    margin-top:1px;
  }
  .slide:hover {
    background-image: url("../../assets/svg/slide-bg-octag-blueborder-deg-white-fill.svg");
    z-index:9999;
  }
  .slide:not(:first-child) {
    margin-left:-1px;
  }

  /*.slide:before {
    position:absolute;
    top:-1px;
    right:-1px;
    bottom:-1px;
    left:-1px;
    content:'';
    background-image: url(../../assets/svg/octagon-gray.svg);
    background-size: 100% 100%;
    z-index:-1;
  }

  .slide:hover:before {
      background-image: url(../../assets/svg/octagon-degrade.svg);
  }*/


  .gp-subcatrow-fixed-item {
    /*min-width: 450px; max-width: 450px; */
    /*height: 360px; //prev height */
    min-width: 30%; max-width: 30%;
    height:200px; /** kgjz */
  }

  

  @media (max-width:1400px) {
    .gp-subcatrow-fixed-item {
      /*min-width: calc(100% - 300px * 3); 
      max-width: calc(100% - 300px * 3); */
      width:25%;
      min-width: 25%; 
      max-width:25%;
    }

    .slide {
      float:left;
      /*width: 300px;
      height: 200px;*/
    }


  }



  @media screen and (max-width:1200px) {
    .gp-subcatrow-fixed-item {
      min-width: calc(100% - 250px * 3); 
      max-width: calc(100% - 250px * 3); 
    }

    .slide {
      float:left;
      /*width: 250px;
      height: 200px;*/
    }
  }



  @media screen and (max-width:1000px) {    

    .gp-slider {
      position:absolute;
      bottom:0px;
      left:250px;
      right:0px;
    }

    .subcategory-row-wrapper {
      height:300px;
    }

    .gp-subcatrow-fixed-item {
      min-width: 200px; 
      max-width: 200px; 
      height:300px;
    }

    

  }


  @media screen and (max-width:820px) {
    
    .slide{/*width:212px; height: 240px;*/}
    .gp-slider{bottom:unset;}
    
  }


  @media screen and (max-width:800px) {
    /*.slide {
      width: calc(100vw - 80px);
    }*/

    .gp-see-all-products {
      position:absolute;
      bottom:50px;
    }

    
  }

  @media screen and (max-width:799px) {    

    .gp-slider {
      position:absolute;
      /* bottom:100px; */
      left:22px;
      right:0px;
    }

    .subcategory-row-wrapper {
      height:450px;
    }

  }


  @media screen and (max-width:590px) {
    .slide {
      /*width: calc(100vw - 80px);*/
    }
    .gp-see-all-products{display:none;}
    .subcategory-row-wrapper{height: 360px;}
    .gp-slider-cat{bottom:unset !important;}
    .gp-text-21{padding-bottom:26px;}
    .gp-text-21:nth-child(1){padding-top:80px;}
  }



@media screen and (max-width:440px) {
.slide {
  /*width: calc(100vw - 218px) ; height: 222px;*/
}
.slider-inner { width:222px !important; }
}


@media screen and (max-width: 420px) {
  .slide {
      /*width: calc(100vw - 200px) !important;
      height: 222px;*/
  }
}

@media screen and (max-width: 400px) {
  .slide {
      /*width: calc(100vw - 178px) !important;
      height: 222px;*/
  }
}

@media screen and (max-width:380px) {
.slide {
  /*width: calc(100vw - 162px) !important; */
}
    /* .gp-slider-cat{bottom:unset !important;} */
}

@media screen and (max-width:360px) {
.slide {
  /*width: calc(100vw - 148px) !important; height: 222px;*/
}
    /* .gp-slider-cat{bottom:unset !important;} */
}

</style>

<style>

.gp-slider-cat {
  margin-bottom:60px;
}
@media screen and (max-width:1400px) {
  .gp-slider-cat {
    margin-bottom:30px;
  }
}

@media screen and (max-width:800px) {
.gp-slider-cat .slider-inner {
  /*width:100% !important;*/
}
}


.gp-subcatrow-fixed-item-title {
  font-size:33px; /**27 */
  line-height: 40px;
  margin-bottom:15px;
}

@media screen and (max-width:1400px) {
.gp-subcatrow-fixed-item-title {
  font-size:24px;
  line-height: 30px;
  margin-bottom:10px;
}
}


</style>