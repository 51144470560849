<template>
  <div class="slide">
    <a :href="$router.resolve(item.url).href"
       class="h-full p-2 pb-4-- slider-item flex flex-col items-center justify-end text-center text-sm cursor-pointer font-semibold-- ">
      <img :src="item.imageUrl" class="gp-slide-img" style="" alt=""/>
      <div class="text-dark-gray manrope text-14 text-light gp-slide-title">
        <div class="text-medium">{{ item.name }}</div>
      </div>
      <!--<div class="gp-mb-22-xx text-14 text-medium gp-slide-itemcount" style="color: #4D4D4D;">{{ item.quantity }} {{ item.quantity === 1 ? 'item' : 'items' }}</div>-->
    </a>
  </div>
</template>

<script>
export default {
  name: "HomeSliderItem",
  components: {},
  props: ['item'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.slide {
  float:left;
  position: relative;
  width: 213px;
  height: 220px;
  margin: 0px 0px 10px 0px;
  background-color: transparent;
  background-image: url("../../assets/svg/slide-bg-octag-white.svg");
  background-size: 213px 220px;
  margin-right:1px;
  margin-left:1px;
  margin-top:1px;
}
.slide:hover {
  background-image: url("../../assets/svg/slide-bg-octag-blueborder-deg.svg");
  z-index:9999;
}

.slide:not(:first-child) {
  margin-left:-1px;
}

/*.slide:before {
    position:absolute;
    top:-1px;
    right:-1px;
    bottom:-1px;
    left:-1px;
    content:'';
    background-image: url(../../assets/svg/octagon-gray.svg);
    background-size: 100% 100%;
    z-index:-1;
}
.slide:hover:before {
    background-image: url(../../assets/svg/octagon-degrade.svg);
}*/

.gp-slide-itemcount {
  margin-bottom:10px;
}


.gp-slide-img {
  max-height: 150px; /** 13-10-2022 */
  width: auto; /**150 */
}

@media (max-width:1400px) {
.gp-slide-img {
  /*height: 180px; /**130 */
  height:auto;
}
}



.gp-slide-title {
  height:30px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height:16px;
  margin-bottom:10px;
}



@media (min-width:2200px) {
  .slide {
    /*width: 400px;
    height: 380px;
    margin: 0px 0px 10px 0px;*/
  }

  .gp-slide-img {
    /*height: 350px; width: auto;*/
  }
}

@media (max-width:1800px) {
.slide {
  /*width: 300px;
  height: 360px;
  margin: 0px 0px 10px 0px;*/
}
}


@media (max-width:1600px) {
.slide {
  /*width: 260px;
  height: 360px;
  margin: 0px 0px 10px 0px;*/
}
}

@media (max-width:1400px) {
.slide {
  /*width: 230px;
  height: 300px;
  margin: 0px 0px 10px 0px;*/
}
}

@media (max-width:1300px) {
.slide {
  /*width: 220px;
  height: 300px;
  margin: 0px 0px 10px 0px;*/
}
}


/* Mobile */
@media screen and (max-width:590px) {
  /*.slide {
    width: calc(100vw - 40px);
    margin: 0;
    background-size: calc(100vw - 40px) 220px;
  }*/
  .slide:before {
    background-size: calc(100vw - 40px + 2px) 222px;
}
}

/*.slider-item:hover, .slider-item.selected {
  background-color: #F0EFE2;
}*/


@media screen and (max-width:440px) {
  .slide {
    margin-left: 1px;
    margin-right: 1px;
    /*width: calc(100vw - 222px);
    background-size: calc(100vw - 222px) 222px;*/
  } 
  .slide:before {
    background-size: calc(100vw - 222px + 2px) 222px;
  }
  
}

@media screen and (max-width:400px) {
  .slide {
    margin-left: 1px;
    margin-right: 1px;
    /*width: calc(100vw - 160px);
    background-size: calc(100vw - 160px) 222px;*/
  } 
  .slide:before {
    background-size: calc(100vw - 160px + 2px) 222px;
  }
  
}

@media screen and (max-width:380px) {
  .slide {
    margin-left: 1px;
    margin-right: 1px;
    /*width: calc(100vw - 160px);
    background-size: calc(100vw - 160px) 222px;*/
  } 
  .slide:before {
    background-size: calc(100vw - 160px + 2px) 222px;
  }
}
</style>
