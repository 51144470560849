<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'LangWrapper',
  components: {},
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  created() {

  },
};
</script>

<style scoped>

</style>

