<template>
  <div class="flex flex-col text-medium" style="padding-top:30px">
    <div class="grid grid-cols-2 md:grid-cols-4 gap-2 cart-line-info">
      
      <div class="col-span-1  cart-item-box flex items-center justify-center cart-item-availability" v-if="!availability" :style="{background: availabilityColor}">{{availabilityLabel}}</div>
        <div v-else class="col-span-1"></div>
        <div class="col-span-1"></div>
        <div class="col-span-1"></div>
        <div class="col-span-1"></div>
      
      
      <div class="col-span-1  cart-item-box flex items-center justify-center">
        {{ item.quantity_pcs }} τεμ <!--{{ item.package_description }}-->
      </div>

      <div class="col-span-1 cart-item-box flex items-center justify-center">
        {{ item.package_description }}
      </div>

      <div class="col-span-2 cart-item-box cart-item-box-plus-minus flex items-center justify-center text-medium" style="padding: 0;">
        <div class="review-cart-packages p-3 mr-2" style="width: 50%">
          {{ item.quantity_packages }} {{ $t('labels.packages') }}
        </div>
        <div @click.stop="$emit('add-package', item)"
             style="width: 25%"
             class="review-cart-add flex items-center justify-center h-full py-2-- gp-p-5 px-3 cursor-pointer text-2xl border-l-1 border-r-1 rounded-md">
          +
        </div>
        <div @click.stop="$emit('subtract-package', item)"
             style="width: 25%"
             class="review-cart-minus flex items-center justify-center h-full py-2-- gp-p-5 px-3 cursor-pointer text-2xl rounded-md">
          -
        </div>
      </div>

      

      <div class="col-span-2 cart-item-box flex items-center justify-center">
        {{ $t('labels.initial-price') }}: {{ prettyNum(item.unit_price) }} {{ $t('labels.per') }} {{ item.unit_description }}
      </div>

      <div class="col-span-1 cart-item-box flex items-center justify-center">
        {{ $t('labels.discount') }}: {{ numParse(item.discount_1) }}% 
      </div>

      <div class="col-span-1 cart-item-box flex items-center justify-center">
        {{ $t('labels.final-price') }}: {{ prettyNum(item.final_price2) }}
      </div>

      <div class="col-span-4 grid grid-cols-2 md:grid-cols-3 gap-2">
        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.total-line') }}: {{ prettyNum(item.total_price2) }}
        </div>

        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.vat') }}: {{ prettyNum(item.vat_value2) }}
        </div>

        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.total-w-vat') }}: {{ prettyNum(item.total_price2 + item.vat_value2) }}
        </div>

      </div>

      


    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutCartItemDetails",
  inject: ['prettyNum'],
  emits: ['add-package', 'subtract-package'],
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    availability() {
      if (this.item.inventory_total >= this.item.quantity_pcs) {
        return true;
      }
      else {
        return false;
      }
    },
    availabilityLabel() {
      /*if (this.item.inventory_total >= this.item.quantity_pcs) {
        return "Available";
      }
      else {
        return this.$t('labels.quantity-not-available');
      }*/
      if (this.item.product_type=='fastener') {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
      else if (this.item.product_type=='tool') {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.available-in-15-days');
      }
      else {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
    },
    availabilityColor() {
      if (this.item.inventory_total >= this.item.quantity_pcs) {
        return "green";
      }
      else {
        return "#ff5f00";
      }
    }
  },

  methods: {
    numParse(x) {
      if (x === undefined || x === null) {
        return '';
      }

      if (typeof x === 'number') {
        return x;
      }

      return x; // parseFloat(x.replace('.', ''));
    }
  }
}
</script>

<style scoped>
  /*.cart-item-box {
    border: 1px solid #212121;
    border-radius: .75rem;
    text-align: center;
    width: 100%;
    padding: .75rem;
    font-size:14px;
    line-height:18px;
  }*/

  .cart-item-box {
    border: 1px solid #212121;
    border-radius: .75rem;
    text-align: center;
    width: 100%;
    padding: 12px 5px;
    font-size:14px;
    line-height:18px;
  }

  @media (min-width: 800px) {
    .cart-item-box {
      background-color: #FFFCF0;
    }

    .checkout-item:hover .cart-item-box,
    .checkout-item:focus .cart-item-box,
    .checkout-item:active .cart-item-box {
      color: #212121;
    }

    .cart-item-box-plus-minus {
      background-color: #E6E5DA;
    }

    .review-cart-packages {
      border-right: 1px solid #212121;
      border-radius: .75rem;
      background-color: #FFFCF0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .review-cart-add {
      background-color: #E6E5DA;
      border-left: 0;
    }

    .review-cart-minus {
      background-color: #EFEFE2;
      border-top-right-radius: .75rem;
      border-bottom-right-radius: .75rem;
    }
  }

  /*.review-cart-add:hover,*/
  /*.review-cart-add:focus,*/
  /*.review-cart-add:active,*/
  /*.review-cart-minus:hover,*/
  /*.review-cart-minus:focus,*/
  /*.review-cart-minus:active {*/
  /*  background-color: #212121;*/
  /*  color: #fff;*/
  /*}*/

  .cart-item-description {
    font-size: 26px;
    font-weight: normal;
  } 


   @media screen and (max-width:800px) {
    .cart-item-box {
      font-size:13px;
      line-height:15px;
      min-height:40px;
    }

    /*.cart-item-description {
      font-size: 20px;
      font-weight: 500;
      padding-left:10px;
    }*/
  }


  .cart-line-info {
    padding-left:60px;
  }

  @media screen and (max-width:800px) {
  .cart-line-info {
    padding-left:0px;
  }  
  }

  @media screen and (max-width:440px) {
    .review-cart-add{background-color: #E5E4D9;}
  }


</style>