<template>
  <transition name="slide-down">
    <div v-if="visible" class="rounded-label col-span-1 border-1 text-14 text-medium text-lineheight-18">
      {{ label }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "MobileFilterTile",
  inject: ['openDialog'],
  props: {
    label: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    //
  },
  methods: {
    //
  },
  created() {
    //
  }
}
</script>

<style scoped>
.rounded-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  text-align: center;
  border-radius: .5rem;
  padding: .75rem;
  cursor: pointer;
  font-weight: 500;
  /*font-size: 1rem;*/
}

.rounded-label.hidden {
  display: none;
}

.rounded-label.green {
  background-color: #00D491;
}

.rounded-label.black {
  color: #fff;
  background-color: #262626;
}

.rounded-label.yellow {
  background-color: #E4C963;
}
</style>